/** @format */

import { forwardRef, memo } from 'react';
import { StyledSelect, StyledOption } from './style';

const Select = forwardRef(
  (
    {
      options,
      placeholder,
      allOption,
      noConnection,
      selectType,
      showTitle,
      noIcon,
      fontSize,
      noOption,
      ...props
    },
    ref
  ) => {
    let ops = options?.length ? options : [];
    return (
      <StyledSelect
        ref={ref}
        fontSize={fontSize}
        suffixIcon={!noIcon}
        popupMatchSelectWidth={false}
        className={selectType}
        placeholder={placeholder || 'Select ...'}
        notFoundContent={'No data found!'}
        {...props}
      >
        {[
          allOption === 'true'
            ? { key: 'All', value: '', id: '' }
            : noOption === 'true'
            ? { key: '--', value: '', id: '' }
            : noConnection === 'true'
            ? { key: 'No connections', value: '', id: '' }
            : { key: 'not-object' },
          ...ops,
        ]?.map((o, i) => {
          return o?.key !== 'not-object' ? (
            <StyledOption
              fontSize={fontSize}
              id={o?.id || i}
              key={i}
              value={o?.value}
              title={showTitle ? o?.value : ''}
            >
              {o?.key}
            </StyledOption>
          ) : null;
        })}
      </StyledSelect>
    );
  }
);
Select.displayName = 'Select';
export default memo(Select);
