import styled from 'styled-components';

export const ErrorMessage = styled.div`
  position: absolute;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 11px;
  color: #ff0000;
  bottom: -12px;
  white-space: nowrap;
`;
