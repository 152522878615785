/** @format */

import { Input as AntInput } from '../../AntDesign';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router';
import { capitalizeWords } from 'formatter';

import { replaceParams, useSearch } from 'hooks';

const Input = styled(AntInput)`
  box-sizing: border-box;
  width: 100%;
  height: var(--appFilterHeight);
  border-radius: var(--appFilterBorderRadius);
  font-size: var(--appFilterFontSize);
  ${({ controls }) => {
    if (controls === false) {
      return `
  input[type='number'] {
    -moz-appearance: textfield;
  }
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }`;
    }
  }}
`;

export const InputFilter = (
  key,
  placeholder,
  type,
  prefix,
  suffix,
  upperCase,
  inputProps
) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const query = useSearch();
  const onChange = (event) => {
    if (event.target?.value === '' && !event.target?.value) {
      let value =
        upperCase === 'upperCase'
          ? event?.target?.value?.replaceAll(' ', '_')?.toUpperCase()
          : event?.target?.value;
      navigate(`${pathname}${replaceParams(key, value)}`);
    }
  };

  const onKeyDown = (e) => {
    if (e?.code === 'Enter' || e?.code === 'NumpadEnter') {
      let value =
        upperCase === 'upperCase'
          ? e?.target?.value?.replaceAll(' ', '_')?.toUpperCase()
          : e?.target?.value;
      navigate(`${pathname}${replaceParams(key, value)}`);
      navigate(`${pathname}${replaceParams('page', '')}`);
    }
  };

  return (
    <Input
      name={key}
      type={type || 'text'}
      defaultValue={
        upperCase === 'upperCase'
          ? capitalizeWords(query.get(key))
          : query.get(key)
      }
      onChange={onChange}
      onKeyDown={onKeyDown}
      suffix={suffix}
      prefix={prefix}
      placeholder={placeholder}
      {...inputProps}
    />
  );
};

export default InputFilter;
