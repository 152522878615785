/** @format */

import React, { forwardRef } from 'react';
import { AntAutoComplete } from './style';

const CellAutocomplete = forwardRef(({ options, ...props }, ref) => {
  return (
    <AntAutoComplete
      allowClear
      className={props?.autocompleteType}
      popupMatchSelectWidth={false}
      placeholder={''}
      options={options || []}
      notFoundContent={props?.notFoundContent || 'No data found!'}
      filterOption={(inputValue, option) =>
        option?.value?.toUpperCase()?.indexOf(inputValue?.toUpperCase()) !== -1
      }
      ref={ref}
      {...props}
    />
  );
});
CellAutocomplete.displayName = 'Autocomplete';
export default React.memo(CellAutocomplete);
