import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  padding: ${({ padding }) => padding || '0 32px'};
`;

export const ItemWrap = styled.div`
  width: 100%;
  padding: ${({ col }) => (col ? '0 0 0 15px' : '0 15px 0 0')};
  border-right: ${({ first }) => first && '1px solid #dadada'};
  font-size: 12px;
  font-weight: 600;
`;

ItemWrap.TextArea = styled('textarea')`
  width: 100%;
  min-height: 70px;
  font-size: 16px;
  line-height: 18px;
  border: 0.4px solid #d9d9d9;
  border-radius: 2px;
  padding: 5px;
  outline: none;
  ::placeholder {
    color: #d9d9d9;
    font-size: 14px;
  }
`;
ItemWrap.Row = styled.div`
  display: grid;
  grid-template-columns: ${({ upload }) => (upload ? '1fr' : '1fr 1fr')};
  grid-gap: ${({ nogap }) => (nogap ? '0px' : '8px')};
  width: 100%;
  align-items: ${({ col }) => (col ? 'flex-start' : 'center')};
`;
