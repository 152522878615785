/** @format */

import styled from 'styled-components';

export const SkeletonContainer = styled.div`
  display: flex;
  gap: ${({ gap }) => gap || '8px'};
  height: ${({ height }) => height || '32px'};
`;

SkeletonContainer.Element = styled.div`
  width: ${({ width }) => width || '100px'};
  min-width: ${({ width }) => width || '100px'};
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;
  border-radius: ${({ radius }) => radius || '3px'};

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.5) 20%,
      rgba(255, 255, 255, 0.7) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
`;
