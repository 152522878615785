/** @format */

import { useNavigate, useLocation } from 'react-router';
import { replaceParams, useSearch } from 'hooks';
import { AntdPagination, AntdSpace } from './style';
import { setCookie } from 'formatter';
export default function Pagination({
  length,
  total,
  spaceMargin,
  defaultCurrent,
  current,
  defaultPageSize,
  pageSize,
}) {
  // Hooks
  const query = useSearch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // Handlers
  const handleShowSizeChange = (current, size) => {
    navigate(`${pathname}${replaceParams('page', current)}`);
    navigate(`${pathname}${replaceParams('size', size)}`);
    setCookie('size', size);
  };
  const handlePageChange = (n) => {
    navigate(`${pathname}${replaceParams('page', n - 1)}`);
  };

  // Render
  if (length <= 0) return null;
  return (
    <AntdSpace margin={spaceMargin}>
      <AntdPagination
        defaultCurrent={+query.get('page') || defaultCurrent || 1}
        current={+query.get('page') + 1 || current || 1}
        total={total}
        defaultPageSize={+query.get('size') || defaultPageSize || 50}
        showSizeChanger
        onShowSizeChange={handleShowSizeChange}
        pageSizeOptions={['10', '20', '50', '75', '100']}
        pageSize={+query.get('size') || pageSize || 50}
        onChange={handlePageChange}
      />
    </AntdSpace>
  );
}
