/** @format */

import { forwardRef, memo } from 'react';
import { StyledSelect, StyledOption } from './style';

const CellSelect = forwardRef(
  ({ options, allOption, selectType, showTitle, fsize, ...props }, ref) => {
    let ops = options?.length ? options : [];
    return (
      <StyledSelect
        ref={ref}
        popupMatchSelectWidth={false}
        className={selectType}
        placeholder={''}
        notFoundContent={props?.notFoundContent || 'No data found!'}
        popupClassName='cell-select-options-box'
        fsize={fsize}
        {...props}
      >
        {[
          allOption === 'true'
            ? { key: 'All', value: '', id: '' }
            : { key: 'not-object' },
          ...ops,
        ]?.map((o, i) => {
          return o?.key !== 'not-object' ? (
            <StyledOption
              id={o?.id || i}
              key={i}
              value={o?.value}
              title={showTitle ? o?.value : ''}
              fsize={fsize}
            >
              {props?.isValueOption ? o?.value : o?.key}
            </StyledOption>
          ) : null;
        })}
      </StyledSelect>
    );
  }
);
CellSelect.displayName = 'Select';
export default memo(CellSelect);
