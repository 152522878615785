/** @format */

export const DataRenderer = ({ data }, keyName, keyNameTotal) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        height: '100%',
      }}
      title={data?.[keyNameTotal]}
    >
      {keyName === 'mile' ? `${data?.[keyName]}  mi` : data?.[keyName]}
    </div>
  );
};
