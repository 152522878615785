/** @format */

export const initialState = {
  dispatchers: [],
  refetch: () => {},
  fetching: false,
  liveRankingModal: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'setLiveRankingDrawer':
      return { ...state, liveRankingModal: action.payload };
    case 'refetch':
      return { ...state, refetch: action.payload };
    case 'setDispatchers':
      return { ...state, dispatchers: action.payload };
    case 'UPDATE_DRIVER_AMOUNT': {
      const { currentNum, unitId, nameId } = action.payload;
      const updatedDispatchers = state.dispatchers.map((dispatcher) => {
        const updatedDrivers = dispatcher.drivers.map((driver) => {
          if (driver.unitId !== unitId) return driver;
          const updatedAmounts = { ...driver.amount, [nameId]: currentNum };
          const totalAmount = Object.values(updatedAmounts).reduce(
            (sum, num) => {
              return typeof num === 'number' && !isNaN(num) ? sum + num : sum;
            },
            0
          );
          const updatedList = driver.list?.map((item) => ({
            ...item,
            amount8: item.amount8 !== currentNum ? currentNum : item.amount8,
          }));
          return {
            ...driver,
            amount: updatedAmounts,
            totalAmount,
            list: updatedList,
          };
        });
        return { ...dispatcher, drivers: updatedDrivers };
      });
      return { ...state, dispatchers: updatedDispatchers };
    }

    case 'UPDATE_DRIVER_MILEAGE': {
      const { currentNum, unitId, nameId } = action.payload;
      const updatedDispatchers = state.dispatchers.map((dispatcher) => {
        const updatedDrivers = dispatcher.drivers.map((driver) => {
          if (driver.unitId !== unitId) return driver;
          const updatedMileages = { ...driver.mileage, [nameId]: currentNum };

          const totalMileage = Object.values(updatedMileages).reduce(
            (sum, num) => {
              return typeof num === 'number' && !isNaN(num) ? sum + num : sum;
            },
            0
          );

          const updatedList = driver.list?.map((item) => ({
            ...item,
            mileage8: item.mileage8 !== currentNum ? currentNum : item.mileage8,
          }));

          return {
            ...driver,
            mileage: updatedMileages,
            totalMileage,
            list: updatedList,
          };
        });

        return { ...dispatcher, drivers: updatedDrivers };
      });

      return { ...state, dispatchers: updatedDispatchers };
    }

    case 'UPDATE_DRIVER_ADDRESS': {
      const { newAddress, unitId, nameId } = action.payload;

      const updatedDispatchers = state.dispatchers.map((dispatcher) => {
        const updatedDrivers = dispatcher.drivers.map((driver) => {
          if (driver.unitId !== unitId) return driver;

          const updatedAddress = { ...driver.address, [nameId]: newAddress };

          const updatedList = driver.list?.map((item) => ({
            ...item,
            [nameId]: item[nameId] !== newAddress ? newAddress : item[nameId],
          }));

          return { ...driver, address: updatedAddress, list: updatedList };
        });

        return { ...dispatcher, drivers: updatedDrivers };
      });

      return { ...state, dispatchers: updatedDispatchers };
    }

    case 'UPDATE_DRIVER_ADDRESS_COLOR': {
      const { newColor, unitId, nameId } = action.payload;

      const updatedDispatchers = state.dispatchers.map((dispatcher) => {
        const updatedDrivers = dispatcher.drivers.map((driver) => {
          if (driver.unitId !== unitId) return driver;

          const updatedAddress = { ...driver.address, [nameId]: newColor };

          const updatedList = driver.list.map((item, index) => {
            return index === 2 ? { ...item, [nameId]: newColor } : item;
          });

          return { ...driver, address: updatedAddress, list: updatedList };
        });

        return { ...dispatcher, drivers: updatedDrivers };
      });

      return { ...state, dispatchers: updatedDispatchers };
    }

    case 'UPDATE_DRIVER_CALCULATE_RANK': {
      const { unitId, checked, dispatcherId } = action.payload;
      const updatedDispatchers = state.dispatchers.map((dispatcher) => {
        return {
          ...dispatcher,
          drivers: dispatcher.drivers.map((driver) =>
            driver.unitId === unitId && driver.dispatcherId === dispatcherId
              ? { ...driver, calculateRank: checked }
              : driver
          ),
        };
      });
      return { ...state, dispatchers: updatedDispatchers };
    }
    case 'UPDATE_DRIVER_DAYS': {
      const { unitId, days } = action.payload;

      const updatedDispatchers = state.dispatchers.map((dispatcher) => {
        let changesMade = false;

        const updatedDrivers = dispatcher.drivers.map((driver) => {
          if (driver.unitId === unitId) {
            changesMade = true;
            return { ...driver, days: Number(days) };
          }
          return driver;
        });

        return changesMade
          ? { ...dispatcher, drivers: updatedDrivers }
          : dispatcher;
      });

      return updatedDispatchers.some(
        (dispatcher, idx) => dispatcher !== state.dispatchers[idx]
      )
        ? { ...state, dispatchers: updatedDispatchers }
        : state;
    }

    case 'UPDATE_DRIVER_MANAGERS_COMMENT': {
      const { unitId, comment } = action.payload;

      const updatedDispatchers = state.dispatchers.map((dispatcher) => {
        let isUpdated = false;
        const updatedDrivers = dispatcher.drivers.map((driver) => {
          if (driver.unitId === unitId && driver.comment !== comment) {
            isUpdated = true;
            return { ...driver, comment: comment };
          }
          return driver;
        });

        return isUpdated
          ? { ...dispatcher, drivers: updatedDrivers }
          : dispatcher;
      });

      return updatedDispatchers.some(
        (dispatcher, idx) => dispatcher !== state.dispatchers[idx]
      )
        ? { ...state, dispatchers: updatedDispatchers }
        : state;
    }

    case 'TRANSFORM_AND_SET_DATALIST': {
      const { dataList, dateFrom } = action.payload;

      const transformedDispatchers = transformData(dataList, dateFrom);
      return { ...state, dispatchers: transformedDispatchers };
    }
    case 'LOADING_WHILE_TRANSFORMATION': {
      return { ...state, fetching: action.payload };
    }
    default:
      return state;
  }
};
function transformData(dataList, dateFrom) {
  const keys = ['amount', 'mileage', 'address'];
  return dataList.map((dispatcherData) => {
    const transformedDrivers = dispatcherData.drivers.map((driver) => {
      const listItems = keys.map((key) => ({
        ...driver[key],
        name: key,
        calculate: key !== 'address' ? 0 : '',
        dateFrom,
        unitId: driver.unitId,
        totalAmount: driver.totalAmount,
        totalMileage: driver.totalMileage,
        rate: driver.rate,
        truckNumber: dispatcherData.truckNumber,
        dispatcherId: dispatcherData.dispatcherTeamData.dispatcherId,
        dispatcher: dispatcherData.dispatcherTeamData.dispatcher,
        office: dispatcherData.dispatcherTeamData.office,
        teamId: dispatcherData.dispatcherTeamData.teamId,
        ...(key !== 'address' && {
          driverName: driver.driverName,
          coDriverName: driver.coDriverName,
          truckNumber: dispatcherData.truckNumber,
          dateFrom,
        }),
      }));

      return {
        ...driver,
        dispatcherId: dispatcherData.dispatcherTeamData.dispatcherId,
        list: listItems,
      };
    });

    return {
      ...dispatcherData,
      drivers: transformedDrivers,
    };
  });
}
