/** @format */

import { dayjs, isoWeek } from 'day-js';
dayjs.extend(isoWeek);
import { getCookie } from 'formatter';

export const initialState = {
  page: 1,
  size: 50,
  total: 0,
  data: [],
  statisticsList: [],
  loads: [],
  drivers: [],
  filtered: [],
  weekOptions: [],
  isLoading: false,
  loadDrawer: false,
  loadType: 'company',
  teamsType: 'company',
  loadNumber: '',
  interval: '',
  draverUnitView: false,
  changedAmount: 0,
  changedMileage: 0,
  refetch: () => {},
  fetching: false,
  liveRankingModal: false,
  currentWek: dayjs().isoWeek(),
  selectedMonth:
    getCookie('selectedMonth') || dayjs(getCookie('dateFrom'))?.format('MMMM'),
  year: 2024,
  years: [{ key: 2024, value: 2024 }],
  teams: [],
  teamMembers: [],
  selectedWeeks: [
    `Week-${dayjs().isoWeek()} / ${dayjs()
      .startOf('isoWeek')
      .format('MMMM DD')} - ${dayjs().endOf('isoWeek').format('MMMM DD')}`,
  ],
  dateRange: [
    dayjs().startOf('isoWeek').format('YYYY-MM-DD'),
    dayjs().endOf('isoWeek').format('YYYY-MM-DD'),
  ],
  defaultWeekKey: `Week-${dayjs().isoWeek()} / ${dayjs()
    .startOf('isoWeek')
    .format('MMMM DD')} - ${dayjs().endOf('isoWeek').format('MMMM DD')}`,
  companyState: 'Company Drivers',
  ownerState: 'Owner Drivers',
  nightDisState: getCookie('load-type') || 'DEDICATED',
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'setYear':
      return { ...state, year: action.payload };
    case 'setLoadType':
      return { ...state, loadType: action.payload };
    case 'setTeamsType':
      return { ...state, teamsType: action.payload };
    case 'setDateRange':
      return { ...state, dateRange: action.payload };
    case 'setSelectedMonth':
      return { ...state, selectedMonth: action.payload };
    case 'setSelectedYear':
      return { ...state, year: action.payload };
    case 'setTeams':
      return { ...state, teams: action.payload };
    case 'setTeamMembers':
      return { ...state, teamMembers: action.payload };
    case 'setWeekOptions':
      return { ...state, weekOptions: action.payload };
    case 'setSelectedWeeks':
      return { ...state, selectedWeeks: action.payload };
    case 'setCompanyState':
      return { ...state, companyState: action.payload };
    case 'setOwnerState':
      return { ...state, ownerState: action.payload };
    case 'setNightDisState':
      return { ...state, nightDisState: action.payload };
    case 'changePage':
      return { ...state, page: action.payload };
    case 'setDrivers':
      return { ...state, drivers: action.payload };
    case 'setLiveRankingDrawer':
      return { ...state, liveRankingModal: action.payload };
    case 'refetch':
      return { ...state, refetch: action.payload };
    case 'isFetching':
      return { ...state, fetching: action.payload };
    case 'setChangedMileage':
      return { ...state, changedMileage: action.payload };
    case 'setChangedAmount':
      return { ...state, changedAmount: action.payload };
    case 'setSize':
      return { ...state, size: action.payload };
    case 'setTotal':
      return { ...state, total: action.payload };
    case 'setData':
      return { ...state, data: action.payload };
    case 'setStatisticsList':
      return { ...state, statisticsList: action.payload };
    case 'setLoads':
      return { ...state, loads: action.payload };
    case 'setLoading':
      return { ...state, isLoading: action.payload };
    case 'setFiltered':
      return { ...state, filtered: action.payload };
    case 'setLoadNumber':
      return { ...state, loadNumber: action.payload };
    case 'setInterval':
      return { ...state, interval: action.payload };
    case 'closeLoadDrawer':
      return { ...state, loadDrawer: false };
    case 'openLoadDrawer':
      return { ...state, loadDrawer: action.payload };
    case 'setUnitId':
      return { ...state, unitId: action.payload };

    case 'setDraverUnitView':
      return { ...state, draverUnitView: action.payload };
    default:
      return state;
  }
};
