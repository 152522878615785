export const initialState = {
  // boolean

  drawerOpen: false,
  isLoading: false,
  // strings
  madalName: '',
  // objects
  permission: {},
  usersList: [],
  user: {},

  roles: [],
  fleetRoles: [],
  dsRoles: [],
  qcRoles: [],
  // numbers
  total: 0,
  // functions
  refetch: null,
  saveFunction: () => {},
  onCancelFunction: () => {},
  stateChange: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'setSaveFunction':
      return { ...state, saveFunction: action.payload };
    case 'setOnCancelFunction':
      return { ...state, onCancelFunction: action.payload };
    case 'setStateChange':
      return { ...state, stateChange: action.payload };
    // setting booleans

    case 'setDrawerOpen':
      return { ...state, drawerOpen: action.payload };
    case 'setLoading':
      return { ...state, isLoading: action.payload };
    // setting strings
    case 'setModalName':
      return { ...state, modalName: action.payload };

    // setting objects
    case 'setUsers':
      return { ...state, usersList: action.payload };
    case 'setUser':
      return { ...state, user: action.payload };
    case 'setFiltered':
      return { ...state, filtered: action.payload };
    case 'setPermission':
      return { ...state, permission: action.payload };
    case 'setRoles':
      return { ...state, roles: action.payload };
    case 'setFleetRoles':
      return { ...state, fleetRoles: action.payload };
    case 'setDsRoles':
      return { ...state, dsRoles: action.payload };
    case 'setQcRoles':
      return { ...state, qcRoles: action.payload };
    // setting numbers
    case 'setTotal':
      return { ...state, total: action.payload };
    // setting functions
    case 'setRefetch':
      return { ...state, refetch: action.payload };
    default:
      return state;
  }
};
