/** @format */

import { Suspense, lazy, useEffect } from 'react';
import { useAuth, useLoads } from 'context';
import { Loading } from 'loaders';
import PageWrapperWithCountedFilter from './PageWrapperWithCountedFilter';
const Planning = lazy(() => import('./Planning'));

export default function Loads() {
  // context
  const [, dispatch] = useLoads();
  const [authState] = useAuth();
  // getting permission
  const pms = authState?.permission?.['Load Group']?.['Load'];
  useEffect(() => {
    if (pms) {
      dispatch({ type: 'setPermission', payload: pms });
    }
  }, [pms?.toString()]);

  return (
    <PageWrapperWithCountedFilter>
      <Suspense fallback={<Loading />}>
        <Planning />
      </Suspense>
    </PageWrapperWithCountedFilter>
  );
}
