import { dayjs, isoWeek } from 'day-js';
dayjs.extend(isoWeek);

/**
 * Calculates the start date based on the specified type and value.
 *
 * @param {string} type - The type of period ("week" or "day").
 * @param {number} value - The value to subtract for calculating the start date.
 * @returns {string|null} The start date in "YYYY-MM-DD" format, or null if input is invalid.
 * @throws {TypeError} Thrown if the input is not a valid number.
 *
 * @example
 * // Get the start date of the current week
 * const result1 = getStartDate('week', 0);
 * console.log(result1); // e.g., "2024-06-10"
 *
 * @example
 * // Get the start date of the previous week
 * const result2 = getStartDate('week', 1);
 * console.log(result2); // e.g., "2024-06-03"
 *
 * @example
 * // Get the start date of the current day
 * const result3 = getStartDate('day', 0);
 * console.log(result3); // e.g., "2024-06-06"
 *
 * @example
 * // Handle invalid type
 * const result4 = getStartDate('month', 1); // Throws a TypeError
 *
 * @example
 * // Handle invalid value (null)
 * const result5 = getStartDate('week', null); // Returns null
 */
function getStartDate(type, value) {
  // Check if type is provided and valid
  if (type !== 'week' && type !== 'day') {
    throw new Error('Invalid type. Type should be either "week" or "day".');
  }

  // Check if value is provided and is a number
  if (typeof value !== 'number' || isNaN(value) || !isFinite(value)) {
    return null;
  }

  // Get the current date
  const today = dayjs();

  // Calculate and return the start date based on the type
  if (type === 'week') {
    // Subtract the specified number of weeks and get the start of that week
    return dayjs(today.subtract(value, 'week').startOf('isoWeek'))?.format(
      'YYYY-MM-DD'
    );
  } else {
    // Get the start of the current week and subtract the specified number of days
    const startOfWeek = today.startOf('isoWeek');
    return dayjs(startOfWeek.subtract(value, 'day'))?.format('YYYY-MM-DD');
  }
}

export { getStartDate };
