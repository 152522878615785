/** @format */

export const initialState = {
  page: 1,
  size: 50,
  total: 0,
  dataList: [],
  data: [],
  id: '',
  unitId: '',
  drawerOpenDriver: false,
  driverInfo: [],
  drivers: [],
  driverLoading: false,
  driverRefetch: () => {},
  driverId: '',
  refetch: () => {},
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'changePage':
      return { ...state, page: action.payload };
    case 'setSize':
      return { ...state, size: action.payload };
    case 'setTotal':
      return { ...state, total: action.payload };
    case 'setDataList':
      return {
        ...state,
        dataList: action.payload,
      };
    case 'setData':
      return { ...state, data: action.payload };
    case 'setRefetch':
      return { ...state, refetch: action.payload };
    case 'setDrivers':
      return { ...state, drivers: action.payload };
    case 'setId':
      return { ...state, id: action.payload };
    case 'setUnitId':
      return { ...state, unitId: action.payload };
    case 'isLoading':
      return { ...state, reload: action.payload };
    case 'setDriverInfo':
      return { ...state, driverInfo: action.payload };
    case 'setDriverLoading':
      return { ...state, driverLoading: action.payload };
    case 'setDriverRefetch':
      return { ...state, driverRefetch: action.payload };
    case 'setDriverId':
      return { ...state, driverId: action.payload };
    default:
      return state;
  }
};
