/** @format */

import styled from 'styled-components';
import { ReactComponent as Attachment } from 'assets/icons/attachment.svg';
import { ReactComponent as Delete } from 'assets/icons/delete.svg';

export const UploaderComponent = styled.div`
  position: relative;
  height: ${({ height }) => (height ? height : '120px')};
  width: ${({ width }) => (width ? width : '150px')};
  border: ${({ isLoading }) =>
    isLoading ? '2px dashed #898A8B' : '2px dashed #0363ff'};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '2px')};
  margin: 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f6ff;

  &:hover,
  &:focus {
    opacity: 0.8;
  }
`;

UploaderComponent.Label = styled.div`
  text-align: center;
  color: #d9d9d9;
  font-weight: 600;
  padding: 10px;
`;

UploaderComponent.Text = styled('p')`
  font-size: 14px;
  color: #6b7280;
  font-weight: 400;
  font-family: var(--appPrimaryFont);
  margin-bottom: 0;
`;

UploaderComponent.Input = styled('input')`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

UploaderComponent.Preview = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

UploaderComponent.Wrapper = styled.div`
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
`;

UploaderComponent.PreviewTitle = styled('p')`
  font-weight: 500;
  margin-bottom: 20px;
`;

UploaderComponent.PreviewItem = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
  overflow: hidden;
  /* margin-bottom: 10px; */
  background-color: #5c6470;
  padding: 9px;
  margin-right: 10px;
  border-radius: 10px;
`;

UploaderComponent.PreviewInfo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
`;

UploaderComponent.InfoText = styled.div`
  width: 100%;
  font-size: 14px;
  color: #fff;
  margin: -5px;
  div.turncate {
    width: calc(100%);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
  }
`;

export const AttachmentIcon = styled(Attachment)`
  width: 16px;
  height: 16px;
  margin-bottom: 8px;
  path {
    fill: #fff;
  }
`;

export const ButtonBox = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DeleteIcon = styled(Delete)`
  width: 14px;
  height: 14px;
  position: absolute;
  top: 6px;
  right: -4px;
  transform: translateX(-50%);
  cursor: pointer;
  path {
    fill: red;
  }
`;
