/** @format */

export const initialState = {
  reloadFunc: null,
  permission: {},

  modalName: '',
  drawerOpen: false,
  drawerNote: false,
  drawerService: false,
  maintenListItem: {},
  serviceItem: {},
  maintenList: [],
  truck: {},
  truckNumber: '',
  truckNumberList: [],
  page: 1,
  size: 50,
  total: 0,
  drivers: [],
  filtered: {},
  isLoading: false,
  attachments: [],
  inspectorName: [],
  categoriesList: [],
  responsibleList: [],
  truckShop: [],
  users: [],
  fetchCategories: null,
  refetch: () => { },
  onSaveFn: () => { },
  onCancelFn: () => { },
  stateChange: false,
  serviceCountRefetch: () => { },
  resultCountRefetch: () => { },

  truckNumberAndIdOptions: [],
  trailerResultantStatusOptions: [],
  userOptions: [],
  responsibleNameAndIdOptions: [],
  parentCategoryNameAndIdOptions: [],
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'setDocs': {
      if (action.own === 'true') {
        return {
          ...state,
          attachments: action.payload,
        };
      }

      let formattedData = action?.payload?.map((d) => {
        return {
          attachmentId: d?.attachmentId || d.uploadedFile?.attachmentId,
          date: d?.date,
          docTypeId: d?.docTypeId,
          name: d?.name,
          notes: d?.notes,
          extension: d?.extension,
          path: d?.path || d?.uploadedFile?.path,
          size: d?.size,
          uploadedFile: d?.uploadedFile || [],
          createdAtDate: d?.createdAtDate,
          createdByNickname: d?.createdByNickname,
          note: d?.note,
        };
      });

      formattedData = state?.attachments?.length
        ? formattedData?.concat(state?.attachments)
        : formattedData;

      return {
        ...state,
        attachments: formattedData,
      };
    }

    case 'setReloadFunc':
      return { ...state, reloadFunc: action.payload };
    case 'setRefetch':
      return { ...state, refetch: action.payload };
    case 'setPermission':
      return { ...state, permission: action.payload };
    case 'setServiceStatusRefetch':
      return { ...state, serviceCountRefetch: action.payload };
    case 'setResultStatusRefetch':
      return { ...state, resultCountRefetch: action.payload };
    case 'setModalName':
      return { ...state, modalName: action.payload };
    case 'setDrawerOpen':
      return { ...state, drawerOpen: action.payload };
    case 'setDrawerService':
      return { ...state, drawerService: action.payload };
    case 'setDrawerNote':
      return { ...state, drawerNote: action.payload };
    case 'setMaintenList':
      return { ...state, maintenList: action.payload };
    case 'setMaintenListItem':
      return { ...state, maintenListItem: action.payload };
    case 'setServiceItem':
      return { ...state, serviceItem: action.payload };
    case 'setTPage':
      return { ...state, page: action.payload };
    case 'setTSize':
      return { ...state, size: action.payload };
    case 'setTotal':
      return { ...state, total: action.payload };
    case 'fetchCategories':
      return { ...state, fetchCategories: action.payload };
    case 'setTruck':
      return { ...state, truck: action.payload };
    case 'setTruckNumber':
      return { ...state, truckNumber: action.payload };
    case 'setTruckNumberList':
      return { ...state, truckNumberList: action.payload };
    case 'setLoading':
      return { ...state, isLoading: action.payload };
    case 'setFiltered':
      return { ...state, filtered: action.payload };
    case 'setInspectorName':
      return { ...state, inspectorName: action.payload };
    case 'setCategoryList':
      return { ...state, categoriesList: action.payload };
    case 'setResponsible':
      return { ...state, responsibleList: action.payload };
    case 'setTruckShop':
      return { ...state, truckShop: action.payload };
    case 'setUsers':
      return { ...state, users: action.payload };
    case 'setSaveFunction':
      return { ...state, saveFunction: action.payload };
    case 'setOnCancelFunction':
      return { ...state, onCancelFunction: action.payload };
    case 'setStateChange':
      return { ...state, stateChange: action.payload };
    case 'setTruckNumberAndIdOptions':
      return { ...state, truckNumberAndIdOptions: action.payload };
    case 'setTrailerResultantStatusOptions':
      return { ...state, trailerResultantStatusOptions: action.payload };
    case 'setUserOptions':
      return { ...state, userOptions: action.payload };
    case 'setResponsibleNameAndIdOptions':
      return { ...state, responsibleNameAndIdOptions: action.payload };
    case 'setParentCategoryNameAndIdOptions':
      return { ...state, parentCategoryNameAndIdOptions: action.payload };

    default:
      return state;
  }
};
