/** @format */

export const initialState = {
  warningMaintenanceList: [],
  warningRegCardList: [],
  warningInspectionList: [],
  inspectionStatus: '',
  regCardStatus: '',
  maintenanceStatus: '',
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'setWarningMaintenanceList':
      return { ...state, warningMaintenanceList: action.payload };
    case 'setWarningRegCardList':
      return { ...state, warningRegCardList: action.payload };
    case 'setWarningInspectionList':
      return { ...state, warningMaintenanceList: action.payload };
    case 'setInspectionsWarning':
      return { ...state, inspectionStatus: action.payload };
    case 'setRegCardWarning':
      return { ...state, regCardStatus: action.payload };
    case 'setMaintenanceWarning':
      return { ...state, maintenanceStatus: action.payload };
    default:
      return state;
  }
};
