/** @format */

import styled from 'styled-components';
import { DatePicker } from '../../AntDesign';
import { InputLabel } from 'globalCSS';

export const MainDatePickerWrapper = styled.div`
  width: ${({ width }) => width || '100%'};
  .ant-picker {
    border-color: ${({ bordercolor }) => bordercolor || '#C4C4C4'};
    width: ${({ width }) => width || '100%'} !important;
    border-radius: ${({ radius }) => radius || '2px'};
  }
`;

export const DatePickerLabel = styled(InputLabel)``;

export const AntDatePicker = styled(DatePicker)`
  &.ant-picker .ant-picker-input {
    display: none !important;
  }
`;
