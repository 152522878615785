/** @format */

import { memo } from 'react';
import { Tooltip } from 'antd';

const CustomTooltip = ({ children, title, color, ...props }) => {
  return (
    <Tooltip
      placement='top'
      autoAdjustOverflow={{ adjustY: false, adjustx: true }}
      color={color || '#fff'}
      overlayStyle={props?.overlayStyle || { maxWidth: '100%' }}
      overlayInnerStyle={props?.overlayInnerStyle}
      title={title}
      zIndex={9999}
      {...props}
    >
      {children}
    </Tooltip>
  );
};
export default memo(CustomTooltip);
