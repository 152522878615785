/** @format */

import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';
export const DocTypeContext = createContext();
export const useDocType = () => useContext(DocTypeContext);

export const DocTypeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DocTypeContext.Provider value={[state, dispatch]}>
      {children}
    </DocTypeContext.Provider>
  );
};
