/** @format */

import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';
export const HiringDriverContext = createContext();

export const useHiringDriver = () => useContext(HiringDriverContext);

export const HiringDriverProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <HiringDriverContext.Provider value={[state, dispatch]}>
      {children}
    </HiringDriverContext.Provider>
  );
};
