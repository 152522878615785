/** @format */

import styled from 'styled-components';

const getColor = (status) => {
  let st = status?.toLowerCase();
  switch (st) {
    case 'in_transit':
      return {
        bg: '#bf7ce7',

        color: '#fff',
      };
    case 'possible':
      return {
        bg: '#34B6FA',
        color: '#fff',
      };
    case 'need_to_check':
      return {
        bg: '#34B6FA',
        color: '#fff',
      };
    case 'available':
      return {
        bg: '#31FD8F',
        color: '#fff',
      };
    case 'damaged':
      return {
        bg: '#FD3131',
        color: '#fff',
      };
    case 'gps_issue':
      return {
        bg: '#FD3131',
        color: '#fff',
      };
    case 'loaded':
      return {
        bg: '#F59A46',
        color: '#fff',
      };
    case 'miss_loaded':
      return {
        bg: '#FD3131',
        color: '#fff',
      };
    case 'reserved':
      return {
        bg: '#7B818B',
        color: '#fff',
      };
    default:
      return {
        bg: '#ABAFB3',
        color: '#fff',
      };
  }
};

export const InfoStyle = styled.div`
  background-color: white;
  min-width: 250px;
  width: fit-content;
  min-height: 80px;
`;

InfoStyle.Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  height: 20px;
`;

InfoStyle.Text = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${({ name, fas, driver }) =>
    name ? '#007fba' : fas ? '#000' : driver ? '#777' : '#ACADAE'};
  font-size: ${({ name, fas }) => (name ? '16px' : fas ? '16px' : '12px')};
  font-weight: ${({ name, fas }) => (name ? 600 : fas ? 600 : 500)};
  padding: 1px 10px;
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : '20px')};
  flex-wrap: ${({ loc }) => (loc ? 'wrap' : 'no-wrap')};
  white-space: ${({ loc }) => (loc ? 'wrap' : 'no-wrap')};
  margin-bottom: ${({ loc }) => (loc === 'true' ? '6px' : '0px')};
`;

InfoStyle.Span = styled.div`
  padding: 1px 10px;
  border-radius: 50px;
  font-weight: 500;
  background: ${({ bg }) => getColor(bg).bg || '#c4c4c4'};
  font-size: 13px;
  color: ${({ bg }) => getColor(bg).color || '#fff'};
  width: ${({ trailer }) => trailer && 'fit-content'};
`;
