/** @format */

export const initialState = {
  teamsheetList: [],
  dispatcherTeamsIds: [],
  dispatcherTeam: [],
  page: 1,
  size: 50,
  total: 0,
  dispatcherBoardData: [],
  dispatcherBoardList: [],
  dispatcherBoardTeam: [],
  driversStatus: [],
  driverStatus: [],
  shiftCylceList: [],
  data: [],
  refetch: () => {},
  refresh: '',
  download: '',
  status: '',
  id: '',
  drawerOpen: false,
  drawerName: '',
  teamSheetViewList: [],
  team: [],

  path: 'team',
  loadDrawer: false,
  selectedLoadNumber: {},
  historyList: [],
  historyTitle: '',
  allHistory: false,
  funcGetShiftCycle: '',
  funcGetDrStatus: '',
  trailersList: [],
  facilityNames: [],
  truckNumber: [],
  loadNumber: [],
  drivers: [],
  editHistoryCell: () => {},
  drawerOpenDriver: false,
  driverInfo: [],
  driverLoading: false,
  driverRefetch: () => {},
  driverId: '',
  unitId: '',
  historyQuery: '',
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'setDispatcherTeamsIds':
      return { ...state, dispatcherTeamsIds: action.payload };
    case 'setTeamsheetData':
      return { ...state, teamsheetList: action.payload };
    case 'setDispatcherTeam':
      return { ...state, dispatcherTeam: action.payload };
    case 'setFuncGetDrStatus':
      return { ...state, funcGetDrStatus: action.payload };
    case 'setDispatcherBoardsTeam':
      return { ...state, dispatcherBoardTeam: action.payload };
    case 'setDriversStatus':
      return { ...state, driversStatus: action.payload };
    case 'setData':
      return { ...state, data: action.payload };
    case 'setRefetch':
      return { ...state, refetch: action.payload };
    case 'setDriverStatus':
      return { ...state, driverStatus: action.payload };
    case 'closeDrawer':
      return { ...state, drawerOpen: false };
    case 'openDrawer':
      return { ...state, drawerOpen: true };
    case 'setId':
      return { ...state, id: action.payload };
    case 'setTeam':
      return { ...state, team: action.payload };

    case 'setPath':
      return { ...state, path: action.payload };
    case 'setFilterAll':
      return { ...state, filterAll: action.payload };
    case 'setFilterTeam':
      return { ...state, filterTeam: action.payload };
    case 'setHistoryList':
      return { ...state, historyList: action.payload };
    case 'setHistoryTitle':
      return { ...state, historyTitle: action.payload };
    case 'setDrawerName':
      return { ...state, drawerName: action.payload };
    case 'setTeamSheetViewList':
      return { ...state, teamSheetViewList: action.payload };
    case 'setShiftCycle':
      return { ...state, shiftCylceList: action.payload };
    case 'setFuncGetShiftCycle':
      return { ...state, funcGetShiftCycle: action.payload };
    case 'onDriver':
      return { ...state, allHistory: action.payload };
    case 'closeLoadDrawer':
      return { ...state, loadDrawer: false };
    case 'openLoadDrawer':
      return { ...state, loadDrawer: action.payload };
    case 'setSelectedLoadNumber':
      return { ...state, selectedLoadNumber: { loadNumber: action.payload } };
    case 'setTrailersList':
      return { ...state, trailersList: action.payload };
    case 'setFacilityNames':
      return { ...state, facilityNames: action.payload };
    case 'setTruckNumber':
      return { ...state, truckNumber: action.payload };
    case 'setLoadNumber':
      return { ...state, loadNumber: action.payload };
    case 'setDrivers':
      return { ...state, drivers: action.payload };
    case 'setEditHistoryCell':
      return { ...state, editHistoryCell: action.payload };
    case 'setDrawerOpenDriver':
      return { ...state, drawerOpenDriver: action.payload };
    case 'setDriverInfo':
      return { ...state, driverInfo: action.payload };
    case 'setDriverLoading':
      return { ...state, driverLoading: action.payload };
    case 'setDriverRefetch':
      return { ...state, driverRefetch: action.payload };
    case 'setDriverId':
      return { ...state, driverId: action.payload };
    case 'setUnitId':
      return { ...state, unitId: action.payload };
    case 'setHistoryQuery':
      return { ...state, historyQuery: action.payload };
    default:
      return state;
  }
};
