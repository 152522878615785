/** @format */
import { Card, Button, Typography } from 'antd';
import { FrownOutlined, SyncOutlined } from '@ant-design/icons';
import { getCookie } from 'formatter';
const { Paragraph, Text } = Typography;
import { useAuth } from 'context';
import { useLocation } from 'react-router-dom';

const ErrorBoudaryComponent = ({ error, resetErrorBoundary }) => {
  const [, dispatch] = useAuth();
  const { pathname } = useLocation();
  let mainUrl = getCookie('mainURL') || getCookie('currentPath');
  let currentPath = getCookie('currentPath');
  const handleReset = () => {
    if (mainUrl) {
      resetErrorBoundary && resetErrorBoundary();
      window.location.href = `${mainUrl}`;
    } else {
      resetErrorBoundary && resetErrorBoundary();
      window.location.href = '/signin';
      dispatch({ type: 'logout' });
    }
  };

  const handleUpdate = () => {
    if (currentPath) {
      resetErrorBoundary && resetErrorBoundary();
      window.location.href = `${pathname}`;
    } else if (mainUrl) {
      resetErrorBoundary && resetErrorBoundary();
      window.location.href = `${pathname}`;
    } else {
      resetErrorBoundary && resetErrorBoundary();
      window.location.href = '/signin';
      dispatch({ type: 'logout' });
    }
  };

  return (
    <>
      {error?.message?.includes(
        'Failed to fetch dynamically imported module:'
      ) ? (
        <Card
          style={{
            maxWidth: 600,
            margin: '50px auto',
            borderRadius: 5,
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
            padding: 0,
            height: 215,
            maxHeight: 'fit-content',
          }}
          actions={[
            <Button
              type='primary'
              key='tryagain'
              onClick={handleUpdate}
              icon={<SyncOutlined />}
            >
              Update
            </Button>,
          ]}
        >
          <div
            style={{
              display: 'flex',
              textAlign: 'center',
              marginBottom: '16px',
              fontSize: 20,
              width: '100%',
            }}
          >
            <div style={{ width: '100%', fontSize: 20, fontWeight: 600 }}>
              New Updates Available
            </div>
          </div>
          <Paragraph
            style={{ fontSize: '1.2em', textAlign: 'center', marginBottom: 16 }}
          >
            <Text strong>
              To ensure you have access to the latest features and improvements,
              please press the `Update` button. This action will update your
              application with the most recent enhancements.
            </Text>
          </Paragraph>
        </Card>
      ) : (
        <Card
          style={{
            maxWidth: 600,
            margin: '50px auto',
            borderRadius: 5,
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
            padding: 20,
            height: 275,
            maxHeight: 'fit-content',
          }}
          actions={[
            <Button
              type='primary'
              key='tryagain'
              onClick={handleReset}
              icon={<FrownOutlined />}
            >
              Go to the main page
            </Button>,
          ]}
        >
          <Paragraph
            style={{ fontSize: '1.2em', textAlign: 'center', marginBottom: 16 }}
          >
            <Text strong>We`re sorry for the inconvenience!</Text>
          </Paragraph>
          <Paragraph
            style={{ fontSize: '1.2em', textAlign: 'center', marginBottom: 16 }}
          >
            <Text strong>
              An unexpected error has occurred. Please try again. If the issue
              persists, contact your IT department or the person responsible for
              technical support.
            </Text>
          </Paragraph>
          <Paragraph
            style={{ color: 'red', marginBottom: 0, textAlign: 'center' }}
          >
            {error.message}
          </Paragraph>
        </Card>
      )}
    </>
  );
};

export default ErrorBoudaryComponent;
