/** @format */
const originStyle = {
  whiteSpace: 'wrap',
  textAlign: 'left',
  height: 'inherit',
  display: 'flex',
  alignItems: 'center',
  lineHeight: 1.1,
};
export const DestinationRender = ({ data }) => {
  return (
    <div style={originStyle} title={data?.endLocation}>
      {data?.endCityState}
    </div>
  );
};
