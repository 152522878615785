import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './annualInspectionsReducer';

export const TruckAnnualInspectionsContext = createContext();

export const useTruckAnnualInspections = () =>
  useContext(TruckAnnualInspectionsContext);

export const TruckAnnualInspectionsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <TruckAnnualInspectionsContext.Provider value={[state, dispatch]}>
      {children}
    </TruckAnnualInspectionsContext.Provider>
  );
};
