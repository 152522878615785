/** @format */

import { ReactComponent as Logo } from 'assets/icons/loader-logo.svg';
import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white !important;
`;

export const Preloader = styled(Logo)`
  width: 200px;
  height: auto;
  overflow: visible;
  background: transparent;

  .part-1,
  .part-2 {
    fill: transparent;
    animation: stroke 2.5s ease-in-out infinite 0.5s;
    stroke-width: inside 5;
    stroke-dashoffset: 140;
    stroke-dasharray: 140;
    stroke: var(--color-full);
    --color-full: rgb(1, 122, 255, 1);
    --color-tr: rgb(1, 122, 255, 0);
  }
  .part-2 {
    stroke-dashoffset: -137;
    --color-full: rgb(237, 50, 55, 1);
    --color-tr: rgb(237, 50, 55, 0);
  }

  @keyframes stroke {
    50% {
      fill: var(--color-tr);
    }
    65% {
      fill: var(--color-full);
      stroke-dashoffset: 0;
      stroke: var(--color-full);
    }
    90% {
      fill: var(--color-full);
      stroke-dashoffset: 0;
      stroke: var(--color-full);
      filter: blur(0);
    }
    100% {
      stroke-dashoffset: 0;
      fill: var(--color-tr);
      stroke: var(--color-tr);
      filter: blur(5px);
    }
  }
`;
