import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';

export const OptionsContext = createContext();

export const useOptions = () => useContext(OptionsContext);

const OptionsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <OptionsContext.Provider value={[state, dispatch]}>
      {children}
    </OptionsContext.Provider>
  );
};

export default OptionsProvider;
