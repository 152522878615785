import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './Reducer';

export const TruckWarningContext = createContext();

export const useTruckWarning = () => useContext(TruckWarningContext);

export const TruckWarningProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <TruckWarningContext.Provider value={[state, dispatch]}>
      {children}
    </TruckWarningContext.Provider>
  );
};
