/** @format */

import { gridOpenFilter } from '../Table/filter';
import { FilterIcon, PlusIcon, UploadIcon } from 'styledIcons';
import { Button } from './style';
import { useEffect, useRef } from 'react';
const GenericButton = ({
  children,
  icon,
  btnType,
  name,
  customFilter,
  noText,
  ...props
}) => {
  const filterBtn = useRef(null);
  useEffect(() => {
    props.btnType = 'cancel';
  }, [props.stateChange]);
  const onClickOfFilter = () => {
    if (customFilter) {
      props?.onClick && props?.onClick();
    } else {
      gridOpenFilter();
    }
    filterBtn.current.classList.toggle('active');
  };
  return (
    <>
      {btnType === 'filter' ? (
        <Button
          className={btnType}
          {...props}
          ref={filterBtn}
          onClick={onClickOfFilter}
        >
          <FilterIcon />
          {!noText && <div>Filter</div>}
        </Button>
      ) : btnType === 'add' ? (
        <Button className={btnType} {...props}>
          <PlusIcon />
          {!noText && <div>{name ? name : 'Add'}</div>}
        </Button>
      ) : btnType === 'upload' ? (
        <Button className={btnType} {...props}>
          <UploadIcon color='rgb(1, 122, 255, 1)' />
          {!noText && <div>{name ? name : 'Upload'}</div>}
        </Button>
      ) : btnType === 'next' ? (
        <Button className={btnType} {...props}>
          {!noText && <div>{name ? name : 'Next'}</div>}
        </Button>
      ) : btnType === 'prev' ? (
        <Button className={btnType} {...props}>
          {!noText && <div>{name ? name : 'Previous'}</div>}
        </Button>
      ) : btnType === 'check' ? (
        <Button className={btnType} name={name} {...props}>
          {!noText && <div>{name ? name : 'Check'}</div>}
        </Button>
      ) : btnType === 'save' ? (
        <Button className={btnType} {...props}>
          {!noText && <div>{name ? name : 'Save'}</div>}
        </Button>
      ) : btnType === 'iconic' ? (
        <Button className={btnType} {...props}>
          {icon}
        </Button>
      ) : (
        // this is default button
        <Button noText={noText} className={btnType} {...props}>
          {children}
        </Button>
      )}
    </>
  );
};

export default GenericButton;
