/** @format */

import styled from 'styled-components';
import { Input } from '../../AntDesign';
import { InputLabel } from 'globalCSS';
export const GenericInput = styled.div`
  width: ${({ width }) => width || '100%'};
  min-width: ${(props) => props.minwidth};
  margin: ${({ margin }) => margin};
  height: fit-content;
  display: flex;
  align-items: left;
  flex-direction: column;
`;
export const Label = styled(InputLabel)``;
export const Box = styled.div`
  position: relative;
  width: ${({ width }) => width || '100%'};
  height: fit-content;
  position: relative;
  align-items: center;
  text-align: center;
  margin: ${({ boxMargin }) => boxMargin || '0 0 0 0'};
`;
Box.IconLeft = styled.img`
  width: 16.93px;
  height: 16.93px;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.32;
  cursor: pointer;
`;
Box.IconRight = styled.img`
  width: 16.93px;
  height: 16.93px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.32;
  cursor: pointer;
`;
Box.Color = styled.div`
  position: absolute;
  border-radius: 4px;
  border: 1px solid #d8d9dd;
  z-index: 1;
  top: 13%;
  right: 8px;
  width: 40px;
  height: 24px;
  background: ${({ selectedColor }) =>
    selectedColor ? selectedColor : '#c4c4c4'};
`;

export const AntInput = styled(Input)`
  width: ${({ width }) => width || '100%'};
  position: relative;
  /* max-width: ${({ maxWidth }) => maxWidth}; */
  height: ${({ height }) => height || '32px'};
  background: ${({ bgcolor }) => bgcolor || '#FFFFFF'};
  color: ${({ color }) => color || '#D8D9DD'};
  border-width: ${({ bwidth }) => bwidth || '1px'};
  border-color: ${({ bcolor, bordercolor }) =>
    bordercolor || bcolor || '#D8D9DD'};
  border-style: ${({ bstyle }) => (bstyle ? bstyle : 'solid')};
  border-radius: ${({ radius }) => radius || '2px'};
  font-size: var(--appTableFontSize);
  outline: none;
  padding-left: ${({ align, icon }) =>
    icon ? (align === 'left' ? '30px' : '7px') : '7px'};
  padding-right: ${({ align, icon }) =>
    icon ? (align === 'right' ? '7px' : '30px') : '7px'};
  box-sizing: border-box;
  ::placeholder {
    color: ${({ plcolor }) => plcolor || '#bfbfbf'};
    /* opacity: 0.7; */
  }
  border: ${(props) => props.border || '1px solid #c4c4c4'};
  border-bottom: ${(props) => props.bbottom};
  padding: ${(props) => props.bbottom && 0};
  padding: ${(props) => props.padding};
  min-width: ${({ minwidth }) => minwidth && minwidth};
  opacity: 0;
  z-index: -1;
  :disabled {
    cursor: not-allowed;
  }
`;

export const WrapperInput = styled.div`
  width: ${({ width }) => width || '100%'};
  position: relative;
  height: ${({ height }) => height || '32px'};
  background: ${({ bgcolor }) => bgcolor || '#FFFFFF'};
  color: ${({ color }) => color || '#D8D9DD'};
  border-width: ${({ bwidth }) => bwidth || '1px'};
  border-color: ${({ bcolor, bordercolor }) =>
    bordercolor || bcolor || '#D8D9DD'};
  border-style: ${({ bstyle }) => (bstyle ? bstyle : 'solid')};
  border-radius: ${({ radius }) => radius || '2px'};
  font-size: var(--appTableFontSize);
  outline: none;
  padding-left: ${({ align, icon }) =>
    icon ? (align === 'left' ? '30px' : '7px') : '7px'};
  padding-right: ${({ align, icon }) =>
    icon ? (align === 'right' ? '7px' : '30px') : '7px'};
  box-sizing: border-box;
  ::placeholder {
    color: ${({ plcolor }) => plcolor || '#bfbfbf'};
  }
  border: ${(props) => props.border || '1px solid #c4c4c4'};
  border-bottom: ${(props) => props.bbottom};
  padding: ${(props) => props.bbottom && 0};
  padding: ${(props) => props.padding};
  min-width: ${({ minwidth }) => minwidth && minwidth};
`;

export const TitleColor = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #313e47;
  position: absolute;
  top: 5px;
  left: 15px;
`;
