export const initialState = {
  permission: {},
  list: [],
  total: 0,
  serverNames: [],
  website: '',
  authUrl: '',
  landmarkUrl: '',
  refetch: '',
  drawerOpen: false,
  saveFunction: () => {},
  onCancelFunction: () => {},
  stateChange: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'setPermission':
      return { ...state, permission: action.payload };
    case 'setSaveFunction':
      return { ...state, saveFunction: action.payload };
    case 'setOnCancelFunction':
      return { ...state, onCancelFunction: action.payload };
    case 'setStateChange':
      return { ...state, stateChange: action.payload };
    case 'setServersList':
      return { ...state, list: action.payload };

    case 'setServersTotal':
      return { ...state, total: action.payload };

    case 'setServersName':
      return { ...state, serverNames: action.payload };

    case 'setRefetch':
      return { ...state, refetch: action.payload };
    case 'setDrawerOpen':
      return { ...state, drawerOpen: action.payload };

    default:
      return state;
  }
};
