import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  height: auto;
  border-left: 1px solid #e5e7eb;
`;

Wrapper.Header = styled.div`
  display: grid;
  grid-template-columns: ${({ grid }) => grid};
  background: ${({ background }) => background || '#f1f3f8'};
  padding-left: 1px;
  min-height: 28px;
  height: 28px;
  position: sticky;
  z-index: ${({ z_index }) => (z_index ? z_index : 999)};
  top: ${({ from_top }) => (from_top ? from_top : 0)};
`;

Wrapper.Cell = styled.div`
  text-transform: uppercase;
  line-height: 28px;
  padding: 0 10px;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: ${({ background }) => background || '#f1f3f8'};
  border-left: ${({ border_left }) => border_left || '1px solid #ccc'};
  font-weight: 600;
`;

export const TablesWrapper = styled.div`
  width: 100%;
  overflow-y: scroll;
  height: calc(100% - 30px);
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const TableRow = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #9c9c9c;
  .team-name {
    width: 200px;
    background: #f2f2f2;
    padding: 6px 10px;
    font-weight: 600px;
  }
  .table-box {
    width: calc(100% - 200px);
    height: fit-content
  }
  
`;

export const TotalsRow = styled.div`
  margin: 0 10px 0 0;
`;

export const FilterWrapper = styled.div`
  padding: 0;
  width: 800px;
`;
