/** @format */

import styled from 'styled-components';
import { Tabs } from '../../AntDesign';

export const Tab = styled(Tabs)`
  margin: ${({ margin }) => (margin ? margin : '0 40px 0 15px')};
  padding: ${({ padding }) => (padding ? padding : '0 0 0 0')};
  min-height: ${({ minheight }) => (minheight ? minheight : '740px')};
  width: ${({ width }) => (width ? width : '100%')};

  .ant-tabs-content {
    height: 100%;
    overflow-y: overlay;
    height: calc(100vh - 60px);
    margin: 0;
    padding: 0 1px 0 0;

    &::-webkit-scrollbar {
      width: 6px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #dedede;
      border-radius: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #dfdfdf;
      border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #ccc;
    }
  }
  .ant-tabs-tab-btn {
    outline: none;
    transition: all 0.3s;
    font-size: ${({ fontSize }) => (fontSize ? fontSize : '14px')}!important;
  }
`;
