/** @format */

import React from 'react';

const Trailers = React.lazy(() => import('./Trailers'));
const TrailersMove = React.lazy(() => import('./Moves'));
const TrailerMovement = React.lazy(() => import('./Movement'));
const TrailerInspections = React.lazy(() => import('./Inspection'));
const TrailersRegCard = React.lazy(() => import('./RegCard'));
const TrailerMaintenance = React.lazy(() => import('./Maintenance'));
const TrailerStatistics = React.lazy(() => import('./Statistics'));
export {
  Trailers,
  TrailersMove,
  TrailerMovement,
  TrailerInspections,
  TrailersRegCard,
  TrailerMaintenance,
  TrailerStatistics,
};
