/** @format */
import { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { InputPlaceholder } from '../style';
import { useRequest, useQuery } from 'hooks';

import { useLoads } from 'context';
import { FilterAutocomplete as CellAutocomplete } from 'filters';

import { Toast } from 'modals';
export const CellRenderQc = ({ data, scrollOut }) => {
  const [team, setTeam] = useState(data?.qcTeamName);
  const [{ qcteamsList }, dispatch] = useLoads();
  const [clicked, setClicked] = useState(false);

  const [inUsing, setInUsing] = useState({ in: false, using: false });
  const { request } = useRequest();
  useEffect(() => {
    setTeam(data?.qcTeamName);
  }, [data?.qcTeamName]);
  // driver
  const { mutate } = useMutation({
    mutationFn: (id) => {
      return request({
        url: `/qc/trips/${encodeURIComponent(
          data?.id
        )}/qc/assign?team-id=${id}`,
        method: 'POST',
      });
    },
  });

  const onChange = (key) => {
    setTeam(key?.value);
  };
  const onSelect = (value, opt) => {
    if (value && opt?.id) {
      mutate(opt?.id, {
        onSuccess: (res) => {
          setTeam(opt?.value);
          Toast({
            type: 'success',
            message: res?.message,
          });
        },
        onError: (err) => {
          Toast({
            type: 'error',
            message: err?.message,
          });
        },
      });
    }
  };

  const qcData = useQuery(
    ['/qc-team/number-id/list', clicked || null],
    () =>
      (qcteamsList === undefined || qcteamsList?.length <= 0) &&
      clicked &&
      request({ url: '/qc-team/number-id/list' }),
    {
      onSuccess: (res) => {
        if (res?.success) {
          let newData = res?.dataList?.map((value) => {
            return { id: value?.id, value: value?.name, key: value?.name };
          });
          dispatch({ type: 'setQcteamsList', payload: newData });
        }
      },
      onError: (err) => {
        Toast({
          type: 'error',
          message: err?.message,
        });
      },
    }
  );
  const handleFocus = (e) => {
    setInUsing({ ...inUsing, using: e });
    if (qcteamsList === undefined || qcteamsList?.length <= 0) {
      setClicked(true);
      qcData?.refetch();
    }
  };
  const onLeaveMouse = (e) => {
    e.stopPropagation();
    e.target.closest('.new-table-cell') !== document.activeElement &&
      e.target.closest('.new-table-cell') &&
      e.target.closest('.new-table-cell').classList.contains('active-cell') &&
      e.target.closest('.new-table-cell').classList.remove('active-cell');
    setInUsing({ ...inUsing, in: false });
  };
  if (!scrollOut) {
    return (
      <div
        style={{ height: '100%' }}
        onMouseLeave={onLeaveMouse}
        onMouseEnter={() => setInUsing({ ...inUsing, in: true })}
      >
        {inUsing.in || inUsing.using ? (
          <CellAutocomplete
            value={team}
            height='100%'
            onChange={onChange}
            options={qcteamsList}
            isloading={qcData?.isLoading}
            onDropdownVisibleChange={handleFocus}
            onSelect={(value, opt) => onSelect(value, opt)}
            notFoundContent={'No data found!'}
          />
        ) : (
          <InputPlaceholder
            style={{ height: '100%', display: 'flex', alignItems: 'center' }}
          >
            {team || ''}
          </InputPlaceholder>
        )}
      </div>
    );
  } else return '...';
};
