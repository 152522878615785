/**
 * Capitalizes the first letter of the given string.
 * Replaces underscores with spaces and converts the rest of the string to lowercase.
 *
 * @param {string} str - The input string to format.
 * @returns {string|null} The formatted string with the first letter capitalized, or null if the input is invalid
 *
 * @example
 * // Capitalize the first letter of a simple string
 * const result = capitalizeFirstLetter('hello_word');
 * console.log(result); // 'Hello world'
 *
 * @example
 * // Handle an empty string input
 * const result = capitalizeFirstLetter('');
 * console.log(result); // null
 *
 * @example
 * // Handle a string with mixed case and underscores
 * const result = capitalizeFirstLetter('tHis_Is_A_TEST');
 * console.log(result); // 'This is a test'
 *
 * @example
 * // Handle a string without underscores
 * const result = capitalizeFirstLetter('simple');
 * console.log(result); // 'Simple'
 *
 * @example
 * // Handle a numeric input
 * const result = capitalizeFirstLetter(1234);
 * console.log(result); // '1234'
 *
 * @example
 * // Handle a numeric string input
 * const result = capitalizeFirstLetter('1234');
 * console.log(result); // '1234'
 */

function capitalizeFirstLetter(str) {
  // Convert the input to a string if it's a number
  if (typeof str === 'number') {
    str = String(str);
  }

  // Return null if the input is not a string or is an empty string
  if (typeof str !== 'string' || !str) return null;

  // Replace all underscores with space and convert the string to lowercase
  str = str?.replaceAll('_', ' ')?.toLowerCase();

  // Capitalize the firs letter of the string
  const result = str?.charAt(0).toUpperCase() + str.slice(1);

  return result;
}

export { capitalizeFirstLetter };
