/** @format */

import styled from 'styled-components';

export const PanelWrap = styled.div`
  width: 100%;
  .table {
    width: 100%;
  }
  .files-box {
    margin-bottom: 10px;
  }
`;

PanelWrap.Flex = styled.div`
  display: flex;
  width: ${({ width }) => width && width};
`;
PanelWrap.Column = styled.div`
  display: flex;
  flex-direction: column;
  min-width: ${({ width }) => width && width};
  width: ${({ width }) => width && width};

  align-items: flex-start;
`;

PanelWrap.TitleImg = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #0167ff;
  :hover {
    text-decoration-line: underline;
  }
`;
PanelWrap.Card = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ galview }) => (galview ? 'row' : 'column')};
  background: #fff;
  border-radius: 6px;
  height: fit-content;
  overflow-x: auto;

  div.turncated {
    width: ${({ width }) => (width ? width : 'calc(97%)')};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
  }
`;
PanelWrap.CardItem = styled.div`
  display: flex;
  flex-direction: ${({ galview }) => (galview === 'true' ? 'column' : 'row')};

  grid-gap: 8px;
  margin-top: 8px;
  padding: 0 8px 0 20px;
`;

PanelWrap.Grid = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  padding: 0 0 0 34px;
`;

PanelWrap.Title = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #374151;
`;
PanelWrap.Table = styled.div`
  display: flex;
  align-items: start;
  gap: 10px;
  margin-bottom: 10px;

  .author-box {
    width: 18%;
    ${'' /* min-width: 160px; */}
    ${'' /* max-width: 160px; */}
  }
  .date-box {
    width: 17%;
  }
  .action-box {
    width: 97px;
    ${'' /* min-width: 120px; */}
    ${'' /* max-width: 120px; */}
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
  }
  .name-box,
  .author-box,
  .date-box,
  .action-box {
    .key {
      color: #666;
      font-weight: 600;
      text-align: left !important;
    }
    .value {
      color: black;
      overflow: hidden;
      text-align: left;
    }
  }
  .name-box {
    width: calc(65% - 97px);
  }
`;

export const FileWrapper = styled.div`
  width: ${({ width }) => width || '50px'};
  height: ${({ height }) => height || '50px'};
  position: relative;
  .uploading {
    position: absolute;
    pointer-events: none;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    font-size: 10px;
    color: white;
  }
  &:hover {
    .uploading {
      opacity: 1;
      svg {
        opacity: 1;
      }
    }
  }
`;
