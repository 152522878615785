/** @format */

import styled from 'styled-components';
import { ReactComponent as download } from 'assets/icons/download.svg';
import { ReactComponent as zoomin } from 'assets/icons/zoom-in-line.svg';
import { ReactComponent as zoomout } from 'assets/icons/zoom-out-line.svg';
import { ReactComponent as rotateClockwise } from 'assets/icons/clockwise-rotate.svg';
import { ReactComponent as Right } from 'assets/icons/arrow-right-s-line.svg';
import { ReactComponent as Left } from 'assets/icons/arrow-left-s-line.svg';
import { ReactComponent as close } from 'assets/icons/close-line.svg';
import { ReactComponent as Eye } from 'assets/icons/eye.svg';
import { Image, Modal } from '../../AntDesign';

const Wrapper = styled.div`
  height: 100%;
  width: ${({ countTags, notshow }) =>
    countTags && !notshow ? '36px' : countTags && notshow ? '100%' : ' 100%'};
  padding: ${({ title }) => (title?.length ? '14px 0px' : 0)};
  border-radius: 5px;
  .ant-modal {
    width: 1000px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  .ant-image-preview-operations-operation {
    margin-left: 12px;
    padding: 0 !important;
    cursor: pointer;
  }
`;

const InputsBox = styled.div`
  display: flex;
  position: relative;
  grid-column-gap: ${({ title }) => (title?.length ? '30px' : '')};
  grid-row-gap: 14px;

  .ant-image-preview-operations-operation {
    margin-left: 12px;
    padding: 0 !important;
    cursor: pointer;
  }

  .ant-image-mask-info {
    font-size: ${({ fontSize }) => fontSize};
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    grid-row-gap: 0;
    margin: 0;
    justify-content: center;
    align-items: center;
  }

  .ril-caption .ril__caption {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute !important;
    bottom: 0;
    left: 100% !important;
    overflow: auto;
    min-width: 230px !important;
    right: 0 !important;
    display: -ms-flexbox;
    display: flex !important;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  @media (max-width: 820px) {
    flex-direction: column;
  }
`;

const DownloadIcon = styled(download)`
  width: ${({ width }) => width || '25px'};
  height: ${({ width }) => width || '25px'};
  cursor: pointer;
`;
const ZoomIn = styled(zoomin)`
  width: ${({ width }) => width || '25px'};
  height: ${({ width }) => width || '25px'};
  cursor: pointer;
  path {
    fill: ${({ color }) => color};
  }
  padding: ${({ padding }) => padding};
  line-height: ${({ lineheight }) => lineheight};
`;
const ZoomOut = styled(zoomout)`
  width: ${({ width }) => width || '25px'};
  height: ${({ width }) => width || '25px'};
  cursor: pointer;
  path {
    fill: ${({ color }) => color};
  }
  padding: ${({ padding }) => padding};
`;
const RotateRight = styled(rotateClockwise)`
  width: ${({ width }) => width || '25px'};
  height: ${({ width }) => width || '25px'};
  cursor: pointer;
  path {
    fill: ${({ color }) => color};
  }
  padding: ${({ padding }) => padding};
`;
const Forward = styled(Right)`
  width: ${({ width }) => width || '25px'};
  height: ${({ width }) => width || '25px'};
  cursor: pointer;
  path {
    fill: ${({ color }) => color};
  }
`;
const Back = styled(Left)`
  width: ${({ width }) => width || '25px'};
  height: ${({ width }) => width || '25px'};
  cursor: pointer;
  path {
    fill: ${({ color }) => color};
  }
`;
const Close = styled(close)`
  width: ${({ width }) => width || '25px'};
  height: ${({ width }) => width || '25px'};
  cursor: pointer;
  path {
    fill: ${({ color }) => color};
  }
`;
const ButtonBox = styled.div`
  width: 120px;
  justify-content: space-between;
  display: flex;
  position: absolute;
  top: -17px;
  right: 30px;
`;
const DownloadIcon2 = styled(download)`
  width: ${({ width }) => width || '25px'};
  height: ${({ width }) => width || '25px'};
  cursor: pointer;
  path {
    fill: ${({ color }) => color};
  }
  opacity: 0.7;
  padding: ${({ padding }) => padding};
  &:hover {
    opacity: 1;
  }
`;
const PreviewModal = styled(Modal)`
  width: 1000px;
`;
const CustomImage = styled('img')`
  width: ${({ width }) => width}px;
  width: ${({ height }) => height}px;
  max-height: 100vh;
`;

const Container = styled.div`
  display: flex;
  grid-gap: 20px;
  padding: 10px;
`;

const Card = styled.div`
  background: transparent;
  display: ${({ trmoves }) => (!trmoves ? 'flex' : 'grid')};
  grid-template-columns: ${({ trmoves }) => (!trmoves ? '' : '1fr 1fr')};
  width: ${({ notshow }) => (notshow ? '100%' : 'unset')};
  position: relative;
  grid-column-gap: ${({ trmoves }) => (!trmoves ? '10px' : '30px')};
  max-width: 396px;
  height: 16px;
  margin-top: ${({ trmoves }) => (!trmoves ? 0 : '15px')};
`;

Card.Image = styled.div`
  height: ${({ imgHeight }) => (imgHeight ? imgHeight : '170px')};
  width: ${({ imgWidth, notshow }) =>
    imgWidth && notshow ? '100%' : imgWidth && !notshow ? imgWidth : '120px'};
  border-radius: 4px;
  overflow: hidden;
  object-fit: contain;
`;

Card.Content = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({ imgHeight }) => (imgHeight ? imgHeight : '170px')};

  overflow-y: auto;
  overflow-x: hidden;
  #inner {
    color: #017aff;
    text-transform: uppercase;
    font-weight: 700;
  }

  div.turncated {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
  }
`;

const Img = styled(Image)`
  height: ${({ imgHeight }) => (imgHeight ? imgHeight : '170px')};
  width: ${({ imgWidth }) => (imgWidth ? imgWidth : '120px')};
  border-radius: 4px;
  overflow: hidden;
  object-fit: fill;

  .ant-image-preview-operations-operation {
    margin-left: 12px;
    padding: 0 !important;
    cursor: pointer;
  }
`;

const CustomMask = styled.div`
  width: ${({ width }) => (width ? width : '50px')};
  height: ${({ height }) => (height ? height : 'i50px')};
  display: flex;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  font-size: 16px;
  color: #fff;
  align-items: center;
  justify-content: center;
  z-index: 10;
  cursor: pointer;
`;

export const IconView = styled(Eye)`
  width: 16px;
  height: 16px;
  cursor: pointer;
  path {
    fill: ${({ color }) => (color ? color : '#5c6470')};
  }
  &:hover {
    opacity: 0.7;
  }
`;

export {
  Wrapper,
  InputsBox,
  DownloadIcon,
  DownloadIcon2,
  ZoomIn,
  ZoomOut,
  RotateRight,
  Forward,
  Back,
  Close,
  ButtonBox,
  PreviewModal,
  CustomImage,
  Container,
  CustomMask,
  Card,
  Img,
};
