/** @format */
const initialApplicant = {
  cdlNumber: null,
  firstname: null,
  lastname: null,
  dateOfBirth: null,
  phoneNumber: null,
  email: null,
  address: null,
  city: null,
  state: null,
  zipCode: null,
  driverPosition: 'OWNER',
  driverType: null,
  recruiterId: null,
  recruiter: null,
  hrAdmin: null,
  hrAdminId: null,
  step: 'APPLICANT',
  rate: null,
  percentage: null,
  attachmentIds: [],
  blackComment: null,
};
const initialEligibility = {
  tenstreet: false,
  tenstreetComment: null,
  mvrPsp: false,
  mvrPspComment: null,
  safety: 'REQUESTED',
  safetyComment: null,
  insurance: 'REQUESTED',
  insuranceComment: null,
  company: [],
  companyIds: [],
  step: 'DRIVER_ELIGIBILITY',
  attachmentIds: [],
  blackComment: null,
};
const initialDocument = {
  drugTest: 'NO_SHOW_EXPIRED',
  drugTestComment: null,
  clearingHouse: false,
  clearingHouseComment: null,
  employmentVerification: false,
  employmentVerificationComment: null,
  docusign: false,
  docusignComment: null,
  safetyTraining: 'NOT_REQUIRED',
  safetyTrainingComment: null,
  zoomMeeting: false,
  zoomMeetingComment: null,
  orientation: false,
  orientationComment: null,
  onBoardingDate: null,
  onBoardingDateComment: null,
  step: 'DRIVER_DOCUMENT',
  attachmentIds: [],
  blackComment: null,
};
const initialCtrd = {
  firstName: null,
  lastName: null,
  suffix: null,
  source: 'FACEBOOK',
  sourceComment: null,
  costPerHire: null,
  costPerHireComment: null,
  description: null,
  step: 'CONTRACTED',
  attachmentIds: [],
  blackComment: null,
};
export const initialState = {
  refetch: {},
  permission: {},
  cardHeader: {},
  cardBody: [],
  cardFooter: [],
  cardExtraFooter: [],
  applicantList: [],
  eligibilityList: [],
  driversDocumentsList: [],
  companiesList: [],
  recruitersList: [],
  hrAdminsList: [],

  initialApplicantState: initialApplicant,
  applicantState: initialApplicant,

  initialDriverEligibilityState: initialEligibility,
  driverEligibilityState: initialEligibility,

  initialDriverDocumentsState: initialDocument,
  driverDocumentsState: initialDocument,

  initialContracted: initialCtrd,
  contracted: initialCtrd,

  currentStep: 0,
  stepCounter: 1,
  totalSteps: 4,
  closeDrawer: () => { },
  saveFunction: () => { },
  onCancelFunction: () => { },
  stateChange: false,
  attachments: [],
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'setDocs': {
      if (action.own === 'true') {
        return {
          ...state,
          attachments: action.payload,
        };
      }

      let formattedData = action?.payload?.map((d) => {
        return {
          attachmentId: d?.attachmentId || d.uploadedFile?.attachmentId,
          date: d?.date,
          docTypeId: d?.docTypeId,
          extension: d?.extension,
          name: d?.name,
          notes: d?.notes,
          path: d?.path || d?.uploadedFile?.path,
          size: d?.size,
          uploadedFile: d?.uploadedFile || [],
        };
      });

      formattedData = state?.attachments?.length
        ? formattedData?.concat(state?.attachments)
        : formattedData;

      return {
        ...state,
        attachments: formattedData,
      };
    }
    case 'changePage':
      return { ...state, page: action.payload };
    case 'setPermission':
      return { ...state, permission: action.payload };
    case 'setCardHeader':
      return { ...state, cardHeader: action.payload };
    case 'setCardBody':
      return { ...state, cardBody: action.payload };
    case 'setCardFooter':
      return { ...state, cardFooter: action.payload };
    case 'setCardExtraFooter':
      return { ...state, cardExtraFooter: action.payload };
    case 'setApplicantList':
      return { ...state, applicantList: action.payload };
    case 'setEligibilityList':
      return { ...state, eligibilityList: action.payload };
    case 'setDriversDocumentList':
      return { ...state, driversDocumentsList: action.payload };
    case 'setOwnerCompanies':
      return { ...state, companiesList: action.payload };
    case 'setRecsList':
      return { ...state, recruitersList: action.payload };
    case 'setHrAdminsList':
      return { ...state, hrAdminsList: action.payload };
    case 'setCurrentStep':
      return { ...state, currentStep: action.payload, stepCounter: 1 };
    case 'closeDrawer':
      return { ...state, closeDrawer: action.payload };
    case 'nextStep':
      return {
        ...state,
        currentStep: state?.currentStep + action.payload,
        stepCounter: +state?.stepCounter + 1
      };

    case 'prevStep':
      return {
        ...state,
        currentStep:
          state.currentStep > 0
            ? state.currentStep - action.payload
            : state.currentStep,
        stepCounter: +state?.stepCounter + 1
      };
    // ---------------------------
    case 'setApplicationState': {
      const { fieldName, value, fieldId, id } = action.payload;
      const fields = {};
      if (fieldName) fields[fieldName] = value;
      if (fieldId) fields[fieldId] = id;
      return {
        ...state,
        applicantState: {
          ...state.applicantState,
          ...fields
        },
      };
    }
    case 'setApplicationStateById':
      return { ...state, applicantState: action.payload };
    case 'setInitialApplicationStateById':
      return { ...state, initialApplicantState: action.payload };
    // ---------------------------
    case 'setDriverEligibilityState': {
      const { fieldName, value, fieldId, id } = action.payload;
      const fields = {};
      if (fieldName) fields[fieldName] = value;
      if (fieldId) fields[fieldId] = id;
      return {
        ...state,
        driverEligibilityState: {
          ...state.driverEligibilityState,
          ...fields
        },
      };
    }
    case 'setDriverEligibilityStateById':
      return { ...state, driverEligibilityState: action.payload };
    case 'setInitialDriverEligibilityStateById':
      return { ...state, initialDriverEligibilityState: action.payload };
    // ---------------------------
    case 'setDriverDocumentsState': {
      const { fieldName, value, fieldId, id } = action.payload;
      const fields = {};
      if (fieldName) fields[fieldName] = value;
      if (fieldId) fields[fieldId] = id;
      return {
        ...state,
        driverDocumentsState: {
          ...state.driverDocumentsState,
          ...fields
        },
      };
    }
    case 'setDriverDocumentsStateById':
      return { ...state, driverDocumentsState: action.payload };
    case 'setInitialDriverDocumentsStateById':
      return { ...state, initialDriverDocumentsState: action.payload };
    // ---------------------------
    case 'setContractState': {
      const { fieldName, value, fieldId, id } = action.payload;
      const fields = {};
      if (fieldName) fields[fieldName] = value;
      if (fieldId) fields[fieldId] = id;
      return {
        ...state,
        contracted: {
          ...state.contracted,
          ...fields
        },
      };
    } case 'setContractedById':
      return { ...state, contracted: action.payload };
    case 'setInitialContractedById':
      return { ...state, initialContracted: action.payload };
    // ---------------------------
    case 'setInitialStates':
      return {
        ...state, initialApplicantState: initialApplicant,
        applicantState: initialApplicant,
        initialDriverEligibilityState: initialEligibility,
        driverEligibilityState: initialEligibility,
        initialDriverDocumentsState: initialDocument,
        driverDocumentsState: initialDocument,
        initialContracted: initialCtrd,
        contracted: initialCtrd,
        currentStep: 0,
        attachments: []
      };
    case 'setRefetch':
      return { ...state, refetch: action.payload };
    case 'setSelected':
      return { ...state, selected: action.payload };
    case 'setSaveFunction':
      return { ...state, saveFunction: action.payload };
    case 'setOnCancelFunction':
      return { ...state, onCancelFunction: action.payload };
    case 'setStateChange':
      return { ...state, stateChange: action.payload };
    default:
      return state;
  }
};
