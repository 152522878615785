/** @format */

import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';

export const DispatcherTeamsContext = createContext();

export const useDispatcherTeams = () => useContext(DispatcherTeamsContext);

export const DispatcherTeamsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DispatcherTeamsContext.Provider value={[state, dispatch]}>
      {children}
    </DispatcherTeamsContext.Provider>
  );
};
