/** @format */

export const initialState = {
  warningPmList: [],
  warningMaintenanceList: [],
  warningRegCardList: [],
  warningInspectionList: [],
  pmStatus: 'NOT_DUE',
  maintenanceStatus: 'NOT_DUE',
  regCardStatus: 'NOT_DUE',
  inspectionStatus: 'NOT_DUE',
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'setWarningPmList':
      return { ...state, warningPmList: action.payload };
    case 'setWarningMaintenanceList':
      return { ...state, warningMaintenanceList: action.payload };
    case 'setWarningRegCardList':
      return { ...state, warningRegCardList: action.payload };
    case 'setWarningInspectionList':
      return { ...state, warningInspectionList: action.payload };
    case 'setPmStatus':
      return { ...state, pmStatus: action.payload };
    case 'setMaintenenaceStatus':
      return { ...state, maintenanceStatus: action.payload };
    case 'setRegCardStatus':
      return { ...state, regCardStatus: action.payload };
    case 'setInspectionStatus':
      return { ...state, inspectionStatus: action.payload };
    default:
      return state;
  }
};
