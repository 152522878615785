/** @format */

import { memo } from 'react';
import { FilterDateRange, FilterInput } from 'filters';
import {
  StatusChangerRenderer,
  pickUpDateRenderer,
  OriginRender,
  DestinationRender,
  deliveryDateRenderer,
  FilterDriver,
  FilterCompany,
  FilterBroker,
  EditDriver,
  CellRenderQc,
  FilterStatus,
  milesRenderer,
  LoadRenderer,
  rateRenderer,
  DestinationRenderer2,
} from './HeaderComponents';
const cellStyle = () => ({
  padding: '0 5px',
  fontSize: '12px',
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'wrap',
});

export const column = [
  {
    headerName: 'LOAD',
    field: 'loadId',
    cellRenderer: LoadRenderer,
    width: 120,
    floatingFilterComponent: () => FilterInput('load-id'),
    resizable: true,
    cellStyle,
  },
  {
    headerName: 'COMPANY code',
    cellRenderer: (data) =>
      DestinationRenderer2(data, 'companyCode', 'companyCode'),
    floatingFilterComponent: memo(FilterCompany),
    cellStyle,
  },
  {
    headerName: 'BROKER',
    cellRenderer: (data) =>
      DestinationRenderer2(data, 'brokerName', 'brokerName'),
    floatingFilterComponent: memo(FilterBroker),
    cellStyle,
  },
  {
    headerName: 'Load STATUS',
    field: 'loadStatus',
    width: 140,
    cellRenderer: StatusChangerRenderer,
    floatingFilterComponent: FilterStatus,
    resizable: true,
    cellStyle: () => ({ padding: 0 }),
  },
  {
    headerName: 'Driver',
    field: 'driverName',
    flex: 1.5,
    cellRenderer: EditDriver,
    floatingFilterComponent: FilterDriver,
    resizable: true,
    cellStyle,
  },

  {
    headerName: 'ORIGIN',
    field: 'startCityState',
    width: 150,
    cellRenderer: OriginRender,

    floatingFilterComponent: () => FilterInput('origin', 'Origin name'),

    resizable: true,
    cellStyle,
  },

  {
    headerName: 'PICKUP DATE',
    field: 'startDate',
    cellRenderer: pickUpDateRenderer,
    floatingFilterComponent: () =>
      FilterDateRange('arrival-date-from', 'arrival-date-to'),
    width: 130,
    resizable: true,
    cellStyle,
  },

  {
    headerName: 'DESTINATION',
    field: 'endCityState',
    cellRenderer: DestinationRender,
    width: 130,
    floatingFilterComponent: () =>
      FilterInput('destination', 'Destination name'),

    resizable: true,
    cellStyle,
  },

  {
    headerName: 'DELIVERY DATE',
    field: 'endDate',
    cellRenderer: deliveryDateRenderer,
    floatingFilterComponent: () =>
      FilterDateRange('departure-date-from', 'departure-date-to'),
    width: 130,
    resizable: true,
    cellStyle,
  },
  {
    headerName: 'Rate',
    field: 'rate',
    cellRenderer: rateRenderer,
    width: 85,
    resizable: true,
    cellStyle,
  },
  {
    headerName: 'MILES',
    field: 'estimateDistance',
    cellRenderer: milesRenderer,
    width: 85,
    resizable: true,
    cellStyle,
  },

  {
    headerName: 'QC TEAM',
    cellRenderer: CellRenderQc,
    cellStyle: () => {
      return {
        padding: 0,
      };
    },
    resizable: true,
  },
];
