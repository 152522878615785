/** @format */

import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { dayjs, isoWeek } from 'day-js';
dayjs.extend(isoWeek);
import { Select, Autocomplete, MultiSelect } from 'forms';
import { PerformanceTopFilter } from 'globalCSS';
import { getCookie, setCookie, deleteCookie } from 'formatter';
import { useRequest, useQuery, useSetQuery, useSearch } from 'hooks';
import getMonthFromCurrentWeek from './getMonthFromCurrentWeek';
import { useLocation } from 'react-router-dom';

const TopHeaderFilters = (props) => {
  const {
    isHideTeams,
    isHideMembers,
    isHideYear,
    isHideMonth,
    isHideWeek,
    width,
    height,
  } = props;
  let newArr = [
    'isHideTeams',
    'isHideMembers',
    'isHideYear',
    'isHideMonth',
    'isHideWeek',
  ];
  let arrayList = new Array(5).fill(false);
  newArr.forEach((propName) => {
    if (props[propName]) {
      arrayList.pop();
    }
  });
  const { search } = useLocation();

  const { request } = useRequest();
  const [year] = useState([{ key: '2024', value: '2024' }]);

  const defaultWeekKey = `Week-${dayjs().isoWeek()} / ${dayjs()
    .startOf('isoWeek')
    .format('MMMM DD')} - ${dayjs().endOf('isoWeek').format('MMMM DD')}`;
  const monthsList = useMemo(
    () =>
      dayjs
        .months()
        ?.map((m) => ({ key: m?.toUpperCase(), value: m?.toUpperCase() })),
    []
  );
  const [teams, setTeams] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [weekOptions, setWeekOptions] = useState([]);
  let currentWeek = dayjs().isoWeek();
  const [selectedWeeks, setSelectedWeeks] = useState([defaultWeekKey]);
  const [selectedMonth, setSelectedMonth] = useState(
    getCookie('selectedMonth')
  );
  const setQuery = useSetQuery();
  const query = useSearch();
  const savedWeeks = getCookie('selectedWeeks');
  const savedMonth = getCookie('selectedMonth');
  const savedDateFrom = getCookie('dateFrom');
  const savedDateTo = getCookie('dateTo');

  const isValidValue = (value) => {
    if (value === null) {
      return false;
    }

    try {
      const parsedValue = JSON.parse(value);
      if (typeof parsedValue === 'number' && !isNaN(parsedValue)) {
        return true;
      }
      if (typeof parsedValue === 'string') {
        return true;
      }
      return false;
    } catch (error) {
      return value.trim().length > 0;
    }
  };
  const isSavedWeeksValid = isValidValue(savedWeeks);
  const isSavedMonthValid = isValidValue(savedMonth);
  const isSavedDateFromValid = isValidValue(savedDateFrom);
  const isSavedDateToValid = isValidValue(savedDateTo);

  const convertToArray = (str) => {
    return str?.split(',').map((name) => name.trim());
  };
  const startOfWeek = useMemo(() => {
    const startDate = query.get('date-from')
      ? dayjs(query.get('date-from'))
      : dayjs();
    return startDate.startOf('isoWeek').add(0, 'day').toDate();
  }, [query.get('date-from')]);
  const endOfWeek = useMemo(() => {
    return query.get('date-from')
      ? dayjs(startOfWeek).endOf('isoWeek').toDate()
      : dayjs(startOfWeek).endOf('isoWeek').toDate();
  }, [query.get('date-from')]);

  const getMatch = async (dateF, dateT) => {
    let matchingWeek = null;
    if (weekOptions?.length) {
      for (const week of weekOptions) {
        if (week.dateFrom === dateF && week.dateTo === dateT) {
          matchingWeek = week;
          break;
        }
      }
    }

    setQuery(
      'month',
      getMonthFromCurrentWeek(dayjs(matchingWeek?.dateFrom).week())
    );
    // setQuery('month', monthsList[dayjs(matchingWeek?.dateFrom).month()]?.key);
    setSelectedMonth(monthsList[dayjs(matchingWeek?.dateFrom).month()]?.key);
    setQuery('year', matchingWeek?.year || 2024);
  };

  useEffect(() => {
    setCookie('selectedWeeks', selectedWeeks);
    // setCookie('selectedMonth', selectedMonth);
    setCookie(
      'dateFrom',
      query.get('date-from')
        ? query.get('date-from')
        : dayjs(dayjs().startOf('isoWeek').toDate())?.format('YYYY-MM-DD')
    );
    setCookie(
      'dateTo',
      query.get('date-to')
        ? query.get('date-to')
        : dayjs(dayjs().endOf('isoWeek').toDate())?.format('YYYY-MM-DD')
    );
    // setQuery('month', selectedMonth);
    // setCookie('selectedMonth', selectedMonth);
  }, [selectedWeeks, selectedMonth]);

  useEffect(() => {
    if (getCookie('qc-team') && getCookie('qc-team-id')) {
      isHideTeams !== true && setQuery('qc-team-id', getCookie('qc-team-id'));
      isHideTeams !== true && setQuery('qc-team', getCookie('qc-team'));
    }

    if (getCookie('qc') && getCookie('qc_id')) {
      isHideMembers !== true && setQuery('qc-id', getCookie('qc_id'));
      isHideMembers !== true && setQuery('qc', getCookie('qc'));
    } else {
      setQuery('qc-id', null);
      setQuery('qc', null);
    }
    if (isSavedDateFromValid) {
      setQuery('date-from', savedDateFrom);
    } else {
      setQuery('date-from', dayjs(startOfWeek).format('YYYY-MM-DD'));
      setCookie(
        'dateFrom',
        query.get('date-from')
          ? query.get('date-from')
          : dayjs(startOfWeek).format('YYYY-MM-DD')
      );
    }
    if (isSavedDateToValid) {
      setQuery('date-to', savedDateTo);
    } else {
      setQuery('date-to', dayjs(startOfWeek).format('YYYY-MM-DD'));

      setCookie(
        'dateTo',
        query.get('date-to')
          ? query.get('date-to')
          : dayjs(startOfWeek).format('YYYY-MM-DD')
      );
    }
    // if (isSavedDateFromValid && isSavedDateToValid) {
    //   setDateRange([savedDateFrom, savedDateTo]);
    // }
    setQuery('year', year[0]?.key);
    getMatch(query.get('date-from'), query.get('date-to'));

    if (isSavedWeeksValid) {
      setSelectedWeeks(convertToArray(savedWeeks));
    } else {
      setSelectedWeeks([defaultWeekKey]);
    }
    if (isSavedMonthValid) {
      setSelectedMonth(savedMonth);
    } else {
      setSelectedMonth(
        getMonthFromCurrentWeek(dayjs(query.get('date-from')).week())
      );
    }

    fetchMem?.refetch();
    return () => {};
  }, [teams.length]);

  useEffect(() => {
    if (isSavedMonthValid) {
      setSelectedMonth(savedMonth);
    } else {
      let thisWeek = getMonthFromCurrentWeek();
      setSelectedMonth(thisWeek);
    }
  }, [currentWeek]);

  const fetchMem = useQuery(
    ['/qc-team/qct-users/${id}', query.get('qc-team-id')],
    () =>
      query.get('qc-team-id') &&
      request({
        url: `/qc-team/qct-users/${query.get('qc-team-id')}`,
      }),
    {
      onSuccess: (res) => {
        if (res?.success) {
          let data = res?.dataList?.map((item) => {
            return {
              key: item?.nickName,
              value: item?.nickName,
              id: item?.userId,
            };
          });
          setTeamMembers(data);
        }
      },
    }
  );

  useQuery(
    ['/qc-team/number-id/list'],
    () => request({ url: '/qc-team/number-id/list' }),
    {
      onSuccess: (res) => {
        if (res?.success) {
          let data = res?.dataList?.map((item) => {
            return {
              key: item?.name,
              value: item?.name,
              id: item?.id,
            };
          });
          setTeams(data);
        }
      },
    }
  );
  const params = new URLSearchParams(search.split('?')[1]);
  params.delete('order');
  const newUrl = `${search.split('?')[0]}?${params.toString()}`;

  useQuery(
    ['/v3/kpi/week-infos/list-8', query?.get('year'), query?.get('month')],
    () =>
      newUrl?.length > 1 &&
      request({
        url: `/v3/kpi/week-infos/list?month=${query?.get(
          'month'
        )}&year=${query?.get('year')}`,
      }),
    {
      onSuccess: (res) => {
        var option = res?.dataList?.map((item) => {
          return {
            value: item?.name,
            label: item?.name,
            dateFrom: item?.dateFrom,
            dateTo: item?.dateTo,
            year: item?.year,
            order: item?.order,
            month: item?.month,
          };
        });
        setWeekOptions(option);
      },
    }
  );
  const fetchMembers = useMutation({
    mutationFn: (id) => {
      return (
        id &&
        request({
          url: `/qc-team/qct-users/${id}`,
          method: 'GET',
        })
      );
    },
  });

  const handleTeams = useCallback((value, opt) => {
    setQuery('qc-team', value || null);
    setQuery('qc-team-id', opt?.id || null);

    if (!opt?.id) {
      deleteCookie('qc-team');
      deleteCookie('qc-team-id');
    } else {
      setCookie('qc-team', value);
      setCookie('qc-team-id', opt?.id);
      fetchMembers.mutateAsync(opt?.id, {
        onSuccess: (res) => {
          let data = res?.dataList?.map((item) => {
            return {
              key: item?.nickName,
              value: item?.nickName,
              id: item?.id,
            };
          });
          setTeamMembers(data);
        },
      });
    }
  }, []);
  const fetchData = useMutation({
    mutationFn: (month) => {
      return (
        month &&
        request({
          url: `/v3/kpi/week-infos/list${newUrl || '?'}`,
          method: 'GET',
        })
      );
    },
  });
  const handleTeamMems = useCallback((value, opt) => {
    if (!value) {
      setQuery('qc', '');
      setQuery('qc-id', '');
      deleteCookie('qc_id');
      deleteCookie('qc');
    } else {
      setQuery('qc', value);
      setQuery('qc-id', opt?.id);
      setCookie('qc', value);
      setCookie('qc_id', opt?.id);
    }
  }, []);
  const handleYear = useCallback((value) => {
    setQuery('year', value);
  }, []);
  const handleMonth = (value) => {
    setQuery('month', value);
    setCookie('selectedMonth', value);
    fetchData.mutateAsync(value, {
      onSuccess: (res) => {
        if (res?.success) {
          let thisMonth = getMonthFromCurrentWeek();
          if (value !== thisMonth) {
            setQuery('month', value);
            setCookie('selectedMonth', value);
            setSelectedWeeks(res?.dataList[0]?.name);

            localStorage.setItem('dateFrom', res?.dataList[0]?.dateFrom);
            localStorage.setItem('dateTo', res?.dataList[0]?.dateTo);
            setQuery('date-from', res?.dataList[0]?.dateFrom);
            setQuery('date-to', res?.dataList[0]?.dateTo);
            setCookie('dateFrom', res?.dataList[0]?.dateFrom);
            setCookie('dateFrom', res?.dataList[0]?.year);
            setCookie('dateTo', res?.dataList[0]?.dateTo);
            setCookie('selectedWeeks', res?.dataList[0]?.name);
          } else {
            setQuery('month', value);
            setCookie('selectedMonth', value);
            setSelectedWeeks(defaultWeekKey);
            localStorage.setItem('dateFrom', dayjs().format('YYYY-MM-DD'));
            localStorage.setItem(
              'dateTo',
              dayjs(endOfWeek).format('YYYY-MM-DD')
            );
            setQuery(
              'date-from',
              dayjs().startOf('isoWeek').format('YYYY-MM-DD')
            );
            setQuery('date-to', dayjs().endOf('isoWeek').format('YYYY-MM-DD'));
            setCookie(
              'dateFrom',
              dayjs().startOf('isoWeek').format('YYYY-MM-DD')
            );
            setCookie('dateTo', dayjs().endOf('isoWeek').format('YYYY-MM-DD'));
            setCookie('selectedWeeks', defaultWeekKey);
          }
        }
      },
    });
  };

  function findDateRange(selectedWeeks) {
    if (selectedWeeks.length === 1) {
      return {
        minDateFrom: selectedWeeks[0].dateFrom,
        maxDateTo: selectedWeeks[0].dateTo,
      };
    } else {
      let minDateFrom = dayjs(selectedWeeks[0].dateFrom);
      let maxDateTo = dayjs(selectedWeeks[0].dateTo);

      selectedWeeks.forEach((week) => {
        const dateFrom = dayjs(week.dateFrom);
        const dateTo = dayjs(week.dateTo);

        if (dateFrom.isBefore(minDateFrom)) {
          minDateFrom = dateFrom;
        }

        if (dateTo.isAfter(maxDateTo)) {
          maxDateTo = dateTo;
        }
      });

      return {
        minDateFrom: minDateFrom.format('YYYY-MM-DD'),
        maxDateTo: maxDateTo.format('YYYY-MM-DD'),
      };
    }
  }

  const handleWeekChange = useCallback((value, opt) => {
    if (!value?.length) {
      const startOfWeek = dayjs().startOf('isoWeek').format('YYYY-MM-DD');
      const endOfWeek = dayjs().endOf('isoWeek').format('YYYY-MM-DD');
      setQuery('date-from', startOfWeek);
      setQuery('date-to', endOfWeek);
      setSelectedWeeks([
        `Week-${dayjs(startOfWeek).week()} / ${dayjs(startOfWeek).format(
          'MMMM DD'
        )} - ${dayjs(endOfWeek).format('MMMM DD')}`,
      ]);
    }
    if (value.length) {
      const { minDateFrom, maxDateTo } = value.length && findDateRange(opt);
      setQuery('date-from', minDateFrom);
      setQuery('date-to', maxDateTo);
      localStorage.setItem('dateFrom', minDateFrom);
      localStorage.setItem('dateTo', maxDateTo);
      setSelectedWeeks(value);
    }
  }, []);

  return (
    <PerformanceTopFilter
      style={{
        position: 'sticky',
        zIndex: 800,
        top: 0,
        height: height || '100%',
        width: width,
      }}
      length={arrayList?.length}
    >
      {isHideTeams !== true && (
        <Select
          allOption='true'
          options={teams}
          onChange={handleTeams}
          placeholder='Search teams'
          value={query.get('qc-team') || 'ALL'}
        />
      )}
      {isHideMembers !== true && (
        <Autocomplete
          allowClear
          options={teamMembers}
          onChange={(value) => {
            handleTeamMems(value);
          }}
          onSelect={(value, opt) => {
            handleTeamMems(value, opt);
          }}
          placeholder='Search members'
          value={query.get('qc') || ''}
        />
      )}
      {!isHideYear && (
        <Select
          options={year}
          onChange={(value, opt) => {
            handleYear(value, opt);
          }}
          placeholder='YYYY'
          value={query.get('year') || '2024'}
        />
      )}
      {!isHideMonth && (
        <Select
          options={monthsList}
          onChange={(value, opt) => {
            handleMonth(value, opt);
          }}
          placeholder='MMMM'
          value={query.get('month') || ''}
        />
      )}
      {!isHideWeek && (
        <MultiSelect
          options={weekOptions}
          onChange={(value, opt) => {
            handleWeekChange(value, opt);
          }}
          placeholder='Week member / MM-DD - MM-DD'
          value={selectedWeeks}
          defaultValue={`${selectedWeeks}`}
        />
      )}
    </PerformanceTopFilter>
  );
};

export default memo(TopHeaderFilters);
