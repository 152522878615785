/** @format */

export const initialState = {
  total: 0,
  permission: {},
  modalName: '',
  drawerOpen: false,
  drawerDetailsOpen: false,
  insuranceContracts: [],
  data: [],
  detailsData: [],
  insuranceContract: {},
  isLoading: false,
  attachments: [],

  byType: '',
  saveFunction: () => {},
  onCancelFunction: () => {},
  stateChange: false,
  // options
  ownCompanyOptions: [],
  insuranceCompanyOptions: [],
  userOptions: [],
};
export const reducer = (state, action) => {
  switch (action.type) {
    case 'setDocs': {
      if (action.own === 'true') {
        return {
          ...state,
          attachments: action.payload,
        };
      }

      let formattedData = action?.payload?.map((d) => {
        return {
          attachmentId: d?.attachmentId || d.uploadedFile?.attachmentId,
          date: d?.date,
          docTypeId: d?.docTypeId,
          extension: d?.extension,
          name: d?.name,
          notes: d?.notes,
          path: d?.path || d?.uploadedFile?.path,
          size: d?.size,
          uploadedFile: d?.uploadedFile || [],
        };
      });

      formattedData = state?.attachments?.length
        ? formattedData?.concat(state?.attachments)
        : formattedData;

      return {
        ...state,
        attachments: formattedData,
      };
    }
    case 'setPermission':
      return { ...state, permission: action.payload };

    case 'setModalName':
      return { ...state, modalName: action.payload };
    case 'setData':
      return { ...state, data: action.payload };
    case 'setDrawerOpen':
      return { ...state, drawerOpen: action.payload };
    case 'setDrawerDetailsOpen':
      return { ...state, drawerDetailsOpen: action.payload };
    case 'setInsuraceContracts':
      return { ...state, insuranceContracts: action.payload };
    case 'setInsuraceContract':
      return { ...state, insuranceContract: action.payload };
    case 'setLoading':
      return { ...state, isLoading: action.payload };
    case 'setFiltered':
      return { ...state, filtered: action.payload };
    case 'setDetailsData':
      return { ...state, detailsData: action.payload };
    case 'setByType':
      return { ...state, byType: action.payload };
    case 'changePage':
      return { ...state, page: action.payload };
    case 'setSize':
      return { ...state, size: action.payload };
    case 'setTotal':
      return { ...state, total: action.payload };
    case 'setSaveFunction':
      return { ...state, saveFunction: action.payload };
    case 'setOnCancelFunction':
      return { ...state, onCancelFunction: action.payload };
    case 'setStateChange':
      return { ...state, stateChange: action.payload };
    case 'setOwnCompanyOptions':
      return { ...state, ownCompanyOptions: action.payload };
    case 'setInsuranceCompanyOptions':
      return { ...state, insuranceCompanyOptions: action.payload };
    case 'setUserOptions':
      return { ...state, userOptions: action.payload };
    default:
      return state;
  }
};
