/** @format */

import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';

export const FMCSAContext = createContext();

export const useFMCSA = () => useContext(FMCSAContext);

export const FMCSAProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <FMCSAContext.Provider value={[state, dispatch]}>
      {children}
    </FMCSAContext.Provider>
  );
};
