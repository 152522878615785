/** @format */

import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';

export const TrailerServiceContext = createContext();

export const useTrailerService = () => useContext(TrailerServiceContext);

export const TrailerServicePovider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <TrailerServiceContext.Provider value={[state, dispatch]}>
      {children}
    </TrailerServiceContext.Provider>
  );
};
