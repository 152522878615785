/** @format */

import { useFactoring } from 'context';
import { useDelete, useSetQuery } from 'hooks';
import { FilterInput } from 'filters';
import { Toast, DeleteConfirm } from 'modals';
import { Actions } from 'globalCSS';

const locationRenederer = ({ data }) => {
  const locationInterface = ({ state, city, address }) => {
    if (state && city && address) return `${state} , ${city} , ${address}`;
    else if (state && city) return `${state} , ${city}`;
    else if (state && address) return `${state} , ${address}`;
    else if (city && address) return `${city} , ${address}`;
    else {
      return state || city || address;
    }
  };
  return <div title={locationInterface(data)}>{locationInterface(data)}</div>;
};

//! action renderer
const ActionRenderer = ({ data }) => {
  // context
  const [{ refetch, permission }] = useFactoring();
  const { EDIT, DELETE } = permission;
  // hooks
  const deleteItem = useDelete();
  const setQuery = useSetQuery();
  // editing
  const handleEdit = (id) => {
    setQuery('modal-name', 'edit-factoring');
    setQuery('id', id);
  };
  // deleting
  const onConfirm = (id) => deleteItem(`/factoring_company/${id}`, refetch);
  const onCancel = () => {
    Toast({
      type: 'error',
      message: 'Action cancelled!',
    });
  };
  // styling
  return (
    <Actions>
      {EDIT && (
        <Actions.Action onClick={() => handleEdit(data?.id)}>
          Edit
        </Actions.Action>
      )}
      {EDIT && DELETE && '|'}
      {DELETE && (
        <DeleteConfirm
          onConfirm={() => onConfirm(data?.id)}
          onCancel={onCancel}
          title={data?.name}
          bgcolor='transparent !important'
          placement='right'
        >
          <Actions.Action color={'#FF0037'}>Delete</Actions.Action>
        </DeleteConfirm>
      )}
    </Actions>
  );
};
// address
// addressLine
// attachData
// attachmentIds
// city
// comment
// ein_number
// email
// factoringFee
// faxNumber
// id
// integrated
// mcNumber
// name
// otherContact
// phoneNumber
// state
// webSite
// zipCode
export const columns = [
  // NAME
  {
    headerName: 'Company name',
    field: 'name',
    resizable: true,
    floatingFilterComponent: () => FilterInput('name', 'Search...'),
  },
  {
    headerName: 'address',
    field: 'address',
    resizable: true,
    floatingFilterComponent: () => FilterInput('address', 'Search...'),
    flex: 0.5,
  },
  {
    headerName: 'address line',
    field: 'addressLine',
    resizable: true,
    floatingFilterComponent: () => FilterInput('address-line', 'Search...'),
  },
  {
    headerName: 'phone',
    field: 'phoneNumber',
    resizable: true,
    floatingFilterComponent: () => FilterInput('phone-number', 'Search...'),
  },
  {
    headerName: 'email',
    field: 'email',
    resizable: true,
    floatingFilterComponent: () => FilterInput('email', 'Search...'),
  },
  // LOCATION
  {
    headerName: 'STATE / CITY',
    cellRenderer: locationRenederer,
    flex: 1.2,
    resizable: true,
    floatingFilterComponent: () => FilterInput('location', 'Search...'),
  },
  {
    headerName: 'ZIP CODE',
    resizable: true,
    field: 'zipCode',
    flex: 0.4,
    floatingFilterComponent: () => FilterInput('zip-code', 'Search...'),
  },

  // ACTION
  {
    headerName: 'actions',
    cellRenderer: ActionRenderer,
    width: '90px',
  },
];
