import { memo } from 'react';
import {
  SearchSelectLabel,
  SearchSelectWrapper,
  CustomSelect,
  CustomOption,
} from './style';

// import { ArrowDownIcon } from 'styledIcons';

const SearchSelect = (props) => {
  const {
    label,
    options,
    onChange,
    bgcolor,
    width,
    height,
    disabled,
    lineheight,
    minwidth,
    tiltecolor,
    texttransform,
    paddingtop,
    selId,
    value,
    paddingTop,
    allowClear,
  } = props;

  const filterAndSort = (optionA, optionB) => {
    if (optionA && optionB) {
      if (optionA?.label && optionB?.label) {
        let a = optionA?.label !== undefined && optionA?.label.toString();
        let b = optionB?.label !== undefined && optionB?.label.toString();
        return a?.toLowerCase()?.localeCompare(b?.toLowerCase());
      }
    }
  };

  return (
    <SearchSelectWrapper
      width={width}
      height={height}
      bgcolor={bgcolor}
      minwidth={minwidth}
      tiltecolor={tiltecolor}
      paddingtop={paddingtop}
    >
      {label && <SearchSelectLabel>{label}</SearchSelectLabel>}
      <div style={{ position: 'relative' }}>
        <CustomSelect
          allowClear={allowClear}
          width={width}
          heigh={height}
          texttransform={texttransform}
          lineheight={lineheight}
          disabled={disabled}
          style={{ width: '100%' }}
          placeholder='Search to Select'
          optionFilterProp='children'
          defaultValue={selId || ''}
          value={value || ''}
          notFoundContent={'No data found!'}
          filterOption={(input, option) => {
            if (option?.children)
              return (
                option?.children?.props?.children[0]?.props?.title
                  ?.toLowerCase()
                  ?.indexOf(input?.toLowerCase()) >= 0
              );
          }}
          filterSort={(optionA, optionB) => filterAndSort(optionA, optionB)}
          onChange={(key, value) => {
            return onChange(key, value);
          }}
        >
          {options?.map((option, index) => {
            return option?.key && option?.value ? (
              <CustomOption
                dispatcher='true'
                value={option?.key}
                label={option?.value}
                key={index.toString()}
                style={{ width: '100%', height: 'fit-content' }}
              >
                <div
                  style={{
                    paddingTop: paddingTop || paddingtop || '15px',
                    minWidth: '100%',
                  }}
                >
                  <div
                    className='turncated'
                    title={option?.value}
                    style={{
                      width: '100%',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textAlign: 'left',
                      marginRight: '10px',
                      fontWeight: 700,
                    }}
                  >
                    {option?.value}:
                  </div>
                  <ul
                    style={{
                      justifyContent: 'right',
                      listStyle: 'none',
                      marginBottom: 0,
                    }}
                    title={option?.opt?.map((opt) => '\n' + opt?.nickName)}
                  >
                    {option?.opt?.map((opt, i) => (
                      <li key={i}>{opt?.nickName}</li>
                    ))}
                  </ul>
                </div>
              </CustomOption>
            ) : (
              <CustomOption value={option} key={index.toString()}>
                {option}
              </CustomOption>
            );
          })}
        </CustomSelect>
        {/* {downicon && <ArrowDownIcon />} */}
      </div>
    </SearchSelectWrapper>
  );
};

export default memo(SearchSelect);
