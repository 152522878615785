/**
 * Deletes specified query parameters from a URL string.
 *
 * @param {URLSearchParams} params - The URLSearchParams object containing the query parameters.
 * @param {string[]} keys - An array of keys to delete from the query parameters.
 * @returns {string} The modified URL string with specified query parameters removed.
 *
 * @example
 * // Remove specified query parameters from a URL string
 * const urlParams = new URLSearchParams(window.location.search);
 * const updatedUrl = deleteQueryParams(urlParams, ['param1', 'param2']);
 * console.log(updatedUrl);
 */
const deleteQueryParams = (params, keys) => {
  // Check if params is provided and is an instance of URLSearchParams
  if (!(params instanceof URLSearchParams)) {
    return;
  }

  // Delete specified keys from the params object
  keys.forEach((key) => params.delete(key));

  // Return the modified URL string
  return `?${params.toString()}`;
};

export { deleteQueryParams };
