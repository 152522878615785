/** @format */

export const initialState = {
  page: 1,
  size: 50,
  total: 0,
  driverOnBoardingList: [],
  driver: {},
  filtered: {},
  selected: {},
  getData: {},
  drawer: { open: false, name: '' },
  drawerSchedule: { open: false, name: '' },
  drawerHistory: { open: false, name: '' },
  permission: {},
  attachments: [],
  drawerOpenDriver: false,
  driverInfo: [],
  driverLoading: false,
  driverRefetch: () => {},
  scheduleRefetch: () => {},
  driverId: '',
  saveFunction: () => {},
  onCancelFunction: () => {},
  stateChange: false,
  teamSheetViewList: [],
  loadingTeam: false,
  drawerOpen: false,
  openFleetDashboard: false,
  drawerName: '',
  truckId: '',
  refetch: () => {},
  statusData: {},
  openStatusModal: false,
  hiringList: [],
  hiringRefetch: () => {},
  hiringOpenModal: false,
  hiringLoading: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'setDocs': {
      if (action.own === 'true') {
        return {
          ...state,
          attachments: action.payload,
        };
      }

      let formattedData = action?.payload?.map((d) => {
        return {
          id: d?.id || d?.uploadedFile?.id || d?.attachmentId,
          date: d?.date || d?.uploadedFile?.date,
          docTypeId: d?.docType?.id || d?.docTypeId,
          docTypeName: d?.docType?.name || d?.docTypeName,
          extension: d?.extension || d?.uploadedFile?.extension,
          fileOriginalName:
            d?.fileOriginalName || d?.uploadedFile?.fileOriginalName,
          name: d?.name || d?.uploadedFile?.name,
          notes:
            d?.notes ||
            d?.uploadedFile?.notes ||
            d?.note ||
            d?.uploadedFile?.note,
          path: d?.path || d?.uploadedFile?.path,
          size: d?.size || d?.uploadedFile?.size,
          createdAtDate: d?.createdAtDate,
          createdByNickname: d?.createdByNickname,
          note: d?.note,
          // uploadedFile: d?.uploadedFile || [],
        };
      });

      formattedData = state?.attachments?.length
        ? formattedData?.concat(state?.attachments)
        : formattedData;

      return {
        ...state,
        attachments: formattedData,
      };
    }
    case 'setHiringLoading':
      return { ...state, hiringLoading: action.payload };
    case 'setHiringRefetch':
      return { ...state, hiringRefetch: action.payload };
    case 'setHiringOpenModal':
      return { ...state, hiringOpenModal: action.payload };
    case 'setHiringList':
      return { ...state, hiringList: action.payload };
    case 'setStatusData':
      return { ...state, statusData: action.payload };
    case 'setOpenStatusModal':
      return { ...state, openStatusModal: action.payload };
    case 'setRefetch':
      return { ...state, refetch: action.payload };
    case 'setTruckId':
      return { ...state, truckId: action.payload };
    case 'setOpenFleetDashboard':
      return { ...state, openFleetDashboard: action.payload };
    case 'setDrawerOpen':
      return { ...state, drawerOpen: action.payload };
    case 'setDrawerName':
      return { ...state, drawerName: action.payload };
    case 'setLoadingTeam':
      return { ...state, loadingTeam: action.payload };
    case 'setTeamSheetViewList':
      return { ...state, teamSheetViewList: action.payload };
    case 'changePage':
      return { ...state, page: action.payload };
    case 'setSize':
      return { ...state, size: action.payload };
    case 'setTotal':
      return { ...state, total: action.payload };
    case 'setDriverOnBoardingList':
      return { ...state, driverOnBoardingList: action.payload };
    case 'setDriver':
      return { ...state, driver: action.payload };
    case 'setFiltered':
      return { ...state, filtered: action.payload };
    case 'setSelected':
      return { ...state, selected: action.payload };
    case 'setSelectedSchedule':
      return { ...state, selectedSchedule: action.payload };
    case 'setGetData':
      return { ...state, getData: action.payload };
    case 'setDrawer':
      return { ...state, drawer: action.payload };
    case 'setDrawerSchedule':
      return { ...state, drawerSchedule: action.payload };
    case 'setDrawerHistory':
      return { ...state, drawerHistory: action.payload };
    case 'setPermission':
      return { ...state, permission: action.payload };
    case 'setDrawerOpenDriver':
      return { ...state, drawerOpenDriver: action.payload };
    case 'setDriverInfo':
      return { ...state, driverInfo: action.payload };
    case 'setDriverLoading':
      return { ...state, driverLoading: action.payload };
    case 'setDriverRefetch':
      return { ...state, driverRefetch: action.payload };
    case 'setScheduleRefetch':
      return { ...state, scheduleRefetch: action.payload };
    case 'setDriverId':
      return { ...state, driverId: action.payload };
    case 'setSaveFunction':
      return { ...state, saveFunction: action.payload };
    case 'setOnCancelFunction':
      return { ...state, onCancelFunction: action.payload };
    case 'setStateChange':
      return { ...state, stateChange: action.payload };
    default:
      return state;
  }
};
