/** @format */

import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  padding: 0;
  border-radius: 5px;
  background: #fff;
`;
Wrapper.Content = styled.div`
  display: grid;
  grid-gap: 12px;
  padding: 0 24px;
`;
