import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';

export const AccidentContext = createContext();

export const useAccident = () => useContext(AccidentContext);

export const AccidentProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AccidentContext.Provider value={[state, dispatch]}>
      {children}
    </AccidentContext.Provider>
  );
};
