/**
 * Validates an email address using a regular expression.
 *
 * @param {string} str - The email address to validate.
 * @return {boolean} True if the email address is valid, false otherwise.
 *
 * @example
 * // Check if an email address is valid
 * const isValidEmail = emailValidation('example@example.com);
 * console.log(isValidEmail); // true
 *
 * @example
 * // Check if an email address is invalid
 * const isValidEmail = emailValidation('example@example);
 * console.log(isValidEmail); // false
 *
 * @example
 * // Check if the function handles the case where the email address is not provided
 * const isValidEmail = emailValidation();
 * console.log(isValidEmail); // false
 */

function emailValidation(str) {
  // Check if the str parameter is provided
  if (typeof str !== 'string' || str.length === 0) {
    return false;
  }

  // Regular expression pattern for validating email addresses
  const emailPattern =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/;

  // Test if the provided string matches the email pattern
  const isValid = emailPattern.test(str);

  // Return the result of the validation
  return isValid;
}

export { emailValidation };
