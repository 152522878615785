/** @format */

import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';
export const RankingContext = createContext();
export const useRanking = () => useContext(RankingContext);

export const RankingProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <RankingContext.Provider value={[state, dispatch]}>
      {children}
    </RankingContext.Provider>
  );
};
