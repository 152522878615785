/** @format */

import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';
export const ExistContext = createContext();
export const useExist = () => useContext(ExistContext);
export const ExistContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <ExistContext.Provider value={[state, dispatch]}>
      {children}
    </ExistContext.Provider>
  );
};
