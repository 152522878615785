/** @format */

import { memo, useCallback, useEffect, useState } from 'react';
import { dayjs } from 'day-js';
import { InfoWindow, Marker } from '@react-google-maps/api';

import { InfoStyle } from './style';
import withTrailer from 'assets/icons/withTrailer.png';
import { useTrucks } from 'context';
import { FuelTankIcon } from 'styledIcons';

const TruckFuelMapInfoPage = ({
  place,
  truck,
  trailer,
  isSingleTruckMarker,
  onClickTruckNumber,
}) => {
  const [open, setOpen] = useState(false);
  const [newLocationOpen, setNewLocationOpen] = useState(false);
  const [tempPosition, setTempPosition] = useState(null);
  const [{ isTruckSelected, selectedTruck }, dispatch] = useTrucks();

  useEffect(() => {
    setTempPosition(place);
    return () => {};
  }, []);

  useEffect(() => {
    if (isSingleTruckMarker) {
      dispatch({ type: 'setSelectedTruck', payload: truck });
      setOpen(false);
    }
  }, [isSingleTruckMarker]);

  const handleClose = useCallback(() => {
    dispatch({ type: 'setSelectedTruck', payload: {} });
    dispatch({ type: 'setIsTruckSelected', payload: false });

    setOpen(false);
    setNewLocationOpen(false);
    setTempPosition(place);
  }, [place]);

  const handleOpen = useCallback((truck) => {
    dispatch({ type: 'setSelectedTruck', payload: truck });
    setOpen(true);
  }, []);

  const engined = () => {
    return {
      path: window.google.maps.SymbolPath.CIRCLE,
      // animation: window.google.maps.Animation.DROP,
      fillColor: truck?.fuelPercent >= 40 ? '#06B638' : '#FFC000',
      background: truck?.fuelPercent >= 40 ? '#06B638' : '#FFC000',
      fillOpacity: 1.0,
      strokeWeight: 2,
      strokeColor: '#fff',
      scale: 7,
    };
  };
  const notEngined = () => {
    return {
      path: window.google.maps.SymbolPath.CIRCLE,
      // animation: window.google.maps.Animation.DROP,
      fillColor: '#064867',
      fillOpacity: 1.0,
      strokeWeight: 2,
      strokeColor: '#fff',
      scale: 7,
    };
  };
  const intransit = ({ marker }) => {
    return {
      path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
      // animation: window.google.maps.Animation.DROP,
      fillColor: '#06B638',
      fillOpacity: 1.0,
      strokeWeight: 1,
      strokeColor: '#fff',
      scale: 4,
      rotation: marker.rotation,
      fixedRotation: false,
    };
  };

  const getIcon = ({ status, degree, speed, trailer }) => {
    if (trailer === null) {
      if (speed > 0) {
        if (status && degree > 0) {
          return {
            icon: intransit({ marker: { rotation: degree } }),
          };
        } else if (degree > 0) {
          return {
            icon: intransit({ marker: { rotation: degree } }),
          };
        } else {
          return {
            icon: engined(),
          };
        }
      } else {
        return {
          icon: notEngined(),
        };
      }
    } else {
      return { icon: withTrailer };
    }
  };

  const handleTruck = useCallback(() => {
    if (onClickTruckNumber) onClickTruckNumber(truck);
  }, [truck]);

  return (
    <>
      <Marker
        position={tempPosition}
        icon={
          getIcon({
            status: truck?.engineStateValue,
            degree: truck?.headingDegrees,
            speed: truck?.speed,
            trailer: trailer,
          })?.icon
        }
        onClick={() => handleOpen(truck)}
      />
      <>
        {(!newLocationOpen &&
          open &&
          selectedTruck?.truckNumber === truck?.truckNumber) ||
        (isTruckSelected &&
          !newLocationOpen &&
          selectedTruck?.truckNumber === truck?.truckNumber) ? (
          <InfoWindow
            position={tempPosition || place}
            onCloseClick={handleClose}
          >
            <InfoStyle onClick={handleTruck} style={{ cursor: 'pointer' }}>
              <InfoStyle.Row>
                <InfoStyle.Text
                  lineHeight='32px'
                  name='true'
                  title={`Truck number: ${truck?.truckNumber}`}
                  style={{ cursor: 'pointer' }}
                  onClick={handleTruck}
                >
                  {truck?.truckNumber || '--'}
                </InfoStyle.Text>
                {truck?.speed ? (
                  <InfoStyle.Span title='speed'>
                    {Math.round(truck?.speed) + ' MPH'}
                  </InfoStyle.Span>
                ) : null}
              </InfoStyle.Row>
              {truck?.driverNames ? (
                <InfoStyle.Text
                  driver='true'
                  lineHeight='20px'
                  title={truck?.driverNames}
                >
                  {truck?.driverNames || '--'}
                </InfoStyle.Text>
              ) : null}
              <InfoStyle.Text lineHeight='13px' loc='true' title='address'>
                {truck?.address || '--'}
                {/* You need to enable JavaScript to run this app. */}
              </InfoStyle.Text>

              {truck?.odometerTime ? (
                <InfoStyle.Text lineHeight='13px' style={{ marginTop: '10px' }}>
                  <div>Odometer time:</div>
                  <div>
                    {dayjs(truck?.odometerTime).format('MM/DD/YYYY HH:mm')}
                  </div>
                </InfoStyle.Text>
              ) : null}

              {truck?.fuelPercent ? (
                <InfoStyle.Text style={{ justifyContent: 'start', gap: '8px' }}>
                  <FuelTankIcon
                    color={truck?.fuelPercent > 40 ? '#06B638' : '#FFC000'}
                    height='18px'
                    width='18px'
                  />
                  {truck?.fuelPercent}%
                </InfoStyle.Text>
              ) : null}
            </InfoStyle>
          </InfoWindow>
        ) : null}
      </>
    </>
  );
};

export default memo(TruckFuelMapInfoPage);
