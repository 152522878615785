/** @format */

import { useContext, createContext, useReducer } from 'react';
import { reducer, initialState } from './reducer';

export const LoadBillingContext = createContext();
export const useLoadBilling = () => useContext(LoadBillingContext);

export const LoadBillingProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <LoadBillingContext.Provider value={[state, dispatch]}>
      {children}
    </LoadBillingContext.Provider>
  );
};
