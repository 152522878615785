/** @format */

import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';

export const CitationContext = createContext();

export const useCitation = () => useContext(CitationContext);

export const CitationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <CitationContext.Provider value={[state, dispatch]}>
      {children}
    </CitationContext.Provider>
  );
};
