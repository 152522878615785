/** @format */

import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';
export const CronTaskContext = createContext();
export const useCronTask = () => useContext(CronTaskContext);

export const CronTaskProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <CronTaskContext.Provider value={[state, dispatch]}>
      {children}
    </CronTaskContext.Provider>
  );
};
