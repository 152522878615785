/** @format */

export const initialState = {
  // boolean

  drawerOpen: false,
  customerDrawerOpen: false,
  // objects
  companies: [],
  company: {},
  permission: {},
  // numbers
  total: 0,
  // function
  refetch: null,
  attachments: [],
  saveFunction: () => {},
  onCancelFunction: () => {},
  stateChange: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'setSaveFunction':
      return { ...state, saveFunction: action.payload };
    case 'setOnCancelFunction':
      return { ...state, onCancelFunction: action.payload };
    case 'setStateChange':
      return { ...state, stateChange: action.payload };
    case 'setDocs': {
      if (action.own === 'true') {
        return {
          ...state,
          attachments: action.payload,
        };
      }

      let formattedData = action?.payload?.map((d) => {
        return {
          attachmentId: d?.attachmentId || d.uploadedFile?.attachmentId,
          date: d?.date,
          docTypeId: d?.docTypeId,
          extension: d?.extension,
          name: d?.name,
          notes: d?.notes,
          path: d?.path || d?.uploadedFile?.path,
          size: d?.size,
          uploadedFile: d?.uploadedFile || [],
          createdAtDate: d?.createdAtDate,
          createdByNickname: d?.createdByNickname,
          note: d?.note,
        };
      });

      formattedData = state?.attachments?.length
        ? formattedData?.concat(state?.attachments)
        : formattedData;

      return {
        ...state,
        attachments: formattedData,
      };
    }

    case 'setDrawerOpen':
      return { ...state, drawerOpen: action.payload };
    case 'setCustomerDrawerOpen':
      return { ...state, customerDrawerOpen: action.payload };
    //! setting objects
    case 'setCompanies':
      return { ...state, companies: action.payload };
    case 'setPermission':
      return { ...state, permission: action.payload };
    //! setting numbers for pagination
    case 'setTotal':
      return { ...state, total: action.payload };
    //! function
    case 'setRefetch':
      return { ...state, refetch: action.payload };
    default:
      return state;
  }
};
