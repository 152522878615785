const { VITE_APP_BASE_URL } = import.meta.env;
import { getCookie } from 'formatter';
const downloader = {
  // Main wrapper for Fetch API

  isLoading: false,
  xslx: (url, token, method, type, body) => {
    downloader.isLoading = true;
    // return fetch(`https://api.trailer-test.nightwell-logistics.com/api${url}`, {
    return fetch(`${VITE_APP_BASE_URL}${url}`, {
      method: method ? method : 'POST',
      responseType: 'blob',
      'Content-Type': 'application/json',
      headers: {
        Authorization: `Bearer ${token || getCookie('token')}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          return response;
        }

        return Promise.reject(response);
      })
      .then((res) => res.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${new Date()}.${type ? type : 'csv'}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .finally(() => {
        downloader.isLoading = false;
      });
  },
};
export default downloader;
