/** @format */

import { Progress } from '../../AntDesign';
import styled from 'styled-components';

const StyledProgress = styled(Progress)`
  .ant-progress-bg {
    width: 100%;
    height: 15px;
    border-radius: 0px !important;
    clip-path: ${({ percent }) =>
      percent && `inset(0px ${100 - percent}% 0px 0px)`} !important;
  }
  .anticon {
    display: none !important;
  }
  &:where(
      .css-dev-only-do-not-override-p7e5j5
    ).ant-progress.ant-progress-status-success
    .ant-progress-text {
    color: #000 !important;
    font-weight: ${({ percent }) => percent === 100 && 600};
  }
`;
const StyledProgressWrapper = styled.div`
  #square .ant-progress-inner {
    position: relative;
    margin: 0 0 0 30px;
    border-radius: 0px !important;
  }

  #square .ant-progress-text {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-inline-start: 0 !important;
    font-size: 11px !important;
  }
`;
const StyledStepProgressWrapper = styled.div`
  .ant-progress-steps {
    position: relative;
    margin: 0 0 0 28px;
  }

  .ant-progress-steps .ant-progress-text {
    position: absolute;
    left: -38px;
    top: 50%;
    transform: translateY(-50%);
    white-space: nowrap;
    font-size: 13px !important;
  }
`;

const ProgressLine = ({ percent, type, width }) => {
  const getColor = (percent) => {
    switch (true) {
      case percent < 10:
        return 'red';
      case percent >= 10 && percent < 40:
        return 'orange';
      case percent >= 40:
        return 'green';
      default:
        return 'transparent'; // Fixed typo here
    }
  };
  const format = (percent) => {
    // if (percent === 100) {
    //   return `100%`; // or `${percent}%` for a more dynamic approach
    // } else {
    return `${percent}%`;
    // }
    // Return null or any other element if you want to display something else at other percentages
  };
  return (
    <>
      {type === 'steps' ? (
        <StyledStepProgressWrapper>
          <Progress
            steps={15}
            percent={percent}
            strokeColor={getColor(percent)}
            size={(20, 15)}
            strokeLinecap='square'
          />
        </StyledStepProgressWrapper>
      ) : (
        <StyledProgressWrapper>
          <StyledProgress
            id='square'
            size={[width || '100%', 15]}
            type='line'
            percent={percent}
            strokeColor={getColor(percent)}
            strokeLinecap='square'
            format={format}
          />
        </StyledProgressWrapper>
      )}
    </>
  );
};
export default ProgressLine;
