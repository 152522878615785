/** @format */

import { Suspense, lazy, memo, useEffect } from 'react';
import { PageWrapper, TopBar, TableWrapper } from 'globalCSS';
import { useRequest, useSearch, useSetQuery, useQuery } from 'hooks';
import { useDedicatedLine, useAuth } from 'context';
import { Table, Button, TopBarTitle } from 'forms';
import { Toast, MainModal } from 'modals';
import { Loading } from 'loaders';
const Edit = lazy(() => import('./Edit'));
import { columns } from './header';
import { useLocation } from 'react-router-dom';

function DedicatedLine() {
  const { search } = useLocation();
  const { request } = useRequest();
  const query = useSearch();
  const setQuery = useSetQuery();
  // context
  const [authState] = useAuth();
  const [contextData, dispatch] = useDedicatedLine();
  const { total, dedicatedLineList, permission } = contextData;

  //! setting permission
  useEffect(() => {
    if (authState?.permission?.['Planning Department']?.['Lane']) {
      dispatch({
        type: 'setPermission',
        payload: authState?.permission?.['Planning Department']?.['Lane'],
      });
    }
  }, [authState?.permission?.['Planning Department']?.['Lane']?.toString()]);

  const params = new URLSearchParams(search);
  const newUrl = `?${params.toString()}`;
  const getDedicatedLineList = useQuery(
    ['/dedicated/lane'],
    () => request({ url: `/dedicated/lane${newUrl || '?'}` }),
    {
      onSuccess: (res) => {
        if (res?.success) {
          dispatch({ type: 'setDedicatedLineList', payload: res?.dataList });
          dispatch({ type: 'setTotal', payload: res?.meta?.total });
        } else {
          Toast({ type: 'error', message: res?.message });
        }
      },
      onError: (err) => Toast({ type: 'error', message: err?.message }),
    }
  );

  //! All modal
  const closeDrawer = () => {
    setQuery('modal-name', null);
    setQuery('id', null);
    dispatch({ type: 'resetData' });
  };

  return (
    <PageWrapper>
      <MainModal
        drawerOpen={
          query.get('modal-name') === 'dedicated-line-add' ||
          query.get('modal-name') === 'dedicated-line-edit'
        }
        closeDrawer={closeDrawer}
        width='840px'
        onSave={contextData?.saveFunction}
        onCancel={contextData?.onCancelFunction}
        stateChange={contextData?.stateChange}
      >
        <Suspense fallback={<Loading />}>
          <Edit
            closeDrawer={closeDrawer}
            refetch={getDedicatedLineList?.refetch}
          />
        </Suspense>
      </MainModal>
      <TopBar>
        <TopBarTitle total={total} title='Dedicated Lane' />
        <TopBar.Right>
          <Button btnType='filter' />
          {permission?.CREATE && (
            <Button
              btnType='add'
              onClick={() => {
                setQuery('modal-name', 'dedicated-line-add');
              }}
            />
          )}
        </TopBar.Right>
      </TopBar>
      <TableWrapper>
        <Table
          rowData={dedicatedLineList}
          columnDefs={columns}
          isFetching={getDedicatedLineList?.isFetching}
          rowHeight='auto'
        />
      </TableWrapper>
    </PageWrapper>
  );
}

export default memo(DedicatedLine);
