/** @format */

export const initialState = {
  meta: {},
  permission: {},
  customersAccountList: [],
  driverList: [],
  companyList: [],
  selected: {},
  attachments: [],
  refetch: () => { },
  saveFunction: () => { },
  onCancelFunction: () => { },
  stateChange: false,
  factoringId: null,
  factoringName: null,
};
export const reducer = (state, action) => {
  switch (action.type) {
    case 'setDocs': {
      if (action.own === 'true') {
        return {
          ...state,
          attachments: action.payload,
        };
      }

      let formattedData = action?.payload?.map((d) => {
        return {
          attachmentId: d?.attachmentId || d.uploadedFile?.attachmentId,
          date: d?.date,
          docTypeId: d?.docTypeId,
          extension: d?.extension,
          name: d?.name,
          notes: d?.notes,
          path: d?.path || d?.uploadedFile?.path,
          size: d?.size,
          uploadedFile: d?.uploadedFile || [],
          createdAtDate: d?.createdAtDate,
          createdByNickname: d?.createdByNickname,
          note: d?.note,
        };
      });

      formattedData = state?.attachments?.length
        ? formattedData?.concat(state?.attachments)
        : formattedData;

      return {
        ...state,
        attachments: formattedData,
      };
    }
    case 'setMeta':
      return { ...state, meta: action.payload };
    case 'setPermission':
      return { ...state, permission: action.payload };
    case 'setCustomersAccountList':
      return { ...state, customersAccountList: action.payload };
    case 'setDrivers':
      return { ...state, driverList: action.payload };
    case 'setCompanies':
      return { ...state, companyList: action.payload };
    case 'setRefetch':
      return { ...state, refetch: action.payload };
    case 'setSelected':
      return { ...state, selected: action.payload };
    case 'setSaveFunction':
      return { ...state, saveFunction: action.payload };
    case 'setOnCancelFunction':
      return { ...state, onCancelFunction: action.payload };
    case 'setStateChange':
      return { ...state, stateChange: action.payload };
    case 'setFactoringId':
      return { ...state, factoringId: action.payload };
    case 'setFactoringName':
      return { ...state, factoringName: action.payload };
    default:
      return state;
  }
};
