/** @format */

import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';
export const PrePlanningContext = createContext();
export const usePrePlanning = () => useContext(PrePlanningContext);

export const PrePlanningProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <PrePlanningContext.Provider value={[state, dispatch]}>
      {children}
    </PrePlanningContext.Provider>
  );
};
