/** @format */

import { dayjs } from 'day-js';

export const initialState = {
  permission: {},
  data: {
    allInRate: 0,
    bookedRate: 0,
    brokerComment: '',
    brokerCompanyId: 0,
    broker: '',
    comment: '',
    dropAddress: '',
    dropCity: '',
    dropDate: '',
    dropLat: 0,
    dropLong: 0,
    dropState: '',
    dropTime: '',
    dropTimeZone: 'EST',
    dropType: 'LIVE',
    dropZip: '',
    dvrpRate: 0,
    mile: 0,
    monday: 0,
    tuesday: 0,
    wednesday: 0,
    thursday: 0,
    friday: 0,
    saturday: 0,
    sunday: 0,
    name: '',
    ownerCompanyId: 0,
    company: '',
    pickupAddress: '',
    pickupCity: '',
    pickupDate: '',
    pickupLat: 0,
    pickupLong: 0,
    pickupState: '',
    pickupTime: '',
    pickupTimeZone: 'EST',
    pickupType: 'LIVE',
    pickupZip: '',
    rateFSC: 0,
    rateType: 'LANEHAUL_FSC',
    responsibleId: '',
    responsibleName: '',

    status: true,

    toll: '',
  },
  initialData: {
    allInRate: 0,
    bookedRate: 0,
    brokerComment: '',
    brokerCompanyId: 0,
    broker: '',
    comment: '',
    dropAddress: '',
    dropCity: '',
    dropDate: '',
    dropLat: 0,
    dropLong: 0,
    dropState: '',
    dropTime: '',
    dropTimeZone: 'EST',
    dropType: 'LIVE',
    dropZip: '',
    dvrpRate: 0,
    friday: 0,
    mile: 0,
    monday: 0,
    name: '',
    ownerCompanyId: 0,
    company: '',
    pickupAddress: '',
    pickupCity: '',
    pickupDate: '',
    pickupLat: 0,
    pickupLong: 0,
    pickupState: '',
    pickupTime: '',
    pickupTimeZone: 'EST',
    pickupType: 'LIVE',
    pickupZip: '',
    rateFSC: 0,
    rateType: 'LANEHAUL_FSC',
    responsibleId: '',
    responsibleName: '',
    saturday: 0,
    status: true,
    sunday: 0,
    thursday: 0,
    toll: '',
    tuesday: 0,
    wednesday: 0,
  },
  autocompletePickup: null,
  autocompleteDrop: null,
  drawerOpen: false,
  dedicatedLineList: [],
  dedicatedLineItem: {},

  page: 1,
  size: 50,
  total: 0,
  saveFunction: () => {},
  onCancelFunction: () => {},
  stateChange: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'setSaveFunction':
      return { ...state, saveFunction: action.payload };
    case 'resetData':
      return {
        ...state,
        data: state?.initialData,
        autocompletePickup: null,
        autocompleteDrop: null,
      };
    case 'SET_DATA': {
      // const { value, key } = action.payload;
      return {
        ...state,
        data: action.payload || state?.initialData,
      };
    }
    case 'HANDLE_SELECT': {
      const { value, key } = action.payload;
      return {
        ...state,
        data: {
          ...state?.data,
          [key]: value ? value?.replaceAll(' ', '_') : null,
        },
      };
    }
    case 'HANDLE_SELECT_TYPE': {
      const { key, value } = action.payload;

      return {
        ...state,
        data: {
          ...state?.data,
          [key]: value || null,
        },
      };
    }
    case 'HANDLE_CHANGE': {
      const { value, name } = action.payload;
      return {
        ...state,
        data: {
          ...state?.data,
          [name]: value || '',
        },
      };
    }
    case 'HANDLE_DAYS_COUNT': {
      const { key, count } = action.payload;
      return {
        ...state,
        data: {
          ...state?.data,
          [key]: count || 0,
        },
      };
    }
    case 'HANDLE_NUMBER_CHANGE': {
      const { value, name } = action.payload;
      return {
        ...state,
        data: {
          ...state?.data,
          [name]: Number(value) || null,
        },
      };
    }
    case 'HANDLE_AUTOCOMPLETE_CHANGE': {
      const { value, name } = action.payload;
      return {
        ...state,
        data: {
          ...state?.data,
          [name]: value || null,
        },
      };
    }
    case 'HANDLE_AUTOCOMPLETE_SELECT': {
      const { value, opt, name, name1 } = action.payload;
      return {
        ...state,
        data: {
          ...state?.data,
          [name]: value || null,
          [name1]: opt?.id,
        },
      };
    }
    case 'HANDLE_PICK_AUTOCOMPLETE': {
      return {
        ...state,
        autocompletePickup: action.payload,
      };
    }
    case 'HANDLE_DROP_AUTOCOMPLETE': {
      return {
        ...state,
        autocompleteDrop: action.payload,
      };
    }
    case 'HANDLE_CHANGE_TIME': {
      const { time, key } = action.payload;
      return {
        ...state,
        data: {
          ...state?.data,
          [key]: time ? dayjs(time).format('HH:mm') : null,
        },
      };
    }
    case 'HANDLE_CHANGE_DATE': {
      const { date, key } = action.payload;
      return {
        ...state,
        data: {
          ...state?.data,
          [key]: date ? dayjs(date).format('YYYY-MM-DD') : null,
        },
      };
    }
    case 'HANDLE_ADDRESS_CHANGE': {
      const { name, value } = action.payload;
      return {
        ...state,
        data: {
          ...state?.data,
          [name]: value ? value : null,
        },
      };
    }
    case 'HANDLE_PICKUP_CHANGE': {
      const {
        pickupAddress,
        pickupLat,
        pickupLong,
        pickupCity,
        pickupState,
        pickupZip,
      } = action.payload;
      return {
        ...state,
        data: {
          ...state?.data,
          pickupAddress: pickupAddress,
          pickupLat: pickupLat,
          pickupLong: pickupLong,
          pickupCity: pickupCity,
          pickupState: pickupState,
          pickupZip: pickupZip,
        },
      };
    }
    case 'HANDLE_PICKUP_BLUR': {
      const { value } = action.payload;
      if (value) {
        return {
          ...state,
          data: {
            ...state?.data,
            pickupAddress: value,
          },
        };
      } else {
        return {
          ...state,
          data: {
            ...state?.data,
            pickupAddress: '',
            pickupLat: null,
            pickupLong: null,
            pickupCity: '',
            pickupState: '',
            pickupZip: '',
          },
        };
      }
    }
    case 'HANDLE_DROP_CHANGE': {
      const { dropAddress, dropLat, dropLong, dropCity, dropState, dropZip } =
        action.payload;
      return {
        ...state,
        data: {
          ...state?.data,
          dropAddress: dropAddress,
          dropLat: dropLat,
          dropLong: dropLong,
          dropCity: dropCity,
          dropState: dropState,
          dropZip: dropZip,
        },
      };
    }
    case 'HANDLE_DROP_BLUR': {
      const { value } = action.payload;
      if (value) {
        return {
          ...state,
          data: {
            ...state?.data,
            dropAddress: value,
          },
        };
      } else {
        return {
          ...state,
          data: {
            ...state?.data,
            dropAddress: '',
            dropLat: null,
            dropLong: null,
            dropCity: '',
            dropState: '',
            dropZip: '',
          },
        };
      }
    }
    case 'setOnCancelFunction': {
      return { ...state, onCancelFunction: action.payload };
    }
    case 'setStateChange':
      return { ...state, stateChange: action.payload };
    case 'setPermission':
      return { ...state, permission: action.payload };

    case 'setDrawerOpen':
      return { ...state, drawerOpen: action.payload };
    case 'setDedicatedLineList':
      return { ...state, dedicatedLineList: action.payload };
    case 'setDedicatedLineItem':
      return { ...state, dedicatedLineItem: action.payload };

    case 'changePage':
      return { ...state, page: action.payload };
    case 'setSize':
      return { ...state, size: action.payload };
    case 'setTotal':
      return { ...state, total: action.payload };
    default:
      return state;
  }
};
