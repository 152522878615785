/** @format */

import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';
export const FuelManagementContext = createContext();
export const useFuelManagement = () => useContext(FuelManagementContext);

export const FuelManagementPovider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <FuelManagementContext.Provider value={[state, dispatch]}>
      {children}
    </FuelManagementContext.Provider>
  );
};
