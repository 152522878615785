/** @format */

import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';

export const TrailerReqsContext = createContext();

export const useTrailerReq = () => useContext(TrailerReqsContext);

export const TrailerReqsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <TrailerReqsContext.Provider value={[state, dispatch]}>
      {children}
    </TrailerReqsContext.Provider>
  );
};
