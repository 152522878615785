/** @format */

import styled from 'styled-components';
import { Select } from '../../AntDesign';
import { getStatusColors } from '../../../../css/globalStyle';
const { Option } = Select;

export const StyledSelect = styled(Select)`
  box-sizing: border-box;
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ height }) => (height ? height : 'var(--appFilterHeight)')};
  border-radius: var(--appFilterBorderRadius);
  font-size: ${({ fontSize }) =>
    fontSize ? fontSize : 'var(--appFilterFontSize)'};
  .ant-select-arrow {
    display: ${({ removeicon }) => removeicon === 'true' && 'none'}!important;
    font-size: ${({ fontSize }) =>
      fontSize ? fontSize : 'var(--appFilterFontSize)'};
  }
  .ant-select-selector {
    width: 100% !important;
    font-size: ${({ fontSize }) =>
      fontSize ? fontSize : 'var(--appFilterFontSize)'};
  }
  &.small_select {
    .ant-select-arrow {
      display: none !important;
    }
    .ant-select-selection-item {
      padding-line-end: 1px !important;
      padding: 1px !important;
    }
    height: 24px !important;
    font-size: ${({ fontSize }) =>
      fontSize ? fontSize : 'var(--appFilterFontSize)'};
    &:where(.css-dev-only-do-not-override-1xej1dl).ant-select-single:not(
        .ant-select-customize-input
      )
      .ant-select-selector {
      padding: 0 1px;
    }
    &:where(
        .css-dev-only-do-not-override-1xej1dl
      ).ant-select-single.ant-select-show-arrow
      .ant-select-selection-item,
    :where(
        .css-dev-only-do-not-override-1xej1dl
      ).ant-select-single.ant-select-show-arrow
      .ant-select-selection-placeholder {
      padding-line-end: 1px !important;
    }
    .ant-select-selection-item {
      font-size: ${({ fontSize }) =>
        fontSize ? fontSize : 'var(--appFilterFontSize)'};
    }
    .ant-select-selection-search-input {
      font-size: ${({ fontSize }) =>
        fontSize ? fontSize : 'var(--appFilterFontSize)'};
    }
  }
  &.onboarding {
    height: 24px !important;
    line-height: 23px;
    font-size: ${({ fontSize }) =>
      fontSize ? fontSize : 'var(--appFilterFontSize)'};
    .ant-select-selection-item {
      height: 24px;
      line-height: 23px;
      font-size: ${({ fontSize }) =>
        fontSize ? fontSize : 'var(--appFilterFontSize)'};
    }
    .ant-select-selection-search-input {
      height: 22px !important;
      line-height: 21px !important;
      font-size: ${({ fontSize }) =>
        fontSize ? fontSize : 'var(--appFilterFontSize)'};
    }
  }
  &.autoheight {
    height: 100% !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: ${({ fontSize }) =>
      fontSize ? fontSize : 'var(--appFilterFontSize)'};
    .ant-select-selector {
      align-items: center !important;
      justify-content: center !important;
      padding: 5px 8px !important;
      font-size: ${({ fontSize }) =>
        fontSize ? fontSize : 'var(--appFilterFontSize)'};
    }
    width: ${({ width }) => width};
    .ant-select-arrow {
      ${'' /* display: none !important; */}
    }
    .ant-select .ant-select-selection-item {
      flex: none !important;
      white-space: normal !important;
      white-space: normal !important;
      text-overflow: clip !important;
      word-wrap: break-word !important;
      font-size: ${({ fontSize }) =>
        fontSize ? fontSize : 'var(--appFilterFontSize)'};
    }
    .ant-select-selector .ant-select-selection-item {
      font-size: ${({ fsize }) => fsize || 'var(--appFilterFontSize)'};
      line-height: 15px !important;
      word-wrap: break-word !important;
      white-space: break-spaces !important;
      align-self: auto !important;
      padding-inline-end: 0 !important;
      font-size: ${({ fontSize }) =>
        fontSize ? fontSize : 'var(--appFilterFontSize)'};
    }
  }
  // used in Employee, Users, truck-maintenance pages
  &.colored_cell_select {
    border: ${({ status }) => getStatusColors({ status }).brd};
    padding: 1px;
    height: 24px !important;
    line-height: 23px;
    font-size: ${({ fontSize }) =>
      fontSize ? fontSize : 'var(--appFilterFontSize)'};
    .ant-select-selection-item {
      color: ${({ status }) => getStatusColors({ status }).text} !important;
      height: 24px;
      line-height: 23px;
      font-size: ${({ fontSize }) =>
        fontSize ? fontSize : 'var(--appFilterFontSize)'};
    }
    .ant-select-selection-search-input {
      height: 22px !important;
      line-height: 21px !important;
      font-size: ${({ fontSize }) =>
        fontSize ? fontSize : 'var(--appFilterFontSize)'};
    }
    .ant-select-selector {
      border: none;
      background-color: ${({ status }) =>
        getStatusColors({ status }).bg} !important;
      font-size: ${({ fontSize }) =>
        fontSize ? fontSize : 'var(--appFilterFontSize)'};
    }
    .ant-select-arrow {
      path {
        color: ${({ status }) => getStatusColors({ status }).text} !important;
        font-size: ${({ fontSize }) =>
          fontSize ? fontSize : 'var(--appFilterFontSize)'};
      }
    }
  }
  &:where(.css-dev-only-do-not-override-1adbn6x).ant-select-single
    .ant-select-selector
    .ant-select-selection-item,
  &:where(.css-dev-only-do-not-override-1adbn6x).ant-select-single
    .ant-select-selector
    .ant-select-selection-placeholder {
    align-items: center !important;
    align-self: unset !important;
    font-size: ${({ fontSize }) =>
      fontSize ? fontSize : 'var(--appFilterFontSize)'};
  }
`;

export const StyledOption = styled(Option)`
  font-size: ${({ fontSize }) =>
    fontSize ? fontSize : 'var(--appFilterFontSize)'};
`;
