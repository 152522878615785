/** @format */

import styled from 'styled-components';
import { Select } from '../../AntDesign';
import { InputLabel } from 'globalCSS';

export const MainWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  width: ${({ width }) => width || '100%'};
  .ant-select-selector {
    border: ${({ border }) => border || '1px solid #d9d9d9'} !important;
  }
`;

export const MultiSelectLabel = styled(InputLabel)``;

export const StyledMultiSelect = styled(Select)``;
