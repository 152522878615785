/** @format */

import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { dayjs, isoWeek } from 'day-js';
dayjs.extend(isoWeek);
import { Select, MultiSelect } from 'forms';
import { PerformanceTopFilter } from 'globalCSS';
import { getCookie, setCookie, deleteCookie } from 'formatter';
import {
  useRequest,
  useQuery,
  useSetQuery,
  useSearch,
  useSetMultiQuery,
} from 'hooks';

const HeaderFilters = ({ liveRankingModal }) => {
  const { request } = useRequest();
  const [year] = useState([{ key: '2024', value: '2024' }]);
  const monthsList = useMemo(
    () =>
      dayjs
        .months()
        ?.map((m) => ({ key: m?.toUpperCase(), value: m?.toUpperCase() })),
    []
  );
  const [teams, setTeams] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [weekOptions, setWeekOptions] = useState([]);
  const setQuery = useSetQuery();
  const setMultiQuery = useSetMultiQuery();
  const query = useSearch();

  const startOfWeek = useMemo(() => {
    const startDate = query.get('date-from')
      ? dayjs(query.get('date-from'))
      : dayjs();
    return startDate.startOf('isoWeek').add(0, 'day').toDate();
  }, [query.get('date-from')]);
  const endOfWeek = useMemo(() => {
    return dayjs(startOfWeek).endOf('isoWeek').toDate();
  }, [query.get('date-from')]);
  const convertToArray = (str) => {
    return str?.split(',').map((name) => name.trim());
  };
  useEffect(() => {
    if (getCookie('team') && getCookie('team-id')) {
      setQuery('team-id', getCookie('team-id'));
      setQuery('team', getCookie('team'));
    } else {
      setQuery('team-id', null);
      setQuery('team', null);
    }
    if (getCookie('dis-name') && getCookie('dis-id')) {
      setMultiQuery('dis-id', convertToArray(getCookie('dis-id')));
      setMultiQuery('dis-name', convertToArray(getCookie('dis-name')));
    } else {
      setMultiQuery('dis-id', []);
      setMultiQuery('dis-name', []);
    }
    setQuery('date-from', dayjs(startOfWeek)?.format('YYYY-MM-DD'));
    setQuery('date-to', dayjs(endOfWeek)?.format('YYYY-MM-DD'));
    setQuery('month', dayjs(startOfWeek)?.format('MMMM')?.toUpperCase());
    setCookie(
      'selectedMonth',
      dayjs(startOfWeek)?.format('MMMM')?.toUpperCase()
    );
    setQuery('year', dayjs(startOfWeek)?.format('YYYY'));
    setQuery(
      'week',
      `Week-${dayjs(startOfWeek).week()} / ${dayjs(startOfWeek).format(
        'MMMM DD'
      )} - ${dayjs(endOfWeek).format('MMMM DD')}`
    );
    setCookie(
      'selectedWeeks',
      `Week-${dayjs(startOfWeek).week()} / ${dayjs(startOfWeek).format(
        'MMMM DD'
      )} - ${dayjs(endOfWeek).format('MMMM DD')}`
    );
    fetchMem?.refetch();
    return () => {};
  }, []);

  useEffect(() => {
    if (liveRankingModal === false) {
      setCookie('selectedMonth', query.get('month'));
      setCookie('dateFrom', query.get('date-from'));
      setCookie('dateTo', query.get('date-to'));
    }
  }, [liveRankingModal, query.get('date-from'), query.get('date-to')]);
  useEffect(() => {
    localStorage.setItem('ranking-dateFrom', query.get('date-from'));
    localStorage.setItem('ranking-dateTo', query.get('date-to'));
  }, [query.get('date-from'), query.get('date-to')]);

  const fetchMem = useQuery(
    ['`/v3/teams/dispatchers/short`', query.get('team-id')],
    () =>
      request({
        url: `/v3/teams/dispatchers/short?team_id=${
          query.get('team-id') || ''
        }`,
      }),
    {
      onSuccess: (res) => {
        if (res?.success) {
          let data = res?.dataList?.map((item) => {
            return {
              key: item?.nickName,
              value: item?.nickName,
              id: item?.userId,
            };
          });
          setTeamMembers(data);
        }
      },
    }
  );

  useQuery(
    ['/v3/teams/list/short'],
    () => request({ url: '/v3/teams/list/short' }),
    {
      onSuccess: (res) => {
        if (res?.success) {
          let data = res?.dataList?.map((item) => {
            return {
              key: item?.name,
              value: item?.name,
              id: item?.id,
            };
          });
          setTeams(data);
        }
      },
    }
  );

  const gettingWeeks = useMutation({
    mutationFn: () =>
      query?.get('year') &&
      query?.get('month') &&
      request({
        url: `/v3/kpi/week-infos/list?month=${query.get(
          'month'
        )}&year=${query.get('year')}`,
      }),
  });
  const getWeeks = (e) => {
    if (e && !weekOptions.length)
      gettingWeeks.mutate('', {
        onSuccess: (res) => {
          var option = res?.dataList?.map((item) => {
            return {
              value: item?.name,
              key: item?.name,
              dateFrom: item?.dateFrom,
              dateTo: item?.dateTo,
              year: item?.year,
              order: item?.order,
              month: item?.month,
            };
          });
          setWeekOptions(option);
        },
      });
  };
  const fetchMembers = useMutation({
    mutationFn: (id) => {
      return request({
        url: `/v3/teams/dispatchers/short?team_id=${id || ''}`,
        method: 'GET',
      });
    },
  });

  const handleTeams = useCallback((value, opt) => {
    setQuery('team', value || null);
    setQuery('team-id', opt?.id || null);
    if (opt?.id) {
      setCookie('team', value);
      setCookie('team-id', opt?.id);
    } else {
      deleteCookie('team');
      deleteCookie('team-id');
    }

    fetchMembers.mutateAsync(opt?.id, {
      onSuccess: (res) => {
        let data = res?.dataList?.map((item) => {
          return {
            key: item?.nickName,
            value: item?.nickName,
            id: item?.userId,
          };
        });
        setTeamMembers(data);
      },
    });
  }, []);
  const fetchData = useMutation({
    mutationFn: (month) => {
      return (
        month &&
        request({
          url: `/v3/kpi/week-infos/list?month=${month}&year=${query.get(
            'year'
          )}`,
          method: 'GET',
        })
      );
    },
  });
  const handleMultiSelectChange = (value, opt, name, name2) => {
    let categoryIds = opt?.map((op) => op?.id);

    if (!value?.length || value?.length === 0) {
      setMultiQuery(name, []);
      setMultiQuery(name2, []);
      deleteCookie(name);
      deleteCookie(name2);
    } else {
      setMultiQuery(name, categoryIds);
      setMultiQuery(name2, value);
      setMultiQuery('page', '');
      setCookie(name, categoryIds);
      setCookie(name2, value);
    }
  };
  const handleYear = useCallback((value) => {
    setQuery('year', value);
  }, []);
  const handleMonth = useCallback((value) => {
    setQuery('month', value);
    setCookie('selectedMonth', value);
    fetchData.mutateAsync(value, {
      onSuccess: (res) => {
        if (res?.success) {
          localStorage.setItem('ranking-dateFrom', res?.dataList[0]?.dateFrom);
          localStorage.setItem('ranking-dateTo', res?.dataList[0]?.dateTo);
          setQuery('date-from', res?.dataList[0]?.dateFrom);
          setQuery('date-to', res?.dataList[0]?.dateTo);
          setQuery('week', res?.dataList[0]?.name);
          setCookie('selectedWeeks', [res?.dataList[0]?.name]);
          let option = res?.dataList?.map((item) => {
            return {
              value: item?.name,
              key: item?.name,
              dateFrom: item?.dateFrom,
              dateTo: item?.dateTo,
              year: item?.year,
              order: item?.order,
              month: item?.month,
            };
          });
          setWeekOptions(option);
        }
      },
    });
  }, []);

  const handleWeekChange = useCallback((value, opt) => {
    setQuery('week', value);
    setQuery('date-from', opt?.dateFrom);
    setQuery('date-to', opt?.dateTo);
    setCookie('dateFrom', opt?.dateFrom);
    setCookie('ranking-dateTo', opt?.dateTo);
    setCookie('ranking-dateFrom', opt?.dateFrom);
    localStorage.setItem('ranking-dateTo', opt?.dateTo);
    localStorage.setItem('ranking-dateFrom', opt?.dateFrom);
    localStorage.setItem('dateTo', opt?.dateTo);
    localStorage.setItem('dateFrom', opt?.dateFrom);
    setCookie('dateTo', opt?.dateTo);
    setCookie('selectedWeeks', [value]);
  }, []);

  return (
    <PerformanceTopFilter
      style={{
        padding: '5px 10px',
        position: 'sticky',
        zIndex: 800,
        top: 41,
      }}
      grid='1fr 1fr 1fr 1fr 1fr'
    >
      <Select
        allOption='true'
        options={teams}
        onChange={handleTeams}
        placeholder='Search teams'
        label='Teams'
        value={query.get('team') || 'ALL'}
      />
      <MultiSelect
        label='Dispatchers'
        placeholder='Select Categories'
        maxTagCount='responsive'
        options={teamMembers}
        onChange={(value, opt) =>
          handleMultiSelectChange(value, opt, 'dis-id', 'dis-name')
        }
        value={query.getAll('dis-name') || []}
      />
      <Select
        options={year}
        onChange={(value, opt) => {
          handleYear(value, opt);
        }}
        placeholder='YYYY'
        label='Year'
        value={query.get('year') || '2024'}
      />
      <Select
        options={monthsList}
        onChange={(value, opt) => {
          handleMonth(value, opt);
        }}
        placeholder='MMMM'
        label='Month'
        value={query.get('month') || ''}
      />
      <Select
        options={weekOptions}
        onDropdownVisibleChange={getWeeks}
        onChange={(value, opt) => {
          handleWeekChange(value, opt);
        }}
        placeholder='Week member / MM-DD - MM-DD'
        label='Week'
        value={query.get('week')}
      />
    </PerformanceTopFilter>
  );
};

export default memo(HeaderFilters);
