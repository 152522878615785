import styled from 'styled-components';

const Form = styled.div`
  max-width: 100%;
  min-width: 100%;
  overflow: hidden;
`;
Form.Header = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  height: 45px;
  width: ${({ width }) => width || '100%'};
  max-width: ${({ max_width, width }) => max_width || width || 'inherite'};
  min-width: ${({ min_width, width }) => min_width || width || 'inherite'};
  padding: ${({ padding }) => padding || '0 42px 0 32px'};
  background: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
`;
Form.Title = styled.div`
  width: fit-content;
  font-weight: 600;
  font-size: ${({ font_size }) => font_size || '16px'};
  margin: ${({ margin }) => margin || 'inherite'};
`;
Form.Action = styled.div`
  width: fit-content;
`;
Form.Body = styled.div`
  width: 100%;
  padding: ${({ padding }) => padding || '0 32px 0 22px'};
  height: ${({ height }) => height || 'calc(100vh - 45px)'};
  overflow-y: overlay;
  scrollbar-gutter: stable both-edges;
  background: ${({ background }) => background || 'inherit'};
  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 5px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #777;
  }
`;

Form.Row = styled.div`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || 'fit-content'};
  margin: ${({ margin }) => margin || '14px 0'};
  display: ${({ display }) => display || 'flex'};
  column-gap: ${({ column_gap }) => column_gap || '24px'};
  justify-content: ${({ justify_content }) =>
    justify_content || 'space-between'};
`;
Form.RowTitle = styled.h3`
  width: ${({ width }) => width || '100%'};
  font-size: ${({ font_size }) => font_size || '14px'};
  margin: ${({ margin }) => margin || '0'};
  padding: ${({ padding }) => padding || '0'};
  color: ${({ color }) => color || 'inherit'};
`;

Form.Column = styled.div`
  position: ${({ position }) => position || 'relative'};
  width: ${({ width }) => width || 'inherite'};
  max-width: ${({ max_width, width }) => max_width || width || 'inherite'};
  min-width: ${({ min_width, width }) => min_width || width || 'inherite'};
  flex: ${({ flex }) => flex || 24};
  align-self: ${({ align_self }) => align_self || 'self-start'};

  background-color: ${({ background_color }) => background_color || 'inherit'};
  border: ${({ border }) => border || 'inherit'};
  padding: ${({ padding }) => padding || '0'};
  margin: ${({ margin }) => margin || '0'};
`;

export { Form };
