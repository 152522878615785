/** @format */

import {
  MainDatePickerWrapper,
  DatePickerLabel,
  DateRangePicker,
} from './style';
const dateFormat = 'MM-DD-YY HH:mm';
const CustomDateRangePicker = ({
  label,
  height,
  showTime,
  onCalendarChange,
  value,
  border,
  minwidth,
  dateformat,
  bgColor,
  width,
  mode,
  ...props
}) => {
  return (
    <MainDatePickerWrapper className='aaaaa' border={border} height={height}>
      {label && <DatePickerLabel>{label}</DatePickerLabel>}
      <DateRangePicker
        mode={mode}
        bgColor={bgColor}
        allowClear
        showTime={showTime === false ? false : true}
        allowEmpty={[true, true]}
        dateRenderer={(current) => {
          return <div>{current.date()}</div>;
        }}
        onCalendarChange={onCalendarChange}
        format={dateformat ? dateformat : dateFormat}
        value={value}
        height={height}
        placeholder={['From', 'To']}
        minwidth={minwidth}
        width={width}
        {...props}
      />
    </MainDatePickerWrapper>
  );
};
export default CustomDateRangePicker;
