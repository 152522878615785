/** @format */

import { Suspense, lazy } from 'react';

//generic modal components
export { default as VerifyOtp } from './Verify';
export { default as Toast } from './Toast';
export { default as ResetPassword } from './ResetPassword';
export { default as NotFound } from './NotFound';
export { default as ModalMiniPopup } from './ModalMiniPopup';
export { default as DeleteConfirm } from './DeleteConfirm';
export { default as Confirmation } from './Confirmation';
export { default as MainModal } from './AllModal';
export { default as Popover } from './Popover';
export { default as PopConfirm } from './PopConfirm';
export { default as FilesViewerModal } from './FilesViewerModal';
export { default as PopupModal } from './PopupModal';
export { default as PasswordChangeForUser } from './PasswordChangeForUser';
export { default as Tooltip } from './CustomTooltip';
export { default as LoadPopover } from './LoadPopover';
export { default as Modal } from './Modal';
// export { default as LoadViewModal } from './ModalWithPcMiler';
const LoadViewModalLazy = lazy(() => import('./ModalWithPcMiler'));
const TripActionsLazy = lazy(() => import('./TripModal/TripActions'));
// export { LoadViewModal };
export const LoadViewModal = (props) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <LoadViewModalLazy {...props} />
    </Suspense>
  );
};
export const TripActions = (props) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <TripActionsLazy {...props} />
    </Suspense>
  );
};
