export const initialState = {
  page: 1,
  size: 50,
  total: 0,
  notificationsList: [],
  notification: {},
  userProfile: '',
  unreadCount: 0,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'changePage':
      return { ...state, page: action.payload };
    case 'setSize':
      return { ...state, size: action.payload };
    case 'setTotal':
      return { ...state, total: action.payload };
    case 'setNotifications':
      return { ...state, notificationsList: action.payload };
    case 'setUnreadCount':
      return { ...state, unreadCount: action.payload };
    case 'setNotification':
      return { ...state, notification: action.payload };
    case 'setUserProfile':
      return { ...state, userProfile: action.payload };
    default:
      return state;
  }
};
