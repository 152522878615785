/**
 * Adds a space as a thousands separator to a given string or number.
 *
 * @param {string|number} str - The input string or number to format.
 * @returns {string} The formatted string with spaces as thousands separators.
 *
 * @example
 * // Format a numeric string
 * const result = thousandsSeparator('1234567');
 * console.log(result); // "1 234 567"
 *
 * @example
 * // Format a number
 * const result = thousandsSeparator(1234567);
 * console.log(result); // "1 234 567"
 *
 * @example
 * // Handle a number with decimal places
 * const result = thousandsSeparator(1234567.89);
 * console.log(result); // "1 234 567.89"
 *
 * @example
 * // Handle a string with decimal places
 * const result = thousandsSeparator('1234567.89');
 * console.log(result); // "1 234 567.89"
 *
 * @example
 * // Handle a small number
 * const result = thousandsSeparator(123);
 * console.log(result); // "123"
 *
 * @example
 * // Handle a number less than 1000
 * const result = thousandsSeparator(999);
 * console.log(result); // "999"
 */

const thousandsSeparator = (str) => {
  if (str === undefined || str === null) {
    return '';
  }

  // Convert the input to a string and add thousand separators
  const thousandSeparated = str
    ?.toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  return thousandSeparated;
  // return srt?.toString().replace([0-9](?=(?:[0-9]{3})+(?![0-9])))
};

export { thousandsSeparator };
