/** @format */

import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';
export const DriverOnBoardingContext = createContext();

export const useDriverOnBoarding = () => useContext(DriverOnBoardingContext);

export const DriverOnBoardingProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DriverOnBoardingContext.Provider value={[state, dispatch]}>
      {children}
    </DriverOnBoardingContext.Provider>
  );
};
