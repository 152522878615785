/** @format */

import React from 'react';
const DispatcherDrivers = React.lazy(() => import('./Drivers'));
const Planning = React.lazy(() => import('./Planning'));
const TeamSheet = React.lazy(() => import('./TeamSheet'));
const PrePlanning = React.lazy(() => import('./PrePlanning/ByState'));
const GrossBoard = React.lazy(() => import('./GrossBoard'));
const DispatcherPerformance = React.lazy(() => import('./Performance'));
const PrePlanningTab = React.lazy(() => import('./PrePlanning'));
export {
  DispatcherDrivers,
  Planning,
  TeamSheet,
  PrePlanning,
  GrossBoard,
  DispatcherPerformance,
  PrePlanningTab,
};
