/** @format */

import { useRef, useState, useEffect } from 'react';
import Button from '../../Button';

import { ButtonBox, Uploder, DeleteIcon, AttachmentIcon } from './style';

export default function FileUploader({
  setState,
  state,
  height,
  borderRadius,
  setlength,
  trip,
}) {
  const wrapperRef = useRef(null);
  const [filesState, setFilesState] = useState([]);
  useEffect(() => {
    setlength(filesState?.length);
    return () => {};
  }, [filesState]);
  const onDragEnter = () => wrapperRef.current.classList.add('dragover');

  const onDragLeave = () => wrapperRef.current.classList.remove('dragover');

  const onDrop = () => wrapperRef.current.classList.remove('dragover');

  const handleImageUpload = (e) => {
    const form = new FormData();

    const filesArr = [];
    const files = e ? [...filesState, ...e.target.files] : filesState;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      file && reader.readAsDataURL(file);
      filesArr.push(file);
      reader.onloadend = function () {
        if (trip) {
          form.append('file', file);
        } else form.append('files', file);
        setState({
          ...state,
          formData: form,
          size: file.size,
        });
      };
    }
    setFilesState(filesArr);
  };

  useEffect(() => {
    handleImageUpload();
    if (filesState?.length === 0) {
      setState((prev) => ({ ...prev, formData: '', size: '' }));
    }
    return () => {};
  }, [filesState?.length]);

  return (
    <div>
      <Uploder
        ref={wrapperRef}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        height={height}
        borderRadius={borderRadius}
      >
        <Uploder.Label>
          <Uploder.Text>Choose file or drag and drop it </Uploder.Text>
          <ButtonBox>
            <Button
              btnType='iconBlue'
              height='40px'
              width='120px'
              fontSize='12px'
            >
              Browse...
            </Button>
          </ButtonBox>
        </Uploder.Label>
        <Uploder.Input
          type='file'
          onChange={handleImageUpload}
          multiple
          title={filesState?.map((file) => file.name).join('\n')}
        />
      </Uploder>
      <Uploder.Wrapper>
        {filesState?.map((f, index) => {
          return (
            <Uploder.PreviewItem key={index}>
              <Uploder.PreviewInfo>
                <AttachmentIcon />
                <Uploder.InfoText>
                  <div className='turncate'>{f.name}</div>
                </Uploder.InfoText>
              </Uploder.PreviewInfo>
              <DeleteIcon
                multiple={'true'}
                onClick={() =>
                  setFilesState((files) =>
                    files.filter(
                      (fil) => fil.name !== f.name || f.size !== fil.size
                    )
                  )
                }
              />
            </Uploder.PreviewItem>
          );
        })}
      </Uploder.Wrapper>
    </div>
  );
}
