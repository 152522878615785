/** @format */

import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';

export const QcReportContext = createContext();
export const useQcReport = () => useContext(QcReportContext);

export const QcReportPovider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <QcReportContext.Provider value={[state, dispatch]}>
      {children}
    </QcReportContext.Provider>
  );
};
