/** @format */

export const columns = [
  // NAME
  {
    headerName: 'broker',
    field: 'brokerName',
    resizable: true,
  },
  // abbr
  {
    headerName: 'total loads',
    field: 'totalLoad',
    resizable: true,
    flex: 0.5,
  },
  {
    headerName: 'onTime',
    field: 'onTime',
    resizable: true,
    flex: 0.5,
  },
  {
    headerName: 'delayed',
    field: 'delayed',
    resizable: true,
    flex: 0.5,
  },
  {
    headerName: 'controllable',
    field: 'controllable',
    resizable: true,
    flex: 0.5,
  },
  {
    headerName: 'unControllable',
    field: 'unControllable',
    resizable: true,
    flex: 0.5,
  },
  {
    headerName: 'rejected',
    field: 'rejected',
    resizable: true,
    flex: 0.5,
  },
  {
    headerName: 'canceled',
    field: 'canceled',
    resizable: true,
    flex: 0.5,
  },
  {
    headerName: 'performance',
    field: 'performance',
    cellRenderer: ({ data }) => {
      return <>{data?.performance + '%'}</>;
    },
    resizable: true,
    flex: 0.5,
  },
];
