import styled from 'styled-components';
import { Pagination, Space } from '../../AntDesign';

export const AntdPagination = styled(Pagination)`
  .ant-pagination-item,
  .ant-select-selector {
    border-radius: 4px;
  }
`;

export const AntdSpace = styled(Space)`
  margin: ${({ margin }) => margin || '5px 0px 3px 0px'};
`;