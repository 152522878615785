import { Select } from '../../AntDesign';
import styled from 'styled-components';

export const StyledMultiSelect = styled(Select)`
  box-sizing: border-box;
  width: 100%;
  height: ${({ height }) => (height ? height : 'var(--appFilterHeight)')};
  border-radius: var(--appFilterBorderRadius);
  .ant-select-arrow {
    display: ${({ removeicon }) => removeicon === 'true' && 'none'}!important;
  }
  .ant-select-selector {
    width: 100% !important;
  }
`;
