import { Progress } from '../../AntDesign';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  width: ${({ width }) => (width ? width + 'px' : 'auto')};
  margin: ${({ margin }) => (margin ? margin : '')};
  .ant-progress-text {
    display: none !important;
  }
`;

Wrapper.Progress = styled.div`
  position: relative;
`;

Wrapper.Icon = styled.div`
  position: absolute;
  bottom: -3px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  height: max-content;
`;

Wrapper.WrapText = styled.div`
  text-align: center;
  margin-top: 16px;
`;

export const Title = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => size + 'px'};
  height: ${({ size }) => size + 'px'};
  background: ${({ bg }) => bg};
  border-radius: 100%;
  white-space: nowrap;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 14px;
  color: ${({ color }) => color};
`;

export const Desc = styled.div`
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
`;

export const ProgressBar = styled(Progress)`
  .ant-progress-inner {
    .ant-progress-circle {
      .ant-progress-circle-trail {
        stroke: ${({ circleTrail }) => circleTrail};
      }
    }
  }
`;
