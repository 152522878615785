/** @format */

export const initialState = {
  total: 0,
  page: 1,
  size: 50,
  locations: [],
  trailers: [],
  vendor: [],
  stateNamesList: [],
  trailerInfo: {},
  trailerNumber: '',
  activeKey: '',
  currentLocation: '',
  date: new Date(),
  status: '',
  trailerType: '',
  loadSize: '',
  motion: '',
  isFiltered: false,
  lastUpdate: null,
  isLoading: false,
  isError: false,
  filtered: {},
  trailerStatuses: [],
  attachments: [],
  trailerConnectionsList: [],
  selectedTrailer: {},
  refetch: () => {},
  refetchTrailersConnections: () => {},
  permission: {},
  saveFunction: () => {},
  onCancelFunction: () => {},
  stateChange: false,
  openIfoDrawer: false,
  showMap: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'setDocs': {
      if (action.own === 'true') {
        return {
          ...state,
          attachments: action.payload,
        };
      }

      let formattedData = action?.payload?.map((d) => {
        return {
          attachmentId: d?.attachmentId || d.uploadedFile?.attachmentId,
          date: d?.date,
          docTypeId: d?.docTypeId,
          name: d?.name,
          expireDate: d?.expireDate,
          notes: d?.notes,
          path: d?.path || d?.uploadedFile?.path,
          size: d?.size,
          uploadedFile: d?.uploadedFile || [],
          createdAtDate: d?.createdAtDate,
          createdByNickname: d?.createdByNickname,
          note: d?.note,
        };
      });

      formattedData = state?.attachments?.length
        ? formattedData?.concat(state?.attachments)
        : formattedData;

      return {
        ...state,
        attachments: formattedData,
      };
    }
    case 'setDocs2':
      return { ...state, attachments2: action.payload };
    case 'setData':
      return { ...state, trailers: action.payload };
    case 'setTrailerInfo':
      return { ...state, trailerInfo: action.payload };
    case 'setTrailerConnectionsList':
      return { ...state, trailerConnectionsList: action.payload };
    case 'setLocations':
      return { ...state, locations: action.payload };

    case 'setVendor':
      return { ...state, vendor: action.payload };
    case 'setStateNames':
      return { ...state, stateNamesList: action.payload };

    case 'setTrailerNumber':
      return { ...state, trailerNumber: action.payload };

    case 'setCurrentLocation':
      return { ...state, currentLocation: action.payload };

    case 'setDate':
      return { ...state, date: action.payload };

    case 'setStatus':
      return { ...state, status: action.payload };
    case 'setTrailerStatuses':
      return { ...state, trailerStatuses: action.payload };

    case 'setTrailerType':
      return { ...state, trailerType: action.payload };

    case 'setLoadSize':
      return { ...state, loadSize: action.payload };

    case 'setMotion':
      return { ...state, motion: action.payload };
    case 'setTotal':
      return { ...state, total: action.payload };
    case 'setTrailerMainPage':
      return { ...state, page: action.payload };
    case 'setTrailerMainPageSize':
      return { ...state, Size: action.payload };
    case 'setIsFiltered':
      return { ...state, isFiltered: action.payload };
    case 'setFiltered':
      return { ...state, filtered: action.payload };
    case 'setLoading':
      return { ...state, isLoading: action.payload };
    case 'setError':
      return { ...state, isError: action.payload };

    case 'setSelectedTrailer':
      return { ...state, selectedTrailer: action.payload };
    case 'setOpenInfoDrawer':
      return { ...state, openIfoDrawer: action.payload };
    case 'setShowMap':
      return { ...state, showMap: action.payload };
    case 'setRefetch':
      return { ...state, refetch: action.payload };
    case 'setRefetchTrailersConnections':
      return { ...state, refetchTrailersConnections: action.payload };
    case 'setPermission':
      return { ...state, permission: action.payload };
    case 'setSaveFunction':
      return { ...state, saveFunction: action.payload };
    case 'setOnCancelFunction':
      return { ...state, onCancelFunction: action.payload };
    case 'setStateChange':
      return { ...state, stateChange: action.payload };
    case 'setActiveKey':
      return { ...state, activeKey: action.payload };
    default:
      return state;
  }
};
