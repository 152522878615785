/** @format */

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Container, SidebarWrapper } from './style';
import { setCookie, getCookie, deleteAllCookies } from 'formatter';

import AuthManager from './Auth/AuthManager';
import AppRoutes from './Auth/AppRoutes';
import Sidebar from '../components/Layout/Sidebar';
import { useAuth } from 'context';
import { request } from 'hooks';
import FeedBackButton from '../components/Generic/FormComponents/Button/FeedBackButton';
import { getUsersCurrentDate } from '../utils/Formatter';
import { dayjs } from 'day-js';
import { useMutation } from '@tanstack/react-query';
import 'leaflet/dist/leaflet.css';

const Root = () => {
  let location = useLocation();
  const [, dispatch] = useAuth();
  const currentDate = getUsersCurrentDate(new Date(Date.now()));
  const { pathname } = location;
  const [activePath, setActivePath] = useState('');
  const expDate = getCookie('tokenExpireDate');
  let newCurrentDate = `${currentDate}Z`;
  const date1 = dayjs(expDate);
  const date2 = dayjs(newCurrentDate);
  useEffect(() => {
    if (!getCookie('token')) {
      dispatch({ type: 'logout' });
      deleteAllCookies();
      localStorage.clear();
    } else if (getCookie('token')) {
      if (date1.diff(date2) === 0) {
        dispatch({ type: 'logout' });
        deleteAllCookies();
        localStorage.clear();
      }
    }
    if (activePath?.length > 0) {
      localStorage.setItem('currentPath', activePath);
    }
    if (location?.search) {
      setCookie('currentPath', activePath + location?.search, 1);
    }
  }, [activePath, pathname, location?.search]);
  let isNotCredentialPath =
    location?.pathname !== '/signin' &&
    location?.pathname !== '/verify' &&
    location?.pathname !== '/reset-password';

  const getContants = useMutation({
    mutationFn: () =>
      getCookie('token') &&
      request({
        url: '/constants/values',
      }),
    onSuccess: (res) => {
      if (res?.success) {
        const data = res?.data;

        if (
          Object.prototype.hasOwnProperty.call(data, 'load_disabled_prev-day')
        ) {
          data.day_type = 'day';
        } else if (
          Object.prototype.hasOwnProperty.call(data, 'load_disabled_prev-week')
        ) {
          data.day_type = 'week';
        } else {
          data.day_type = 'week';
          data['load_disabled_prev-week'] = 1;
        }

        setCookie('constants', JSON.stringify({ ...data }));
      }
    },
  });
  useEffect(() => {
    getContants.mutate();
  }, [!isNotCredentialPath]);
  return (
    <Container>
      <AuthManager setActivePath={setActivePath}>
        {isNotCredentialPath && (
          <SidebarWrapper>
            <Sidebar activePath={activePath} setActivePath={setActivePath} />
          </SidebarWrapper>
        )}

        <AppRoutes activePath={activePath} setActivePath={setActivePath} />
      </AuthManager>
      <FeedBackButton />
    </Container>
  );
};

export default Root;
