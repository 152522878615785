/** @format */

import React, { forwardRef } from 'react';
import { GenericInput, Label, Box, AntInput } from './style';
import RedStars from '../RedStars';

const Input = forwardRef(
  (
    {
      isrequired,
      width,
      align,
      icon,
      label,
      bgcolor,
      plcolor,
      color,
      bcolor,
      bwidth,
      radius,
      disabled,
      type,
      minwidth,
      placeholder,
      labelmargin,
      boxWidth,
      boxMargin,
      size,
      height,
      editable,
      maxWidth,
      lineheight,
      defaultValue,
      bordercolor,
      bstyle,
      border,
      error,
      vinerror,
      truckerror,
      errormessage,
      suffix,
      prefix,
      copied,
      min,
      max,
      maxLength,
      value,

      ...props
    },
    ref
  ) => {
    const onChange = (e) => {
      const { type } = props; // Assuming type is received from props

      if (type === 'number') {
        const { value: inputValue } = e.target;
        const reg = /^-?\d*(\.\d*)?$/;

        if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
          if (typeof props.onChange === 'function') {
            props.onChange(e);
          } else {
            // eslint-disable-next-line no-console
            console.error('props.onChange is not a function');
          }
        }
      } else {
        if (typeof props.onChange === 'function') {
          props.onChange(e);
        } else {
          // eslint-disable-next-line no-console
          console.error('props.onChange is not a function');
        }
      }
    };
    return (
      <GenericInput
        copied={copied}
        width={width}
        margin={labelmargin}
        truckerror={truckerror}
        vinerror={vinerror}
      >
        {label && label !== '' ? (
          <div style={{ display: 'flex' }}>
            <Label>
              {label}
              {isrequired ? <RedStars /> : null}
            </Label>
            {errormessage && (
              <p
                style={{
                  marginBottom: 0,
                  color: 'red',
                  lineHeight: lineheight,
                  fontSize: '12px',
                  marginLeft: '5px',
                }}
              >
                {errormessage?.replace('{', '')?.replace('}', '')}
              </p>
            )}
          </div>
        ) : null}
        <Box width={boxWidth ? boxWidth : width} boxMargin={boxMargin}>
          {!icon ? (
            ''
          ) : align === 'left' ? (
            <Box.IconLeft src={icon} color={color} />
          ) : (
            <Box.IconRight src={icon} color={color} />
          )}
          <AntInput
            ref={ref}
            type={type || 'text'}
            maxLength={maxLength}
            vinerror={vinerror}
            truckerror={truckerror}
            height={height}
            align={align}
            bgcolor={bgcolor}
            border={border}
            bordercolor={bordercolor}
            color={color}
            suffix={suffix}
            prefix={prefix}
            bcolor={bcolor}
            editable={editable}
            maxWidth={maxWidth}
            bwidth={bwidth}
            plcolor={plcolor}
            radius={radius}
            disabled={disabled && true}
            icon={icon}
            minwidth={minwidth}
            placeholder={placeholder}
            styles={{ size }}
            bstyle={bstyle}
            defaultValue={defaultValue}
            value={value}
            min={min}
            max={max}
            {...props}
            onChange={onChange}
          />
          {error && <span style={{ color: 'red' }}>{error}</span>}
        </Box>
      </GenericInput>
    );
  }
);
Input.displayName = 'Input';
export default React.memo(Input);
