/** @format */
const originStyle = {
  whiteSpace: 'wrap',
  textAlign: 'left',
  height: 'inherit',
  display: 'flex',
  alignItems: 'center',
  lineHeight: 1.1,
};
import { LocationIcon } from 'styledIcons';
import { useLoads } from 'context';

export const OriginRender = ({ data }) => {
  const [, dispatch] = useLoads();
  const handleShowStops = () => {
    dispatch({ type: 'openStopList', payload: true });
    dispatch({ type: 'stopListView', payload: data?.loadStops });
  };
  return (
    <div style={originStyle}>
      <div title={data?.startLocation}>{data?.startCityState}</div>
      {data?.loadStops?.length > 0 && (
        <div
          style={{
            display: 'flex',
            gridGap: '3px',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={handleShowStops}
        >
          <LocationIcon width='12px' height='12px' />
          <div>{data?.loadStops?.length}</div>
        </div>
      )}
    </div>
  );
};
