/** @format */

import { useEffect, useState } from 'react';
import { Container, InputsBox, InputWrapper, Password, Wrapper } from './style';
import { Logo } from 'files';
import { Input, Button } from 'forms';
import { useAuth } from 'context';
import { Loading } from 'loaders';
import { useRequest, useUserMe } from 'hooks';
import { useMutation } from '@tanstack/react-query';
import { getCookie } from 'formatter';
import { useNavigate } from 'react-router';
import { Toast } from 'modals';
import { EyeIcon, EyeSlashIcon } from 'styledIcons';
// import avatar from 'assets/imgs/user-avatar2.png';

const ResetPassword = ({ setActivePath }) => {
  const [{ email }, dispatch] = useAuth();
  const getUserMe = useUserMe();

  const [load, setLoad] = useState(false);
  const { request } = useRequest();
  const navigate = useNavigate();
  const [eye, setEye] = useState({
    pass: false,
    rePass: false,
  });
  // const { VITE_APP_BASE_ME_URL } = import.meta.env;

  const passwordPattern =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{6,}$/;

  const [validPassword, setValidPassword] = useState({
    errorStatus: false,
    inputName: '',
    errorMsg: '',
  });

  const [state, setState] = useState({
    password: '',
    rePassword: '',
    email: email,
    platform: 'WEB_DASHBOARD',
  });

  const { password, rePassword, platform } = state;
  useEffect(() => {
    setActivePath([]);
    return () => {};
  }, []);

  // const getUserMe = useMutation({
  //   mutationFn: () => {
  //     return request({ baseurl: VITE_APP_BASE_ME_URL });
  //   },
  // });
  // const getLastProfileImage = useMutation({
  //   mutationFn: () => {
  //     () => {
  //       return (
  //         token &&
  //         request({
  //           url: '/user/get-last-profile-image',
  //           method: 'GET',
  //         })
  //       );
  //     };
  //   },
  // });

  const { mutate } = useMutation({
    mutationFn: (params) => {
      return request({
        url: '/auth/otp-reset-password',
        method: 'POST',
        body: params,
      });
    },
  });

  const handleSubmit = () => {
    setLoad(true);
    mutate(
      {
        email: getCookie('user-email'),
        password: password,
        platform: platform,
      },
      {
        onSuccess: async (res) => {
          if (res?.success && res?.meta?.token) {
            dispatch({ type: 'login', payload: res.meta.token });
            setLoad(false);
            getUserMe?.mutateAsync();
            // getLastProfileImage?.mutate(
            //   {},
            //   {
            //     onSuccess: (response) => {
            //       if (response?.success) {
            //         localStorage.setItem(
            //           'profile-image',
            //           response?.data?.path ? response?.data?.path : avatar
            //         );
            //       }
            //     },
            //   }
            // );
            localStorage.removeItem('user-email');
            localStorage.removeItem('reset-password');

            dispatch({ type: 'setPreLoader', payload: false });
            navigate('/main/all');
          }

          if (!res?.success) {
            let errNew = {};
            res?.response?.data?.dataList?.map(({ key, value }) => {
              errNew[key] = value;
            });
            setLoad(false);
            dispatch({ type: 'setPreLoader', payload: false });

            if (!(res?.response?.data?.dataList?.length > 0)) {
              Toast({
                type: 'error',
                message:
                  res?.response?.data?.message ||
                  'Something went wrong while signing in!',
              });
            }
          }
        },
        onError: (err) => {
          setLoad(false);
          dispatch({ type: 'setPreLoader', payload: false });

          Toast({
            type: 'error',
            message: err?.message || 'Something went wrong while signing in!',
          });
        },
      }
    );
  };

  const goBack = () => {
    navigate('/signin');
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (password?.length > 0 && name === 'rePassword') {
      if (password !== value) {
        setValidPassword({
          errorStatus: true,
          errorMsg: 'Password did not match.',
        });
      } else {
        setValidPassword({
          errorStatus: false,
          errorMsg: '',
        });
      }
    }
  };
  const onChange = (e) => {
    const { name, value } = e.target;

    if (!passwordPattern.test(value)) {
      setValidPassword({
        errorStatus: true,
        errorMsg:
          'Password must contain at least one uppercase letter, one lowercase letter, one number, one special character and at least 8 characters.',
      });
    } else {
      setValidPassword({
        errorStatus: false,
        inputName: '',
        errorMsg: '',
      });
    }
    setState({
      ...state,
      [name]: value,
    });
    if (password?.length > 0 && name === 'rePassword') {
      if (password !== value) {
        setValidPassword({
          errorStatus: true,
          inputName: 'rePassword',
          errorMsg: 'Password did not match.',
        });
      } else {
        setValidPassword({
          errorStatus: false,
          inputName: '',
          errorMsg: '',
        });
      }
    }
    if (rePassword?.length > 0 && name === 'password') {
      if (rePassword !== value) {
        setValidPassword({
          errorStatus: true,
          errorMsg: 'Password did not match.',
        });
      } else {
        setValidPassword({
          errorStatus: false,
          errorMsg: '',
        });
      }
    }
  };

  const inputsStyles = {
    height: '36px',
    color: '#313E47',
    bgcolor: '#fff',
  };

  return (
    <Container>
      <Logo />
      <Wrapper>
        <InputsBox>
          <InputWrapper>
            <InputWrapper.Top>
              <InputWrapper.Label>Password</InputWrapper.Label>
            </InputWrapper.Top>
            <div style={{ position: 'relative' }}>
              <Input
                {...inputsStyles}
                type={!eye.pass ? 'text' : 'password'}
                name='password'
                onChange={onChange}
                onBlur={handleBlur}
                value={password}
                placeholder={'Password'}
              />
              <Password>
                <EyeIcon
                  eye={eye.pass.toString()}
                  onClick={() => {
                    setEye({ ...eye, pass: false });
                  }}
                />
                <EyeSlashIcon
                  eye={eye.pass.toString()}
                  onClick={() => {
                    setEye({ ...eye, pass: true });
                  }}
                />
              </Password>
            </div>

            <InputWrapper.Warn incorrect={validPassword?.errorStatus}>
              {validPassword?.errorMsg}
            </InputWrapper.Warn>
          </InputWrapper>

          <InputWrapper>
            <InputWrapper.Top>
              <InputWrapper.Label>Retype password</InputWrapper.Label>
            </InputWrapper.Top>
            <div style={{ position: 'relative' }}>
              <Input
                {...inputsStyles}
                type={!eye.rePass ? 'text' : 'password'}
                name='rePassword'
                onChange={onChange}
                value={rePassword}
                placeholder={'Retype password '}
              />
              <Password>
                <EyeIcon
                  eye={eye.rePass.toString()}
                  onClick={() => {
                    setEye({ ...eye, rePass: false });
                  }}
                />
                <EyeSlashIcon
                  eye={eye.rePass.toString()}
                  onClick={() => {
                    setEye({ ...eye, rePass: true });
                  }}
                />
              </Password>
            </div>
            <InputWrapper.Warn
              incorrect={
                validPassword?.errorStatus &&
                validPassword?.errorMsg === 'Password did not match.'
              }
            >
              {validPassword?.errorMsg}
            </InputWrapper.Warn>
          </InputWrapper>
          <InputWrapper>
            <Button
              className='reset'
              width='100%'
              margin='3px 0'
              onClick={handleSubmit}
            >
              {load ? (
                <Loading
                  color='#fff'
                  size='default'
                  height={'100%'}
                  fontsize='22px'
                />
              ) : (
                <div>Save</div>
              )}
            </Button>
          </InputWrapper>
          <InputWrapper>
            <div
              style={{
                display: 'flex',
                textAlign: 'center',
                gridGap: 3,
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <div>Go back to</div>{' '}
              <div className='goBackToSignIn' onClick={goBack}>
                {' '}
                Sing in page
              </div>
            </div>
          </InputWrapper>
        </InputsBox>
      </Wrapper>
    </Container>
  );
};

export default ResetPassword;
