/** @format */

import { lazy, useEffect, useState, Suspense, memo, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { deleteQueryParams } from 'formatter';
import { useAuth, useLoads } from 'context';
import {
  Button,
  Table,
  DriverInfoPanel,
  ExportButton,
  TopBarTitle,
  Pagination,
} from 'forms';
import { MainModal, LoadViewModal } from 'modals';
import { useRequest, useSetQuery, useSearch, useQuery } from 'hooks';
import { column } from './header';
import { PageWrapper, TopBar } from 'globalCSS';
import HeaderFiltersWithDate from './HeaderFiltersWithDate';
// import HeaderFilter from './HeaderFilter';

const StopListView = lazy(() => import('./StopListView'));
const Assigner = lazy(() => import('./Assigner'));
const AddBroker = lazy(() => import('./../DataLibrary/Company/EditCompany'));
const AddLoad = lazy(() => import('../Loads/AddLoad'));

const ScheduledLoad = () => {
  // hooks
  const setQuery = useSetQuery();
  const { search } = useLocation();
  const { request } = useRequest();
  const query = useSearch();
  // context
  const [
    {
      assignedDr,
      openStopList,
      stopListView,
      openAssigner,
      loadId,
      // permission,
      loadNumber,
      drawerAddLoad,
      saveFunction,
      onCancelFunction,
      stateChange,
      total,
    },
    dispatch,
  ] = useLoads();
  const [{ state }] = useAuth();
  // const [planingData, setPlaningData] = useState([]);
  const [loadModalWith, setLoadMadalWidth] = useState('1100px');
  const [drawerCompany, setDrawerCompany] = useState(false);

  const pms = state?.permission?.['Load Group']?.['Load'];
  useEffect(() => {
    if (pms) {
      dispatch({ type: 'setPermission', payload: pms });
    }
    return () => {};
  }, [pms?.toString()]);

  const newUrl = useMemo(() => {
    const params = new URLSearchParams(search);
    const keysToDelete = [
      'load_id',
      'driver-id',
      'driver-info-panel',
      'co-driver-id',
      'id-of-load',
      'trip-action-modal',
    ];
    return deleteQueryParams(params, keysToDelete);
  }, [search]);
  const getUpcoming = useQuery(
    [`/v3/load/pageable/list${newUrl || '?'}`, newUrl],
    () =>
      request({
        url: `/v3/load/pageable/list${newUrl || '?'}`,
      }),

    {
      onSuccess: (response) => {
        // setPlaningData(response?.dataList);

        dispatch({ type: 'setTotal', payload: response?.meta?.total });
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    dispatch({ type: 'setRefetch', payload: getUpcoming?.refetch });
    return () => {};
  }, []);

  const closeDrawerAddLoad = () => {
    setQuery('id-of-load', null);
    dispatch({ type: 'setDrawerAddLoad', payload: false });
    dispatch({ type: 'setStateChange', payload: false });
    dispatch({ type: 'doReset' });
  };

  const closeCompany = () => {
    setDrawerCompany(!drawerCompany);
  };

  const closeDrawer = () => {
    // getUpcoming?.refetch();
    setQuery('load_id', null);
  };

  return (
    <PageWrapper style={{ height: '100%', margin: 0 }}>
      <Suspense fallback={''}>
        <MainModal
          drawerOpen={drawerCompany}
          width={'648px'}
          closeDrawer={closeCompany}
        >
          <AddBroker closeDrawer={closeCompany} />
        </MainModal>
        <MainModal
          drawerOpen={Boolean(query.get('id-of-load')) || drawerAddLoad}
          width={loadModalWith}
          closeDrawer={closeDrawerAddLoad}
          onSave={saveFunction}
          onCancel={onCancelFunction}
          stateChange={stateChange}
          refetch={getUpcoming?.refetch}
        >
          <AddLoad
            refetch={getUpcoming?.refetch}
            closeDrawerAddLoad={closeDrawerAddLoad}
            setLoadMadalWidth={setLoadMadalWidth}
            fetching={getUpcoming?.isLoading}
          />
        </MainModal>
        <MainModal
          drawerOpen={openStopList}
          width={'668px'}
          noPadding
          closeDrawer={() => dispatch({ type: 'openStopList', payload: false })}
        >
          <StopListView data={stopListView} />
        </MainModal>
        <Assigner
          open={openAssigner}
          onCancel={() => dispatch({ type: 'openAssigner', payload: false })}
          load={{
            loadId,
            loadNumber,
            assigned: assignedDr,
          }}
          refetch={getUpcoming?.refetch}
        />
        <MainModal
          drawerOpen={query.get('driver-info-panel')}
          width={query.get('co-driver-id') ? '900px' : '450px'}
          closeDrawer={() => {
            setQuery('driver-info-panel');
            setQuery('driver-id');
            setQuery('co-driver-id');
          }}
        >
          <div style={{ display: 'flex' }}>
            <DriverInfoPanel driverId={query.get('driver-id')} />
            {query.get('co-driver-id') && (
              <DriverInfoPanel driverId={query.get('co-driver-id')} />
            )}
          </div>
        </MainModal>

        <LoadViewModal
          transit
          drawerOpen={query.get('load_id') || false}
          component='load'
          closeDrawer={closeDrawer}
          pageRefetch={getUpcoming?.refetch}
          pageLoading={getUpcoming?.isLoading}
          name='teamsheet'
          isTeamsheet={true}
        />
      </Suspense>

      <TopBar>
        <TopBarTitle total={0} title={'Scheduled Loads '} />
        <TopBar.Right>
          <Button btnType={'filter'} noText />
          <ExportButton url={'/v3/load/export'} noText />
        </TopBar.Right>
      </TopBar>
      <HeaderFiltersWithDate />
      <Table
        rowData={[]}
        columnDefs={column}
        isLoading={getUpcoming?.isLoading}
        isFetching={getUpcoming?.isFetching}
        rowHeight='auto'
      />
      <Pagination length={0} total={total} />
    </PageWrapper>
  );
};

export default memo(ScheduledLoad);
