/** @format */

import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';

export const CompaniesContext = createContext();
export const useCompanies = () => useContext(CompaniesContext);

export const CompaniesPovider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <CompaniesContext.Provider value={[state, dispatch]}>
      {children}
    </CompaniesContext.Provider>
  );
};
