/** @format */

import { useQuery as useTanstackQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

export const useQuery = (
  queryKey,
  queryFn,
  { enabled, onSuccess, onError, ...props }
) => {
  const keys = Array.isArray(queryKey)
    ? queryKey?.filter((key) => key !== undefined)
    : queryKey !== undefined
    ? [queryKey]
    : [null];
  const { data, error, status, ...rest } = useTanstackQuery({
    queryKey: keys,
    queryFn,
    enabled: enabled || true,
    retry: false,
    refetchOnWindowFocus: props?.refetchOnWindowFocus || false,
    refetchOnReconnect: 'always',
    ...props,
  });

  useEffect(() => {
    if (status === 'success') {
      onSuccess && onSuccess(data);
    }
    if (status === 'error') {
      onError && onError(error);
    }
    return () => {};
  }, [rest.fetchStatus]);

  return {
    data,
    error,
    status,
    ...rest,
  };
};
