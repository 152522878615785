/** @format */

export const initialState = {
  // objects
  docTypes: [],
  docType: {},
  filtered: {},
  permission: {},
  docTypeTypeNames: [],
  // numbers
  page: 1,
  size: 50,
  total: 0,
  // functions
  refetch: null,
  saveFunction: () => {},
  onCancelFunction: () => {},
  stateChange: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'setSaveFunction':
      return { ...state, saveFunction: action.payload };
    case 'setOnCancelFunction':
      return { ...state, onCancelFunction: action.payload };
    case 'setStateChange':
      return { ...state, stateChange: action.payload };
    //! objects
    case 'setDocTypes':
      return { ...state, docTypes: action.payload };
    case 'setDocType':
      return { ...state, docType: action.payload };
    case 'setFiltered':
      return { ...state, filtered: action.payload };
    case 'setPermission':
      return { ...state, permission: action.payload };
    case 'setDocTypeTypeNames':
      return { ...state, docTypeTypeNames: action.payload };
    //! numbers
    case 'changePage':
      return { ...state, page: action.payload };
    case 'setSize':
      return { ...state, size: action.payload };
    case 'setTotal':
      return { ...state, total: action.payload };
    //! functions
    case 'setRefetch':
      return { ...state, refetch: action.payload };
    default:
      return state;
  }
};
