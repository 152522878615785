/** @format */

import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';

export const ExpenseContext = createContext();

export const useExpense = () => useContext(ExpenseContext);

export const ExpenseProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ExpenseContext.Provider value={[state, dispatch]}>
      {children}
    </ExpenseContext.Provider>
  );
};
