/** @format */

import React from 'react';

const Trucks = React.lazy(() => import('./Trucks'));
const Reservation = React.lazy(() => import('./Reservation'));
const AnnualInspection = React.lazy(() =>
  import('./Inspections/AnnualInspection')
);
const PAInspection = React.lazy(() => import('./Inspections/PAInspection'));
const WeeklyInspection = React.lazy(() =>
  import('./Inspections/WeeklyInspection')
);
const Pm = React.lazy(() => import('./Pm'));
const OrginalContracts = React.lazy(() => import('./OriginalContact'));
const RegCard = React.lazy(() => import('./RegCard'));
const OnBoarding = React.lazy(() => import('./OnBoarding'));
const FleetDashboard = React.lazy(() => import('./FleetDashboardModal'));
const WeeklyHistory = React.lazy(() =>
  import('./Inspections/WeeklyInspection/InspectionHistory')
);
const Permit = React.lazy(() => import('./Permit'));
const TruckMaintenance = React.lazy(() => import('./Maintenance'));
const TruckStatistics = React.lazy(() => import('./Statistics'));
const TrucksBoard = React.lazy(() => import('./TrucksBoard'));

export {
  Trucks,
  Reservation,
  Pm,
  AnnualInspection,
  PAInspection,
  WeeklyInspection,
  WeeklyHistory,
  RegCard,
  OnBoarding,
  OrginalContracts,
  FleetDashboard,
  Permit,
  TruckMaintenance,
  TruckStatistics,
  TrucksBoard,
};
