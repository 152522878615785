/** @format */

import { memo, useState } from 'react';
import { Wrapper } from './style';
import Tabs from './Tabs';
import Map from 'map';
import { useRequest, useQuery } from 'hooks';
import { Toast } from 'modals';

const TruckBox = ({ truckId }) => {
  const [item, setItem] = useState();
  const [currentGpsData, setCurrentGpsData] = useState();
  const { request } = useRequest();

  const getInfo = useQuery(
    ['/truck/${id}/get_info', truckId],
    () => {
      return (
        truckId &&
        request({
          url: `/truck/${truckId}/get_short_info`,
        })
      );
    },
    {
      onSuccess: async (res) => {
        setItem({
          ...res?.data,
          drivers: [res?.data?.driver, res?.data?.coDriver],
        });
        const data = [
          {
            address: res?.data?.truckCurrentGps?.address,
            gpsAction: res?.data?.truckCurrentGps?.gpsAction,
            lat: res?.data?.truckCurrentGps?.latitude,
            lng: res?.data?.truckCurrentGps?.longitude,
            seconds: res?.data?.truckCurrentGps?.seconds,
            speed: res?.data?.truckCurrentGps?.speed,
            timeStamp: res?.data?.truckCurrentGps?.timeStamp,
            lastUpdated: res?.data?.truckCurrentGps?.timeStamp,
            isFromTeamSheet: true,
            truckNumber: res?.data?.truckNumber,
            truckId: res?.data?.truckId,
          },
        ];
        setCurrentGpsData(data);
      },
      onError: (err) => {
        Toast({
          type: 'error',
          message: err?.message,
        });
      },

      refetchOnWindowFocus: false,
    }
  );

  return (
    <div style={{ display: 'flex' }}>
      <Wrapper>
        <div style={{ padding: '0 24px 24px 14px' }}>
          <Tabs
            item={item}
            isLoading={getInfo?.isLoading || getInfo?.isFetching}
          />
        </div>
      </Wrapper>
      <Map
        id={'nightwell-map'}
        height={'100vh'}
        width='600px'
        isTruckMap={true}
        isLoading={getInfo?.isFetching}
        locations={currentGpsData}
        isSingleTruckMarker={true}
      />
    </div>
  );
};

export default memo(TruckBox);
