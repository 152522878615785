/** @format */

import { lazy } from 'react';

const DOTReport = lazy(() => import('./DOTReport'));
const FMCSACode = lazy(() => import('./FMCSA'));
const Eld = lazy(() => import('./Eld'));
const Citation = lazy(() => import('./Citation'));
const CitationExpense = lazy(() => import('./CitationExpense'));
const SafetyDashboard = lazy(() => import('./SafetyDashboard'));

export {
  DOTReport,
  FMCSACode,
  Eld,
  Citation,
  CitationExpense,
  SafetyDashboard,
};
