/**
 * Formats the provided date object into an ISO date string.
 *
 * @param {Date} currentDate - The date object to format.
 * @returns {string | null} The formatted ISO date string, or null if the currentDate is not a valid Date object.
 *
 * @example
 * // Format the current date
 * const formattedDate = getUsersCurrentDate(new Date());
 * console.log(formattedDate); // "2024-06-11T12:00:00"
 *
 * @example
 * // Attempt to format an invalid date (null)
 * const formattedDate = getUsersCurrentDate(null);
 * console.log(formattedDate); // null
 */

const getUsersCurrentDate = (currentDate) => {
  // Check if currentDate is a valid Date object
  if (!(currentDate instanceof Date) || isNaN(currentDate.getTime())) {
    return null;
  }

  // Get the year, month, day, hours, minutes, seconds, and milliseconds from the currentDate
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');
  const seconds = String(currentDate.getSeconds()).padStart(2, '0');
  const milliseconds = String(currentDate.getMilliseconds()).padStart(3, '0');

  // Create the ISO date string
  const isoDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;

  // Return the ISO date string
  return isoDate;
};

export { getUsersCurrentDate };
