/** @format */

import {
  TypeRenderer,
  DataRenderer,
  StatusRenderer,
  RateTypeRenderer,
  RateRenderer,
  ActionsRenderer,
  ResponsibleRenderer,
} from './HeaderComponents';
export const columns = [
  {
    headerName: 'ACCOUNT',
    field: 'account',
    title: 'field',
    cellRenderer: (data) => DataRenderer(data, 'name'),
    flex:1,
  },
  {
    headerName: 'LOAD TYPE',
    cellRenderer: TypeRenderer,
    flex:1
  },

  {
    headerName: 'BROKER',
    field: 'broker',
    cellRenderer: (data) => DataRenderer(data, 'broker'),
    title: 'field',
    flex: 1,
  },

  {
    headerName: 'PICKUP',
    field: 'pickup',
    title: 'field',
    cellRenderer: (data) =>
      DataRenderer(data, 'pickupAddress', 'pickupAddress'),
    flex: 0.9,
  },

  {
    headerName: 'DELIVERY',
    field: 'drop',
    title: 'field',
    cellRenderer: (data) => DataRenderer(data, 'dropAddress', 'dropAddress'),
    flex: 0.9,
  },

  {
    headerName: 'MILES',
    cellRenderer: (data) => DataRenderer(data, 'mile'),
    width: 75
  },
  {
    headerName: 'Rate type',
    field: 'locationUrl',
    cellRenderer: RateTypeRenderer,
    width: 110,
  },
  {
    headerName: 'Booked rate',
    field: 'locationUrl',
    title: 'field',
    cellRenderer: (data) => RateRenderer(data, 'bookedRate'),
    width: 105
  },
  {
    headerName: 'Rate included',
    field: 'allInRate',
    title: 'field',
    cellRenderer: (data) => RateRenderer(data, 'allInRate'),
    width: 110
  },
  {
    headerName: 'Current rate',
    field: 'currentRate',
    title: 'field',
    cellRenderer: (data) => RateRenderer(data, 'currentRate'),
    width: 105
  },
  {
    headerName: 'Current DVRP rate',
    field: 'dvrpRate',
    title: 'field',
    cellRenderer: (data) => RateRenderer(data, 'dvrpRate'),
    width: 140
  },
  {
    headerName: 'prm',
    field: 'prm',
    title: 'field',
    cellRenderer: (data) => RateRenderer(data, 'prmRate'),
    width: 50,
  },
  {
    headerName: 'Volume',
    field: 'locationUrl',
    title: 'field',
    cellRenderer: (data) => DataRenderer(data, 'volume'),
    width: 70,
  },
  {
    headerName: 'Lane representative',
    field: 'responsibleName',
    cellRenderer: ResponsibleRenderer,
    title: 'field',
    flex: 1,
  },
  {
    headerName: 'Status',
    field: 'locationUrl',
    title: 'field',
    cellRenderer: StatusRenderer,
    width: 65
  },
  {
    headerName: 'Action',
    field: '',
    title: '',
    width: 65,
    cellRenderer: ActionsRenderer,
  },
];
