/** @format */

import { Fragment } from 'react';
import { BackButton, Title, Total, Wrapper } from './style';
import { ArrowLeftSingle } from 'styledIcons';

const TopBarTitle = ({ title, total, border, isAnotherPage, navigate }) => {
  const goBack = () => navigate('/performance');

  const titleParts = title.split('/').map((part) => part.trim());
  const formattedTitle = titleParts.map((part, index) => (
    <Fragment key={index}>
      {index !== 0 && <h1> / </h1>}{' '}
      <h1
        style={{ color: index === titleParts.length - 1 ? 'blue' : 'inherit' }}
      >
        {part}
      </h1>
    </Fragment>
  ));

  return (
    <Wrapper border={border}>
      {isAnotherPage && (
        <BackButton onClick={goBack} aria-label='Go back'>
          <ArrowLeftSingle id='back__button' />
          <div>
            <h1>Back</h1>
          </div>
        </BackButton>
      )}
      <Title>
        {formattedTitle.reduce((prev, curr) => (
          <>
            {prev}
            {curr}
          </>
        ))}{' '}
        {/* Combining all parts into one line */}
      </Title>
      {total && (
        <Total>
          <h1>{total} found</h1>
        </Total>
      )}
    </Wrapper>
  );
};

export default TopBarTitle;
