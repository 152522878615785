/** @format */

import { Suspense, lazy } from 'react';
import Loading from '../../Generic/Loaders/Loading';
const QcBoardLazy = lazy(() => import('./QcPanel'));
const QcPerformanceLazy = lazy(() => import('./index'));
const QcStatisticsLazy = lazy(() => import('./Statistics'));
export const QcBoard = (props) => {
  return (
    <Suspense>
      <QcBoardLazy {...props} fallback={<Loading />} />
    </Suspense>
  );
};
export const QcPerformance = (props) => {
  return (
    <Suspense>
      <QcPerformanceLazy {...props} fallback={<Loading />} />
    </Suspense>
  );
};
export const QcStatistics = (props) => {
  return (
    <Suspense>
      <QcStatisticsLazy {...props} fallback={<Loading />} />
    </Suspense>
  );
};
