/** @format */

export const initialState = {
  page: 1,
  size: 50,
  total: 0,
  dispatcherBoardData: [],
  dispatcherBoardList: [],
  dispatcherBoardTeam: [],
  driversStatus: [],
  driverStatus: [],
  dispatchers: [],
  data: [],
  refetch: () => {},
  reload: false,
  refresh: '',
  download: '',
  status: '',
  id: '',
  unitId: '',
  drawerOpen: false,
  openSearch: false,
  team: [],

  filterPlanning: false,
  filterTeam: false,
  path: 'all',
  filterAll: false,
  loadDrawer: false,
  currentGpsData: {},
  refetchCounter: '',
  funcGetDrStatus: '',
  drawerOpenDriver: false,
  driverInfo: [],
  drivers: [],
  driverLoading: false,
  driverRefetch: () => {},
  driverId: '',
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'status':
      return { ...state, status: action.payload };
    case 'refresh':
      return { ...state, refresh: action.payload };
    case 'setFuncGetDrStatus':
      return { ...state, funcGetDrStatus: action.payload };
    case 'download':
      return { ...state, download: action.payload };
    case 'changePage':
      return { ...state, page: action.payload };
    case 'setSize':
      return { ...state, size: action.payload };
    case 'setTotal':
      return { ...state, total: action.payload };
    case 'setDispatchers':
      return { ...state, dispatchers: action.payload };
    case 'setDispatcherBoardData':
      return { ...state, dispatcherBoardData: action.payload };

    case 'setPath':
      return { ...state, path: action.payload };
    case 'setFilterTeam':
      return { ...state, filterTeam: action.payload };
    case 'setFilterPlanning':
      return { ...state, filterPlanning: !state.filterPlanning };
    case 'setFilterAll':
      return { ...state, filterAll: action.payload };
    case 'setDispatcherBoardsList':
      return {
        ...state,
        dispatcherBoardList: action.payload,
      };
    case 'setDispatcherBoardsTeam':
      return { ...state, dispatcherBoardTeam: action.payload };
    case 'setDriversStatus':
      return { ...state, driversStatus: action.payload };
    case 'setData':
      return { ...state, data: action.payload };
    case 'setRefetch':
      return { ...state, refetch: action.payload };
    case 'setRefetchCounter':
      return { ...state, refetchCounter: action.payload };
    case 'setDriverStatus':
      return { ...state, driverStatus: action.payload };
    case 'setDrivers':
      return { ...state, drivers: action.payload };
    case 'closeDrawer':
      return { ...state, drawerOpen: false };
    case 'openDrawer':
      return { ...state, drawerOpen: true };
    case 'setOpenSearch':
      return { ...state, openSearch: action.payload };
    case 'closeLoadDrawer':
      return { ...state, loadDrawer: false };
    case 'openLoadDrawer':
      return { ...state, loadDrawer: action.payload };
    case 'setId':
      return { ...state, id: action.payload };
    case 'setUnitId':
      return { ...state, unitId: action.payload };
    case 'setCurrentData':
      return { ...state, currentGpsData: action.payload };
    case 'setTeam':
      return { ...state, team: action.payload };
    case 'isLoading':
      return { ...state, reload: action.payload };
    case 'setDrawerOpenDriver':
      return { ...state, drawerOpenDriver: action.payload };
    case 'setDriverInfo':
      return { ...state, driverInfo: action.payload };
    case 'setDriverLoading':
      return { ...state, driverLoading: action.payload };
    case 'setDriverRefetch':
      return { ...state, driverRefetch: action.payload };
    case 'setDriverId':
      return { ...state, driverId: action.payload };
    default:
      return state;
  }
};
