import { deleteCookie } from './deleteCookie.js';

/**
 * Deletes all cookies by setting their expiration date to a past time.
 *
 * @example
 * // Delete all cookies
 * deleteAllCookies();
 */

function deleteAllCookies() {
  // Split the document.cookie string into individual cookies and iterate over them
  document.cookie.split(';').forEach(function (c) {
    // Remove leading spaces and replace the cookie's value with an empty string to delete it
    document.cookie = c
      .replace(/^ +/, '')
      .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
  });

  // Split the document.cookie string again to ensure all cookies are deleted
  let cookies = document.cookie.split(';');

  // Check if there are any cookies to delete
  if (cookies.length === 0) {
    return;
  }

  // Iterate over each cookie and delete it
  cookies.forEach((cookie) => {
    // Extract the cookie name and trim any leading or trailing spaces
    const cookieName = cookie.split('=')[0].trim();
    // Delete the cookie using the deleteCookie function imported from index.js
    deleteCookie(cookieName);
  });
}

export { deleteAllCookies };
