export const initialState = {
  permission: {},

  drawerOpen: false,
  truckGpsList: [],
  truckGpsItem: {},

  page: 1,
  size: 50,
  total: 0,
  saveFunction: () => {},
  onCancelFunction: () => {},
  stateChange: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'setSaveFunction':
      return { ...state, saveFunction: action.payload };
    case 'setOnCancelFunction':
      return { ...state, onCancelFunction: action.payload };
    case 'setStateChange':
      return { ...state, stateChange: action.payload };
    case 'setPermission':
      return { ...state, permission: action.payload };

    case 'setDrawerOpen':
      return { ...state, drawerOpen: action.payload };
    case 'setTruckGpsList':
      return { ...state, truckGpsList: action.payload };
    case 'setTruckGpsItem':
      return { ...state, truckGpsItem: action.payload };

    case 'changePage':
      return { ...state, page: action.payload };
    case 'setSize':
      return { ...state, size: action.payload };
    case 'setTotal':
      return { ...state, total: action.payload };
    default:
      return state;
  }
};
