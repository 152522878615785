/** @format */

import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';
export const FuelExpenseContext = createContext();
export const useFuelExpense = () => useContext(FuelExpenseContext);

export const FuelExpenseProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <FuelExpenseContext.Provider value={[state, dispatch]}>
      {children}
    </FuelExpenseContext.Provider>
  );
};
