/** @format */

import { useMutation } from '@tanstack/react-query';
import useRequest from './useRequest';
import { Toast } from 'modals';
export default function useDelete() {
  const { request } = useRequest();
  const mutation = useMutation({
    mutationFn: (url) => {
      return request({ url: url, method: 'DELETE' });
    },
  });
  const deleting = (urlWithId, refetch) => {
    mutation.mutate(urlWithId, {
      onSuccess: (res) => {
        if (res?.success) {
          refetch && refetch();
          Toast({
            type: 'success',
            message: res?.message,
          });
        }
      },
      onError: (err) => {
        Toast({
          type: 'error',
          message: err?.message,
        });
      },
    });
  };
  return deleting;
}
