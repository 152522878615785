/** @format */

import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';
export const GrossBoardDataContext = createContext();
export const useGrossBoardData = () => useContext(GrossBoardDataContext);

export const GrossBoardDataProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <GrossBoardDataContext.Provider value={[state, dispatch]}>
      {children}
    </GrossBoardDataContext.Provider>
  );
};
