/** @format */

export const initialState = {
  //! ATTACHMENTS
  attachments: [],
  loadsCount: 0,
  openStatusBar: false,
  total: 0,
  permission: {},
  loadId: null,
  drawerAddLoad: false, // open and close drawer for AddLoad Modal
  driverLocationData: {},
  //! STOPS
  stopsList: [],
  stopPoint: {
    company: null,
    hasAppointment: true,
    date: null,
    timeFrom: null,
    timeTo: null,
    timeZone: null,
    typeOfStop: null,
    locationId: null,
    locationName: null,
    address: null,
    state: null,
    zip: null,
    city: null,
    note: null,
    coordinates: {},
  },
  //! PICK UP and DELIVERY LOCATION
  startPoint: { hasAppointment: true, company: '' },
  endPoint: { hasAppointment: true, company: '' },
  point: {
    company: null,
    hasAppointment: true,
    date: null,
    timeFrom: null,
    timeTo: null,
    timeZone: null,
    locationId: null,
    locationName: null,
    address: null,
    state: null,
    city: null,
    zipCode: null,
    note: null,
    coordinates: {},
  },
  pointChange: false,
  //! LOAD INFO
  loadType: null,
  companyCode: null,
  ownCompanyId: null,
  ownCompanyName: null,
  loadNumber: null,
  paymentType: null,
  pcsNumber: null,
  po: null,
  rate: null,
  tonu: null,
  dspNickname: null,
  dspId: null,

  //! TRIP INFO

  loadedMile: null,
  dhMile: null,
  trailerNumber: null,
  trailerId: null,
  checkInAs: null,
  specialNote: null,
  //! DRIVER INFO
  unitId: null,
  driversNames: [],
  truckNumber: null,
  dispatcherTeam: null,
  dispatcherTeamId: null,
  teamMemberNickname: null,
  teamMemberId: null,
  //! BROKER INFO
  brokerId: null,
  brokerName: null,
  brokerEmail: null,
  brokerPhone: null,

  // close confirm
  saveFunction: () => {},
  onCancelFunction: () => {},
  stateChange: false,
  // =================

  // driver
  ownCompaniesList: [],

  // driver
  driversList: [],
  driverAssigned: [],
  driverAssigne: {},
  qcAssigned: [],
  qcAssigne: {},
  driverLoading: false,
  drawerOpenDriver: false,
  // stop view
  openStopList: false,
  stopListView: [],
  refetchStopsList: () => {},
  awsLoading: false,
  // Loads/Planning
  planningData: [],
  qcteamsList: [],
  openAssigner: false,
  assignedDr: null,
  assignedQc: null,
  assignedQcId: null,
  selectedQcTeam: null,
  selectedQcTeamId: null,
  selectedTrip: null,
  // Loads/Active
  loadStatuses: [],
  loadStatus: '',
  loadDrawer: false,
  trailers: [],
  loadsHistoryList: [],
  transitData: [],
  transitTotal: 0,
  refetch: () => {},
  // Loads/History
  historyLoadsStatuses: [],
  lastTripStage: '',
  editLoadModal: false,
  selected: {},
  reportModal: false,
  driverInfo: [],
  driverRefetch: () => {},
  loadInfoRefetch: () => {},
  selectedUnitId: 0,
  loadAddOpen: false,
  loadViewOpen: false,
  qcTeamName: null,
  qcTeamId: null,
  teamMembers: [],
  qcTeams: [],
  toTime: null,
  fromTime: null,
  currentDate: undefined,
  isFetching: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'setDocs': {
      if (action.own === 'true') {
        return {
          ...state,
          attachments: action.payload,
        };
      }

      let formattedData = action?.payload?.map((d) => {
        return {
          attachmentId: d?.attachmentId || d.uploadedFile?.attachmentId,
          date: d?.date,
          docTypeId: d?.docTypeId,
          extension: d?.extension,
          name: d?.name,
          notes: d?.notes,
          path: d?.path || d?.uploadedFile?.path,
          size: d?.size,
          uploadedFile: d?.uploadedFile || [],
          createdAtDate: d?.createdAtDate,
          createdByNickname: d?.createdByNickname,
          note: d?.note,
        };
      });

      formattedData = state?.attachments?.length
        ? formattedData?.concat(state?.attachments)
        : formattedData;

      return {
        ...state,
        attachments: formattedData,
      };
    }

    case 'setTotal':
      return { ...state, total: action.payload };
    case 'doReset': {
      return {
        ...state,
        loadId: null,
        attachments: [],
        driverLocationData: null,
        stopsList: [],
        stopPoint: {},
        startPoint: {},
        endPoint: {},
        point: {},
        type: null,
        companyCode: null,
        ownCompanyId: null,
        ownCompanyName: null,
        loadNumber: null,
        paymentType: null,
        pcsNumber: null,
        billingStatus: null,
        po: null,
        dspId: null,
        dspNickname: null,
        rate: null,
        tonu: null,
        loadedMile: null,
        dhMile: null,
        trailerId: null,
        trailerNumber: null,
        checkInAs: null,
        specialNote: null,
        unitId: null,
        driversNames: null,
        truckNumber: null,
        dispatcherTeamId: null,
        dispatcherTeamName: null,
        teamMemberId: null,
        teamMemberNickname: null,
        brokerId: null,
        qcTeamName: null,
        qcTeamId: null,
        brokerName: null,
        brokerEmail: null,
        brokerPhone: null,
        stateChange: false,
      };
    }
    case 'setPermission':
      return { ...state, permission: action.payload };
    case 'isFetching':
      return { ...state, isFetching: action.payload };
    case 'setTeamMembers':
      return { ...state, teamMembers: action.payload };
    case 'setQcTeams':
      return { ...state, qcTeams: action.payload };
    case 'setLoadsCount':
      return { ...state, loadsCount: action.payload };
    case 'setOpenStatusBar':
      return { ...state, openStatusBar: action.payload };
    case 'setSelectedUnitId':
      return { ...state, selectedUnitId: action.payload };
    case 'openLoadAdd':
      return { ...state, loadAddOpen: action.payload };
    case 'openLoadView':
      return { ...state, loadViewOpen: action.payload };
    case 'setLoadStatus':
      return { ...state, loadStatus: action.payload };
    case 'selectedLoadsList':
      return { ...state, loadsHistoryList: action.payload };
    case 'setLoadId':
      return { ...state, loadId: action.payload };
    case 'setDrawerAddLoad':
      return { ...state, drawerAddLoad: action.payload };
    case 'setDriverLocationData':
      return { ...state, driverLocationData: action.payload };
    // Stop locations
    case 'setStopsList':
      return { ...state, stopsList: action.payload };
    case 'setStopPoint':
      return { ...state, stopPoint: action.payload };
    //! PICK UP and DELIVERY LOCATION
    case 'setStartPoint':
      return { ...state, startPoint: action.payload };
    case 'setEndPoint':
      return { ...state, endPoint: action.payload };
    case 'setPoint':
      return { ...state, point: action.payload };
    case 'setPointChange':
      return { ...state, pointChange: action.payload };

    //! LOAD INFO
    case 'setType':
      return { ...state, loadType: action.payload };
    case 'setCompanyCode':
      return { ...state, companyCode: action.payload };
    case 'setOwnCompanyId':
      return { ...state, ownCompanyId: action.payload };
    case 'setOwnCompanyName':
      return { ...state, ownCompanyName: action.payload };
    case 'setLoadNumber':
      return { ...state, loadNumber: action.payload };
    case 'setPaymentType':
      return { ...state, paymentType: action.payload };
    case 'setPcsNumber':
      return { ...state, pcsNumber: action.payload };
    case 'setPo':
      return { ...state, po: action.payload };
    case 'setRate':
      return { ...state, rate: action.payload };
    case 'setTonu':
      return { ...state, tonu: action.payload };
    case 'setDspNickname':
      return { ...state, dspNickname: action.payload };
    case 'setDspId':
      return { ...state, dspId: action.payload };
    case 'setBillingStatus':
      return { ...state, billingStatus: action.payload };

    //! Trip info
    case 'setLoadedMile':
      return { ...state, loadedMile: action.payload };
    case 'setDhMile':
      return { ...state, dhMile: action.payload };
    case 'setTrailerNumber':
      return { ...state, trailerNumber: action.payload };
    case 'setTrailerId':
      return { ...state, trailerId: action.payload };
    case 'setCheckInAs':
      return { ...state, checkInAs: action.payload };
    case 'setSpecialNote':
      return { ...state, specialNote: action.payload };

    //! UNIT
    case 'setUnitId':
      return { ...state, unitId: action.payload };
    case 'setDriversNames':
      return { ...state, driversNames: action.payload };
    case 'setTruckNumber':
      return { ...state, truckNumber: action.payload };
    case 'setDispatcherTeamId':
      return { ...state, dispatcherTeamId: action.payload };
    case 'setDispatcherTeamName':
      return { ...state, dispatcherTeamName: action.payload };
    case 'setTeamMemberNickname':
      return { ...state, teamMemberNickname: action.payload };
    case 'setTeamMemberId':
      return { ...state, teamMemberId: action.payload };

    //! BROKER INFO
    case 'setBrokerId':
      return { ...state, brokerId: action.payload };
    case 'setBrokerName':
      return { ...state, brokerName: action.payload };
    case 'setBrokerEmail':
      return { ...state, brokerEmail: action.payload };
    case 'setBrokerPhone':
      return { ...state, brokerPhone: action.payload };

    // close confirm
    case 'setSaveFunction':
      return { ...state, saveFunction: action.payload };
    case 'setOnCancelFunction':
      return { ...state, onCancelFunction: action.payload };
    case 'setStateChange':
      return { ...state, stateChange: action.payload };
    //! ===================
    case 'driverAssigned':
      return {
        ...state,
        driverAssigned: [...state.driverAssigned, action.payload],
      };
    case 'driverAssigne':
      return {
        ...state,
        driverAssigne: {
          ...state?.driverAssigne,
          [action.payload?.loadId]: action.payload?.driver,
        },
      };
    case 'qcAssigned':
      return {
        ...state,
        qcAssigned: [...state.qcAssigned, action.payload],
      };
    case 'qcAssigne':
      return {
        ...state,
        qcAssigne: {
          ...state?.qcAssigne,
          [action.payload?.loadId]: action.payload?.qcTeam,
        },
      };

    case 'refetchStopsList':
      return { ...state, refetchStopsList: action.payload };
    case 'assignedDr':
      return { ...state, assignedDr: action.payload };
    case 'assignedQc':
      return { ...state, assignedQc: action.payload };
    case 'assignedQcId':
      return { ...state, assignedQcId: action.payload };
    case 'selectedQcTeam':
      return { ...state, selectedQcTeam: action.payload };
    case 'selectedQcTeamId':
      return { ...state, selectedQcTeamId: action.payload };
    case 'setQcTeamName':
      return { ...state, qcTeamName: action.payload };
    case 'setQcTeamId':
      return { ...state, qcTeamId: action.payload };
    case 'selectedTrip':
      return { ...state, selectedTrip: action.payload };
    case 'setTrailers':
      return { ...state, trailers: action.payload };
    case 'openStopList':
      return { ...state, openStopList: action.payload };
    case 'openAssigner':
      return { ...state, openAssigner: action.payload };
    case 'stopListView':
      return { ...state, stopListView: action.payload };
    case 'setAwsLoading':
      return { ...state, awsLoading: action.payload };

    case 'setOwnCompaniesList':
      return { ...state, ownCompaniesList: action.payload };

    case 'setDriversList':
      return { ...state, driversList: action.payload };
    case 'setQcteamsList':
      return { ...state, qcteamsList: action.payload };
    case 'setPlanningData':
      return { ...state, planningData: action.payload };
    case 'setTransitData':
      return { ...state, transitData: action.payload };
    case 'setTransitTotal':
      return { ...state, transitTotal: action.payload };
    case 'setLoadStatuses':
      return { ...state, loadStatuses: action.payload };
    case 'setLoadHistoryStatuses':
      return { ...state, historyLoadsStatuses: action.payload };
    case 'closeLoadDrawer':
      return { ...state, loadDrawer: false };
    case 'setLoadDrawer':
      return { ...state, loadDrawer: action.payload };

    case 'setReportModal':
      return { ...state, reportModal: action.payload };
    case 'setEditLoadModal':
      return { ...state, editLoadModal: action.payload };
    case 'setSelected':
      return { ...state, selected: action.payload };
    case 'setDrawerOpenDriver':
      return { ...state, drawerOpenDriver: action.payload };
    case 'setDriverId':
      return { ...state, driverId: action.payload };
    case 'setDriverInfo':
      return { ...state, driverInfo: action.payload };
    case 'setDriverLoading':
      return { ...state, driverLoading: action.payload };
    case 'setRefetch':
      return { ...state, refetch: action.payload };
    case 'setDriverRefetch':
      return { ...state, driverRefetch: action.payload };
    case 'setFromTime':
      return { ...state, fromTime: action.payload };
    case 'setToTime':
      return { ...state, toTime: action.payload };
    case 'setCurrentDate':
      return { ...state, currentDate: action.payload };
    case 'setLoadInfoRefetch':
      return initialState;

    default:
      return state;
  }
};
