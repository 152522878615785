/** @format */

import { useSetQuery } from 'hooks';
import { useLoads } from 'context';
const originStyle = {
  whiteSpace: 'wrap',
  textAlign: 'left',
  height: 'inherit',
  display: 'flex',
  alignItems: 'center',
  lineHeight: 1.1,
};
export const LoadRenderer = ({ data }) => {
  const [, dispatch] = useLoads();
  const setQuery = useSetQuery();

  return (
    <div style={originStyle}>
      <span
        onClick={() => {
          dispatch({ type: 'setLoadDrawer', payload: data });
          setQuery('load_id', data?.id);
        }}
        style={{
          color: data.loadId
            ? 'var(--appTableCellLinkColor)'
            : 'var(--appTableDisabledLinkColor)',
          fontWeight: 'var(--appTableFontWeight)',
          fontSize: 'var(--appTableFontSize)',
          cursor: 'pointer',
          position: 'relative',
        }}
      >
        {data?.loadId || 'none'}
      </span>
    </div>
  );
};
