import { memo, useEffect, useState } from 'react';
import Row from './Row';

export default memo(function LazyRenderingRows({
  data,
  rowHeight,
  defaultRowHeight,
  columnDefs,
  onRowClick,
  gridtemplatecolumns,
  clearActiveOnCell,
  removeLastBorder,
  lazyRendering,
}) {
  const [currentStep, setCurrentStep] = useState(lazyRendering?.start || 50);

  useEffect(() => {
    if (currentStep < data.length) {
      const timer = setTimeout(() => {
        setCurrentStep((prevStep) => prevStep + lazyRendering?.step || 1);
      }, 100); // 0.1-second delay between each step
      return () => clearTimeout(timer);
    }
  }, [currentStep, data.length]);

  return data?.slice(0, currentStep)?.map((item, i) => {
    return (
      <Row
        key={`${i}row`}
        i={i}
        item={item}
        rowHeight={rowHeight}
        defaultRowHeight={defaultRowHeight}
        columnDefs={columnDefs}
        onRowClick={onRowClick}
        gridtemplatecolumns={gridtemplatecolumns}
        clearActiveOnCell={clearActiveOnCell}
        removeLastBorder={removeLastBorder}
      />
    );
  });
});
