/** @format */
import { Turncated } from 'globalCSS';
const originStyle = {
  whiteSpace: 'wrap',
  textAlign: 'left',
  height: 'inherit',
  display: 'flex',
  alignItems: 'center',
  lineHeight: 1.1,
};

export const DestinationRenderer2 = ({ data }, name1, name2) => {
  return (
    <Turncated title={data?.[name2] ? data?.[name2] : ''} style={originStyle}>
      {data?.[name1] ? data?.[name1] : data?.[name2]}
    </Turncated>
  );
};
