/** @format */

export const initialState = {
  account: '0',
  active: true,
  leaser: null,
  leaserId: null,
  login: null,
  connectionType: 'API',
  name: null,
  pathUrl: null,
  ownerCompany: null,
  ownerCompanyId: null,
  password: null,
  serviceName: null,
  id: null,
  editable: false,
  currentPage: 1,
  currentTab: 0,
  newConnectionId: null,
  permission: {},
  closeDrawer: () => {},
  refetch: () => {},
};

export const addConnectionsReducer = (state, action) => {
  switch (action.type) {
    case 'refetch':
      return { ...state, refetch: action.payload };
    case 'closeDrawer':
      return { ...state, closeDrawer: action.payload };

    case 'clear':
      return {
        ...state,
        login: null,
        password: null,
        name: null,
        account: 0,
        leaser: null,
        leaserId: null,
        serviceName: null,
        ownerCompany: null,
        ownerCompanyId: null,
        connectionType: 'API',
        active: false,
        id: null,
        editable: false,
      };
    case 'clearCredentials':
      return {
        ...state,
        login: null,
        password: null,
        account: 0,
      };
    case 'clearInfo':
      return {
        ...state,
        leaser: null,
        name: null,
        pathUrl: null,
        leaserId: null,
        serviceName: null,
        ownerCompany: null,
        ownerCompanyId: null,
        connectionType: 'API',
        active: false,
        id: null,
        editable: false,
      };

    case 'account':
      return { ...state, account: Number(action.payload) };
    case 'name':
      return { ...state, name: action.payload };
    case 'pathUrl':
      return { ...state, pathUrl: action.payload };
    case 'active':
      return { ...state, active: action.payload };
    case 'connectionType':
      return { ...state, connectionType: action.payload };
    case 'leaser':
      return { ...state, leaser: action.payload };
    case 'leaserId':
      return { ...state, leaserId: action.payload };
    case 'login':
      return { ...state, login: action.payload };
    case 'ownerCompany':
      return { ...state, ownerCompany: action.payload };
    case 'ownerCompanyId':
      return { ...state, ownerCompanyId: action.payload };
    case 'password':
      return { ...state, password: action.payload };
    case 'serviceName':
      return { ...state, serviceName: action.payload };
    case 'id':
      return { ...state, id: action.payload };
    case 'editable':
      return { ...state, editable: action.payload };

    case 'setCurrentPage':
      return { ...state, currentPage: action.payload };
    case 'setCurrentTab':
      return { ...state, currentTab: action.payload };
    case 'newConnectionId':
      return { ...state, newConnectionId: action.payload };
    case 'setPermission':
      return { ...state, permission: action.payload };
    default:
      return state;
  }
};
