/** @format */

import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';

export const QCTeamsContext = createContext();

export const useQCTeams = () => useContext(QCTeamsContext);

export const QCTeamsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <QCTeamsContext.Provider value={[state, dispatch]}>
      {children}
    </QCTeamsContext.Provider>
  );
};
