import styled from 'styled-components';
import { AutoComplete } from '../../AntDesign';
export const AntAutoComplete = styled(AutoComplete)`
  box-sizing: border-box;
  width: 100%;
  width: ${({ width }) => width || '100% !important'};
  height: ${({ height }) => height || 'var(--appFilterHeight)'} !important;
  .ant-select-selection-search-input,
  .ant-select-selection-placeholder {
    font-size: ${({ fsize }) => fsize || 'var(--appFilterFontSize)'} !important;
    height: ${({ height }) => height || 'var(--appFilterHeight)'} !important;
    line-height: ${({ height }) =>
      height || 'var(--appFilterHeight)'} !important;
  }
`;
