/** @format */

import React from 'react';
import { SelectWrapper, StyledOpt, StyledSelect } from './style';

const IconicSelect = ({
  options,
  value,
  width,
  allOption,
  bordercolor,
  suffixIcon,
  ...props
}) => {
  return (
    <SelectWrapper width={width} bordercolor={bordercolor}>
      <StyledSelect
        className='custom-select'
        popupMatchSelectWidth={false}
        suffixIcon={suffixIcon}
        value={value || null}
        notFoundContent={'No data found!'}
        {...props}
      >
        {allOption === 'true' && (
          <StyledOpt key='' value=''>
            No Load
          </StyledOpt>
        )}
        {options?.length &&
          options?.map((o) => {
            return (
              <StyledOpt key={o?.key} value={o?.value} {...o}>
                {o?.key}
              </StyledOpt>
            );
          })}
      </StyledSelect>
    </SelectWrapper>
  );
};
export default React.memo(IconicSelect);
