/**
 * Deletes a cookie by setting its expiration date to a past time.
 *
 * @param {string} name - The name of the cookie to delete.
 *
 * @example
 * // Delete a cookie named 'username'
 * deleteCookie('username');
 *
 * @example
 * // Attempt to delete a cookie without providing a name
 * deleteCookie();
 */

function deleteCookie(name) {
  // Check if the name parameter is provided and is a non-empty string
  if (typeof name !== 'string' || name.length === 0) {
    // Return early if the name is invalid or not provided
    return;
  }

  // Set the cookie's expiration date to a past time to effectively delete it
  document.cookie = `${name}=; Max-Age=0; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
}

export { deleteCookie };
