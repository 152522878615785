import styled from 'styled-components';
import { TimePicker } from '../../AntDesign';
import { InputLabel } from 'globalCSS';

export const Wrapper = styled('div')`
  background-color: ${({ w_bg_color }) => w_bg_color || 'inherite'};
  width: ${({ w_width }) => w_width || '100%'};
`;
export const Label = styled(InputLabel)``;

export const Box = styled('div')`
  width: ${({ b_width }) => b_width || '100%'};
  margin: ${({ b_margin }) => b_margin || 'inherite'};

  // The following are styles of AntTimePicker
  .ant-picker {
    background: ${({ background }) => background || '#FFFFFF'};
    border: ${({ border }) => border || '0.5px solid #C4C4C4'};
    border-color: ${({ border_color }) => border_color || '#C4C4C4'};
    width: ${({ width }) => width || '117px'} !important;
    height: ${({ height }) => height || '32px'} !important;
    border-radius: ${({ radius }) => radius || '2px'};
  }
`;

export const AntTimePicker = styled(TimePicker)`
  // give style to AntTimePicker in Box by useing classes, please!
`;
