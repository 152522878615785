/** @format */

import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';

export const FactoringContext = createContext();
export const useFactoring = () => useContext(FactoringContext);

export const FactoringPovider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <FactoringContext.Provider value={[state, dispatch]}>
      {children}
    </FactoringContext.Provider>
  );
};
