import styled from 'styled-components';

export const Wrapper = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: ${({ fontSize }) => fontSize || 'var(--appTopNavFontSize)'};
  line-height: ${({ lineheight }) =>
    lineheight || 'var(--appTopNavTitleLineHeight)'};
  color: ${({ color }) => color || 'var(--appTopNavFontColor)'};
  height: ${({ height }) => height || 'var(--appDrawerHeaderHeight)'};
  display: flex;
  align-items: center;
  padding: ${({ padding }) => padding || '0px 32px'};
  border-bottom: 1px solid #f0f2fa;
`;
