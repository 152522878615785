/** @format */

import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import RedStars from '../RedStars';
import { MainDatePickerWrapper, DatePickerLabel, AntDatePicker } from './style';
// import { DatePicker } from 'antd';
// import 'antd/dist/antd.css';

export const DatepickerMask = ({
  isrequired,
  width,
  value,
  format,
  bordercolor,
  label,
  placeholder,
  defaultValue,
  showTime,
  allowClear = false,
  // changeOnBlur = true,
  no_header,
  onChange,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState(
    value ? dayjs(value).format('MM/DD/YYYY') : ''
  );

  const datePickerRef = useRef(null);

  useEffect(() => {
    if (value) {
      setInputValue(dayjs(value).format('MM/DD/YYYY'));
    }
  }, [value]);

  const handleInputChange = (e) => {
    let val = e.target.value.replace(/\D/g, '');
    if (val.length > 8) return; // Ensure the input value does not exceed 8 digits (MMDDYYYY)

    if (val.length >= 3 && val.length <= 4) {
      val = val.slice(0, 2) + '/' + val.slice(2);
    } else if (val.length >= 5) {
      val = val.slice(0, 2) + '/' + val.slice(2, 4) + '/' + val.slice(4);
    }

    setInputValue(val);

    if (dayjs(val, 'MM/DD/YYYY', true).isValid()) {
      onChange(dayjs(val, 'MM/DD/YYYY'));
    } else if (!val) {
      onChange(null);
    }
  };

  const handleDateChange = (date, dateString) => {
    setInputValue(dateString);
    onChange(date);
  };

  const onOpenChange = () => {
    if (datePickerRef.current) {
      datePickerRef.current.focus();
      setOpen(true);
    }
  };

  return (
    <MainDatePickerWrapper
      width={width}
      bordercolor={bordercolor}
      onClick={onOpenChange}
    >
      {label && (
        <DatePickerLabel>
          {label}
          {isrequired ? <RedStars /> : null}
        </DatePickerLabel>
      )}
      <input
        value={inputValue}
        onChange={handleInputChange}
        onFocus={onOpenChange}
        placeholder='MM/DD/YYYY'
        style={{
          width: '100%',
          marginBottom: '8px',
          padding: '8px',
          border: '1px solid #ced4da',
          borderRadius: '2px',
          fontSize: '12px',
        }}
        maxLength={10}
      />
      <AntDatePicker
        ref={datePickerRef}
        open={open}
        onOpenChange={onOpenChange}
        popupClassName={no_header ? 'no-header' : ''}
        placeholder={placeholder || 'MM/DD/YYYY'}
        value={value ? dayjs(value) : null}
        defaultValue={defaultValue ? dayjs(defaultValue) : null}
        format={format || 'MM/DD/YYYY'}
        allowClear={allowClear}
        showTime={showTime === false ? false : true}
        onChange={handleDateChange}
        {...props}
        // style={{ display: 'none' }}
        // style={{
        //   position: 'absolute',
        //   zIndex: 1024,
        //   opacity: 0,
        //   pointerEvents: 'none',
        // }}
      />
    </MainDatePickerWrapper>
  );
};

export default React.memo(DatepickerMask);
