/** @format */

import { LogoImg, LogoText, LogoWrapper } from './style';
import LogoAsset from 'assets/imgs/logomini.png';
const Logo = () => {
  const mode = import.meta.env.MODE;
  return (
    <LogoWrapper>
      <LogoText>{mode === 'simona' ? 'Simona' : 'Cargo Prime'}</LogoText>
      <LogoImg src={LogoAsset} />
    </LogoWrapper>
  );
};

export default Logo;
