/** @format */

import { Loading } from 'loaders';
import React, { useEffect, useRef, useState } from 'react';
import Collapse from './Collapse';
import Row from './Row';
import GridFilter from './filter';
import { AntCollapse, GridTable } from './styled';
import { ArrowDownIcon } from 'styledIcons';
import LazyRenderingRows from './LazyRenderingRows';

const Table = ({
  removeNoRecords,
  columnDefs,
  onlyHeader,
  rowData,
  groupRowRendererParams,
  isLoading,
  isFetching,
  rowCollapsible: PanelRenderer,
  onRowClick,
  rowHeight,
  defaultRowHeight,
  generalStyle,
  headerHeight,
  headerBackground,
  expandIconPosition,
  removecolborder,
  removeHeader,
  removeLastBorder,
  lazyRendering,
}) => {
  const table = useRef(null);
  // const table = ref || localTableRef;
  const [data, setData] = useState();
  const [rowId, setRowId] = useState();
  const [gridtemplatecolumns, setGridTemplateColumns] = useState('');
  const [defaultgridtemplatecolumns, setDefaultgridtemplatecolumns] =
    useState('');
  useEffect(() => {
    let gridtemplatecolumns = columnDefs
      ?.map((columnDef) =>
        columnDef?.['flex']
          ? `${columnDef?.['flex']}fr`
          : columnDef?.['width']
          ? `${
              isNaN(columnDef?.['width'])
                ? columnDef?.['width']
                : columnDef?.['width'] + 'px'
            }`
          : '1fr'
      )
      ?.join(' ');

    setGridTemplateColumns(gridtemplatecolumns);
    setDefaultgridtemplatecolumns(defaultgridtemplatecolumns);

    return () => {};
  }, [columnDefs]);
  // useEffect(() => {
  //   const defaultgridtemplatecolumns = columnDefs
  //     ?.map((columnDef) =>
  //       columnDef?.['flex']
  //         ? `${columnDef?.['flex']}fr`
  //         : columnDef?.['width']
  //         ? `${
  //             isNaN(columnDef?.['width'])
  //               ? columnDef?.['width']
  //               : columnDef?.['width'] + 'px'
  //           }`
  //         : '1fr'
  //     )
  //     ?.join(' ');

  //   setDefaultgridtemplatecolumns(defaultgridtemplatecolumns);

  //   return () => {};
  // }, [columnDefs]);

  // const defaultgridtemplatecolumns = columnDefs
  //   ?.map((columnDef) =>
  //     columnDef?.['flex']
  //       ? `${columnDef?.['flex']}fr`
  //       : columnDef?.['width']
  //       ? `${
  //           isNaN(columnDef?.['width'])
  //             ? columnDef?.['width']
  //             : columnDef?.['width'] + 'px'
  //         }`
  //       : '1fr'
  //   )
  //   ?.join(' ');

  const minimumWidth = columnDefs?.map((columnDef) =>
    columnDef?.['minWith']
      ? `${
          columnDef?.['minWith']?.endsWith('px')
            ? Number(columnDef?.['minWith']?.replace('px', ''))
            : Number(columnDef?.['minWith'])
        }`
      : 20
  );
  function groupBy(objectArray, property) {
    return objectArray.reduce((acc, obj) => {
      const key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      // Add object to list for given key's value
      acc[key].push(obj);
      return acc;
    }, {});
  }

  useEffect(() => {
    function getData() {
      if (groupRowRendererParams?.['groupBy']) {
        let objData = groupBy(rowData, groupRowRendererParams?.['groupBy']);
        let headers = Object.keys(objData);
        groupRowRendererParams?.['firstGroupName']
          ? headers?.unshift(
              headers?.splice(
                headers?.indexOf(groupRowRendererParams?.['firstGroupName']),
                1
              )[0]
            )
          : headers?.unshift(headers?.splice(headers?.indexOf('null'), 1)[0]);
        groupRowRendererParams?.['lastGroupName'] &&
          headers?.push(
            headers?.splice(
              headers?.indexOf(groupRowRendererParams?.['lastGroupName']),
              1
            )[0]
          );
        let data = headers?.map((title) => {
          let obj = {};
          obj[groupRowRendererParams?.['titleField']] = title;
          obj[groupRowRendererParams?.['dataField']] = objData[title];
          return obj;
        });
        setData(data);
      } else {
        setData(rowData);
      }
    }
    getData();
    return () => {};
  }, [rowData]);
  const handleCollapse = (id) => {
    setRowId(...id);
  };
  const clearActiveOnCell = () => {
    const cells = table?.current?.querySelectorAll('.new-table-cell');
    for (let cell of cells) {
      cell?.classList?.contains('active-cell') &&
        cell?.classList?.remove('active-cell');
    }
  };

  useEffect(() => {
    const resizers = table.current.querySelectorAll('.resizer');
    let templateColumns = gridtemplatecolumns;
    resizers.forEach((resizer) => {
      let element = resizer?.closest('.header-cell');
      resizer.addEventListener('mousedown', initResize, false);
      var currentX = 0;
      var currentWidth = 0;

      //Window function mousemove & mouseup
      function initResize(e) {
        currentX = e.clientX;
        currentWidth = element.clientWidth;
        document.documentElement.style.cursor = 'col-resize';
        document.documentElement.style.userSelect = 'none';
        document.documentElement.addEventListener('mousemove', Resize, false);
        document.documentElement.addEventListener('mouseup', stopResize, false);
      }

      //resize the element
      function Resize(e) {
        const index = element?.getAttribute('data-index');
        const filter = table.current.querySelector('[filter=true]');
        const rows = table.current.querySelectorAll('.table-row');
        let tempCols = templateColumns.split(' ');
        let newWidth =
          currentWidth + e.clientX - currentX >= minimumWidth[index]
            ? currentWidth + e.clientX - currentX
            : minimumWidth[index];
        tempCols[index] = newWidth + 'px';

        for (let row of rows)
          row.style.gridTemplateColumns = tempCols?.join(' ');
        filter.style.gridTemplateColumns = tempCols?.join(' ');
        templateColumns = tempCols?.join(' ');
        setGridTemplateColumns(templateColumns);
      }
      //on mouseup remove windows functions mousemove & mouseup
      function stopResize() {
        document.documentElement.style.cursor = 'auto';
        document.documentElement.style.userSelect = 'auto';
        document.documentElement.removeEventListener(
          'mousemove',
          Resize,
          false
        );
        document.documentElement.removeEventListener(
          'mouseup',
          stopResize,
          false
        );
        // resizer.removeEventListener('mousedown', initResize, false);
      }
    });
  }, [table?.current, isLoading, isFetching]);

  return (
    <GridTable
      ref={table}
      height={table?.current?.offsetTop}
      style={generalStyle}
    >
      {!removeHeader && (
        <GridTable.Head>
          <GridTable.Tr
            length={columnDefs?.length}
            gridtemplatecolumns={
              gridtemplatecolumns || defaultgridtemplatecolumns
            }
            headerBackground={headerBackground}
            style={{
              paddingLeft:
                PanelRenderer && expandIconPosition !== 'end' ? '25px' : 0,
              paddingRight:
                PanelRenderer && expandIconPosition === 'end' ? '25px' : 0,
            }}
            header='true'
            height={headerHeight || '28px'}
            className='table-row'
          >
            {columnDefs?.map(
              (
                {
                  headerName,
                  headerRenderer: HeaderRenderer,
                  headerStyle,
                  resizable,
                },
                i
              ) => (
                <GridTable.Th
                  removecolborder={removecolborder}
                  key={headerName || `${i}header`}
                  style={headerStyle && headerStyle()}
                  height={headerHeight || '28px'}
                  background={headerBackground}
                  className='header-cell'
                  data-index={i}
                  title={HeaderRenderer ? null : headerName?.toUpperCase()}
                >
                  {HeaderRenderer ? (
                    <HeaderRenderer headerName={headerName} />
                  ) : (
                    <h3>{headerName?.toUpperCase()}</h3>
                  )}
                  {resizable && !isLoading && !isFetching ? (
                    <GridTable.Resizer className='resizer' />
                  ) : null}
                </GridTable.Th>
              )
            )}
          </GridTable.Tr>

          <GridFilter
            gridtemplatecolumns={
              gridtemplatecolumns || defaultgridtemplatecolumns
            }
            columnDefs={columnDefs}
            PanelRenderer={PanelRenderer}
          />
        </GridTable.Head>
      )}
      {onlyHeader ? null : (
        <GridTable.Body>
          {rowData?.length && (isLoading || isFetching) ? (
            <div className='loader-wrapper'>
              <Loading height={'100%'} />
            </div>
          ) : null}
          {!rowData?.length && (isLoading || isFetching) ? (
            <Loading height={'100%'} />
          ) : rowData?.length < 1 && !removeNoRecords ? (
            <GridTable.Tr>
              <GridTable.Td colSpan={columnDefs?.length}>
                No records
              </GridTable.Td>
            </GridTable.Tr>
          ) : groupRowRendererParams ? (
            data?.map((item, i) => {
              return item?.[groupRowRendererParams?.['titleField']] !== null ? (
                <Collapse
                  key={`${i}collapse`}
                  defaultOpenCollapse={
                    groupRowRendererParams?.activeIndex
                      ? i === groupRowRendererParams?.activeIndex
                      : i === 0
                  }
                  activeKey={
                    groupRowRendererParams?.activeIndex
                      ? groupRowRendererParams?.activeIndex
                      : 0
                  }
                  defaultActiveKey={
                    groupRowRendererParams?.activeIndex
                      ? [`${groupRowRendererParams?.activeIndex}collapse`]
                      : ['0collapse']
                  }
                  order={i}
                  item={item}
                  groupRowRendererParams={groupRowRendererParams}
                  columnDefs={columnDefs}
                  gridtemplatecolumns={
                    gridtemplatecolumns || defaultgridtemplatecolumns
                  }
                  Header={groupRowRendererParams?.innerRenderer}
                  openAllCollapse={groupRowRendererParams?.openAllCollapse}
                  onRowClick={onRowClick}
                  clearActiveOnCell={clearActiveOnCell}
                  rowHeight={
                    groupRowRendererParams?.['height']
                      ? groupRowRendererParams?.['height']
                      : rowHeight
                  }
                  defaultRowHeight={defaultRowHeight}
                />
              ) : (
                <div className='new-table-collapse' key={i}>
                  <div className={'inner-collapse-data'}>
                    {item?.data?.map((item, i) => {
                      return (
                        <Row
                          key={i}
                          i={i}
                          item={item}
                          rowHeight={rowHeight}
                          onRowClick={onRowClick}
                          columnDefs={columnDefs}
                          gridtemplatecolumns={
                            gridtemplatecolumns || defaultgridtemplatecolumns
                          }
                          clearActiveOnCell={clearActiveOnCell}
                          removeLastBorder={removeLastBorder}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })
          ) : PanelRenderer ? (
            <AntCollapse
              onChange={handleCollapse}
              destroyInactivePanel
              accordion
              rowHeight={
                rowHeight === 'auto' || `${rowHeight}`?.endsWith('px')
                  ? rowHeight
                  : rowHeight
                  ? rowHeight + 'px'
                  : '28px'
              }
              expandIconPosition={expandIconPosition || 'start'}
              expandIcon={({ isActive }) => (
                <ArrowDownIcon
                  width={'10px'}
                  height={'10px'}
                  style={{
                    // marginLeft: '10px',
                    transform: `rotate(${isActive ? '180deg' : '0'})`,
                  }}
                />
              )}
              items={
                Array.isArray(data)
                  ? data?.map((item, i) => {
                      return {
                        key: i,
                        className: 'new-table-collapse-panel',
                        label: (
                          <Row
                            i={i}
                            item={item}
                            rowHeight={rowHeight}
                            columnDefs={columnDefs}
                            gridtemplatecolumns={
                              gridtemplatecolumns || defaultgridtemplatecolumns
                            }
                            onRowClick={onRowClick}
                            collapsibleRow={!!PanelRenderer}
                            clearActiveOnCell={clearActiveOnCell}
                            removeLastBorder={removeLastBorder}
                          />
                        ),
                        children: (
                          <div
                            style={{
                              borderBottom: '1px solid #e5e7eb',
                              marginLeft:
                                expandIconPosition !== 'end' ? '25px' : '13px',
                              marginRight:
                                expandIconPosition === 'end' ? '13px' : '0',
                            }}
                          >
                            <PanelRenderer rowId={rowId} data={item} />
                          </div>
                        ),
                      };
                    })
                  : []
              }
            />
          ) : Array.isArray(data) ? (
            lazyRendering ? (
              <LazyRenderingRows
                data={data}
                lazyRendering={lazyRendering}
                rowHeight={rowHeight}
                defaultRowHeight={defaultRowHeight}
                columnDefs={columnDefs}
                onRowClick={onRowClick}
                gridtemplatecolumns={
                  gridtemplatecolumns || defaultgridtemplatecolumns
                }
                clearActiveOnCell={clearActiveOnCell}
                removeLastBorder={removeLastBorder}
              />
            ) : (
              data?.map((item, i) => {
                return (
                  <Row
                    key={`${i}row`}
                    i={i}
                    item={item}
                    rowHeight={rowHeight}
                    defaultRowHeight={defaultRowHeight}
                    columnDefs={columnDefs}
                    onRowClick={onRowClick}
                    gridtemplatecolumns={
                      gridtemplatecolumns || defaultgridtemplatecolumns
                    }
                    clearActiveOnCell={clearActiveOnCell}
                    removeLastBorder={removeLastBorder}
                  />
                );
              })
            )
          ) : null}
        </GridTable.Body>
      )}
    </GridTable>
  );
};

export default React.memo(Table);
