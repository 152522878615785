/** @format */

import { useState, useEffect, useRef, useMemo } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useRequest, useSearch, useQuery } from 'hooks';
import { Form } from 'globalCSS';
import {
  Input,
  Buttons,
  Textarea,
  Uploader,
  RequiredCaption,
  ExistCaption,
  Button,
} from 'forms';
import { Toast } from 'modals';
import { emailValidation } from 'formatter';
import { Loading } from 'loaders';
import avatar from 'assets/imgs/logo-avatar.png';
import { formatUsLocalPhoneNumber } from '../../../../../utils/Formatter';
import { UsFlagIcon, UzbFlagIcon } from '../../../../styledIcons';
import { PersonnalBox, ProfileImage, UploadImage } from './style';

export default function EditFactoring({
  data,
  dispatch,
  closeDrawer,
  refetch,
}) {
  // hooks
  const { request } = useRequest();
  const query = useSearch();
  const inputRef = useRef(null);

  // states
  const [errorData, setErrorData] = useState({});
  const [fileTypes, setFileTypes] = useState([]);
  const [errorPolicy, setErrorPolicy] = useState('');
  // "address": "string",
  // "addressLine": "string",
  // "attachmentIds": [
  //   0
  // ],
  // "city": "string",
  // "comment": "string",
  // "ein_number": "string",
  // "email": "string",
  // "mcNumber": "string",
  // "name": "string",
  // "phoneNumber": "string",
  // "state": "string",
  // "webSite": "string",
  // "zipCode": "string"
  const [stateData, setState] = useState({
    address: null,
    addressLine: null,
    city: null,
    comment: null,
    ein_number: null,
    email: null,
    mcNumber: null,
    name: null,
    phoneNumber: null,
    state: null,
    webSite: null,
    zipCode: null,
    attachmentIds: [],
  });
  const [itemData, setItemData] = useState(stateData);
  const [logoImage, setLogoImage] = useState(null);
  const [logoImageId, setLogoImageId] = useState(null);
  useEffect(() => {
    setItemData(stateData);
    return () => {};
  }, []);
  useEffect(() => {
    setState({
      ...stateData,
      attachmentIds: data?.attachments?.length
        ? data?.attachments.map(({ attachmentId }) => attachmentId)
        : [],
    });
  }, [data?.attachments]);

  const {
    address,
    addressLine,
    city,
    comment,
    ein_number,
    email,
    mcNumber,
    name,
    phoneNumber,
    state,
    webSite,
    zipCode,
    attachmentIds,
  } = stateData;

  let id = query.get('id');

  let getCompanyById = useQuery(
    ['/factoring_company/id - 1686128388612', id],
    () => id && request({ url: `/factoring_company/${id}` }),
    {
      onSuccess: (res) => {
        if (res?.success) {
          const result = {
            address: res?.data?.address,
            addressLine: res?.data?.addressLine,
            city: res?.data?.city,
            comment: res?.data?.comment,
            ein_number: res?.data?.ein_number,
            email: res?.data?.email,
            mcNumber: res?.data?.mcNumber,
            name: res?.data?.name,
            phoneNumber: res?.data?.phoneNumber,
            state: res?.data?.state,
            webSite: res?.data?.webSite,
            zipCode: res?.data?.zipCode,
            attachmentIds: res?.data?.attachments?.length
              ? res?.data?.attachments.map((a) => a?.attachmentId)
              : [],
          };
          dispatch({
            type: 'setDocs',
            payload: res?.data?.attachments,
            own: 'true',
          });
          setLogoImage(res?.data.logo?.path);
          setLogoImageId(res?.data.logoId);
          setState({ ...stateData, ...result });
          setItemData({ ...stateData, ...result });
        }
      },
    }
  );

  useQuery(
    ['/doc-type/list?doc-type=FACTORING_COMPANY&page=0&size=20'],
    () =>
      request({
        url: '/doc-type/list?doc-type=FACTORING_COMPANY&page=0&size=20',
      }),
    {
      onSuccess: (res) => {
        if (res?.success) {
          setFileTypes(res?.dataList);
        }
      },
    }
  );

  const postSave = useMutation({
    mutationFn: (values) => {
      return request({
        url: id ? `/factoring_company/${id}` : '/factoring_company',
        method: id ? 'PUT' : 'POST',
        body: values,
      });
    },
  });
  const onSave = () => {
    let hasError = Object.values(errorData)?.find((v) => Boolean(v));
    if (!hasError)
      postSave.mutate(
        {
          address,
          city,
          comment,
          ein_number,
          email,
          name,
          phoneNumber,
          state,
          webSite,
          zipCode,
          mcNumber,
          attachmentIds,
          logoId: logoImageId,
        },
        {
          onSuccess: (res) => {
            if (res?.success) {
              Toast({
                type: 'success',
                message: res?.message,
              });
              refetch();
              closeDrawer();
            } else {
              let errNew = {};
              res?.dataList?.map(({ key, value }) => {
                errNew[key] = value;
              });
              setErrorData(errNew);
            }
          },
          onError: (err) => {
            let errNew = {};
            err?.dataList?.map(({ key, value }) => {
              errNew[key] = value;
            });
            setErrorData(errNew);

            Toast({
              type: 'error',
              message: err?.message,
            });
          },
        }
      );
    else {
      let errors = Object.values(errorData)
        .filter((error) => error)
        .join(', \n');
      Toast({ type: 'warning', message: errors });
    }
  };
  useEffect(() => {
    dispatch({
      type: 'setSaveFunction',
      payload: onSave,
    });
    dispatch({
      type: 'setOnCancelFunction',
      payload: closeDrawer,
    });
    dispatch({
      type: 'setStateChange',
      payload: JSON.stringify(itemData) !== JSON.stringify(stateData),
    });

    return () => {};
  }, [JSON.stringify(itemData) !== JSON.stringify(stateData)]);

  const onChange = (e) => {
    const { name, value } = e.target;

    setState({ ...stateData, [name]: value || null });
  };

  const handleBlurEmail = (e) => {
    const { value } = e.target;
    if (value && !emailValidation(value)) {
      setErrorData({ ...errorData, email: 'Email is not valid.' });
    }
  };

  const checkExists = useMutation({
    mutationFn: ({ value, name }) => {
      let newName = name.replace('_n', 'N');
      let body = { [newName]: value, id: id ? id : null };

      return (
        value &&
        request({
          url: `/company/already_exists${
            name == 'mcNumber' ? '_mc' : name === 'dotNumber' ? '_dot' : '_ein'
          }`,
          body,
          method: 'POST',
        })
      );
    },
  });

  const checkNameValue = (e) => {
    let { value, name } = e.target;

    checkExists.mutate(
      { value, name },
      {
        onSuccess: (res) => {
          if (!res?.success) {
            setErrorData((prev) => ({ ...prev, [name]: res?.message }));
          }
        },
        onError: (err) => {
          setErrorData((prev) => ({ ...prev, [name]: err?.message }));
        },
      }
    );
  };

  const checkCompany = useMutation({
    mutationFn: (body) => {
      return (
        body?.companyName &&
        request({
          url: '/company/check-company-name',
          method: 'POST',
          body: body,
        })
      );
    },
    onSuccess: (res) => {
      if (!res?.success) {
        setErrorPolicy(res?.message?.replace('{', '')?.replace('}', ''));
      }
    },
    onError: (err) => {
      setErrorData({
        ...errorData,
        name: err?.message?.replace('{', '')?.replace('}', ''),
      });
    },
  });

  const checkName = (e) => {
    let { value } = e.target;
    checkCompany.mutate({
      companyName: value,
      id,
    });
  };

  useEffect(() => {
    inputRef?.current?.focus();
  }, [stateData?.phoneNumber]);
  const phoneInfo = useMemo(() => {
    return stateData?.phoneNumber
      ? formatUsLocalPhoneNumber(stateData?.phoneNumber)
      : { value: '', format: null };
  }, [stateData?.phoneNumber]);

  const { mutate, isPending } = useMutation({
    mutationFn: async (file) => {
      return await request({
        url: '/file/upload-logo',
        method: 'POST',
        body: file,
        isPost: false,
      });
    },
  });

  const handleImageUpload = (e) => {
    const formData = new FormData();
    const file = e.target.files[0];

    const reader = new FileReader();
    file && reader.readAsDataURL(file);

    reader.onloadend = function () {
      const uploadFile = async () => {
        formData.append('image', file);

        formData.append('doc-type', 'image/*');

        mutate(formData, {
          onSuccess: (res) => {
            Toast({
              type: 'success',
              message: res?.message || 'File has been uploaded successfully!',
            });
            setLogoImage(res?.data?.path || avatar);
            setLogoImageId(res?.data?.id || null);
          },
          onError: (err) => {
            Toast({
              type: 'error',
              message: err?.message,
            });
            setLogoImage(avatar);
            setLogoImageId(null);
          },
        });
      };

      uploadFile();
    };
  };

  const FileData = () => (
    <PersonnalBox.Preview>
      {isPending ? (
        <div
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid #f6f6f6',
          }}
        >
          Uploading...
        </div>
      ) : (
        <ProfileImage src={logoImage || avatar} alt='user-avatar' />
      )}
    </PersonnalBox.Preview>
  );
  if (postSave.isLoading || getCompanyById?.isLoading)
    return (
      <div className='flexCenter'>
        <Loading />
      </div>
    );
  return (
    <Form>
      <Form.Header>
        <Form.Title>{id ? 'Edit a Factoring' : 'Add a Factoring'}</Form.Title>
      </Form.Header>

      <Form.Body>
        <Form.Row>
          <Form.Column>
            <Input
              isrequired='true'
              name='name'
              onChange={(value) => {
                onChange(value);
                setErrorData({ ...errorData, name: null });
                setErrorPolicy(null);
              }}
              value={name}
              onBlur={checkName}
              label='Company Name'
              placeholder='Name'
              border={
                errorData?.name ? '1px solid #FF0000' : '1px solid #C4C4C4'
              }
            />
            <RequiredCaption errorData={errorData} field='name' />
            <ExistCaption message={errorPolicy} />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column>
            <Input
              isrequired='true'
              name='address'
              value={address}
              label='Address'
              placeholder='Address'
              onChange={(value) => {
                onChange(value);
                setErrorData({ ...errorData, address: null });
              }}
              border={
                errorData?.address ? '1px solid #FF0000' : '1px solid #C4C4C4'
              }
            />
            <RequiredCaption errorData={errorData} field='address' />
          </Form.Column>
          <Form.Column>
            <Input
              isrequired='true'
              name='addressLine'
              value={addressLine}
              label='Address Line'
              placeholder='Address line'
              onChange={(value) => {
                onChange(value);
                setErrorData({ ...errorData, addressLine: null });
              }}
              border={
                errorData?.addressLine
                  ? '1px solid #FF0000'
                  : '1px solid #C4C4C4'
              }
            />
            <RequiredCaption errorData={errorData} field='addressLine' />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          {' '}
          <Form.Column>
            <Input
              ref={inputRef}
              isrequired='true'
              type='tel'
              name='phoneNumber'
              value={
                stateData?.phoneNumber && phoneInfo?.value
                  ? phoneInfo?.value
                  : ''
              }
              label='Phone number'
              placeholder='Phone number'
              onChange={(value) => {
                onChange(value);
                setErrorData({ ...errorData, phoneNumber: null });
              }}
              border={
                errorData?.phoneNumber
                  ? '1px solid #FF0000'
                  : '1px solid #C4C4C4'
              }
              suffix={
                phoneInfo.format === 'uzb' ? (
                  <UzbFlagIcon />
                ) : phoneInfo.format === 'usa' ? (
                  <UsFlagIcon />
                ) : null
              }
            />
            <RequiredCaption errorData={errorData} field='phoneNumber' />
          </Form.Column>
          <Form.Column>
            <Input
              type='text'
              name='email'
              value={email}
              label='Email'
              placeholder='Email'
              onChange={(e) => {
                onChange(e);
                setErrorData({ ...errorData, email: null });
              }}
              onBlur={handleBlurEmail}
            />
            <RequiredCaption errorData={errorData} field='email' />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <Input
              name='city'
              value={city}
              label='City'
              placeholder='City'
              onChange={(value) => {
                onChange(value);
                setErrorData({ ...errorData, city: null });
              }}
              border={
                errorData?.city ? '1px solid #FF0000' : '1px solid #C4C4C4'
              }
            />
            <RequiredCaption errorData={errorData} field='city' />
          </Form.Column>
          <Form.Column>
            <Input
              name='state'
              value={state}
              label='State'
              placeholder='State'
              onChange={(value) => {
                onChange(value);
                setErrorData({ ...errorData, state: null });
              }}
              border={
                errorData?.state ? '1px solid #FF0000' : '1px solid #C4C4C4'
              }
            />
            <RequiredCaption errorData={errorData} field='state' />
          </Form.Column>

          <Form.Column>
            <Input
              name='zipCode'
              value={zipCode}
              label='Zip code'
              placeholder='Zip code'
              onChange={(value) => {
                onChange(value);
                setErrorData({ ...errorData, zipCode: null });
              }}
              border={
                errorData?.zipCode ? '1px solid #FF0000' : '1px solid #C4C4C4'
              }
            />
            <RequiredCaption errorData={errorData} field='zipCode' />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column>
            <Input
              name='ein_number'
              value={ein_number}
              label='Ein number'
              placeholder='Ein number'
              onBlur={checkNameValue}
              onChange={(value) => {
                onChange(value);
                setErrorData({ ...errorData, ein_number: null });
              }}
              border={
                errorData?.ein_number
                  ? '1px solid #FF0000'
                  : '1px solid #C4C4C4'
              }
            />
            <RequiredCaption errorData={errorData} field='ein_number' />
          </Form.Column>
          <Form.Column>
            <Input
              name='mcNumber'
              value={mcNumber}
              label='mc number'
              placeholder='mc number'
              onBlur={checkNameValue}
              onChange={(value) => {
                onChange(value);
                setErrorData({ ...errorData, mcNumber: null });
              }}
              border={
                errorData?.mcNumber ? '1px solid #FF0000' : '1px solid #C4C4C4'
              }
            />
            <RequiredCaption errorData={errorData} field='mcNumber' />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <Input
              name='webSite'
              value={webSite}
              label='Website'
              placeholder='Website'
              onChange={(value) => {
                onChange(value);
                setErrorData({ ...errorData, webSite: null });
              }}
              border={
                errorData?.webSite ? '1px solid #FF0000' : '1px solid #C4C4C4'
              }
            />
            <RequiredCaption errorData={errorData} field='webSite' />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column comment='true'>
            <Textarea
              name='comment'
              onChange={onChange}
              label='Comment'
              value={comment}
              rows={3}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column flex='3'>
            <PersonnalBox.Left>
              {FileData()}
              <Button>
                <UploadImage type='file' onChange={handleImageUpload} />
                SET LOGO IMAGE
              </Button>
            </PersonnalBox.Left>
          </Form.Column>
          <Form.Column>
            <Uploader
              fileTypes={fileTypes}
              data={data}
              dispatch={dispatch}
              deletePermission
            />
          </Form.Column>
        </Form.Row>
        <Form.Row right>
          <Form.Column>
            <Buttons
              onCancel={closeDrawer}
              onSave={onSave}
              closeDrawer={closeDrawer}
              isLoading={postSave?.isLoading}
              stateChange={data?.stateChange}
            />
          </Form.Column>
        </Form.Row>
      </Form.Body>
    </Form>
  );
}
