/** @format */

export const initialState = {
  dispatcherTeams: {},
  dispatcherTeamId: '',
  qcTeamId: '',
  qcTeams: {},
  driversList: [],
  permission: {},
  truckDrivers: [],
  trucksList: [],
  attachments: [],
  attachments2: [],
  originalContract: [],
  reservation: [],
  truckEquipments: [],
  truckRegCards: [],
  truckInspections: [],
  truckPermitList: [],
  truckDocuments: [],
  truckMovementsList: [],
  truckMaintainList: [],
  truckInsuranceList: [],
  truckNumbersList: [],
  truckPMList: [],
  truck: {},
  driver: {},
  currentGpsData: {},
  subPath: 'info',
  unitStatus: '',
  comment: '',
  samsaraActive: false,
  samsaraLastConnection: '',
  ensureActive: false,
  ensureLastConnection: '',
  unitId: null,
  unitActive: false,
  isLoading: false,
  checkState: [],
  mapItem: {},
  refetch: () => {},
  refetchMainPage: () => {},
  historyCount: 0,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'setDocs': {
      if (action.own === 'true') {
        return {
          ...state,
          attachments: action.payload,
        };
      }

      let formattedData = action?.payload?.map((d) => {
        return {
          attachmentId: d?.attachmentId || d.uploadedFile?.attachmentId,
          date: d?.date,
          docTypeId: d?.docTypeId,
          extension: d?.extension,
          name: d?.name,
          notes: d?.notes,
          path: d?.path || d?.uploadedFile?.path,
          size: d?.size,
          uploadedFile: d?.uploadedFile || [],
          createdAtDate: d?.createdAtDate,
          createdByNickname: d?.createdByNickname,
          note: d?.note,
        };
      });

      formattedData = state?.attachments?.length
        ? formattedData?.concat(state?.attachments)
        : formattedData;

      return {
        ...state,
        attachments: formattedData,
      };
    }
    case 'setRefetch':
      return { ...state, refetch: action.payload };
    case 'setRefetchMainPage':
      return { ...state, refetchMainPage: action.payload };
    case 'setHistoryCount':
      return { ...state, historyCount: action.payload };
    case 'setDocs2':
      return { ...state, attachments2: action.payload };
    case 'setDispatcherTeams':
      return { ...state, dispatcherTeams: action.payload };
    case 'setMapItem':
      return { ...state, mapItem: action.payload };
    case 'setDispatcherTeamId':
      return { ...state, dispatcherTeamId: action.payload };
    case 'setQcTeams':
      return { ...state, qcTeams: action.payload };
    case 'setQcTeamId':
      return { ...state, qcTeamId: action.payload };
    case 'setDriversList':
      return { ...state, driversList: action.payload };
    case 'setTruckDrivers':
      return { ...state, truckDrivers: action.payload };
    case 'setTrucksList':
      return { ...state, trucksList: action.payload };
    case 'setTruck':
      return { ...state, truck: action.payload };
    case 'setDriver':
      return { ...state, driver: action.payload };
    case 'setSubPath':
      return { ...state, subPath: action.payload };
    case 'setUnitStatus':
      return { ...state, unitStatus: action.payload };
    case 'setUnitId':
      return { ...state, unitId: action.payload };
    case 'setComment':
      return { ...state, comment: action.payload };
    case 'setOrginalContract':
      return { ...state, originalContract: action.payload };
    case 'setReservation':
      return { ...state, reservation: action.payload };
    case 'setEquipments':
      return { ...state, truckEquipments: action.payload };
    case 'setTruckRegCards':
      return { ...state, truckRegCards: action.payload };
    case 'setTruckInspections':
      return { ...state, truckRegCards: action.payload };
    case 'setTruckPermitList':
      return { ...state, truckPermitList: action.payload };
    case 'setTruckMovements':
      return { ...state, truckMovementsList: action.payload };
    case 'setTruckDocuments':
      return { ...state, truckDocuments: action.payload };
    case 'setTruckPm':
      return { ...state, truckPMList: action.payload };
    case 'setTruckMaintain':
      return { ...state, truckMaintainList: action.payload };
    case 'setUnitActive':
      return { ...state, unitActive: action.payload };
    case 'setSamsaraActive':
      return { ...state, samsaraActive: action.payload };
    case 'setSamsaraLastConnection':
      return { ...state, samsaraLastConnection: action.payload };
    case 'setEnsureActive':
      return { ...state, ensureActive: action.payload };
    case 'setEnsureLastConnection':
      return { ...state, ensureLastConnection: action.payload };
    case 'setCurrentGpsData':
      return { ...state, currentGpsData: action.payload };
    case 'setTruckInsurance':
      return { ...state, truckInsuranceList: action.payload };
    case 'setTruckNumberList':
      return { ...state, truckNumbersList: action.payload };
    case 'setCheckState':
      return { ...state, checkState: action.payload };
    case 'setPermission':
      return { ...state, permission: action.payload };
    default:
      return state;
  }
};
