/** @format */

import { useCallback, useEffect, useState } from 'react';

import {
  InfoWindow,
  Marker,
  Autocomplete as GoogleAutocomplete,
} from '@react-google-maps/api';
import { InfoStyle } from './style';
// import { useNavigate } from 'react-router-dom';
import { Button, Input } from 'forms';
import { Toast } from 'modals';
import withTrailer from 'assets/icons/withTrailer.png';
import { dayjs } from 'day-js';
import { useTrucks } from 'context';
import { thousandsSeparatorByComa } from 'formatter';
import { useMutation } from '@tanstack/react-query';
import { useRequest } from 'hooks';
// import withoutTrailer from 'assets/icons/withoutTrailer.png';
const MainMapInfoPage = ({
  place,
  truck,
  truckId,
  trailer,
  clusterer,
  refetch,
  setLatLng,
  latLng,
  noRefetch,
  pageRefetch,
  isSingleTruckMarker,
  onClickTruckNumber,
}) => {
  const { request } = useRequest();
  const [open, setOpen] = useState(false);
  const [openAddress, setOpenAddress] = useState(false);
  const [newLocationOpen, setNewLocationOpen] = useState(false);
  const [completedPosition, setCompletedPosition] = useState(null);
  const [tempPosition, setTempPosition] = useState(null);
  const [{ isTruckSelected, selectedTruck }, dispatch] = useTrucks();
  // const navigate = useNavigate();
  const [state, setState] = useState({
    address: '',
  });
  const [inputMode, setInputMode] = useState('address');
  useEffect(() => {
    setTempPosition(place);
    return () => {};
  }, []);
  const handleClose = () => {
    dispatch({ type: 'setSelectedTruck', payload: {} });
    dispatch({ type: 'setIsTruckSelected', payload: false });

    setOpen(false);
    setOpenAddress(false);
    setNewLocationOpen(false);
    setTempPosition(place);
  };
  const handleCancel = () => {
    setNewLocationOpen(false);
    setOpenAddress(false);
    setOpen(true);
    setTempPosition(place);
  };

  useEffect(() => {
    if (isSingleTruckMarker) {
      dispatch({ type: 'setSelectedTruck', payload: truck });
      setOpen(true);
    }
  }, [isSingleTruckMarker]);

  const handleOpen = (truck) => {
    dispatch({ type: 'setSelectedTruck', payload: truck });
    setOpen(true);
  };

  const engined = () => {
    return {
      path: window.google.maps.SymbolPath.CIRCLE,
      // animation: window.google.maps.Animation.DROP,
      fillColor: '#06B638',
      fillOpacity: 1.0,
      strokeWeight: 2,
      strokeColor: '#fff',
      scale: 7,
    };
  };
  const notEngined = () => {
    return {
      path: window.google.maps.SymbolPath.CIRCLE,
      // animation: window.google.maps.Animation.DROP,
      fillColor: '#064867',
      fillOpacity: 1.0,
      strokeWeight: 2,
      strokeColor: '#fff',
      scale: 7,
    };
  };
  const intransit = ({ marker }) => {
    return {
      path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
      // animation: window.google.maps.Animation.DROP,
      fillColor: '#06B638',
      fillOpacity: 1.0,
      strokeWeight: 1,
      strokeColor: '#fff',
      scale: 4,
      rotation: marker.rotation,
      fixedRotation: false,
    };
  };

  const getIcon = ({ status, degree, speed, trailer }) => {
    if (trailer === null) {
      if (speed > 0) {
        if (status && degree > 0) {
          return {
            icon: intransit({ marker: { rotation: degree } }),
          };
        } else if (degree > 0) {
          return {
            icon: intransit({ marker: { rotation: degree } }),
          };
        } else {
          return {
            icon: engined(),
          };
        }
      } else {
        return {
          icon: notEngined(),
        };
      }
    } else {
      return { icon: withTrailer };
    }
  };

  const handleTruck = () => {
    if (onClickTruckNumber) onClickTruckNumber(truck);
    // truck?.truckId &&
    //   history.location?.pathname &&
    //   localStorage.setItem('prev-location', history.location.pathname);
    // truck?.truckId &&
    //   navigate(`/fleet-department/trucks/:${truck?.truckId}/info`);
  };
  const handleOpenAddress = () => {
    setOpenAddress(!openAddress);
    setOpen(false);
  };

  const [autoCompletePlace, setAutoCompletePlace] = useState({});

  const onAutoCompleteLoad = (autocomplete) => {
    setAutoCompletePlace(autocomplete);
  };

  const handlePlaceChange = () => {
    if (autoCompletePlace !== null) {
      const place = autoCompletePlace.getPlace();

      setState({
        ...state,
        address: place.formatted_address,
      });
      if (place.formatted_address) {
        setCompletedPosition({
          lat: place?.geometry?.location?.lat(),
          lng: place?.geometry?.location?.lng(),
        });
      }
    }
  };

  const handleLatChange = useCallback((event) => {
    const { value, name } = event.target;
    setLatLng({ ...latLng, [name]: value });
  }, []);

  const handleInputModeChange = useCallback((event) => {
    setInputMode(event.target.value);
  }, []);

  const handleShowLocation = () => {
    setTempPosition(completedPosition);
    setLatLng(completedPosition);
    setOpen(false);
    setOpenAddress(false);
    setNewLocationOpen(true);
  };
  const handleChangeSearch = (e) => {
    const { value } = e.target;

    if (!value) {
      setLatLng({
        lat: trailer?.latitude,
        lng: trailer?.longitude,
      });
    }
    setState({ ...state, address: value });
  };
  const setLocation = useMutation({
    mutationFn: (values) => {
      return (
        (truckId || truck?.truckId) &&
        request({
          url: `/v3/trucks/${
            truckId || truck?.truckId
          }/change_location_manually?latitude=${values?.lat}&longitude=${
            values?.lng
          }`,
          method: 'PUT',
          body: values,
        })
      );
    },
  });

  const onSetLocation = () => {
    setLocation.mutate(latLng, {
      onSuccess: (res) => {
        if (res?.success) {
          Toast({
            type: 'success',
            message: res?.message,
          });
          setNewLocationOpen(false);
          pageRefetch && pageRefetch();
          setOpen(true);
          !noRefetch && refetch();
        }
      },
      onError: (err) => {
        Toast({
          type: 'error',
          message: err?.message,
        });
      },
    });
  };

  const autocompleteOptions = {
    componentRestrictions: { country: 'us' },
  };
  return (
    <>
      <Marker
        position={tempPosition}
        clusterer={clusterer}
        icon={
          getIcon({
            status: truck?.engineStateValue,
            degree: truck?.headingDegrees,
            speed: truck?.speed,
            trailer: trailer,
          })?.icon
        }
        onClick={() => handleOpen(truck)}
      />
      <>
        {(!newLocationOpen &&
          open &&
          selectedTruck?.truckNumber === truck?.truckNumber) ||
        (isTruckSelected &&
          !newLocationOpen &&
          selectedTruck?.truckNumber === truck?.truckNumber) ? (
          <InfoWindow
            position={tempPosition || place}
            onCloseClick={handleClose}
          >
            <InfoStyle>
              <InfoStyle.Row>
                <InfoStyle.Text
                  lineHeight='32px'
                  name='true'
                  title={`Truck number: ${truck?.truckNumber}`}
                  style={{ cursor: 'pointer' }}
                  onClick={handleTruck}
                >
                  {truck?.truckNumber || '--'}
                </InfoStyle.Text>
                {truck?.speed ? (
                  <InfoStyle.Span title='speed'>
                    {Math.round(truck?.speed) + ' MPH'}
                  </InfoStyle.Span>
                ) : null}
              </InfoStyle.Row>
              {truck?.driverNames ? (
                <InfoStyle.Text
                  driver='true'
                  lineHeight='20px'
                  title={truck?.driverNames}
                >
                  {truck?.driverNames || '--'}
                </InfoStyle.Text>
              ) : null}
              <InfoStyle.Text lineHeight='13px' loc='true' title='address'>
                {truck?.address || '--'}
                {/* You need to enable JavaScript to run this app. */}
              </InfoStyle.Text>
              {truck?.driver?.firstName ? (
                <InfoStyle.Text driver='true' lineHeight='15px'>
                  <div title='Driver'>
                    {truck?.driver?.firstName + ' ' + truck?.driver?.lastName}
                  </div>
                  <div title='Phone number'>{truck?.driver?.phoneNumber}</div>
                </InfoStyle.Text>
              ) : null}
              {truck?.coDriver?.firstName ? (
                <InfoStyle.Text driver='true' lineHeight='15px'>
                  <div title='Driver'>
                    {truck?.coDriver?.firstName +
                      ' ' +
                      truck?.coDriver?.lastName}
                  </div>
                  <div title='Phone number'>{truck?.coDriver?.phoneNumber}</div>
                </InfoStyle.Text>
              ) : null}
              {truck?.odometerTime ? (
                <InfoStyle.Text lineHeight='13px' style={{ marginTop: '10px' }}>
                  <div>Odometer time:</div>
                  <div>
                    {dayjs(truck?.odometerTime).format('MM/DD/YYYY HH:mm')}
                  </div>
                </InfoStyle.Text>
              ) : null}
              {truck?.odometerValue ? (
                <InfoStyle.Text lineHeight='13px'>
                  <div>Odometer value:</div>
                  <div>
                    {thousandsSeparatorByComa(
                      truck?.odometerValue?.toFixed(2) || 0
                    )}
                  </div>
                </InfoStyle.Text>
              ) : null}
              {truck?.engineStateTime ? (
                <InfoStyle.Text lineHeight='13px'>
                  <div>Engine time:</div>
                  {dayjs(truck?.engineStateTime).format('MM/DD/YYYY HH:mm')}
                </InfoStyle.Text>
              ) : null}
              {truck?.lastUpdated ? (
                <InfoStyle.Text lineHeight='13px'>
                  <div>Last updated time:</div>
                  {dayjs(truck?.lastUpdated).format('MM/DD/YYYY HH:mm')}
                </InfoStyle.Text>
              ) : null}

              {trailer !== null && (
                <InfoStyle.Span trailer>
                  Trailer number: <strong>{trailer}</strong>
                </InfoStyle.Span>
              )}
              {!isSingleTruckMarker && !truck.refreshLocationFromRemote ? (
                <Button
                  position='absolute'
                  right='10px'
                  bottom='15px'
                  btnType='map_button'
                  onClick={() => handleOpenAddress(trailer)}
                >
                  Move
                </Button>
              ) : null}
            </InfoStyle>
          </InfoWindow>
        ) : null}
        {isSingleTruckMarker ? (
          <InfoWindow
            position={tempPosition || place}
            onCloseClick={handleClose}
          >
            <InfoStyle>
              <InfoStyle.Row>
                <InfoStyle.Text
                  lineHeight='32px'
                  name='true'
                  title={`Truck number: ${truck?.truckNumber}`}
                  style={{ cursor: 'pointer' }}
                  onClick={handleTruck}
                >
                  {truck?.truckNumber || '--'}
                </InfoStyle.Text>
                {truck?.speed ? (
                  <InfoStyle.Span title='speed'>
                    {Math.round(truck?.speed) + ' MPH'}
                  </InfoStyle.Span>
                ) : null}
              </InfoStyle.Row>
              <InfoStyle.Text lineHeight='13px' loc='true' title='address'>
                {truck?.address || '--'}
                {/* You need to enable JavaScript to run this app. */}
              </InfoStyle.Text>
              {truck?.driver?.firstName ? (
                <InfoStyle.Text driver='true' lineHeight='15px'>
                  <div title='Driver'>
                    {truck?.driver?.firstName + ' ' + truck?.driver?.lastName}
                  </div>
                  <div title='Phone number'>{truck?.driver?.phoneNumber}</div>
                </InfoStyle.Text>
              ) : null}
              {truck?.coDriver?.firstName ? (
                <InfoStyle.Text driver='true' lineHeight='15px'>
                  <div title='Driver'>
                    {truck?.coDriver?.firstName +
                      ' ' +
                      truck?.coDriver?.lastName}
                  </div>
                  <div title='Phone number'>{truck?.coDriver?.phoneNumber}</div>
                </InfoStyle.Text>
              ) : null}
              {truck?.odometerTime ? (
                <InfoStyle.Text lineHeight='13px' style={{ marginTop: '10px' }}>
                  <div>Odometer time:</div>
                  <div>
                    {dayjs(truck?.odometerTime).format('MM/DD/YYYY HH:mm')}
                  </div>
                </InfoStyle.Text>
              ) : null}
              {truck?.odometerValue ? (
                <InfoStyle.Text lineHeight='13px'>
                  <div>Odometer value:</div>
                  <div>
                    {thousandsSeparatorByComa(
                      truck?.odometerValue?.toFixed(2) || 0
                    )}
                  </div>
                </InfoStyle.Text>
              ) : null}
              {truck?.engineStateTime ? (
                <InfoStyle.Text lineHeight='13px'>
                  <div>Engine time:</div>
                  {dayjs(truck?.engineStateTime).format('MM/DD/YYYY HH:mm')}
                </InfoStyle.Text>
              ) : null}
              {truck?.lastUpdated ? (
                <InfoStyle.Text lineHeight='13px'>
                  <div>Last updated time:</div>
                  {dayjs(truck?.lastUpdated).format('MM/DD/YYYY HH:mm')}
                </InfoStyle.Text>
              ) : null}

              {trailer !== null && (
                <InfoStyle.Span trailer>
                  Trailer number: <strong>{trailer}</strong>
                </InfoStyle.Span>
              )}
              {/* {!truck.refreshLocationFromRemote ? (
                <Button
                  position='absolute'
                  right='10px'
                  bottom='15px'
                  btnType='map_button'
                  onClick={() => handleOpenAddress(trailer)}
                >
                  Move
                </Button>
              ) : null} */}
            </InfoStyle>
          </InfoWindow>
        ) : null}
        {!newLocationOpen && openAddress ? (
          <InfoWindow
            position={tempPosition || place}
            onCloseClick={handleClose}
          >
            <InfoStyle>
              <InfoStyle.Text style={{ fontWeight: 600, color: '#000' }}>
                Set a new location
              </InfoStyle.Text>
              <div style={{ display: 'grid', gridGap: '4px' }}>
                <label style={{ display: 'flex', gridGap: '5px' }}>
                  <input
                    type='radio'
                    value='address'
                    checked={inputMode === 'address'}
                    onChange={handleInputModeChange}
                  />
                  <div>Address</div>
                </label>{' '}
                <GoogleAutocomplete
                  onPlaceChanged={handlePlaceChange}
                  onLoad={onAutoCompleteLoad}
                  options={autocompleteOptions}
                >
                  <Input
                    height='27px'
                    placeholder='Search address'
                    onChange={handleChangeSearch}
                    value={inputMode !== 'latlng' ? state?.address : ''}
                    disabled={inputMode === 'latlng'}
                  />
                </GoogleAutocomplete>
                <label style={{ display: 'flex', gridGap: '5px' }}>
                  <input
                    type='radio'
                    value='latlng'
                    checked={inputMode === 'latlng'}
                    onChange={handleInputModeChange}
                  />
                  <div>Lat-Lng</div>
                </label>
                <div style={{ display: 'flex', gridGap: '5px' }}>
                  <Input
                    type='text'
                    name='lat'
                    height='27px'
                    value={inputMode !== 'address' ? latLng?.lat : ''}
                    onChange={handleLatChange}
                    placeholder='Latitude'
                    disabled={inputMode === 'address'}
                  />
                  <Input
                    type='text'
                    name='lng'
                    height='27px'
                    value={inputMode !== 'address' ? latLng?.lng : ''}
                    onChange={handleLatChange}
                    placeholder='Longitude'
                    disabled={inputMode === 'address'}
                  />
                </div>
              </div>

              <InfoStyle.Row
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gridGap: '5px',
                }}
              >
                {!isSingleTruckMarker && truck !== null && open ? (
                  <Button
                    position='absolute'
                    right='0px'
                    top='5px'
                    btnType='map_button'
                    onClick={handleOpenAddress}
                  >
                    Move
                  </Button>
                ) : null}
                {truck !== null && openAddress ? (
                  <Button
                    position='absolute'
                    right='0px'
                    top='5px'
                    btnType='map_button'
                    onClick={handleShowLocation}
                  >
                    Show location
                  </Button>
                ) : null}
              </InfoStyle.Row>
            </InfoStyle>
          </InfoWindow>
        ) : null}
        {newLocationOpen ? (
          <InfoWindow position={tempPosition} onCloseClick={handleCancel}>
            <InfoStyle height='fit-content'>
              <InfoStyle.Text style={{ fontWeight: 600, color: '#000' }}>
                A new location
              </InfoStyle.Text>
              <InfoStyle.Text>{state?.address}</InfoStyle.Text>

              <InfoStyle.Row
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gridGap: '5px',
                }}
              >
                {truck !== null && newLocationOpen ? (
                  <>
                    <Button
                      btnType='map_button'
                      color='red'
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button btnType='map_button' onClick={onSetLocation}>
                      Accept
                    </Button>
                  </>
                ) : null}
              </InfoStyle.Row>
            </InfoStyle>
          </InfoWindow>
        ) : null}
      </>
    </>
  );
};

export default MainMapInfoPage;
