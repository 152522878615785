/** @format */

import { useContext, createContext, useReducer } from 'react';
import { reducer, initialState } from './reducer';

export const CustomersContext = createContext();
export const useCustomers = () => useContext(CustomersContext);

export const CustomersProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <CustomersContext.Provider value={[state, dispatch]}>
      {children}
    </CustomersContext.Provider>
  );
};
