/** @format */

import { useRef } from 'react';
import { Wrapper, AntModal, Container, ModalClose } from './style';
import { CloseIcon } from 'styledIcons';
import { ConfirmBeforeLeaving } from 'forms';
export default function MainModal({
  drawerOpen,
  closeDrawer,
  children,
  width,
  paddingtop,
  position,
  contentPosition,
  placement,
  noPadding = true,
  onCancel,
  stateChange,
  refetch,
  minwidth,
  zIndex,
  padding,
}) {
  const closeBtn = useRef(null);
  const handleCancel = () => {
    closeDrawer && closeDrawer();
    onCancel && onCancel();
    refetch && refetch();
  };

  const handleClose = () => {
    if (stateChange) {
      closeBtn.current.click();
      refetch && refetch();
    } else {
      closeDrawer && closeDrawer();
      refetch && refetch();
    }
  };

  return (
    <Container position={position}>
      <AntModal
        zIndex={zIndex || 998}
        open={drawerOpen}
        width={width || '720px'}
        minwidth={minwidth}
        size={width || '720px'}
        placement={placement || 'right'}
        onClose={handleClose}
        padding={padding}
        destroyOnClose
        styles={{
          header: {
            display: 'none',
          },
          body: {
            padding: '0',
            position: 'relative',
          },
        }}
        wrapper={{
          padding: '0',
        }}
        position={position || 'relative'}
      >
        {stateChange ? (
          <ConfirmBeforeLeaving
            onConfirm={handleCancel}
            onCancel={() => {}}
            placement='bottomRight'
            zIndex='9999'
          >
            <ModalClose width={width || '648px'} ref={closeBtn}>
              <CloseIcon
                left='5px'
                width='25px'
                height='25px'
                className='close-icon'
              />
            </ModalClose>
          </ConfirmBeforeLeaving>
        ) : (
          <ModalClose onClick={() => handleCancel()} width={width || '648px'}>
            <CloseIcon
              left='5px'
              width='25px'
              height='25px'
              className='close-icon'
            />
          </ModalClose>
        )}

        <Wrapper
          position={contentPosition}
          paddingtop={paddingtop}
          nopadding={noPadding.toString()}
        >
          {children}
        </Wrapper>
      </AntModal>
    </Container>
  );
}
