/** @format */

import 'react-toastify/dist/ReactToastify.css';
import { Container } from './ToastStyle.js';
import { ToastContainer } from 'react-toastify';

export default function ToastProvider({ children }) {
  return (
    <Container>
      {children}
      <ToastContainer closeOnClick stacked />
    </Container>
  );
}
