/** @format */
import React from 'react';

const Company = React.lazy(() => import('./Company'));
const DocType = React.lazy(() => import('./DocType'));
const Zone = React.lazy(() => import('./Zone'));
const Location = React.lazy(() => import('./Location'));
const CategoriesPage = React.lazy(() => import('./Category'));
const Constants = React.lazy(() => import('./Constants'));

export { Company, DocType, Zone, Location, CategoriesPage, Constants };
