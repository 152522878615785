/** @format */

import styled from 'styled-components';

import { Collapse } from 'antd';

const StyledCollapse = styled(Collapse)`
  .ant-collapse-expand-icon {
    margin-inline-start: 0px !important;
  }
`;

export { StyledCollapse };
