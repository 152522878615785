/** @format */

import React from 'react';
import ShowFiles from '../Files';
import { MainWrapper } from './style';

const ShowFilesWithGroup = ({
  item,
  fileTypes,
  fontSize,
  imgWidth,
  imgHeight,
  unDeletable,
  class_name,
}) => {
  return (
    item?.attachments?.length > 0 &&
    fileTypes?.length > 0 && (
      <MainWrapper className={class_name}>
        {fileTypes?.map((fileType, index) => {
          let files = item?.attachments?.filter(
            (attach) =>
              attach?.docTypeName === fileType?.name ||
              attach?.name === fileType?.name ||
              attach?.docTypeId === fileType?.id
          );
          return files?.length > 0 ? (
            <div key={index} className='doc_types'>
              <div className='doc_types_title'>{fileType?.name}</div>
              <ShowFiles
                fontSize={fontSize}
                files={files}
                imgWidth={imgWidth}
                imgHeight={imgHeight}
                unDeletable={unDeletable}
              />
            </div>
          ) : null;
        })}
      </MainWrapper>
    )
  );
};

export default React.memo(ShowFilesWithGroup);
