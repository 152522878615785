/** @format */

import React from 'react';
import { SelectWrapper, SelectLable, StyledOpt, StyledSelect } from './style';
import RedStars from '../RedStars';

const SimpleSelect = ({
  isrequired,
  options,
  placeholder,
  label,
  value,
  width,
  allOption,
  bordercolor,
  height,
  onSearch,
  fromPerformance,
  showSearch,
  fsize,
  border,
  ...props
}) => {
  return (
    <SelectWrapper
      width={width}
      bordercolor={bordercolor}
      border={border}
      height={fromPerformance && height}
      fsize={fsize}
    >
      {label && (
        <SelectLable>
          {label}
          {isrequired ? <RedStars /> : null}
        </SelectLable>
      )}
      <StyledSelect
        width={width}
        onSearch={onSearch}
        showSearch={showSearch}
        popupMatchSelectWidth={false}
        value={value || null}
        placeholder={placeholder || 'Select ...'}
        notFoundContent={'No data found!'}
        {...props}
      >
        {allOption === 'true' && (
          <StyledOpt key='' value=''>
            All
          </StyledOpt>
        )}
        {options?.length &&
          options?.map(({ key, value, ...rest }) => {
            return (
              <StyledOpt key={key} value={value} {...rest}>
                {key}
              </StyledOpt>
            );
          })}
      </StyledSelect>
    </SelectWrapper>
  );
};
export default React.memo(SimpleSelect);
