import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './cdlReducer';
export const CDLContext = createContext();
export const useCDL = () => useContext(CDLContext);

export const CDLContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <CDLContext.Provider value={[state, dispatch]}>
      {children}
    </CDLContext.Provider>
  );
};
