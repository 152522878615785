/** @format */

import styled from 'styled-components';
import pdf from 'assets/icons/pdf-logo.png';
import word from 'assets/icons/word-logo.png';
import excel from 'assets/icons/excel-logo.png';
import htmlFile from 'assets/icons/file-html.png';
import mp3File from 'assets/icons/file-mp3.png';
import mp4File from 'assets/icons/file-mp4.png';
import ppt from 'assets/icons/file-ppt.png';
import sqlFile from 'assets/icons/file-sql.png';
import zipFile from 'assets/icons/file-zip.png';
import txtFile from 'assets/icons/file-text.png';
import jsonFile from 'assets/icons/file-json.png';
import { Collapse } from 'antd';

const { Panel } = Collapse;

export const Wrapper = styled.div`
  /* display: flex; */
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '16px')}!important;

  width: 100%;
`;

export const AntPanelHead = styled(Panel)`
  font-size: ${({ isload }) => (isload === 'true' ? '14px' : '12px')};
  font-weight: ${({ isload }) => (isload === 'true' ? '700' : '600')};
  line-height: 28px;
  background: ${({ isload }) =>
    isload ? 'rgba(4, 166, 251, 0.1)' : 'rgba(1, 122, 255, 0.1)'};
  color: ${({ isload }) =>
    isload === 'true' ? '#1893FF' : '#5c6470'} !important;
  padding: 6px 0;
  padding-left: ${({ isload }) =>
    isload === 'true' ? '8px' : '16px'} !important;
  /* border: 0.5px solid #c4c4c4; */
`;

export const AntCollapse = styled(Collapse)`
  /* min-width: 727px; */
  width: 100%;
  text-align: center;
  border: none !important;
  .listheader > .ant-collapse-header {
    color: inherit;
    font-style: normal;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    height: 100%;
  }
  .listheader > .ant-collapse-header .ant-collapse-arrow {
    display: none;
  }
  div.ant-collapse-item {
    display: flex;
    flex: 1;
    flex-direction: column;
    border-top: 0.5px solid #f0f2fa;
    border-bottom: 0.5px solid #f0f2fa;
    div.ant-collapse-content {
      border-top: 0.5px solid #f0f2fa;
      border-bottom: 0.5px solid #f0f2fa;
      div.ant-collapse-content-box {
        padding: 0 !important;
      }
    }
    div.ant-collapse-header {
      svg {
        fill: black;
      }
      display: flex;
      align-items: center;
      /* height: 100%; */
      /* margin-right: 10px; */
      padding-left: 8px;
      padding-right: 8px;
      > div {
        color: #313e47 !important;
        font-family: var(--appPrimaryFont) !important;
        font-style: normal !important;
        font-size: var(--appTableFontSize);

        line-height: 18px !important;
        div {
          div {
            div:last-child {
              font-size: 13px !important;
            }
          }
        }
      }
    }
  }
  div.listheader {
    div.ant-collapse-header {
      svg {
        fill: transparent;
      }
    }
    div {
      cursor: default !important;
    }
  }
`;

const Img = styled(Image)`
  position: relative;
  height: ${({ imgHeight }) => (imgHeight ? imgHeight : '170px')};
  width: ${({ imgWidth }) => (imgWidth ? imgWidth : '120px')};
  border-radius: 4px;
  overflow: hidden;
  object-fit: fill;

  .ant-image-preview-operations-operation {
    margin-left: 12px;
    padding: 0 !important;
    cursor: pointer;
  }
`;

const CustomMask = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  font-size: 16px;
  color: #fff;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  cursor: pointer;
`;

const AntImage = styled(Image)`
  object-fit: cover;
  width: ${({ imgWidth }) => (imgWidth ? imgWidth : '150px')};
  height: ${({ imgHeight }) => (imgHeight ? imgHeight : '175px')};
  cursor: pointer;
  &:hover {
    opacity: 1 !important;
  }
`;
const PdfReader = styled.div`
  background-image: url(${pdf});
  background-repeat: no-repeat;
  width: ${({ imgWidth }) => (imgWidth ? imgWidth : '150px')};
  height: ${({ imgHeight }) => (imgHeight ? imgHeight : '175px')};
  background-size: contain;
  margin-left: 6px;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;
const TextReader = styled.div`
  background-image: url(${txtFile});
  background-repeat: no-repeat;
  width: ${({ imgWidth }) => (imgWidth ? imgWidth : '150px')};
  height: ${({ imgHeight }) => (imgHeight ? imgHeight : '175px')};
  background-size: contain;
  margin-left: 6px;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;
const HtmlReader = styled.div`
  background-image: url(${htmlFile});
  background-repeat: no-repeat;
  width: ${({ imgWidth }) => (imgWidth ? imgWidth : '150px')};
  height: ${({ imgHeight }) => (imgHeight ? imgHeight : '175px')};
  background-size: contain;
  margin-left: 6px;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;
const PptReader = styled.div`
  background-image: url(${ppt});
  background-repeat: no-repeat;
  width: ${({ imgWidth }) => (imgWidth ? imgWidth : '150px')};
  height: ${({ imgHeight }) => (imgHeight ? imgHeight : '175px')};
  background-size: contain;
  margin-left: 6px;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;
const SqlReader = styled.div`
  background-image: url(${sqlFile});
  background-repeat: no-repeat;
  width: ${({ imgWidth }) => (imgWidth ? imgWidth : '150px')};
  height: ${({ imgHeight }) => (imgHeight ? imgHeight : '175px')};
  background-size: contain;
  margin-left: 6px;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;
const ZipReader = styled.div`
  background-image: url(${zipFile});
  background-repeat: no-repeat;
  width: ${({ imgWidth }) => (imgWidth ? imgWidth : '150px')};
  height: ${({ imgHeight }) => (imgHeight ? imgHeight : '175px')};
  background-size: contain;
  margin-left: 6px;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;
const Mp3File = styled.div`
  background-image: url(${mp3File});
  background-repeat: no-repeat;
  width: ${({ imgWidth }) => (imgWidth ? imgWidth : '150px')};
  height: ${({ imgHeight }) => (imgHeight ? imgHeight : '175px')};
  background-size: contain;
  margin-left: 6px;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;
const Mp4File = styled.div`
  background-image: url(${mp4File});
  background-repeat: no-repeat;
  width: ${({ imgWidth }) => (imgWidth ? imgWidth : '150px')};
  height: ${({ imgHeight }) => (imgHeight ? imgHeight : '175px')};
  background-size: contain;
  margin-left: 6px;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;
const JsonReaderIcon = styled.div`
  background-image: url(${jsonFile});
  background-repeat: no-repeat;
  width: ${({ imgWidth }) => (imgWidth ? imgWidth : '150px')};
  height: ${({ imgHeight }) => (imgHeight ? imgHeight : '175px')};
  background-size: contain;
  margin-left: 6px;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;
const WordReader = styled.div`
  background-image: url(${word});
  background-repeat: no-repeat;
  width: ${({ imgWidth }) => (imgWidth ? imgWidth : '150px')};
  height: ${({ imgHeight }) => (imgHeight ? imgHeight : '175px')};
  background-size: contain;
  margin-left: 6px;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;
const ExcelReader = styled.div`
  background-image: url(${excel});
  background-repeat: no-repeat;
  width: ${({ imgWidth }) => (imgWidth ? imgWidth : '150px')};
  height: ${({ imgHeight }) => (imgHeight ? imgHeight : '175px')};
  background-size: contain;
  margin-left: 6px;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;

const FileWrapper = styled.div`
  width: ${({ width }) => width || '50px'};
  height: ${({ height }) => height || '50px'};
  position: relative;
  .uploading {
    position: absolute;
    pointer-events: none;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.6);
    font-size: 10px;
    color: white;

    .wrapper-btns {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &:hover {
    .uploading {
      opacity: 1;
      svg {
        opacity: 1;
      }
    }
  }
`;

export {
  FileWrapper,
  Img,
  CustomMask,
  AntImage,
  PdfReader,
  TextReader,
  HtmlReader,
  PptReader,
  SqlReader,
  ZipReader,
  Mp3File,
  Mp4File,
  JsonReaderIcon,
  WordReader,
  ExcelReader,
};
