/** @format */

import React, { useState, useRef, forwardRef } from 'react';
import { GridTable } from './styled';

const gridOpenFilter = () => {
  const filter = document.querySelector('.grid-table-filter');
  filter.classList.toggle('open');
  return filter?.classList?.contains('open');
};

const GridFilter = forwardRef(
  ({ gridtemplatecolumns, columnDefs, PanelRenderer }, ref) => {
    const localFilterRef = useRef(null);
    const filter = ref || localFilterRef;
    const [openFilter, setOpenFilter] = useState(false);

    const attrObserver = new MutationObserver((mutations) => {
      mutations.forEach((mu) => {
        if (mu.type !== 'attributes' && mu.attributeName !== 'class') return;
        setOpenFilter(filter.current?.classList?.contains('open'));
      });
    });

    const filters = document.querySelectorAll('.grid-table-filter');
    filters.forEach((el) => attrObserver.observe(el, { attributes: true }));

    return (
      <GridTable.Filter
        ref={filter}
        filter='true'
        className='grid-table-filter'
        gridtemplatecolumns={gridtemplatecolumns}
        style={{ paddingLeft: PanelRenderer ? '25px' : 0 }}
      >
        {columnDefs?.map(
          (
            { floatingFilterComponent: Filter, filterStyle, headerName },
            index
          ) => (
            <GridTable.Th
              key={index}
              style={filterStyle && filterStyle(headerName)}
              filter='true'
            >
              {Filter && <Filter openFilter={openFilter} />}
            </GridTable.Th>
          )
        )}
      </GridTable.Filter>
    );
  }
);
GridFilter.displayName = 'GridFilter';

export { gridOpenFilter };
export default React.memo(GridFilter);
