import styled from 'styled-components';
import { ReactComponent as Gallary } from 'assets/icons/gallery-view.svg';
import { ReactComponent as Upload } from 'assets/icons/upload-2-line.svg';
import { ReactComponent as List } from 'assets/icons/list-unordered.svg';

const getColor = (status) => {
  let st = status?.toLowerCase();
  switch (st) {
    case 'good':
      return '#00CC0E';
    case 'warning':
      return '#FFC000';
    case 'expired':
      return '#ff0000';
  }
};

export const IconUpload = styled(Upload)`
  width: 20px;
  height: 18px;
  cursor: pointer;

  path {
    fill: #5c6470;
  }
  &:hover {
    path {
      fill: #000;
    }
  }
`;
export const IconGallery = styled(Gallary)`
  width: 18px;
  height: 18px;
  cursor: pointer;

  path {
    fill: #5c6470;
  }
  &:hover {
    path {
      fill: #000;
    }
  }
`;
export const IconList = styled(List)`
  cursor: pointer;

  width: 18px;
  height: 16.9px;
  path {
    fill: #5c6470;
  }
  &:hover {
    path {
      fill: #000;
    }
  }
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 140px;
  width: 100%;
  align-items: center;
  padding: 1px 0;
  .header {
    font-weight: 600 !important;
  }
  font-size: var(--appTableFontSize);
  div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    width: 100%;

    div.date {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

Container.Flex = styled.div`
  height: transparent;
  display: flex;
  align-items: center;
`;

Container.Title = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: ${({ status }) => status && getColor(status)};
  margin: 0 0 0 10px;
  width: fit-content;
`;

export const WrapperTr = styled.div`
  padding: 1px 0;
  #selectWrapper {
    min-width: 200px;
    margin: 0 auto;

    span {
      color: rgba(68, 68, 68, 0.66);
    }
  }
  .iconsWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    div {
      max-width: 46px;
    }
  }
`;
