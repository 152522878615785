/** @format */

import React, { useState, useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css';

import pdf from 'assets/icons/pdf-logo.png';
import word from 'assets/icons/word-logo.png';
import excel from 'assets/icons/excel-logo.png';
import loading from 'assets/icons/Spinner.gif';

import { DownloadIcon, IconView, DeleteIcon, NoteViewIcon } from 'styledIcons';
import { Toast, DeleteConfirm } from 'modals';
import { useRequest } from 'hooks';
import { Loading } from 'loaders';
import { ShowFiles } from 'files';

import { PanelWrap } from './style';
import { useLocation } from 'react-router-dom';
import { Tooltip } from 'antd';
import { DownloadingSpinner } from '../../../../FormComponents/Uploader/style';
import { getUsersTimeByZoneLTS } from '../../../../../../utils/Formatter';
const useDownloadFile = () => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [fileId, setFileId] = useState(null);
  const [fileIndex, setFileIndex] = useState(null);

  // Utility function to encode file paths for URLs
  const encodeFilePath = useCallback((path) => {
    if (typeof path === 'string') {
      const lastIndex = path.lastIndexOf('/');
      return (
        path.substring(0, lastIndex + 1) +
        encodeURIComponent(path.substring(lastIndex + 1))
      );
    }
    return path;
  }, []);

  const downloadFile = useCallback(
    async (fileName, filePath, fileId, photoIndex) => {
      setIsDownloading(true);
      setFileId(fileId);
      setFileIndex(photoIndex);
      try {
        const encodedFilePath = encodeFilePath(filePath);
        const response = await fetch(encodedFilePath, {
          headers: {
            'Content-Type': 'application/octet-stream',
          },
          method: 'GET',
        });

        if (!response.ok) throw new Error(`Error: ${response.statusText}`);

        const contentLength = response.headers.get('Content-Length');
        let receivedLength = 0;
        const reader = response.body.getReader();
        const chunks = []; // chunks of received data

        // Read the data
        let read = await reader.read();
        while (!read.done) {
          chunks.push(read.value);
          receivedLength += read.value.length;

          if (contentLength) {
            setDownloadProgress(
              (receivedLength / parseInt(contentLength)) * 100
            );
          }

          read = await reader.read();
        }

        const blob = new Blob(chunks);
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        setIsDownloading(true);

        // Clean up
        window.URL.revokeObjectURL(downloadUrl);
        link.remove();
      } catch (error) {
        Toast({
          type: 'error',
          message: error?.message,
        });
      } finally {
        setIsDownloading(false);
      }
    },
    [encodeFilePath]
  );
  return { isDownloading, downloadProgress, downloadFile, fileId, fileIndex };
};

const SubContent = ({
  isLoading,
  attachments,
  dispatch,
  galView,
  data,
  deletePermission,
  driverId,
  driverRefetch,
}) => {
  const { pathname, search } = useLocation();
  const { request } = useRequest();
  const [imagePath, setImagePath] = useState('');
  const [fileOrgName, setFileOrgName] = useState('');
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const { isDownloading, downloadFile, fileId, fileIndex } = useDownloadFile();
  const encodeFilePath = (path) => {
    if (typeof path === 'string') {
      const lastIndex = path.lastIndexOf('/');
      return (
        path.substring(0, lastIndex + 1) +
        encodeURIComponent(path.substring(lastIndex + 1))
      );
    }
    return pathname + search;
  };
  const deleteAttachment = useMutation({
    mutationFn: (id) => {
      return request({
        url: `/v2/file_attachments/${id}`,
        method: 'DELETE',
      });
    },
  });
  const onConfirm = (attached) => {
    !driverId
      ? deleteAttachment.mutate(attached?.id || attached?.attachmentId, {
          onSuccess: () => {
            let newData = data?.attachments?.filter(
              (doc) =>
                doc.attachmentId !== attached?.attachmentId ||
                doc.id !== attached?.id
            );

            dispatch({
              type: 'setDocs',
              payload: newData,
              own: 'true',
            });

            Toast({
              type: 'success',
              message: 'Files successfully deleted !',
            });
          },
          onError: (err) => {
            Toast({
              type: 'error',
              message: err?.message,
            });
          },
        })
      : deleteAttachment.mutate(attached?.id || attached?.attachmentId, {
          onSuccess: () => {
            driverRefetch?.mutate(
              {
                unitNumber: driverId,
              },
              {
                onSuccess: (res) => {
                  dispatch({
                    type: 'setDriverLoading',
                    payload: false,
                  }),
                    dispatch({
                      type: 'setDriverInfo',
                      payload: res?.dataList || [res?.data],
                    });
                  Toast({
                    type: 'success',
                    message: 'Files successfully deleted !!!',
                  });
                },
                onError: (err) => {
                  Toast({
                    type: 'error',
                    message: err?.message,
                  });
                },
              }
            );
          },
        });
  };

  const onCancel = (msg) => {
    Toast({
      type: 'error',
      message: msg || 'Action cancelled!',
    });
  };

  let imageList =
    attachments?.length > 0 &&
    attachments?.filter((file) => {
      return (
        file?.uploadedFile?.extension === 'image/jpeg' ||
        file?.uploadedFile?.extension === 'image/png' ||
        file?.uploadedFile?.extension === 'image/bmp' ||
        file?.uploadedFile?.extension === 'image/gif' ||
        file?.uploadedFile?.extension === 'image/svg+xml' ||
        file?.uploadedFile?.extension === 'image/*' ||
        file?.extension === 'image/jpeg' ||
        file?.extension === 'image/png' ||
        file?.extension === 'image/bmp' ||
        file?.extension === 'image/gif' ||
        file?.extension === 'image/svg+xml' ||
        file?.extension === 'image/*' ||
        file?.uploadedFile?.extension?.endsWith('.jpg') ||
        file?.uploadedFile?.extension?.endsWith('.png') ||
        file?.uploadedFile?.extension?.endsWith('.bmp') ||
        file?.uploadedFile?.extension?.endsWith('.gif') ||
        file?.uploadedFile?.extension?.endsWith('.svg') ||
        file?.extension?.endsWith('.jpg') ||
        file?.extension?.endsWith('.png') ||
        file?.extension?.endsWith('.bmp') ||
        file?.extension?.endsWith('.svg') ||
        file?.extension?.endsWith('.gif')
      );
    });
  let newArr =
    attachments?.length > 0 &&
    attachments
      ?.filter((fil) => {
        return (
          fil?.uploadedFile?.extension ===
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
          fil?.uploadedFile?.extension === 'application/pdf' ||
          fil?.uploadedFile?.extension ===
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
          fil?.uploadedFile?.extension === 'application/msword' ||
          fil?.extension ===
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
          fil?.extension === 'application/pdf' ||
          fil?.extension ===
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
          fil?.extension === 'application/msword' ||
          fil?.uploadedFile?.extension?.endsWith('.pdf') ||
          fil?.extension?.endsWith('.pdf') ||
          fil?.uploadedFile?.extension?.endsWith('.doc') ||
          fil?.uploadedFile?.extension?.endsWith('.docx') ||
          fil?.extension?.endsWith('.doc') ||
          fil?.extension?.endsWith('.docx') ||
          fil?.uploadedFile?.extension?.endsWith('.xls') ||
          fil?.uploadedFile?.extension?.endsWith('.xlsx') ||
          fil?.extension?.endsWith('.xls') ||
          fil?.extension?.endsWith('.xlsx')
        );
      })
      ?.map((file) => {
        return {
          ...file,
          iconpath:
            file?.uploadedFile?.extension ===
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            file?.extension ===
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            file?.uploadedFile?.extension?.endsWith('.xls') ||
            file?.uploadedFile?.extension?.endsWith('.xlsx') ||
            file?.extension?.endsWith('.xls') ||
            file?.extension?.endsWith('.xlsx')
              ? excel
              : file?.uploadedFile?.extension === 'application/pdf' ||
                file?.extension === 'application/pdf' ||
                file?.uploadedFile?.extension?.endsWith('.pdf') ||
                file?.extension?.endsWith('.pdf')
              ? pdf
              : word,
        };
      });

  const images = attachments?.length && [
    ...attachments.map((file) => {
      return file?.extension?.includes('image') && file?.path;
    }),
  ];
  const captions =
    attachments?.length &&
    attachments?.map((file) => {
      return file?.vehicleExpenditureCategoryDTOS?.length
        ? file?.vehicleExpenditureCategoryDTOS?.map((sub, i) => (
            <div style={{ display: 'flex', gridGap: '5px' }} key={i}>
              <div style={{ color: '#1893FF', fontWeight: 700 }}>
                {sub?.symbol + ' : '}
              </div>
              <div style={{ fontWeight: 200, letterSpacing: '0.8px' }}>
                {sub?.description}
              </div>
            </div>
          ))
        : '';
    });
  const imageTitles = attachments?.length && [
    ...attachments.map((file) => {
      return file?.fileOriginalName
        ? file?.fileOriginalName
        : file?.name?.substring(14);
    }),
  ];

  const handleShowFilesInLine = (e, item, index) => {
    if (item?.uploadedFile?.length) {
      let fileName = item?.uploadedFile?.fileOriginalName
        ? item?.uploadedFile?.fileOriginalName
        : item?.uploadedFile?.name?.substring(14);
      setFileOrgName(fileName);
      setImagePath(item?.uploadedFile?.path);
    } else {
      let fileName = item?.fileOriginalName
        ? item?.fileOriginalName
        : item?.name?.substring(14);
      setImagePath(item?.path);
      setFileOrgName(fileName);
    }
    e.stopPropagation();
    setPhotoIndex(index);
    setIsOpen(true);
  };

  const handleDownload = (e, fileOrgName, imagePath, fileId, photoIndex) => {
    e.stopPropagation();
    downloadFile(fileOrgName, imagePath, fileId, photoIndex);
  };

  const icons = [
    // eslint-disable-next-line react/jsx-key
    <div
      style={{
        width: 43,
        height: 49,
        padding: 9,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onClick={(e) => {
        e.stopPropagation();
        handleDownload(e, fileOrgName, imagePath, null, photoIndex);
      }}
    >
      {isDownloading && photoIndex === fileIndex ? (
        <DownloadingSpinner width='27px' height='27px' src={loading} />
      ) : (
        <DownloadIcon
          lineheight='30px'
          width='27px'
          height='27px'
          color='#fff'
          id='img-download-antd'
        />
      )}
    </div>,
  ];

  return (
    <>
      {isOpen && images?.length > 0 ? (
        // yet-another-react-lightbox
        <Lightbox
          style={{ zIndex: 1040 }}
          toolbarButtons={icons}
          imageCaption={captions[photoIndex % images.length]}
          imageTitle={imageTitles[photoIndex % images.length]}
          mainSrc={images[photoIndex % images.length]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          mainSrcThumbnail={images[photoIndex % images.length]}
          nextSrcThumbnail={images[(photoIndex + 1) % images.length]}
          prevSrcThumbnail={
            images[(photoIndex + images.length - 1) % images.length]
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => {
            setPhotoIndex((photoIndex + images.length - 1) % images.length);
            setImagePath(
              images[(photoIndex + images.length - 1) % images.length]
            );
            setFileOrgName(
              imageTitles[(photoIndex + images.length - 1) % images.length]
            );
          }}
          onMoveNextRequest={() => {
            setPhotoIndex((photoIndex + 1) % images.length);
            setImagePath(images[(photoIndex + 1) % images.length]);
            setFileOrgName(imageTitles[(photoIndex + 1) % images.length]);
          }}
        />
      ) : null}
      {isLoading ? (
        <Loading height='70' />
      ) : attachments?.length > 0 ? (
        <PanelWrap>
          {galView ? (
            <div className='files-box'>
              <ShowFiles
                files={attachments || [...imageList, ...newArr]}
                imgWidth='80px'
                imgHeight='80px'
                dispatch={dispatch}
                showConfirm={onConfirm}
                onCancel={onCancel}
                deletePermission={deletePermission}
                previewFile={handleShowFilesInLine}
              />
            </div>
          ) : (
            attachments?.map((attached, index) => {
              return (
                <PanelWrap.Table className='table' key={index}>
                  <div
                    className='name-box'
                    title={attached?.name || attached?.uploadedFile?.name}
                  >
                    <div className='key'>Name:</div>
                    <div className='value turncated'>
                      {attached?.fileOriginalName ||
                        attached?.uploadedFile?.fileOriginalName ||
                        attached?.name ||
                        attached?.uploadedFile?.name}{' '}
                    </div>{' '}
                  </div>
                  <div
                    className='author-box'
                    title={attached?.createdByNickname}
                  >
                    <div className='key'>Uploaded by:</div>
                    <div className='value turncated'>
                      {attached?.createdByNickname}
                    </div>
                  </div>
                  <div
                    className='date-box'
                    title={getUsersTimeByZoneLTS(attached?.createdAtDate, 'L')}
                  >
                    <div className='key'>Uploaded date:</div>
                    <div className='value turncated'>
                      {getUsersTimeByZoneLTS(attached?.createdAtDate, 'L')}
                    </div>
                  </div>
                  <div className='action-box'>
                    <Tooltip title={attached?.note} trigger={'click'}>
                      <NoteViewIcon />
                    </Tooltip>
                    {attached?.extension?.includes('image/') ||
                    attached?.uploadedFile?.extension?.includes('image/') ? (
                      <IconView
                        title='View file'
                        onClick={(e) =>
                          handleShowFilesInLine(e, attached, index)
                        }
                      />
                    ) : (
                      <a
                        style={{ lineHeight: '10px' }}
                        href={encodeFilePath(attached?.path)}
                        target='_blank'
                        rel='noreferrer'
                        title='View file'
                      >
                        <IconView title='View file' />
                      </a>
                    )}

                    {isDownloading &&
                    (attached?.id || attached?.attachmentId) === fileId ? (
                      <DownloadingSpinner src={loading} />
                    ) : (
                      <DownloadIcon
                        title='Download file'
                        onClick={(e) => {
                          handleDownload(
                            e,
                            attached?.fileOriginalName || attached?.fileName,
                            attached?.path,
                            attached?.id || attached?.attachmentId
                          );
                        }}
                      />
                    )}

                    {deletePermission && (
                      <DeleteConfirm
                        title={` ${
                          attached?.name ||
                          attached?.uploadedFile?.fileOriginalName
                        }`}
                        onConfirm={() => onConfirm(attached)}
                        onCancel={onCancel}
                      >
                        <DeleteIcon title='Delete file' />
                      </DeleteConfirm>
                    )}
                  </div>
                </PanelWrap.Table>
              );
            })
          )}
        </PanelWrap>
      ) : (
        <div style={{ width: '100%', marginBottom: '12px' }}>No records!</div>
      )}
    </>
  );
};

export default React.memo(SubContent);
