/**
 * Retrieves the value of a specified cookie
 *
 * @param {string} cName - The name of the the cookie to retrieve.
 * @return {string|null} The value of the cookie, or null if the cookie is not found.
 *
 * @example
 * // Assuming document.cookie = "username=JohnDoe; expires=The, 18 Dec 2024 12:00:00 UTC"
 * const username = getCookie('username');
 * console.log(username); // "JohnDoe"
 *
 * @example
 * // Assuming document.cookie does not contain "session_id"
 * const sessionId = getCookie("session_id");
 * console.log(sessionId); // null
 *
 * @example
 * // Invalid cookie name
 * const invalid = getCookie('');
 * console.log(invalid); // null
 *
 * @example
 * // Invalid cookie name
 * const invalid = getCookie();
 * console.log(invalid); // null
 */

function getCookie(cName) {
  // Check if cName is a non-empty string
  if (typeof cName !== 'string' || cName.length === 0) {
    return null;
  }

  // Construct the search string with the cookie name and an equal sign
  let name = `${cName}=`;
  // Split the document.cookie string into an array of individual cookies
  let cookies = document?.cookie?.split(';') || [];

  // Iterate through each cookie in the array
  for (const cookie of cookies) {
    // Remove leading and trailing whitespace from the cookie
    let trimmedCookie = cookie?.trim();

    // Check if the trimmed cookie starts with the search string
    if (trimmedCookie?.startsWith(name)) {
      // return te value of the cookie (substring after the cookie name and equal sign)
      return trimmedCookie?.substring(name?.length);
    }
  }

  // Return null if the cookie is not found
  return null;
}

export { getCookie };
