/** @format */

import { ErrorValidationIcon } from 'styledIcons';
import { ErrorMessage } from './style';
import { memo } from 'react';

const ExistCaption = ({ message }) => {
  return (
    <>
      {message && (
        <>
          <ErrorValidationIcon />
          <ErrorMessage>
            {message?.replace('{', '"')?.replace('}', '"')}
          </ErrorMessage>
        </>
      )}
    </>
  );
};

export default memo(ExistCaption);
