/** @format */

import styled from 'styled-components';
import { InputLabel } from 'globalCSS';

export const SelectWrapper = styled.div`
  width: ${({ width }) => width || '100%'};
  #statusSwitch {
    position: absolute;
    top: 4px;
    right: 8px;
    height: 24px;
    width: 48px;
  }
  .ant-switch-checked {
    background: #00b238;
  }
  .ant-switch-handle {
    top: 3px;
    left: 3px;
  }
  .ant-switch-checked .ant-switch-handle {
    left: calc(100% - 21px);
  }
`;

export const StatusText = styled.div`
  position: absolute;
  top: 3px;
  left: 10px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #313e47 !important;
`;

export const Label = styled(InputLabel)``;

export const InputWrapper = styled.div`
  width: ${({ width }) => width || '100%'};
  height: fit-content;
  position: relative;
  align-items: center;
  text-align: center;
  margin: ${({ boxMargin }) => boxMargin || '0 0 0 0'};
`;
