/** @format */

export const milesRenderer = ({ data }) => {
  return (
    <div
      style={{
        width: '100%',
        height: 'inherit',
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
      }}
    >
      {data?.totalDistance ? data?.totalDistance + ' mi' : ''}
    </div>
  );
};
