import styled from 'styled-components';

export const Wrapper = styled.tr`
  border-top: 1px solid #e7ebf3;
  background: #fff;
  align-items: center;
  background: #fff;
  height: 40px;
  display: ${({ open }) => (!open ? 'none' : 'contents')};
  transition: all 0.3s ease;
`;
