/** @format */

import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Input } from 'antd';
const { TextArea } = Input;
import { GridTable } from './styled';

const EditableCell = forwardRef(
  (
    {
      index,
      item,
      colDefs,
      scrollOut,
      clearActiveOnCell,
      collapsibleRow,
      rowHeight,
    },
    ref
  ) => {
    const {
      cellRenderer: CellRenderer,
      editable,
      onSave,
      cellStyle,
      field,
      maxwidth,
      title,
      cellInputBorder,
      saveOnBlur,
      inputType,
    } = colDefs;
    const [editCell, setEditCell] = useState(false);
    const cellRef = useRef(null);
    const textAreaRef = useRef(null);
    const cell = ref || cellRef;
    const [success, setSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const changable =
      typeof editable === 'function' ? editable(item) : editable;
    const field2 = typeof field === 'function' ? field(item) : field;
    const [initialText, setInitialText] = useState(item?.[field2]);
    const [text, setText] = useState(item?.[field2]);
    // change cell active positions for editable cell
    let lastPositionCursor = 0;

    useEffect(() => {
      setSuccess(false);
      return () => {};
    }, [item]);

    useEffect(() => {
      if (success) {
        setInitialText(text);
      } else {
        setText(item?.[field2]);
        setInitialText(item?.[field2]);
        setIsLoading(false);
      }
      return () => {};
    }, [item, success]);
    const save = async (e, isEnter) => {
      setIsLoading(true);
      setEditCell(false);
      const success = await (onSave &&
        onSave({
          data: item,
          value: e?.target?.value,
        }));

      setIsLoading(false);
      if (typeof success === 'string') {
        setText(success);
      }
      if (!success) {
        setText(item?.[field2]);
        setInitialText(item?.[field2]);
      }
      if (success) {
        setInitialText(text);
      }

      setSuccess(success);
      if (isEnter) cell?.current?.focus();
    };

    const onEditCell = () => {
      onClickCell?.();
      setEditCell(changable && true);
      !success && setText(item?.[field2]);
    };

    const onBlur = (editable) => {
      if (editable) {
        if (!editCell) {
          cell?.current?.classList?.contains('active-cell') &&
            cell?.current?.classList?.remove('active-cell');
        }
      } else
        cell?.current?.classList?.contains('active-cell') &&
          cell?.current?.classList?.remove('active-cell');
    };

    const onClickCell = useCallback(() => {
      clearActiveOnCell?.();
      cell?.current?.classList?.add('active-cell');
    }, [clearActiveOnCell]);

    // table data td
    const onKeyDown = useCallback(
      (e) => {
        // for copy to clipboard
        const key = e.which || e.keyCode;
        const ctrl = e.ctrlKey ? e.ctrlKey : key === 17 ? true : false;
        if (key == 67 && ctrl) {
          navigator.clipboard
            .writeText(cell?.current?.innerText || e?.target?.value)
            ?.then(() => {
              cell?.current?.classList?.add('copied');
              const current = cell?.current;
              const timeout = setTimeout(() => {
                if (current?.classList?.contains('copied'))
                  current?.classList?.remove('copied');
              }, 1000);

              return () => {
                return clearTimeout(timeout);
              };
            });
        }

        // for navigation cell
        switch (e?.code) {
          case 'Space':
            if (changable && !editCell) {
              e.preventDefault();
              onEditCell();
            }
            break;
          case 'Escape':
            e.preventDefault();
            if (changable && editCell) {
              setEditCell(false);
              cell?.current?.focus();
              !cell?.current?.classList?.contains('active-cell') &&
                cell?.current?.classList?.add('active-cell');
            }
            break;
          case 'NumpadEnter':
          case 'Enter':
            if (changable && editCell) {
              e.preventDefault();
              save(e, true);
              !cell?.current?.classList?.contains('active-cell') &&
                cell?.current?.classList?.add('active-cell');
            }
            break;
          case 'ArrowRight':
            if (cell?.current && !editCell) {
              e.preventDefault();
              if (cell?.current?.nextElementSibling) {
                cell?.current?.classList?.remove('active-cell');
                cell?.current?.nextElementSibling?.classList?.add(
                  'active-cell'
                );
                cell?.current?.nextElementSibling?.focus();
              }
            }
            break;
          case 'ArrowLeft':
            if (cell?.current && !editCell) {
              e.preventDefault();
              if (cell?.current?.previousElementSibling) {
                cell?.current?.classList?.remove('active-cell');
                cell?.current?.previousElementSibling?.classList?.add(
                  'active-cell'
                );
                cell?.current?.previousElementSibling?.focus();
              }
            }
            break;
          case 'ArrowUp':
          case 'ArrowDown':
            e.preventDefault();
            if (cell?.current) {
              const currentRow = cell?.current?.closest(
                collapsibleRow ? '.new-table-collapse-panel' : '.new-table-row'
              );
              const currentPosCell = cell?.current?.getAttribute('tabIndex');
              if (e?.code === 'ArrowUp') {
                if (currentRow?.previousElementSibling) {
                  cell?.current?.classList?.remove('active-cell');
                  const prevCell =
                    currentRow?.previousElementSibling?.querySelector(
                      `.new-table-cell[tabIndex='${currentPosCell}']`
                    );
                  prevCell?.focus();
                  prevCell?.classList?.add('active-cell');
                } else {
                  const currentCollapse = currentRow?.closest(
                    '.new-table-collapse'
                  );
                  if (
                    currentCollapse?.previousElementSibling &&
                    currentCollapse?.previousElementSibling?.querySelector(
                      '.new-table-row'
                    )
                  ) {
                    currentCollapse?.previousElementSibling?.classList?.contains(
                      'close-collapse'
                    ) &&
                      currentCollapse?.previousElementSibling?.classList?.remove(
                        'close-collapse'
                      );
                    cell?.current?.classList?.remove('active-cell');
                    const prevRow =
                      currentCollapse?.previousElementSibling?.querySelector(
                        '.inner-collapse-data .new-table-row:last-of-type'
                      );
                    const prevCell = prevRow?.querySelector(
                      `.new-table-cell[tabIndex='${currentPosCell}']`
                    );
                    prevCell?.focus();
                    prevCell?.classList?.add('active-cell');
                  }
                }
              }
              if (e?.code === 'ArrowDown') {
                if (currentRow?.nextElementSibling) {
                  cell?.current?.classList?.remove('active-cell');
                  const nextCell =
                    currentRow?.nextElementSibling?.querySelector(
                      `.new-table-cell[tabIndex='${currentPosCell}']`
                    );
                  nextCell?.focus();
                  nextCell?.classList?.add('active-cell');
                } else {
                  const currentCollapse = currentRow?.closest(
                    '.new-table-collapse'
                  );
                  if (
                    currentCollapse?.nextElementSibling &&
                    currentCollapse?.nextElementSibling?.querySelector(
                      '.new-table-row'
                    )
                  ) {
                    currentCollapse?.nextElementSibling?.classList.contains(
                      'close-collapse'
                    ) &&
                      currentCollapse?.nextElementSibling?.classList.remove(
                        'close-collapse'
                      );
                    cell?.current?.classList?.remove('active-cell');
                    const nextRow =
                      currentCollapse?.nextElementSibling?.querySelector(
                        '.inner-collapse-data .new-table-row:first-of-type'
                      );
                    const nextCell = nextRow?.querySelector(
                      `.new-table-cell[tabIndex='${currentPosCell}']`
                    );
                    nextCell?.focus();
                    nextCell?.classList?.add('active-cell');
                  }
                }
              }
            }
            break;
          default:
            null;
        }
      },
      [editCell, changable, onBlur]
    );

    const onChangeInput = (e) => {
      const { value } = e.target;
      setText(value);
    };
    // const onFocus = () => {
    //   textareaRef.current.focus({ cursor: 'all' });
    // };

    const onKeyUp = (e) => {
      const { code } = e;
      const { value, selectionStart } = e.target;
      if (code === 'ArrowUp' || code === 'ArrowDown') {
        setEditCell(false);
        if (saveOnBlur) {
          if (item?.[field2] !== text) {
            save(e);
          }
        } else {
          save(e);
        }
        onKeyDown(e);
      }
      if (
        (value?.length === selectionStart &&
          code === 'ArrowRight' &&
          lastPositionCursor === value?.length) ||
        (selectionStart === 0 &&
          code === 'ArrowLeft' &&
          lastPositionCursor === 0)
      ) {
        setEditCell(false);
        if (saveOnBlur) {
          if (item?.[field2] !== text) {
            save(e);
          }
        } else {
          save(e);
        }
        onKeyDown(e);
        e.preventDefault();
        if (cell?.current?.nextElementSibling && code === 'ArrowRight') {
          cell?.current?.classList?.remove('active-cell');
          cell?.current?.nextElementSibling?.classList?.add('active-cell');
          cell?.current?.nextElementSibling?.focus();
        }
        if (cell?.current?.previousElementSibling && code === 'ArrowLeft') {
          cell?.current?.classList?.remove('active-cell');
          cell?.current?.previousElementSibling?.classList?.add('active-cell');
          cell?.current?.previousElementSibling?.focus();
        }
      } else {
        e.preventDefault();
        e.stopPropagation();
      }
      lastPositionCursor = selectionStart;
    };

    const onCopyDown = async (e) => {
      if (e.ctrlKey && (e.code === 'KeyC' || e.code === 'Digit3')) {
        e.preventDefault();
        const textToCopy = text;
        const textToCopy2 =
          textAreaRef.current.resizableTextArea.textArea.value;

        try {
          await navigator.clipboard.writeText(`${textToCopy}` || textToCopy2);
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('Failed to copy:', err);
        }
      }
    };

    const inputBlur = (e) => {
      // if (saveOnBlur) {
      if (initialText !== text) {
        save(e);
      }
      // } else {
      //   save(e);
      // }
      if (
        e?.target
          ?.closest('.new-table-cell')
          ?.classList?.contains('active-cell')
      )
        e?.target?.closest('.new-table-cell')?.classList?.remove('active-cell');
      setEditCell(false);
    };

    return (
      <GridTable.Td
        ref={cell}
        tabIndex={index}
        focusable={true}
        className={'new-table-cell'}
        maxwidth={maxwidth}
        height={
          rowHeight === 'auto' || `${rowHeight}`?.endsWith('px')
            ? rowHeight
            : rowHeight
            ? rowHeight + 'px'
            : '28px'
        }
        onClick={editable ? onEditCell : onClickCell}
        onKeyDown={onKeyDown}
        onBlur={() => onBlur(changable)}
        onDoubleClick={onEditCell}
        style={cellStyle && cellStyle(item)}
        title={
          title === 'field'
            ? item?.[field2]
              ? item?.[field2]
              : ''
            : typeof title === 'function'
            ? title(item)
            : title
        }
      >
        {!editCell ? (
          CellRenderer ? (
            <CellRenderer
              scrollOut={scrollOut}
              data={item}
              field={field}
              success={success}
              changedValue={text}
              isLoading={isLoading}
            />
          ) : changable ? (
            text
          ) : (
            item?.[field2]
          )
        ) : (
          <TextArea
            ref={textAreaRef}
            variant='borderless '
            autoSize
            className='edit-cell-text'
            type={inputType || 'text'}
            value={text || ''}
            onChange={onChangeInput}
            onBlur={inputBlur}
            onKeyUp={onKeyUp}
            onKeyDown={onCopyDown}
            autoFocus
            style={{ border: cellInputBorder || '1px solid #e5e7eb' }}
          />
        )}
      </GridTable.Td>
    );
  }
);

EditableCell.displayName = 'EditableCell';
export default React.memo(EditableCell);
