/** @format */

import { memo, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useRequest, useSearch, useSetQuery, useQuery } from 'hooks';
import { useAuth, useFactoring } from 'context';
import { TopBarTitle, Pagination, Table, Button, ExportButton } from 'forms';
import { Toast, MainModal } from 'modals';
import { PageWrapper, TopBar, TableWrapper } from 'globalCSS';

import { columns } from './header';
import EditCompany from './Edit';

const Factoring = () => {
  const { search } = useLocation();
  const setQuery = useSetQuery();
  const { request } = useRequest();
  const query = useSearch();

  const [authState] = useAuth();
  const [companyState, dispatch] = useFactoring();

  const { total, companies, permission } = companyState;

  useEffect(() => {
    if (authState?.permission?.['Data Library']?.Company) {
      dispatch({
        type: 'setPermission',
        payload: authState?.permission?.['Data Library']?.Company,
      });
    }
  }, [authState?.permission?.['Data Library']?.Company?.toString()]);

  const params = new URLSearchParams(search);
  params.delete('modal-name');
  params.delete('id');
  const newUrl = `?${params.toString()}`;
  const getCompanyList = useQuery(
    ['/factoring_company', newUrl],
    () =>
      request({
        url: `/factoring_company${newUrl || '?'}&sort=id%2Cdesc`,
      }),
    {
      onSuccess: (data) => {
        dispatch({ type: 'setCompanies', payload: data?.dataList });
        dispatch({ type: 'setTotal', payload: data?.meta?.total });
      },
      onError: ({ message }) => Toast({ type: 'error', message }),
    }
  );
  useEffect(() => {
    dispatch({ type: 'setRefetch', payload: getCompanyList?.refetch });
    () => {};
  }, []);

  //! allModal
  const closeDrawer = useCallback(() => {
    setQuery('modal-name', null);
    setQuery('id', null);
    dispatch({ type: 'setDrawerOpen', payload: false });
    dispatch({ type: 'setDocs', payload: [], own: 'true' });
  }, []);

  //! TopBar
  const handleAdd = useCallback(() => {
    setQuery('modal-name', 'add-factoring');
  }, []);

  return (
    <PageWrapper>
      <MainModal
        drawerOpen={
          query.get('modal-name') === 'add-factoring' ||
          query.get('modal-name') === 'edit-factoring'
        }
        closeDrawer={closeDrawer}
        width='800px'
        onSave={companyState?.saveFunction}
        onCancel={companyState?.onCancelFunction}
        stateChange={companyState?.stateChange}
      >
        <EditCompany
          open={
            query.get('modal-name') === 'add-factoring' ||
            query.get('modal-name') === 'edit-factoring'
          }
          refetch={getCompanyList?.refetch}
          closeDrawer={closeDrawer}
          data={companyState}
          dispatch={dispatch}
        />
      </MainModal>

      <TopBar>
        <TopBarTitle title={'Data Library / Factoring'} total={total} />
        <TopBar.Right>
          {permission?.DOWNLOAD && (
            <ExportButton url={'/v3/company/export'} btnType='export' />
          )}
          <Button btnType='filter' />
          {permission?.CREATE && <Button btnType='add' onClick={handleAdd} />}
        </TopBar.Right>
      </TopBar>

      <TableWrapper>
        <Table
          rowData={companies}
          columnDefs={columns}
          isFetching={getCompanyList?.isFetching}
        />
      </TableWrapper>
      <Pagination length={columns?.length} total={total} />
    </PageWrapper>
  );
};

export default memo(Factoring);
