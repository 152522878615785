import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './truckDashboardReducer';

export const TruckDashboardContext = createContext();

export const useTruckDashboard = () => useContext(TruckDashboardContext);

export const TruckDashboardProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <TruckDashboardContext.Provider value={[state, dispatch]}>
      {children}
    </TruckDashboardContext.Provider>
  );
};
