/** @format */

import Driver from './Driver';
import Truck from './Truck';
import Tabs from '../../Tabs';
import { memo } from 'react';

const TrucksListTab = ({ item, isLoading }) => {
  const drivers =
    item?.driver && item?.coDriver
      ? [item?.driver, item?.coDriver]
      : item?.driver
      ? [item?.driver]
      : item?.coDriver
      ? [item?.coDriver]
      : null;
  const items = [
    {
      key: '1',
      label: 'Driver Info',
      children: <Driver drivers={drivers} data={item} isLoading={isLoading} />,
    },
    {
      key: '2',
      label: 'Truck Info',
      children: (
        <Truck
          item={item}
          trailerNumber={item?.trailer}
          isLoading={isLoading}
        />
      ),
    },
  ];
  return (
    <Tabs
      defaultActiveKey='1'
      tabBarStyle={{
        padding: '0 10px',
        color: 'rgba(68, 68, 68, 0.6)',
      }}
      items={items}
    />
  );
};

export default memo(TrucksListTab);
