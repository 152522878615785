/** @format */

// request for editable cells, onSave prop function.

import axios from 'axios';
import { getCookie } from 'formatter';

const { VITE_APP_BASE_URL } = import.meta.env;

export const request = async ({
  baseurl = VITE_APP_BASE_URL,
  url = '',
  method = 'GET',
  body = null,
  headers = {},
  includeToken = true,
  isPost = true,
  isRefreshToken = false,
}) => {
  let data;

  try {
    if (body && isPost) {
      body = JSON.stringify(body);
      headers['Content-Type'] = 'application/json';
    }
    if (includeToken) {
      headers['Authorization'] = `Bearer ${getCookie('token')}`;
    }
    if (isRefreshToken) {
      const currentDate = new Date();
      headers = { ...headers, currentUserDate: currentDate.toISOString() };
    }

    await axios({
      url: `${baseurl}${url}`,
      method: method,
      data: body,
      headers: headers,
    })
      .then(function (response) {
        data = response?.data;
      })
      .catch(function (error) {
        let errorCode = error?.response?.status;
        if (errorCode === 401) {
          data = error?.response.data;
          localStorage.clear();
        } else if (errorCode === 400) {
          data = error?.response.data;
        } else {
          data = error?.response.data;
        }
      });
    if (data?.success) {
      return data;
    } else {
      data = { ...data, message: data?.error || data?.message };
    }
  } catch (error) {
    data = { ...data, message: error || error?.message };
  }
  return data;
};
