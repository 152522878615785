/** @format */

import { PopconfirmAntd } from './style';

export default function ConfirmBeforeLeaving({
  // open,
  onConfirm,
  onCancel,
  bgcolor,
  children,
  okText,
  placement,
  cancelText,
  zIndex,
}) {
  return (
    <PopconfirmAntd
      title='Do you want to close the Modal without saving changes?'
      onConfirm={onConfirm}
      onCancel={onCancel}
      bg={bgcolor}
      placement={placement || 'topRight'}
      okText={okText || 'Yes'}
      cancelText={cancelText || 'No'}
      zIndex={zIndex}
    >
      {children}
    </PopconfirmAntd>
  );
}
