/** @format */

export const initialState = {
  refetch: {},
  permission: {},
  dataList: [],
  page: 1,
  total: 0,
  stateChange: false,
  saveFunction: () => {},
  onCancelFunction: () => {},
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'changePage':
      return { ...state, page: action.payload };
    case 'setPermission':
      return { ...state, permission: action.payload };
    case 'setTotal':
      return { ...state, total: action.payload };
    case 'setDataList':
      return { ...state, dataList: action.payload };

    case 'setRefetch':
      return { ...state, refetch: action.payload };
    case 'setSaveFunction':
      return { ...state, saveFunction: action.payload };
    case 'setOnCancelFunction':
      return { ...state, onCancelFunction: action.payload };
    case 'setStateChange':
      return { ...state, stateChange: action.payload };
    default:
      return state;
  }
};
