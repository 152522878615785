import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';

export const DOTReportContext = createContext();

export const useDOTReport = () => useContext(DOTReportContext);

export const DOTReportProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DOTReportContext.Provider value={[state, dispatch]}>
      {children}
    </DOTReportContext.Provider>
  );
};
