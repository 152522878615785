/** @format */

import { memo, useCallback, useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import {
  InfoWindow,
  Marker,
  Autocomplete as GoogleAutocomplete,
} from '@react-google-maps/api';

import { Button, Input } from 'forms';
import { IconWiFiError, IconWiFiOn } from 'styledIcons';
import { InfoStyle } from './style';
import intransit from 'assets/icons/intransit.png';
import available from 'assets/icons/available.png';
import need_to_check from 'assets/icons/need_to_check.png';
import damaged from 'assets/icons/damaged.png';
import gpsissue from 'assets/icons/gpsissue.png';
import unknown from 'assets/icons/unknown.png';
import using from 'assets/icons/using.png';
import missloaded from 'assets/icons/missloaded.png';
import loaded from 'assets/icons/loaded.png';
import returned from 'assets/icons/returned.png';
import anothercarrier from 'assets/icons/anothercarrier.png';
import reserved from 'assets/icons/reserved.png';
import withTrailer from 'assets/icons/withTrailer.png';
import withoutTrailer from 'assets/icons/withoutTrailer.png';
// import { useMutation } from '@tanstack/react-query';
// import { useRequest } from 'hooks';
// import { Toast } from 'modals';
import { useTrailers } from 'context';
import { useRequest, useSearch } from 'hooks';
import { Toast } from 'modals';
import { geocodeAddress } from '../MarkerByAddress';
const MainMapTrailerInfoPage = ({
  place,
  trailer,
  color,
  type,
  item,
  name,
  trailerNumber,
  clusterer,
  refetch,
  setLatLng,
  latLng,
  noRefetch,
  pageRefetch,
}) => {
  const [open, setOpen] = useState(false);
  const query = useSearch();
  const [openAddress, setOpenAddress] = useState(false);
  const [newLocationOpen, setNewLocationOpen] = useState(false);
  const [tempPosition, setTempPosition] = useState(null);
  const [completedPosition, setCompletedPosition] = useState(null);
  const [{ selectedTrailer }, dispatch] = useTrailers();
  const [state, setState] = useState({
    address: '',
  });
  const [inputMode, setInputMode] = useState('address');

  const { request } = useRequest();
  useEffect(() => {
    if (place?.lat && place?.lng) {
      setTempPosition(place);
    } else {
      geocodeAddress(place?.address)
        .then((coordinates) => {
          setTempPosition(coordinates);
        })
        .catch((error) => {
          //   setNoGpsData(true);

          // eslint-disable-next-line no-console
          console.log(error, 'error');
        });
    }
    return () => {};
  }, []);
  // const { request } = useRequest();
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = (trailer) => {
    dispatch({ type: 'setSelectedTrailer', payload: trailer });
    setOpen(true);
    setLatLng({ lat: trailer?.latitude, lng: trailer?.longitude });
  };
  const getColor = (status) => {
    let st = status?.toLowerCase();

    if (type === 'truck') {
      return getIcon();
    }

    switch (st) {
      case 'in_transit':
        return { icon: intransit };
      case 'possible':
        return { icon: available };
      case 'available':
        return { icon: available };
      case 'damaged':
        return { icon: damaged };
      case 'gps_issue':
        return { icon: gpsissue };
      case 'loaded':
        return { icon: loaded };
      case 'miss_loaded':
        return { icon: missloaded };
      case 'returned':
        return { icon: returned };
      case 'reserved':
        return { icon: reserved };
      case 'using':
        return { icon: using };
      case 'another_carrier':
        return { icon: anothercarrier };
      case 'need_to_check':
        return { icon: need_to_check };
      default:
        return { icon: unknown };
    }
  };

  const getIcon = () => {
    if (!item?.trailer) {
      return {
        icon: withoutTrailer,
      };
    } else {
      return {
        icon: withTrailer,
      };
    }
  };

  // const { mutate } = useMutation({
  //   mutationFn: (id) =>
  //     id &&
  //     request({
  //       url: `/trailer_states/${id}/current-info`,
  //     }),
  // });

  // const handleApi = (id) => {
  //   mutate(id, {
  //     onSuccess: (res) => {
  //       let data = res?.data?.[res?.data?.responseType];
  //       setResponseData({ ...data, drivers: [data?.driver, data?.coDriver] });
  //       setResponseName(data.trailerStatus);
  //     },
  //     onError: (err) => {
  //       Toast({
  //         type: 'error',
  //         message: err?.message,
  //       });
  //     },
  //   });
  //   setOpenName('trailerSub');
  //   setDrawerOpenForAdd(true);
  // };
  const handleOpenAddress = (trailer) => {
    setOpenAddress(!openAddress);
    setOpen(false);
    setLatLng({ lat: trailer?.latitude, lng: trailer?.longitude });
  };

  const [autoCompletePlace, setAutoCompletePlace] = useState({});

  const onAutoCompleteLoad = (autocomplete) => {
    setAutoCompletePlace(autocomplete);
  };
  const autocompleteOptions = {
    componentRestrictions: { country: 'us' },
  };
  const handlePlaceChange = () => {
    if (autoCompletePlace !== null) {
      const place = autoCompletePlace.getPlace();
      setState({
        ...state,
        address: place.formatted_address,
      });
      if (place.formatted_address) {
        setCompletedPosition({
          lat: place?.geometry?.location?.lat(),
          lng: place?.geometry?.location?.lng(),
        });
      }
    }
  };
  const handleChangeSearch = (e) => {
    const { value } = e.target;

    if (!value) {
      setLatLng({
        lat: trailer?.latitude,
        lng: trailer?.longitude,
      });
    }
    setState({ ...state, address: value });
  };
  const handleCancel = () => {
    setNewLocationOpen(false);
    setOpenAddress(false);
    setOpen(true);
    setTempPosition(place);
  };

  const handleLatChange = useCallback((event) => {
    const { value, name } = event.target;
    setLatLng({ ...latLng, [name]: value });
  }, []);

  const handleInputModeChange = useCallback((event) => {
    setInputMode(event.target.value);
  }, []);

  const handleShowLoaction = () => {
    setTempPosition(completedPosition);
    setOpen(false);
    setOpenAddress(false);
    setNewLocationOpen(true);
    setLatLng(completedPosition);
  };
  const setLocation = useMutation({
    mutationFn: (values) => {
      return (
        (query?.get('trl-id') || trailer?.id) &&
        request({
          url: `/v3/trailers/${
            query?.get('trl-id') || trailer?.id
          }/change_location_manually?latitude=${values?.lat}&longitude=${
            values?.lng
          }`,
          method: 'PUT',
          body: values,
        })
      );
    },
  });
  const onSetLocation = () => {
    setLocation.mutate(latLng, {
      onSuccess: (res) => {
        if (res?.success) {
          Toast({
            type: 'success',
            message: res?.message,
          });
          setNewLocationOpen(false);
          pageRefetch && pageRefetch();
          setOpen(true);
          !noRefetch && refetch();
        }
      },
      onError: (err) => {
        Toast({
          type: 'error',
          message: err?.message,
        });
      },
    });
  };

  return (
    <>
      <Marker
        clusterer={clusterer}
        position={tempPosition}
        icon={getColor(trailer?.trailerStatus || name).icon}
        onClick={() => handleOpen(trailer)}
      />
      {open && selectedTrailer?.name === trailer?.name ? (
        <InfoWindow position={tempPosition} onCloseClick={handleClose}>
          <InfoStyle>
            <InfoStyle.Row>
              <InfoStyle.Text
                name
                // onClick={() => handleApi(trailer?.name || trailerNumber)}
              >
                {trailer?.name || trailerNumber || trailer?.trailerNumber}
              </InfoStyle.Text>
              <InfoStyle.Span bg={trailer?.trailerStatus || color}>
                {trailer?.trailerStatus
                  ? trailer?.trailerStatus.replaceAll('_', ' ')
                  : 'N/A'}
              </InfoStyle.Span>
            </InfoStyle.Row>
            <InfoStyle.Text fas>
              {trailer?.currentFacility || 'N/A'}
            </InfoStyle.Text>
            <InfoStyle.Text loc>
              {trailer?.currentLocation || trailer?.address || 'N/A-'}
            </InfoStyle.Text>
            <InfoStyle.Row>
              <div
                style={{
                  width: '100%',
                  height: 'inherit',
                  display: 'flex',
                  justifyContent: 'left',
                  alignItems: 'center',
                  marginLeft: '15px',
                }}
                // title={trailer?.connectionStatus ? 'Connected' : 'Disconnected'}
              >
                {trailer?.connectionStatus ? (
                  <IconWiFiOn color='#00CC0E' title='NORMAL' />
                ) : (
                  <IconWiFiError color='#C5CCD6' title='No Connection' />
                )}
              </div>
              {trailer !== null ? (
                <Button
                  btnType='map_button'
                  onClick={() => handleOpenAddress(trailer)}
                >
                  Move
                </Button>
              ) : null}
            </InfoStyle.Row>
          </InfoStyle>
        </InfoWindow>
      ) : null}
      {!newLocationOpen && openAddress ? (
        <InfoWindow position={tempPosition} onCloseClick={handleClose}>
          <InfoStyle>
            <InfoStyle.Text style={{ fontWeight: 600, color: '#000' }}>
              Set a new location
            </InfoStyle.Text>
            <div style={{ display: 'grid', gridGap: '4px' }}>
              <label style={{ display: 'flex', gridGap: '5px' }}>
                <input
                  type='radio'
                  value='address'
                  checked={inputMode === 'address'}
                  onChange={handleInputModeChange}
                />
                <div>Address</div>
              </label>{' '}
              <GoogleAutocomplete
                onPlaceChanged={handlePlaceChange}
                onLoad={onAutoCompleteLoad}
                options={autocompleteOptions}
              >
                <Input
                  height='27px'
                  placeholder='Search address'
                  onChange={handleChangeSearch}
                  value={inputMode !== 'latlng' ? state?.address : ''}
                  disabled={inputMode === 'latlng'}
                />
              </GoogleAutocomplete>
              <label style={{ display: 'flex', gridGap: '5px' }}>
                <input
                  type='radio'
                  value='latlng'
                  checked={inputMode === 'latlng'}
                  onChange={handleInputModeChange}
                />
                <div>Lat-Lng</div>
              </label>
              <div style={{ display: 'flex', gridGap: '5px' }}>
                <Input
                  type='text'
                  name='lat'
                  height='27px'
                  value={inputMode !== 'address' ? latLng?.lat : ''}
                  onChange={handleLatChange}
                  placeholder='Latitude'
                  disabled={inputMode === 'address'}
                />
                <Input
                  type='text'
                  name='lng'
                  height='27px'
                  value={inputMode !== 'address' ? latLng?.lng : ''}
                  onChange={handleLatChange}
                  placeholder='Longitude'
                  disabled={inputMode === 'address'}
                />
              </div>
            </div>

            <InfoStyle.Row>
              <div />
              {trailer !== null && open ? (
                <Button
                  position='absolute'
                  right='10px'
                  top='15px'
                  btnType='map_button'
                  onClick={handleOpenAddress}
                >
                  Move
                </Button>
              ) : null}
              {trailer !== null && openAddress ? (
                <Button
                  position='absolute'
                  right='15px'
                  bottom='10px'
                  btnType='map_button'
                  onClick={handleShowLoaction}
                >
                  Show location
                </Button>
              ) : null}
            </InfoStyle.Row>
          </InfoStyle>
        </InfoWindow>
      ) : null}
      {newLocationOpen ? (
        <InfoWindow position={tempPosition} onCloseClick={handleCancel}>
          <InfoStyle height='fit-content'>
            <InfoStyle.Text style={{ fontWeight: 600, color: '#000' }}>
              A new location
            </InfoStyle.Text>
            <InfoStyle.Text>{state?.address}</InfoStyle.Text>

            <InfoStyle.Row>
              {trailer !== null && newLocationOpen ? (
                <div
                  style={{
                    display: 'flex',
                    gridGap: '5px',
                    position: 'absolute',
                    bottom: '10px',
                    right: '15px',
                  }}
                >
                  <Button
                    btnType='map_button'
                    color='red'
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <Button btnType='map_button' onClick={onSetLocation}>
                    Accept
                  </Button>
                </div>
              ) : null}
            </InfoStyle.Row>
          </InfoStyle>
        </InfoWindow>
      ) : null}
    </>
  );
};

export default memo(MainMapTrailerInfoPage);
