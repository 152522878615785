/** @format */
/**
 * Custom React hook to handle authentication processes such as login, and post-login actions.
 * This hook integrates with the backend authentication endpoint to submit login requests
 * and manages the application state based on the response. It leverages `useMutation` from React Query
 * for managing the login request lifecycle.
 *
 * Upon a successful login, the hook sets relevant cookies for the user token and its expiration,
 * updates the global state with user information, and navigates the user depending on their state
 * (e.g., requiring OTP verification or password reset).
 *
 * In case of an error during login, the hook updates the UI to reflect the error state and redirects the user
 * to the sign-in page.
 *
 * @param {Object} localState - The local state of the login form including user inputs like email and password.
 * @param {function} navigate - The navigate function from React Router for redirection.
 * @param {function} setErrorData - Function to set error messages for the UI.
 * @param {function} setLoad - Function to toggle the loading state during the authentication process.
 * @returns {Object} Contains the `handleSubmit` function that triggers the login process.
 *
 * @example
 * const { handleSubmit } = useAuthHandler(localState, navigate, setErrorData, setLoad);
 * handleSubmit(); // Invokes the login process.
 */
import { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import useRequest from './useRequest';
import { useUserMe } from './useUserMe';
import { useAuth } from 'context';
import { setCookie } from 'formatter';
// import * as Sentry from '@sentry/react';
import { dayjs, utc } from 'day-js';
dayjs.extend(utc);
export const useAuthHandler = (localState, navigate, setErrorData, setLoad) => {
  const { request } = useRequest();
  const [, dispatch] = useAuth();
  const getUserMe = useUserMe();

  const loginMutation = useMutation({
    mutationFn: (localState) =>
      request({
        url: '/auth/login',
        method: 'POST',
        body: { ...localState, userDateTimeZone: dayjs().format() },
      }),
  });

  const handleSuccess = useCallback(
    (res) => {
      if (!res?.success) return;
      if (res?.success) {
        const { token, tokenExpireDate } = res.meta || {};
        const { sendOtp, resetPassword, email } = res.data || {};
        setCookie('token', token, 1);
        setCookie('size', '50');
        setCookie('tokenExpireDate', tokenExpireDate, 1);
        dispatch({ type: 'login', payload: token });
        dispatch({ type: 'pageSize', payload: 50 }); // Assuming 50 is a default page size
        dispatch({ type: 'setPreLoader', payload: true });

        if (sendOtp || resetPassword) {
          setCookie('user-email', email);
          navigate(resetPassword ? '/reset-password' : '/verify');
        }
        getUserMe.mutate();
        // Sentry.setUser({
        //   id: res?.meta?.id,
        //   username: res?.meta?.fullname || res?.meta?.nickname,
        //   ip_address: 'auto', // Optional
        // });
      }
    },
    [dispatch, navigate, getUserMe]
  );

  const handleError = useCallback(
    (err) => {
      setLoad(false);
      setErrorData({
        email: err?.response?.data?.message || 'Login failed',
        password: err?.response?.data?.message || 'Login failed',
      });
      // Sentry.setUser(null);
      navigate('/signin');
    },
    [navigate, setErrorData, setLoad]
  );

  const handleSubmit = useCallback(() => {
    setErrorData({});
    setLoad(true);
    loginMutation.mutate(localState, {
      onSuccess: handleSuccess,
      onError: handleError,
    });
  }, [
    localState,
    loginMutation,
    handleSuccess,
    handleError,
    setErrorData,
    setLoad,
  ]);

  return { handleSubmit };
};
