/** @format */

import styled from 'styled-components';
import { Checkbox } from '../../AntDesign';

export const AntCheckbox = styled(Checkbox)`
  margin: ${({ margin }) => margin || '0'};
  cursor: pointer;
  &:hover {
    .ant-checkbox-inner {
      border-color: ${({ border_color }) => border_color || 'inherite'};
    }
  }
  .ant-checkbox .ant-checkbox-inner {
    width: ${({ width, height }) => width || height || 'inherite'};
    height: ${({ height, width }) => height || width || 'inherite'};
    border-radius: ${({ border_radius }) => border_radius || 'inherite'};
    &:hover {
      border-color: ${({ border_color }) => border_color || 'inherite'};
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    border-radius: ${({ border_radius }) => border_radius || 'inherite'};
    background: ${({ background }) => background || 'inherite'};
    border-color: ${({ border_color }) => border_color || 'inherite'};
    &:hover {
      border-color: ${({ border_color }) => border_color || 'inherite'};
    }
  }
  .ant-checkbox-checked::after {
    border: ${({ border_color }) => border_color || 'inherite'};
  }
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${({ border_color }) => border_color || 'inherite'};
  }
`;
