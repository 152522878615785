/** @format */

import { Popover as AntdPopover } from 'antd';
import { useState, useEffect, memo } from 'react';

function Popover({
  content,
  children,
  placement,
  trigger,
  id,
  open,
  marginXS,
  zIndex,
  ...props
}) {
  const [visible, setVisible] = useState(false);
  const handleVisibleChange = (visible) => {
    setVisible(visible);
  };

  useEffect(() => {
    setVisible(open);

    return () => {};
  }, [open]);

  return (
    <AntdPopover
      id={id}
      placement={placement}
      content={content}
      trigger={trigger || 'click'}
      open={visible}
      onOpenChange={handleVisibleChange}
      marginXS={marginXS}
      zIndex={zIndex}
      {...props}
    >
      {children}
    </AntdPopover>
  );
}

export default memo(Popover);
