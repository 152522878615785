/** @format */

import styled from 'styled-components';
import { ReactComponent as Error } from 'assets/icons/exclamation-circle-outlined.svg';
import { ReactComponent as Eye } from 'assets/icons/eye.svg';

import { ReactComponent as EyeSlash } from 'assets/icons/eye-slash.svg';
const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f5f7f7;
  width: 100%;
  .Toastify__toast-container {
    width: fit-content;
    min-width: 400px;
  }

  .Toastify__progress-bar-theme--light {
    background: var(--toastify-color-progress-error);
  }
`;

export const Wrapper = styled.div`
  background: white;
  width: 30%;
  min-width: 300px;
  padding: 20px;
`;

const Form = styled.form`
  background: white;
  width: 30%;
  min-width: 300px;
  padding: 20px;
  #email {
    margin-bottom: 5px;
  }
  .custom {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .ant-spin-dot {
      font-size: 27px;
      margin-right: 10px;
    }
    .load {
      position: absolute;
      /* margin-right: 4px; */
    }
  }
`;

const ErrorIcon = styled(Error)`
  width: ${({ width }) => (width ? width : '20px')};
  height: ${({ height }) => (height ? height : '20px')};
  path {
    fill: var(--toastify-color-progress-error);
  }
`;

const Message = styled.div`
  color: red;
`;

const ToastifyContant = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  grid-gap: 8px;
`;
ToastifyContant.Text = styled.div`
  color: var(--toastify-color-progress-error);
  font-weight: 600;
  font-size: 16px;
  text-align: center;
`;
export const InputWrapper = styled.div`
  /* margin-top: 14px; */
  position: relative;
  display: flex;
  grid-gap: 12px;
  width: 100%;
  justify-content: center;
  margin: 0 0 12px 0;
  /* width: 50%; */
  .ant-select-arrow {
    display: none;
  }
  .otpSep {
    margin: 0 6px;
  }

  .inputFocusStyle {
    border: 1px solid #46a9ff !important;
    border-color: #46a9ff !important;
    border-width: 1px !important;
  }
  .inputStyles {
    height: 48px !important;
    width: 48px !important;
    border-radius: 8px !important;
    background: #f6f8f9 !important;
    border: 1px solid #e2e4e9 !important;
  }

  .goBackToSignIn {
    color: #46a9ff;
    cursor: pointer !important;
    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 820px) {
    width: 100%;
  }
  @media (max-width: 820px) {
    input {
      width: 100%;
    }
  }
`;
export const MessageWrap = styled.div`
  margin: -10px 0;
  height: 20px;
  display: flex;
  justify-content: center;
  color: red;
  /* width: 50%; */
`;

export const Password = styled.div`
  position: absolute;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  right: 10px;
  font-size: 12px;
  line-height: 11px;
  color: #ff0000;
`;

Password.Eye = styled(Eye)`
  width: ${({ eye }) => (eye === 'true' ? '15px' : '0px')};
  cursor: pointer;
  height: 40px;
  transition: all 0.2s ease;
`;
Password.EyeSlash = styled(EyeSlash)`
  width: ${({ eye }) => (eye === 'true' ? '0px' : '15px')};
  cursor: pointer;
  height: 40px;
  transition: all 0.2s ease;
`;

InputWrapper.Warn = styled('p')`
  position: absolute;
  visibility: ${({ incorrect }) => (incorrect ? 'visible' : 'none')};
  opacity: ${({ incorrect }) => (incorrect ? 1 : 0)};
  color: red;
  font-size: 12px;
  line-height: 16px;
  transition: all 0.3s ease-in-out;
  margin-bottom: -12px;
`;
InputWrapper.ErrMessage = styled.div`
  position: absolute;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 11px;
  color: #ff0000;
  bottom: -12px;
`;

export { Container, Form, ErrorIcon, Message, ToastifyContant };
