import { dayjs, utc, timezone, localizedFormat } from 'day-js';

import { getCookie } from './getCookie.js';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

/**
 * Formats a given date according to the user's timezone and a specified format.
 *
 * @param {string|Date} date - The date to format.
 * @param {string} format - The format string for the output date.
 * @returns {string} The formatted date string.
 *
 * @example
 * // Format a date string in the user's timezone
 * const result = getUsersTimeByZoneLTS('2024-06-11T12:34:56Z', 'YYYY-MM-DD HH:mm:ss');
 * console.log(result); // Outputs the date in the user's timezone
 *
 * @example
 * // Format the current date in the user's timezone
 * const result = getUsersTimeByZoneLTS(new Date(), 'YYYY-MM-DD HH:mm:ss');
 * console.log(result); // Outputs the current date in the user's timezone
 *
 * @example
 * // Handle an invalid date input
 * const result = getUsersTimeByZoneLTS(null, 'YYYY-MM-DD HH:mm:ss');
 * console.log(result); // Outputs an empty string
 */
const getUsersTimeByZoneLTS = (date, format) => {
  if (!date) {
    return '';
  }

  // Retrieve the user's timezone from a cookie
  let dateUtc = getCookie('usersTimeZone');
  const offset = dayjs(dateUtc).utcOffset();

  // Guess the user's timezone if not specified
  const guessedTimeZone = dayjs.tz.guess();

  // Format the date in the user's timezone
  let currentUserDate = date
    ? dayjs.utc(date).tz(guessedTimeZone)
    : dayjs().tz(guessedTimeZone);
  // Get result TimeZone
  const result = currentUserDate
    ? currentUserDate?.format(format)
    : dayjs
      .utc(date || dayjs())
      .utcOffset(offset / 60, true)
      .format(format);

  return result;
};

export { getUsersTimeByZoneLTS };
