/** @format */
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  position: relative;
`;

export const OrderBtn = styled.div`
  color: var(--color-full);
  user-select: none;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  &:active {
    filter: brightness(1.5);
  }
`;
