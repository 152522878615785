export const initialState = {
  // objects
  cronTaskList: [],
  cronTaskItem: {},
  permission: {},
  // numbers
  total: 0,
  // functions
  refetch: () => {},
};

export const reducer = (state, action) => {
  switch (action.type) {
    //! objects
    case 'setCronTaskList':
      return { ...state, cronTaskList: action.payload };
    case 'setCronTaskItem':
      return { ...state, cronTaskItem: action.payload };
    case 'setPermission':
      return { ...state, permission: action.payload };

    //! numbers
    case 'setTotal':
      return { ...state, total: action.payload };
    //! functions
    case 'setRefetch':
      return { ...state, refetch: action.payload };
    default:
      return state;
  }
};
