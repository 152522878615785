/** @format */

import React, { useRef } from 'react';
import {
  GenericInput,
  Label,
  Box,
  AntInput,
  WrapperInput,
  TitleColor,
} from './style';
const ColorPicker = ({
  width,
  align,
  icon,
  label,
  bgcolor,
  plcolor,
  color,
  bcolor,
  bwidth,
  radius,
  disabled,
  type,
  minwidth,
  placeholder,
  labelmargin,
  boxWidth,
  boxMargin,
  size,
  height,
  editable,
  maxWidth,
  lineheight,
  value,
  bordercolor,
  bstyle,
  border,
  error,
  errormessage,
  selectedColor,
  ...props
}) => {
  const InputRef = useRef('');

  const Click = () => {
    InputRef?.current?.input?.click();
  };

  return (
    <GenericInput width={width} margin={labelmargin}>
      {label && label !== '' ? (
        <div style={{ display: 'flex' }}>
          <Label>
            {label}
          </Label>
          {errormessage && (
            <p
              style={{
                marginBottom: 0,
                color: 'red',
                lineHeight: lineheight,
                fontSize: '12px',
                marginLeft: '5px',
              }}
            >
              {errormessage?.replace('{', '')?.replace('}', '')}
            </p>
          )}
        </div>
      ) : null}
      <Box width={boxWidth ? boxWidth : width} boxMargin={boxMargin}>
        {!icon ? (
          ''
        ) : align === 'left' ? (
          <Box.IconLeft src={icon} color={color} />
        ) : (
          <Box.IconRight src={icon} color={color} />
        )}
        <WrapperInput>
          <AntInput
            ref={InputRef}
            type={type}
            height={height}
            align={align}
            bgcolor={bgcolor}
            border={border}
            bordercolor={bordercolor}
            color={color}
            bcolor={bcolor}
            editable={editable}
            maxWidth={maxWidth}
            bwidth={bwidth}
            plcolor={plcolor}
            radius={radius}
            disabled={disabled && true}
            icon={icon}
            minwidth={minwidth}
            placeholder={placeholder}
            styles={{ size }}
            bstyle={bstyle}
            value={value}
            {...props}
          />
          <TitleColor>{selectedColor}</TitleColor>
        </WrapperInput>

        <Box.Color selectedColor={selectedColor} onClick={Click} />
        {error && <span style={{ color: 'red' }}>{error}</span>}
      </Box>
    </GenericInput>
  );
};
export default React.memo(ColorPicker);
