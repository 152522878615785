/** @format */

import styled from 'styled-components';

export const InfoStyle = styled.div`
  background-color: white;
  width: 280px;
  min-height: 80px;
  height: ${({ height }) => height || '155px'};
`;

InfoStyle.Row = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  height: 20px;
`;

InfoStyle.Text = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${({ name, fas, driver }) =>
    name ? '#007fba' : fas ? '#000' : driver ? '#777' : '#ACADAE'};
  font-size: ${({ name, fas }) => (name ? '16px' : fas ? '16px' : '12px')};
  font-weight: ${({ name, fas }) => (name ? 600 : fas ? 600 : 500)};
  padding: 1px 10px;
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : '20px')};
  flex-wrap: ${({ loc }) => (loc ? 'wrap' : 'no-wrap')};
  margin-bottom: ${({ loc }) => (loc === 'true' ? '6px' : '0px')};

  & > span.turncated {
    color: #666;
  }
`;

InfoStyle.Span = styled.div`
  padding: 1px 10px;
  border-radius: 50px;
  font-weight: 500;
  background: #0db010;
  font-size: 13px;
  color: #fff;
  width: ${({ trailer }) => trailer && 'fit-content'};
`;
InfoStyle.Button = styled.div`
  padding: 1px 10px;
  border-radius: 50px;
  font-weight: 500;
  background: #acadae;
  font-size: 13px;
  color: #fff;
  width: ${({ trailer }) => trailer && 'fit-content'};
`;
