/** @format */

import { dayjs, weekOfYear, isoWeek } from 'day-js';

// Extend dayjs with weekOfYear plugin
dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);

/**
 * Gets the corresponding month based on the current week of the year.
 * This function uses a switch statement for clarity and readability.
 *
 * @returns {string} - The name of the month corresponding to the current week.
 */
function getMonthFromCurrentWeek() {
  const currentWeek = dayjs().isoWeek();

  // Use switch-case for clearer mapping of week ranges to months
  switch (true) {
    case currentWeek >= 1 && currentWeek <= 4:
      return 'JANUARY';
    case currentWeek >= 5 && currentWeek <= 8:
      return 'FEBRUARY';
    case currentWeek >= 9 && currentWeek <= 13:
      return 'MARCH';
    case currentWeek >= 14 && currentWeek <= 17:
      return 'APRIL';
    case currentWeek >= 18 && currentWeek <= 22:
      return 'MAY';
    case currentWeek >= 23 && currentWeek <= 26:
      return 'JUNE';
    case currentWeek >= 27 && currentWeek <= 30:
      return 'JULY';
    case currentWeek >= 31 && currentWeek <= 35:
      return 'AUGUST';
    case currentWeek >= 36 && currentWeek <= 39:
      return 'SEPTEMBER';
    case currentWeek >= 40 && currentWeek <= 44:
      return 'OCTOBER';
    case currentWeek >= 45 && currentWeek <= 48:
      return 'NOVEMBER';
    case currentWeek >= 49 && currentWeek <= 53:
      return 'DECEMBER';
    default:
      // Handle invalid week numbers
      throw new Error('Invalid week number: ' + currentWeek);
  }
}

export default getMonthFromCurrentWeek;
