/** @format */

import { Route, Routes, Navigate } from 'react-router-dom';
import { SignIn, Profile } from 'pages';
import { WeeklyHistory } from 'departments/fleet';
import { Dispatcher } from '../../components/Departments/Hr';
import { BlackList } from '../../components/Departments/Hr';
import PrivateRoute from '../PrivateRoutes';
import { data } from '../../utils/SidebarData';
import { useAuth } from 'context';
import { getCookie } from 'formatter';
import { useUserMe } from 'hooks';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorBoudaryComponent from '../ErrorBoudaryComponent';
import ErrorFallback from '../ErrorFallback';
import { VerifyOtp, ResetPassword } from 'modals';
import NotFound from '../../components/Generic/ModalComponents/NotFound';
import { Suspense } from 'react';
import { Loading } from 'loaders';
const AppRoutes = ({ setActivePath }) => {
  const [{ viewItems }] = useAuth();
  const getUserMe = useUserMe();

  let isNotCredentialPath =
    location?.pathname !== '/signin' &&
    location?.pathname !== '/verify' &&
    location?.pathname !== '/reset-password';
  function fallbackRender({ error, resetErrorBoundary }) {
    return (
      <ErrorBoudaryComponent
        error={error}
        resetErrorBoundary={resetErrorBoundary}
      />
    );
  }

  const routeComponents = isNotCredentialPath ? (
    <Routes>
      <Route
        path='/'
        element={<Navigate to={getCookie('path') || getCookie('mainURL')} />}
      />
      <Route
        path='/profile/*'
        element={
          <PrivateRoute>
            <Suspense fallback={<Loading />}>
              <Profile />
            </Suspense>
          </PrivateRoute>
        }
      />
      <Route
        path='/department/hr/teams/dispatchers'
        element={
          <PrivateRoute>
            <Suspense fallback={<Loading />}>
              <Dispatcher />
            </Suspense>
          </PrivateRoute>
        }
      />

      {data.map(
        ({ pathname, id, component: ParentComponent, hidden, title }) =>
          ParentComponent &&
          !hidden &&
          viewItems?.[title]?.view && (
            <Route
              element={
                <PrivateRoute>
                  <Suspense fallback={<Loading />}>
                    <ParentComponent />
                  </Suspense>
                </PrivateRoute>
              }
              errorElement={<ErrorFallback />}
              key={id}
              path={pathname}
            />
          )
      )}
      {data?.map((parent) => {
        return (
          !parent?.hidden &&
          parent?.child?.map(
            ({ pathname, hidden, component: ChildComponent, title, id }) => {
              return (
                ChildComponent &&
                viewItems?.[parent?.title]?.[title]?.view &&
                !hidden && (
                  <Route
                    exact
                    key={id}
                    path={`${parent?.pathname}${pathname}`}
                    element={
                      <PrivateRoute>
                        <Suspense fallback={<Loading />}>
                          <ChildComponent />
                        </Suspense>
                      </PrivateRoute>
                    }
                    errorElement={<ErrorFallback />}
                  />
                )
              );
            }
          )
        );
      })}
      {data?.map(
        (parent) =>
          !parent?.hidden &&
          parent?.child?.map((subchild) => {
            return (
              !subchild?.hidden &&
              subchild?.child?.map(
                ({ title, id, component: Grandchild, pathname, hidden }) => {
                  return (
                    Grandchild &&
                    viewItems?.[parent?.title]?.[subchild?.title]?.[title]
                      ?.view &&
                    !hidden && (
                      <Route
                        exact
                        key={id}
                        path={`${parent?.pathname}${subchild.pathname}${pathname}`}
                        element={
                          <PrivateRoute>
                            <Suspense fallback={<Loading />}>
                              <Grandchild />
                            </Suspense>
                          </PrivateRoute>
                        }
                        errorElement={<ErrorFallback />}
                      />
                    )
                  );
                }
              )
            );
          })
      )}
      <Route
        path='/department/hr/recruitment-black-list'
        element={
          <PrivateRoute>
            <Suspense fallback={<Loading />}>
              <BlackList />
            </Suspense>
          </PrivateRoute>
        }
      />
      <Route
        path='/department/fleet/truck-inspections/weekly-inspection/:number/:id'
        element={
          <PrivateRoute>
            <Suspense fallback={<Loading />}>
              <WeeklyHistory />
            </Suspense>
          </PrivateRoute>
        }
      />
      <Route path='/signin' element={<SignIn />} />

      {getUserMe?.isPending && <Route path='*' element={<NotFound />} />}
    </Routes>
  ) : (
    <Routes>
      <Route
        path='/signin'
        element={
          <Suspense fallback={<Loading />}>
            <SignIn />{' '}
          </Suspense>
        }
      />
      <Route
        path='/verify'
        element={<VerifyOtp setActivePath={setActivePath} />}
      />
      <Route
        path='/reset-password'
        element={<ResetPassword setActivePath={setActivePath} />}
      />
    </Routes>
  );

  return (
    <ErrorBoundary fallbackRender={fallbackRender}>
      {routeComponents}
    </ErrorBoundary>
  );
};

export default AppRoutes;
