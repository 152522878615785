/** @format */

import { useMutation } from '@tanstack/react-query';
import { useAuth } from 'context';
import { setCookie, getCookie, deleteCookie } from 'formatter';
import { useNavigate } from 'react-router-dom';
import { dayjs, utc } from 'day-js';
import useRequest from './useRequest';
import { sidebarController } from '../root/sidebarView';
dayjs.extend(utc);
export const useUserMe = () => {
  const [, dispatch] = useAuth();
  const VITE_APP_BASE_ME_URL = import.meta.env.VITE_APP_BASE_ME_URL;
  const navigate = useNavigate();
  const { request } = useRequest();
  const navigateToAppropriatePath = (mainUrl) => {
    const currentPath = getCookie('currentPath');
    if (currentPath && currentPath.startsWith('?')) {
      deleteCookie('currentPath');
      navigate(mainUrl);
    }
    if (currentPath && !currentPath.includes('signin')) {
      navigate(currentPath);
    } else if (mainUrl) {
      navigate(mainUrl);
    } else {
      navigate('/department/dispatcher/all');
    }
  };

  const getUserMe = useMutation({
    mutationFn: () => {
      const tokenFromCookie = getCookie('token');
      return (
        tokenFromCookie &&
        request({
          baseurl: `${VITE_APP_BASE_ME_URL}`,
        })
      );
    },
    onSuccess: (res) => {
      if (res?.success && getCookie('token')) {
        navigateToAppropriatePath(res?.meta?.mainUrl);
        dispatch({
          type: 'setPermissions',
          payload: res?.meta?.permissions,
        });

        setCookie('username', res?.meta?.fullname);
        if (res?.meta?.team && res?.meta?.team_id) {
          setCookie('team', res?.meta?.team);
          setCookie('team-id', res?.meta?.team_id);
        }
        setCookie('email', res?.meta?.email);
        setCookie('nickname', res?.meta?.nickname);
        setCookie('mainURL', res?.meta?.mainUrl);
        setCookie('userId', res?.meta?.id);
        setCookie('usersTimeZone', dayjs().format());
        setCookie('size', '50');

        dispatch({
          type: 'setUser',
          payload: {
            id: res?.meta?.id,
            email: res?.meta?.email,
            fullname: res?.meta?.fullname,
            incomplete: res?.meta?.incomplete,
            nickname: res?.meta?.nickname,
            userId: res?.meta?.id,
          },
        });

        let viewItems = sidebarController(res?.meta?.permissions);
        dispatch({ type: 'setViewItems', payload: viewItems });
        dispatch({
          type: 'setUserRoles',
          payload: res?.meta?.roles,
        });
      } else {
        navigate('/signin');
        dispatch({ type: 'logout' });
      }
    },
    onError: () => {
      navigate('/signin');
      dispatch({ type: 'logout' });
    },
  });

  return getUserMe;
};
