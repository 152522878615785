/** @format */

import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';
export const ReservationContext = createContext();
export const useReservation = () => useContext(ReservationContext);

export const ReservationsPovider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ReservationContext.Provider value={[state, dispatch]}>
      {children}
    </ReservationContext.Provider>
  );
};
