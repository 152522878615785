/** @format */

export const sidebarController = (permission) => {
  // GROUPS
  const hrManagement = permission?.['HR Management'];
  const dataLibrary = permission?.['Data Library'];
  const loadGroup = permission?.['Load Group'];
  const fleet = permission?.['Truck Management'];
  const fuel = permission?.['Fuel Department'];
  const insurance = permission?.['Insurance Department'];
  const trailersManagement = permission?.['Trailer Management'];
  const settingsList = permission?.['Settings'];
  const dispatcher = permission?.['Main'];
  const performance = permission?.['Performance'];
  const qcDepartment = permission?.['QC Department'];
  const dedicatedLane = permission?.['Planning Department'];
  const scheduledLoads = permission?.['Planning Department'];
  const accountingDepartment = permission?.['Accounting Department'];
  const safety = permission?.['Safety'];

  const view = {
    // Dispatcher
    all: dispatcher?.All?.VIEW,
    mainLoadPlanning: dispatcher?.['Load Planning']?.VIEW,
    teamSheet: dispatcher?.['Team Sheet']?.VIEW,
    prePlanning: dispatcher?.['Pre-Planning']?.VIEW,
    grossBoard: dispatcher?.['Gross Board']?.VIEW,

    dispatcherKPI: performance?.['Performance']?.VIEW,

    // Dedicated Line
    dedicatedLane: dedicatedLane?.['Lane']?.VIEW,
    scheduledLoads: scheduledLoads?.['Scheduled Loads']?.VIEW || true,

    // Performance
    performance: performance?.['Performance']?.VIEW,
    qcTeamKpi: performance?.['QC Team KPI']?.VIEW,
    teamKpi: performance?.['Team KPI']?.VIEW,
    unitKpi: performance?.['Unit KPI']?.VIEW,

    // LOAD
    load: loadGroup?.Load?.VIEW,
    loadPlanning: loadGroup?.Load?.VIEW,
    loadTransit: loadGroup?.['Transit']?.VIEW,
    loadHistory: loadGroup?.['Loads History']?.VIEW,

    // Qc Board
    qcDashboard: qcDepartment?.['QC Dashboard']?.VIEW,
    qcPanel: qcDepartment?.['QC Panel']?.VIEW,
    qcPerformance: qcDepartment?.['QC Performance']?.VIEW,
    qcReport: qcDepartment?.['QC Performance']?.VIEW,
    qcLoad: loadGroup?.Load?.VIEW,
    qcLoadPlanning: loadGroup?.Load?.VIEW,
    qcLoadTransit: loadGroup?.['Transit']?.VIEW,
    qcLoadCompleted: loadGroup?.['Loads History']?.VIEW,
    qcTeam: hrManagement?.['User']?.VIEW,

    // HR Department
    driver: hrManagement?.Driver?.VIEW,
    hrStatistics: hrManagement?.['HR Statistics']?.VIEW,
    medicalCertificate: hrManagement?.['Medical Certificate']?.VIEW,
    cdl: hrManagement?.['Commercial Drivers Licence']?.VIEW,
    employee: hrManagement?.['Employee']?.VIEW,
    office: hrManagement?.['Office']?.VIEW,
    department: hrManagement?.['Department']?.VIEW,
    users: hrManagement?.['User']?.VIEW,
    // qcTeam: hrManagement?.['User']?.VIEW,
    team: hrManagement?.['User']?.VIEW,

    // DataLibrary
    company: dataLibrary?.['Company']?.VIEW,
    constants: dataLibrary?.['Company']?.VIEW,
    factoring: dataLibrary?.['Company']?.VIEW,
    category: trailersManagement?.['Trailer Expenditure Category']?.VIEW,
    customer: dataLibrary?.['Customer']?.VIEW,
    location: dataLibrary?.['Location']?.VIEW,
    zone: dataLibrary?.['Zone']?.VIEW,
    roles: dataLibrary?.['Role']?.VIEW,
    docTypes: dataLibrary?.['DocType']?.VIEW,

    // Fleet Department
    trucks: fleet?.['Trucks']?.VIEW,
    reservation: fleet?.['Reservation']?.VIEW,
    originalContract: fleet?.['Original Contract']?.VIEW,
    truckInspection: fleet?.['Truck Inspection']?.VIEW,
    truckAnnualInspection: fleet?.['Truck Federal Annual Inspection']?.VIEW,
    truckPAInspection: fleet?.['Truck PA Inspection']?.VIEW,
    truckWeeklyInspection: fleet?.['Truck Weekly Inspection']?.VIEW,
    pm: fleet?.['PM Service']?.VIEW,
    inspection: fleet?.['PM Service']?.VIEW,
    onboarding: fleet?.['Truck Movement']?.VIEW,
    regCard: fleet?.['Truck Registration Card']?.VIEW,
    permit: fleet?.['Truck Permit']?.VIEW,
    truckMaintenance: fleet?.['Truck Maintenance']?.VIEW,
    truckStatistics: fleet?.['Truck Dashboard']?.VIEW,

    // Fuel Department
    fuelDashboard: fuel?.['Fuel Dashboard']?.VIEW,
    fuelManagement: fuel?.['Fuel Management']?.VIEW,
    fuelStates: fuel?.['Fuel States']?.VIEW,
    fuelStations: fuel?.['Fuel Stations']?.VIEW,
    fuelExpense: fuel?.['Fuel Expense']?.VIEW,
    exist: fuel?.['Exist']?.VIEW,

    // Insurance Department
    insuranceStatistics: insurance?.['Insurance Statistics']?.VIEW,
    policy: insurance?.['Insurance Contract']?.VIEW,
    change: insurance?.['Insurance Change']?.VIEW,
    endorsement: insurance?.['Endorsement']?.VIEW,
    accident: insurance?.['Accident']?.VIEW,
    accidentExpenses: insurance?.['Accident']?.VIEW,

    // Safety department
    safetyDashboard: safety?.['Safety Dashboard']?.VIEW,
    dotReport: safety?.['Dot Report']?.VIEW,
    citation: safety?.['Citation']?.VIEW,
    citationExpense: safety?.['Citation expense']?.VIEW,
    FMCSACode: safety?.['FMCSA Code']?.VIEW,
    Eld: safety?.['ELD']?.VIEW || true,

    // Trailers Department
    trailers: trailersManagement?.['Trailer']?.VIEW,
    trailersMovement: trailersManagement?.['Movement']?.VIEW,
    trailersMoves: trailersManagement?.['Trailer Moves']?.VIEW,
    trailersInspections: trailersManagement?.['Trailer Inspection']?.VIEW,
    trailersRegCard: trailersManagement?.['Trailer Registration Card']?.VIEW,
    trailersMaintenance: trailersManagement?.['Trailer Expenditure']?.VIEW,
    trailersStatistics: trailersManagement?.['TRailer Dashboard']?.VIEW,

    // Settings
    connection: settingsList?.['Connection']?.VIEW,
    cronTask: settingsList?.['Cron Task']?.VIEW,
    truckGPS: settingsList?.['Truck GPS']?.VIEW,
    servers: settingsList?.['Server']?.VIEW,
    // Accounting
    samsaraTrips: accountingDepartment?.['Trip History']?.VIEW,
    loadBilling: accountingDepartment?.['Load Billing']?.VIEW || true,
  };

  // Dispatcher board view
  const DispatcherView =
    view.all || view.mainLoadPlanning || view.teamSheet || view.weeklySchedule;
  const PlanningView = view.dedicatedLane || view?.scheduledLoads;
  // Loads Menu view
  const loadsView = view.loadPlanning || view.loadHistory || view.loadTransit;
  const qcLoadsView = view.qcPanel || view.qcDashboard || view.qcPerformance;
  // HR sub menus view
  const HRStatisticsView = view.hrStatistics;

  const HRDriverView =
    view.driver ||
    view.hrStatistics ||
    view.medicalCertificate ||
    view.cdl ||
    view.driverAssignHistory;

  // Hr Employee subMenu view
  const HREmployeeView = view.employee || view.office || view.department;

  //DataLibrary view
  const DataLibraryView =
    view.company ||
    view.constants ||
    view.location ||
    view.zone ||
    view.roles ||
    view.docTypes ||
    view.factoring;

  // Hr Teams subMenu view
  const AccountingView = view.samsaraTrips || view.loadBilling;

  // Hr Teams subMenu view
  const HRTeamsView = view.team || view.qcTeam;

  // Hr Users subMenu view
  const HRUsersView = view.users || view.roles;

  // HR Department view
  const hrManagementView =
    HRDriverView || HREmployeeView || HRTeamsView || HRStatisticsView;

  // Performance view
  const PerformanceView =
    view.performance || view.qcTeamKpi || view.teamKpi || view.unitKpi;

  //Fuel Department
  const FuelView =
    view.fuelDashboard ||
    view.fuelManagement ||
    view.fuelStates ||
    view.fuelStations ||
    view.exist ||
    view.fuelExpense;

  //Insurance Department
  const insuranceView =
    view.insuranceStatistics ||
    view.policy ||
    view.change ||
    view.endorsement ||
    view.accident ||
    view.accidentExpenses;

  //Safety Department
  const safetyView =
    view.dotReport ||
    view.safetyDashboard ||
    view.citation ||
    view.FMCSACode ||
    view.Eld;

  //Fleet Department
  const FleetView =
    view.trucks ||
    view.reservation ||
    view.originalContract ||
    view.pm ||
    view.truckStatistics ||
    FuelView;

  //Trailers Department
  const TrailerView =
    view.trailers ||
    view.trailersMovement ||
    view.trailersMoves ||
    view.trailersInspections ||
    view.trailersRegCard ||
    view.trailersMaintenance ||
    view.trailersStatistics;

  //Settings
  const SettingsView =
    view.cronTask || view.connection || view.truckGPS || view.servers;

  // view elements
  const viewEL = {
    'Dispatcher Board': {
      view: DispatcherView,
      'Gross Board': { view: view.grossBoard },
      Board: { view: view.all },
      'Load Planning': { view: view.mainLoadPlanning },
      'Team Sheet': { view: view.teamSheet },
      'Pre-Planning': { view: view.prePlanning },
      Performance: { view: PerformanceView },
    },
    'KPI Board': {
      view: PerformanceView,
    },
    'Planning Department': {
      view: PlanningView,
      'Dedicated Lane': {
        view: view?.dedicatedLane,
      },
      'Scheduled Loads': {
        view: view?.scheduledLoads,
      },
    },
    Loads: {
      view: loadsView,
      'Load planning': { view: view.loadPlanning },
      Transit: { view: view.loadTransit },
      History: { view: view.loadHistory },
    },
    'QC Board': {
      view: qcLoadsView,
      Dashboard: { view: view.qcDashboard },
      Panel: { view: view.qcPanel },
      Performance: { view: view.qcPerformance },
      'QC Report': { view: view.qcReport },

      'In Transit': { view: view.qcPanel },
      Completed: { view: view.qcPanel },
      'QC Teams': { view: view.qcTeam },
      'Failed Loads': { view: view.qcPerformance },
      'All Loads': { view: view.qcPerformance },
      'KPI by Office': { view: view.qcPerformance },
      'KPI by Qc Members': { view: view.qcPerformance },
      'Rankings by Qc Members': { view: view.qcPerformance },
      'Rankings by Teams': { view: view.qcPerformance },
    },
    'HR Department': {
      view: hrManagementView,
      Dashboard: { view: HRStatisticsView },
      'Recruitment Kanban Board': { view: HRStatisticsView },

      Drivers: {
        view: HRDriverView,
        'Inactive Drivers': { view: view.driver },
        Drivers: { view: view.driver },
        'Driver On Boarding': { view: view.driver },
        'Drivers Calendar': { view: view.driver },
        'Assign History': { view: view.driverAssignHistory },
        'Medical Certificates': { view: view.medicalCertificate },
        CDL: { view: view.cdl },
      },

      Employee: {
        view: HREmployeeView,
        Employees: { view: view.employee },
        Offices: { view: view.office },
        Departments: { view: view.department },
      },
      Teams: {
        view: HRTeamsView,
        Teams: { view: view.team },
        // Dispatchers: { view: view.team },
        // 'QC Teams': { view: view.qcTeam },
      },
      Users: {
        view: HRUsersView,
        Users: { view: view.users },
        Roles: { view: view.roles },
      },
    },
    'Accounting Department': {
      view: AccountingView,
      'Samsara Trips': { view: view?.samsaraTrips },
      'Load Billing': { view: view?.loadBilling },
    },
    'Data Library': {
      view: DataLibraryView,
      Company: { view: view.company },
      Constants: { view: view.constants },
      Factoring: { view: view.factoring },
      Locations: { view: view.location },
      Categories: { view: view.category },
      Zone: { view: view.zone },
      Customer: { view: view.customer },
      DocType: { view: view.docTypes },
    },
    'Fleet Department': {
      view: FleetView,
      'Trucks List': { view: view.trucks },
      Reservation: { view: view.reservation },
      'Original Contracts': { view: view.originalContract },
      Dashboard: { view: view.truckStatistics },
      'Truck Inspection': {
        view: view.truckAnnualInspection || view.truckWeeklyInspection,

        'PA Inspection': { view: view.truckPAInspection },
        'Annual Inspection': { view: view.truckAnnualInspection },
        'Weekly Inspection': { view: view.truckWeeklyInspection },
      },
      PM: { view: view.pm },
      'Trucks Board': { view: view.inspection },
      'On Boarding': { view: view.onboarding },
      'Registration Card': { view: view.regCard },
      Permit: { view: view.permit },
      'Truck Maintenance': { view: view.truckMaintenance },
    },
    'Fuel Department': {
      view: FuelView,
      Dashboard: { view: view.fuelDashboard },
      'Fuel Management': { view: view.fuelManagement },
      'Fuel States': { view: view.fuelStates },
      'Fuel Stations': { view: view.fuelStations },
      'Fuel Expense': { view: view.fuelExpense },
      Exit: { view: view.exist || true },
    },
    'Insurance Department': {
      view: insuranceView,
      Dashboard: { view: view.insuranceStatistics },
      Policy: { view: view.policy },
      Changes: { view: view.change },
      Truck: { view: view.change },
      Trailer: { view: view.change },
      Driver: { view: view.change },
      Endorsement: { view: view.endorsement },
      Accident: { view: view.accident },
      'Accident Expenses': { view: view.accidentExpenses },
      'DOT Report': { view: view.dotReport },
      FMCSA: { view: view.FMCSACode },
    },
    'Safety Department': {
      view: safetyView,
      Dashboard: { view: view.safetyDashboard },
      Report: { view: view.dotReport },
      Citation: { view: view.citation },
      'Citation Expense': { view: view.citationExpense },
      FMCSA: { view: view.FMCSACode },
      'ELD Board': { view: view.Eld },
    },
    'Trailers Department': {
      view: TrailerView,
      Trailers: { view: view.trailers },
      Movements: { view: view.trailersMovement },
      Moves: { view: view.trailersMoves },
      Inspections: { view: view.trailersInspections },
      'Registration Card': { view: view.trailersInspections },
      Maintenance: { view: view.trailersMaintenance },
      Dashboard: { view: view.trailersStatistics },
    },
    Settings: {
      view: SettingsView,
      Connections: { view: view?.connection },
      'Cron Task': { view: view?.cronTask },
      'Truck GPS': { view: view?.truckGPS },
      Servers: { view: view?.servers },
    },
    SignIn: { view: true },
  };
  return viewEL;
};
