/** @format */

import styled from 'styled-components';
const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f5f7f7;
  width: 100%;
  .Toastify__toast-container {
    width: fit-content;
    min-width: 400px;
  }

  .Toastify__progress-bar-theme--light {
    background: var(--toastify-color-progress-error);
  }
`;

export const Wrapper = styled.div`
  background: white;
  width: 30%;
  min-width: 300px;
  padding: 20px;
`;

const Form = styled.form`
  background: white;
  width: 30%;
  min-width: 300px;
  padding: 20px;
  #email {
    margin-bottom: 5px;
  }
  .custom {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .ant-spin-dot {
      font-size: 27px;
      margin-right: 10px;
    }
    .load {
      position: absolute;
      /* margin-right: 4px; */
    }
  }
`;

const Message = styled.div`
  color: red;
`;

const ToastifyContant = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  grid-gap: 8px;
`;
ToastifyContant.Text = styled.div`
  color: var(--toastify-color-progress-error);
  font-weight: 600;
  font-size: 16px;
  text-align: center;
`;
export const InputWrapper = styled.div`
  /* margin-top: 14px; */
  position: relative;
  width: 100%;
  /* width: 50%; */
  .ant-select-arrow {
    display: none;
  }
  .otpSep {
    margin: 0 6px;
  }

  .inputFocusStyle {
    border: 1px solid #46a9ff !important;
    border-color: #46a9ff !important;
    border-width: 1px !important;
  }
  .inputStyles {
    height: 48px !important;
    width: 48px !important;
    border-radius: 8px !important;
    background: #f6f8f9 !important;
    border: 1px solid #e2e4e9 !important;
    }

    .goBackToSignIn{
      color: #46a9ff;
      cursor: pointer !important;
      &:hover{
        text-decoration: underline;
      }
    }
  


`;

InputWrapper.Warn = styled('p')`
  position: absolute;
  visibility: ${({ incorrect }) => (incorrect ? 'visible' : 'none')};
  opacity: ${({ incorrect }) => (incorrect ? 1 : 0)};
  color: red;
  font-size: 12px;
  line-height: 16px;
  transition: all 0.3s ease-in-out;
  margin-bottom: -12px;
`;
InputWrapper.ErrMessage = styled.div`
  position: absolute;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 11px;
  color: #ff0000;
  bottom: -12px;
`;

InputWrapper.Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .generatorPass {
    display: flex;
    grid-gap: 7px;
    color: #46a9ff;
    cursor: pointer;
    align-items: center;

    &:hover {
      text-decoration: underline;
      color: #1661f7;
      .svg {
        path {
          fill: #1661f7;
        }
      }
    }
  }
`;

InputWrapper.Label = styled.div`
  display: ${({ multi }) => multi && 'flex'};
  justify-content: ${({ multi }) => multi && 'space-between'};
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 4px;
  line-height: 16px;
  text-transform: uppercase;
  color: #5c6470;
`;

InputWrapper.Warn = styled('p')`
  visibility: ${({ incorrect }) => (incorrect ? 'visible' : 'none')};
  opacity: ${({ incorrect }) => (incorrect ? 1 : 0)};
  color: red;
  font-size: 12px;
  line-height: 16px;
  transition: all 0.3s ease-in-out;
  margin: 0;
`;

export const Password = styled.div`
  position: absolute;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  right: 10px;
  height: 32px;
`;

export const InputsBox = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  /* grid-column-gap: 30px; */
  grid-row-gap: 16px;
  /* justify-content: ${({ right }) =>
    right ? 'flex-end' : 'space-between'}; */

  /* @media (max-width: 820px) {
    flex-direction: column;
  } */
`;

export { Container, Form, Message, ToastifyContant };
