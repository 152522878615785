/** @format */

import { QuestionCircleOutlined } from '@ant-design/icons';
// import { message } from 'antd';
import { PopconfirmAntd } from './style';
import { Toast } from 'modals';

const confirm = () => {
  Toast({
    type: 'success',
    message: 'set onConfirm function to props!',
  });
};

const cancel = () => {
  Toast({
    type: 'error',
    message: 'set onCancel function to props!',
  });
};

export default function DeleteConfirm(props) {
  const icon = (
    <QuestionCircleOutlined
      style={{
        color: 'red',
      }}
    />
  );
  return (
    <PopconfirmAntd
      // ==== function ====
      onConfirm={props?.onConfirm || confirm}
      onCancel={props?.onCancel || cancel}
      onOpenChange={props?.onVisibleChange}
      // ==== content ====
      icon={props?.icon || icon}
      title={
        props?.customTitle ? (
          <span>
            {props?.customTitle}
            <span style={{ color: 'red' }}>{`${props?.target}`}</span>
          </span>
        ) : (
          <span>
            {'Are you sure to delete '}
            <span style={{ color: 'red' }}>{`${props?.title}`}</span>
          </span>
        )
      }
      okText={props?.okText || 'Yes'}
      cancelText={props?.cancelText || 'No'}
      // ==== style ====
      placement={props?.placement || 'top'}
      open={props.visible}
      bg={props?.bgcolor}
    >
      {props?.children}
    </PopconfirmAntd>
  );
}
