/** @format */

export const RateRenderer = ({ data }, key) => {
  let rate = data?.[key] ? data?.[key] : 0;
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        height: '100%',
      }}
      title={rate}
    >
      {'$ ' + rate || 0}
    </div>
  );
};
