/** @format */

export const initialState = {
  // booleans
  isLoading: false,

  drawerOpen: false,
  // arrays
  rolenameList: [],
  rolenameListFilter: [],
  permissionsList: [],
  filtered: [],
  // objects
  roleName: {},
  permission: {},
  // numbers
  total: 0,
  // functions
  refetch: null,
  saveFunction: () => {},
  onCancelFunction: () => {},
  stateChange: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'setSaveFunction':
      return { ...state, saveFunction: action.payload };
    case 'setOnCancelFunction':
      return { ...state, onCancelFunction: action.payload };
    case 'setStateChange':
      return { ...state, stateChange: action.payload };
    // setting booleans

    case 'setDrawerOpen':
      return { ...state, drawerOpen: action.payload };
    case 'setLoading':
      return { ...state, isLoading: action.payload };
    // setting arrays
    case 'setRolenamesList':
      return { ...state, rolenameList: action.payload };
    case 'setRolenamesListFilter':
      return { ...state, rolenameListFilter: action.payload };
    case 'setPermissionsList':
      return { ...state, permissionsList: action.payload };
    // setting objects
    case 'setFiltered':
      return { ...state, filtered: action.payload };
    case 'setRoleName':
      return { ...state, roleName: action.payload };
    case 'setPermission':
      return { ...state, permission: action.payload };
    // setting numbers
    case 'setTotal':
      return { ...state, total: action.payload };
    // functions
    case 'setRefetch':
      return { ...state, refetch: action.payload };
    default:
      return state;
  }
};
