/** @format */

export const initialState = {
  page: 0,
  size: 50,
  total: 0,
  inspections: [],
  attachments: [],
  historyList: [],
  historyDrawerOpen: false,
  selectInspection: {},
  permission: {},
  refetch: () => {},
  saveFunction: () => {},
  onCancelFunction: () => {},
  stateChange: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'setDocs': {
      if (action.own === 'true') {
        return {
          ...state,
          attachments: action.payload,
        };
      }

      let formattedData = action?.payload?.map((d) => {
        return {
          attachmentId: d?.attachmentId || d.uploadedFile?.attachmentId,
          date: d?.date,
          docTypeId: d?.docTypeId || d.uploadedFile?.docTypeId,
          name: d?.name || d.uploadedFile?.name,
          fileOriginalName:
            d?.fileOriginalName || d?.uploadedFile?.fileOriginalName,
          extension: d?.extension || d?.uploadedFile?.extension,
          docTypeName: d?.docTypeName || d?.uploadedFile?.docTypeName,
          notes: d?.notes,
          path: d?.path || d?.uploadedFile?.path,
          size: d?.size,
          uploadedFile: d?.uploadedFile || [],
          createdAtDate: d?.createdAtDate,
          createdByNickname: d?.createdByNickname,
          note: d?.note,
        };
      });

      formattedData = state?.attachments?.length
        ? formattedData?.concat(state?.attachments)
        : formattedData;

      return {
        ...state,
        attachments: formattedData,
      };
    }

    case 'changePage':
      return { ...state, page: action.payload };
    case 'setSize':
      return { ...state, size: action.payload };
    case 'setTotal':
      return { ...state, total: action.payload };
    case 'setInspections':
      return { ...state, inspections: action.payload };
    case 'setSelectInspection':
      return { ...state, selectInspection: action.payload };
    case 'setPermission':
      return { ...state, permission: action.payload };
    case 'setHistory':
      return { ...state, historyList: action.payload };
    case 'setRefetch':
      return { ...state, refetch: action.payload };
    case 'setSaveFunction':
      return { ...state, saveFunction: action.payload };
    case 'setOnCancelFunction':
      return { ...state, onCancelFunction: action.payload };
    case 'setStateChange':
      return { ...state, stateChange: action.payload };
    case 'setHistoryDrawerOpen':
      return { ...state, historyDrawerOpen: action.payload };
    default:
      return state;
  }
};
