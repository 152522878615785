/** @format */

import { ErrorValidationIcon } from 'styledIcons';
import { ErrorMessage } from './style';
import { memo } from 'react';

const RequiredCaption = ({ errorData, field, iconLess, position }) => {
  return (
    <>
      {errorData?.[field] ? (
        <>
          {!iconLess && <ErrorValidationIcon />}
          <ErrorMessage position={position}>{errorData?.[field]}</ErrorMessage>
        </>
      ) : null}
    </>
  );
};

export default memo(RequiredCaption);
