/** @format */

import { Suspense, lazy } from 'react';

const DriverInfoPanelLazy = lazy(() => import('./DriverInfoPanel'));
const TruckBoxLazy = lazy(() => import('./TruckBox'));
const LoadNoteLazy = lazy(() => import('./LoadNote'));
const NoteTable2 = lazy(() => import('./NoteTable2'));

// export { DriverInfoPanel, TruckBox };
export function TruckBox(props) {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <TruckBoxLazy {...props} />
    </Suspense>
  );
}
export function DriverInfoPanel(props) {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <DriverInfoPanelLazy {...props} />
    </Suspense>
  );
}
export function LoadNote(props) {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <LoadNoteLazy {...props} />
    </Suspense>
  );
}
export function NoteTable(props) {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <NoteTable2 {...props} />
    </Suspense>
  );
}
// export { default as TruckBox } from './TruckBox';
// export { default as DriverInfoPanel } from './DriverInfoPanel';
export { default as Input } from './Input';
export { default as Password } from './Password';
export { default as RedStars } from './RedStars';
export { default as Button } from './Button';
export { default as Uploader } from './Uploader';
export { default as Table } from './Table';
export { default as Autocomplete } from './Autocomplete';
export { default as MultiSelect } from './MultiSelect';
export { default as Datepicker } from './Datepicker';
export { default as Select } from './Select';
export { default as Buttons } from './ButtonsSaveCancel';
export { default as Textarea } from './Textarea';
export { default as ConfirmBeforeLeaving } from './ConfirmBeforeLeaving';
export { default as Header } from './FormHeader';
export { default as ExportButton } from './Button/ExportButton';
export { default as TopBarTitle } from './TopBarTitle';
export { default as Pagination } from './Pagination';
export { RequiredCaption, ExistCaption } from './ValidationCaptions';
export { default as DateRangePicker } from './DateRange';
export { default as Tabs } from './Tabs';
export { default as SearchSelectForDis } from './SearchSelectForDis';
export { default as StatusSwitch } from './StatusSwitch';
export { default as ColorPicker } from './ColorPicker';
export { default as TimePicker } from './TimePicker';
export { default as Progress } from './Progress';
export { default as Switch } from './Switch';
export { default as SetDocsTrailer } from './SetDocMultiple';
export { default as MoreActionButtons } from './MoreActionButtons';
export { default as Checkbox } from './Checkbox';
export { default as RadioGroup } from './RadioGroup';
export { default as TabsForForm } from './TabsForForm';
export { default as IconicSelect } from './IconicSelect';
export { default as ProgressLine } from './ProgressBar';
export { default as CellSelect } from './CellSelect';
export { default as CellAutocomplete } from './CellAutocomplete';
export { default as TruckDriverInfo } from './TruckDriverInfoOnQc';
export { default as Collapse } from './Collapse';
export { default as DatepickerMask } from './DatepickerMask';
