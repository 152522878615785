/** @format */

// Initial state configuration for the reducer
export const initialState = {
  permission: {},
  truckStatusList: [],
  truckStatusListTotal: 0,
  trailerStatusList: [],
  trailerStatusListTotal: 0,
  driverInsuranceStatusList: [],
  driverInsuranceStatusListTotal: 0,
  truckInsuranceStatusList: [],
  truckInsuranceStatusListTotal: 0,
  trailerInsuranceStatusList: [],
  trailerInsuranceStatusListTotal: 0,
  activeTruckPerLessorList: [],
  activeTruckPerLessorListTotal: 0,
  activeTrailerPerLessorList: [],
  activeTrailerPerLessorListTotal: 0,
  claimStatusPerPerson: {},
  claimStatusPerPersonTotal: 0,
  claimStatus: [],
  claimStatusTotal: 0,
  expense: [],
  expenseTotal: 0,
};

// Reducer function to manage state updates based on action types
export const reducer = (state, action) => {
  switch (action.type) {
    case 'setPermission':
      return { ...state, permission: action.payload };

    case 'setTruckStatus':
      return { ...state, truckStatusList: action.payload };
    case 'setTrailerStatus':
      return { ...state, trailerStatusList: action.payload };
    case 'setDriverInsuranceStatus':
      return { ...state, driverInsuranceStatusList: action.payload };
    case 'setTruckInsuranceStatus':
      return { ...state, truckInsuranceStatusList: action.payload };
    case 'setTrailerInsuranceStatus':
      return { ...state, trailerInsuranceStatusList: action.payload };
    case 'setTruckPerLessor':
      return { ...state, activeTruckPerLessorList: action.payload };
    case 'setTrailerPerLessor':
      return { ...state, activeTrailerPerLessorList: action.payload };
    case 'setClaimStatusPerPerson':
      return { ...state, claimStatusPerPerson: action.payload };
    case 'setClaimStatus':
      return { ...state, claimStatus: action.payload };
    case 'setExpense':
      return { ...state, expense: action.payload };
    case 'setTruckStatusTotal':
      return { ...state, truckStatusListTotal: action.payload };
    case 'setTrailerStatusTotal':
      return { ...state, trailerStatusListTotal: action.payload };
    case 'setDriverInsuranceStatusTotal':
      return { ...state, driverInsuranceStatusListTotal: action.payload };
    case 'setTruckInsuranceStatusTotal':
      return { ...state, truckInsuranceStatusListTotal: action.payload };
    case 'setTrailerInsuranceStatusTotal':
      return { ...state, trailerInsuranceStatusListTotal: action.payload };
    case 'setTruckPerLessorTotal':
      return { ...state, activeTruckPerLessorListTotal: action.payload };
    case 'setTrailerPerLessorTotal':
      return { ...state, activeTrailerPerLessorListTotal: action.payload };
    case 'setClaimStatusPerPersonTotal':
      return { ...state, claimStatusPerPersonTotal: action.payload };
    case 'setClaimStatusTotal':
      return { ...state, claimStatusTotal: action.payload };
    case 'setExpenseTotal':
      return { ...state, expenseTotal: action.payload };
    default:
      return state;
  }
};
