/** @format */

import { Loading } from 'loaders';
import { Wrapper } from './style';
import { memo } from 'react';

function Driver({ drivers, data, onlyProgress, isLoading }) {
  return (
    <Wrapper>
      {onlyProgress ? null : isLoading ? (
        <Loading />
      ) : (
        <>
          <div>
            {!data?.driver && data?.coDriver ? (
              <div className='center'>
                <center>No data!</center>
              </div>
            ) : (
              <>
                {drivers?.map((d, i) => (
                  <div key={d?.id} id='driver'>
                    <div className='header'>
                      {i == 0 ? 'Driver:' : 'Co Driver:'}
                    </div>
                    {d?.fullName ? (
                      <Wrapper.Content>
                        <div>Full name </div>
                        <div className='value'>{d?.fullName || 'N/A'}</div>
                      </Wrapper.Content>
                    ) : null}
                    {d?.phoneNumber ? (
                      <Wrapper.Content>
                        <div>Phone number</div>
                        <div className='value'>{d?.phoneNumber || 'N/A'}</div>
                      </Wrapper.Content>
                    ) : null}
                    {d?.otherNumber ? (
                      <Wrapper.Content>
                        <div>Other number</div>
                        <div className='value'>{d?.otherNumber || 'N/A'}</div>
                      </Wrapper.Content>
                    ) : null}
                    {d?.companyName ? (
                      <Wrapper.Content>
                        <div>Company</div>
                        <div className='value'>{d?.companyName || 'N/A'}</div>
                      </Wrapper.Content>
                    ) : null}
                    {d?.ownershipType ? (
                      <Wrapper.Content>
                        <div>Ownership type</div>
                        <div className='value'>
                          {d?.ownershipType?.replaceAll('_', ' ') || 'N/A'}
                        </div>
                      </Wrapper.Content>
                    ) : null}
                    {d?.email ? (
                      <Wrapper.Content>
                        <div>Email</div>
                        <div className='value'>{d?.email || 'N/A'}</div>
                      </Wrapper.Content>
                    ) : null}
                    {d?.address ? (
                      <Wrapper.Content>
                        <div>Home address</div>
                        <div className='value'>{d?.address || 'N/A'}</div>
                      </Wrapper.Content>
                    ) : null}
                    {d?.cdl?.length ? (
                      <Wrapper.Content>
                        <div>CDL</div>
                        <div className='value'>
                          {d?.cdl?.map(
                            ({ path, fileOriginalName, fileName }) => (
                              <a
                                key={path}
                                href={path}
                                target='_blank'
                                download={path}
                                rel='noreferrer'
                              >
                                {fileOriginalName || fileName || ''}
                              </a>
                            )
                          ) || 'N/A'}
                        </div>
                      </Wrapper.Content>
                    ) : null}
                  </div>
                ))}
              </>
            )}
          </div>
        </>
      )}
    </Wrapper>
  );
}

export default memo(Driver);
