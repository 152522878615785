/** @format */

import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';

export const RegCardContext = createContext();

export const useRegCard = () => useContext(RegCardContext);

export const RegCardProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <RegCardContext.Provider value={[state, dispatch]}>
      {children}
    </RegCardContext.Provider>
  );
};
