/** @format */

import { createContext, useContext, useReducer } from 'react';

import { reducer, initialState } from './reducer';

export const TrailersContext = createContext();

export const useTrailers = () => useContext(TrailersContext);

export const TrailersContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <TrailersContext.Provider value={[state, dispatch]}>
      {children}
    </TrailersContext.Provider>
  );
};
