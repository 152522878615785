/** @format */

import styled from 'styled-components';
import { ReactComponent as Down } from 'assets/icons/arrow-down.svg';

export const SearchSelectWrapper = styled('div')`
  width: ${({ width }) => width || '100%'};
  display: flex;
  flex-direction: column;
  min-width: ${({ minwidth }) => minwidth && minwidth};
  .ant-select-item-option-content {
    display: flex !important;
    justify-content: space-around !important;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    font-weight: 400;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: ${({ bgcolor }) => bgcolor && bgcolor};
    border: ${({ bordercolor }) => bordercolor && `1px solid ${bordercolor}`};
  }
  .ant-select-selector {
    height: 100% !important;
    height: 32px !important;
    height: ${({ inpheight }) => (inpheight ? inpheight : '32px')} !important;
    align-items: center;
    padding-left: 7px !important;
  }

  .ant-select-selection-item {
    color: ${({ tiltecolor }) => tiltecolor && tiltecolor};
    font-weight: ${({ tiltecolor }) => tiltecolor && '400'};
    line-height: ${({ inpheight }) =>
      inpheight ? inpheight : '32px'} !important;
    min-height: ${({ inpheight }) =>
      inpheight ? inpheight : '32px'} !important;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    height: ${({ height }) => (height ? height : '32px')} !important;
    line-height: ${({ contentheight }) => contentheight && contentheight};
    overflow: hidden;
    .turncated {
      text-overflow: ellipsis;
      width: calc(98%);
      white-space: nowrap;
      overflow: hidden;
      text-align: left;
    }
  }
  .ant-select-show-search.ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    input {
    height: ${({ inpheight }) => (inpheight ? inpheight : '32px')} !important;

    line-height: ${({ contentheight }) => contentheight && contentheight};
  }

  .ant-select-selction-search-input {
    ::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      font-weight: 400;
    }
    :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      font-weight: 400;
    }
    ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      font-weight: 400;
    }
    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      font-weight: 400;
    }
    ::-ms-input-placeholder {
      /* Microsoft Edge */
      font-weight: 400;
    }

    ::placeholder {
      /* Most modern browsers support this now. */
      font-weight: 400;
    }
  }
`;

export const SearchSelectLabel = styled('p')`
  text-align: left;
  font-family: ${({ fontfamily }) =>
    fontfamily ? fontfamily : 'var(--appPrimaryFont)'};
  margin: ${({ margin }) => margin};
  font-style: normal;
  font-weight: ${({ fontweight }) => (fontweight ? fontweight : 600)};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '15px')};
  line-height: ${({ lineheight }) => (lineheight ? lineheight : '16px')};
  text-transform: ${({ texttransform }) =>
    texttransform ? texttransform : 'uppercase'};
  color: ${({ color }) => (color ? color : '#5c6470')};
`;

export const DownIcon = styled(Down)`
  width: 14px;
  height: 11px;
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
`;
