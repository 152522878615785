import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './dispatcherTeamsReducer';
export const DispatcherTeamsContext = createContext();
export const useDispatcherTeamSheet = () => useContext(DispatcherTeamsContext);

export const DispatcherTeamSheetProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DispatcherTeamsContext.Provider value={[state, dispatch]}>
      {children}
    </DispatcherTeamsContext.Provider>
  );
};
