/** @format */

import { dayjs, customParseFormat } from 'day-js';
import React from 'react';
import RedStars from '../RedStars';
import { MainDatePickerWrapper, DatePickerLabel, AntDatePicker } from './style';
dayjs.extend(customParseFormat);

export const Datepicker = ({
  isrequired,
  width,
  value,
  format,
  bordercolor,
  label,
  placeholder,
  defaultValue,
  showTime,
  allowClear = false,
  changeOnBlur = true,
  no_header,
  ...props
}) => {
  return (
    <MainDatePickerWrapper width={width} bordercolor={bordercolor}>
      {label && (
        <DatePickerLabel>
          {label}
          {isrequired ? <RedStars /> : null}
        </DatePickerLabel>
      )}

      <AntDatePicker
        popupClassName={no_header ? 'no-header' : ''}
        placeholder={placeholder || 'MM/DD/YYYY'}
        value={value ? dayjs(value) : null}
        defaultValue={defaultValue ? dayjs(defaultValue) : null}
        format={format || 'MM/DD/YYYY'}
        allowClear={allowClear}
        showTime={showTime === false ? false : true}
        changeOnBlur={changeOnBlur}
        {...props}
      />
    </MainDatePickerWrapper>
  );
};

export default React.memo(Datepicker);
