/** @format */

import { Select } from '../../AntDesign';
import styled from 'styled-components';
const { Option } = Select;
export const SelectWrapper = styled('div')`
  width: ${({ width }) => width || '100%'};
  height: 29px;
  display: flex;
  flex-direction: column;
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: ${({ bordercolor }) => `1px solid ${bordercolor || '#374151'}`};
    border-radius: 4px;
  }
`;
export const StyledSelect = styled(Select)`
  height: 29px;
  .custom-select .ant-select-selector {
    visibility: hidden; /* Hide the input field */
  }

  .custom-select .ant-select-arrow {
    visibility: visible; /* Ensure the arrow icon is visible */
  }
`;

export const StyledOpt = styled(Option)`
  min-width: 150px !important;
`;
