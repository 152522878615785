/** @format */

import styled from 'styled-components';
import { Modal } from 'antd';

export const AntModal = styled(Modal)`
  z-index: 1000 !important;
  /* width: 636px !important; */
  width: ${({ width }) => width || '636px'} !important;
`;
