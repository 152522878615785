/**
 * Capitalizes the first letter of each word in a string while converting underscores to spaces.
 *
 * @param {string} str - The input string to be capitalized.
 * @returns {string|null} The capitalized string, or null if input is invalid.
 *
 * @example
 * // Capitalize words in a string
 * const result = capitalizeWords('hello_world');
 * console.log(result); // 'Hello World'
 *
 * @example
 * // Handle invalid input
 * const result = capitalizeWords(null);
 * console.log(result); // null
 */
function capitalizeWords(str) {
  // Check if str is provided and is a string
  if (typeof str !== 'string' || !str) {
    return null;
  }

  // Capitalize each word in the string
  return str?.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
}

export { capitalizeWords };
