import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './medicalCertificatesReducer';
export const MedicalCertificateContext = createContext();
export const useMedicalCertificate = () =>
  useContext(MedicalCertificateContext);

export const MedicalCertificateContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <MedicalCertificateContext.Provider value={[state, dispatch]}>
      {children}
    </MedicalCertificateContext.Provider>
  );
};
