/** @format */

import React from 'react';
import { Container, Preloader } from './style';

const PreLoader = () => {
  return (
    <Container>
      <Preloader />
    </Container>
  );
};

export default React.memo(PreLoader);
