/** @format */

export const StatusRenderer = ({ data }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        height: '100%',
      }}
    >
      {data?.status === true ? 'Active' : 'Inactive'}
    </div>
  );
};
