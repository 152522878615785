/** @format */

import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';
export const FuelStationsContext = createContext();
export const useFuelStations = () => useContext(FuelStationsContext);

export const FuelStationsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <FuelStationsContext.Provider value={[state, dispatch]}>
      {children}
    </FuelStationsContext.Provider>
  );
};
