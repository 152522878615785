/** @format */

// Initial state configuration for the reducer
export const initialState = {
  permission: {},
  expenseList: [],
  dataList: [],
  data: {},
  saveFunction: () => { },
  onCancelFunction: () => { },
  refetch: () => { },
  stateChange: false,
};

// Reducer function to manage state updates based on action types
export const reducer = (state, action) => {
  switch (action.type) {
    case 'setPermission':
      return { ...state, permission: action.payload };

    case 'setData':
      return { ...state, data: action.payload };

    case 'setExpenseList':
      return { ...state, expenseList: action.payload };

    case 'setDataList':
      return { ...state, dataList: action.payload };

    case 'setSaveFunction':
      return { ...state, saveFunction: action.payload };

    case 'setOnCancelFunction':
      return { ...state, onCancelFunction: action.payload };

    case 'setStateChange':
      return { ...state, stateChange: action.payload };

    case 'setReFetch':
      return { ...state, refetch: action.payload };
    default:
      return state;
  }
};
