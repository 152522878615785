/** @format */

export const initialState = {
  total: 0,
  permission: {},
  employeeList: [],
  employee: {},

  refetch: Function.prototype,

  onSaveFn: Function.prototype,
  onCancelFn: Function.prototype,
  onStateChange: Function.prototype,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'setDocs': {
      if (action.own === 'true') {
        return {
          ...state,
          attachments: action.payload,
        };
      }

      let formattedData = action?.payload?.map((d) => {
        return {
          attachmentId: d?.attachmentId || d.uploadedFile?.attachmentId,
          date: d?.date || d?.uploadedFile?.date,
          docTypeId: d?.docTypeId || d?.uploadedFile?.docTypeId,
          docTypeName: d?.docTypeName || d?.uploadedFile?.docTypeName,
          extension: d?.extension || d?.uploadedFile?.extension,
          fileOriginalName:
            d?.fileOriginalName || d?.uploadedFile?.fileOriginalName,
          name: d?.name || d?.uploadedFile?.name,
          notes: d?.notes || d?.uploadedFile?.notes,
          path: d?.path || d?.uploadedFile?.path,
          size: d?.size || d?.uploadedFile?.size,
          createdAtDate: d?.createdAtDate,
          createdByNickname: d?.createdByNickname,
          note: d?.note,
          // uploadedFile: d?.uploadedFile || [],
        };
      });

      formattedData = state?.attachments?.length
        ? formattedData?.concat(state?.attachments)
        : formattedData;

      return {
        ...state,
        attachments: formattedData,
      };
    }
    case 'setTotal':
      return { ...state, total: action.payload };
    case 'setPermission':
      return { ...state, permission: action.payload };
    case 'setEmployeeList':
      return { ...state, employeeList: action.payload };
    case 'setEmployee':
      return { ...state, employee: action.payload };
    case 'setRefetch':
      return { ...state, refetch: action.payload };
    // for asking before closing drawer
    case 'setOnSaveFn':
      return { ...state, onSaveFn: action.payload };
    case 'setOnCancelFn':
      return { ...state, OnCancelFn: action.payload };
    case 'setStateChange':
      return { ...state, stateChange: action.payload };
    default:
      return state;
  }
};
