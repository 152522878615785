import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './dispatcherBoardReducer';
export const DispatcherBoardContext = createContext();
export const useDispatcherBoard = () => useContext(DispatcherBoardContext);

export const DispatcherBoardProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DispatcherBoardContext.Provider value={[state, dispatch]}>
      {children}
    </DispatcherBoardContext.Provider>
  );
};
