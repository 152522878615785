/** @format */

import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';
export const MovementContext = createContext();
export const useTrailerMovement = () => useContext(MovementContext);

export const TrailerMovementProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <MovementContext.Provider value={[state, dispatch]}>
      {children}
    </MovementContext.Provider>
  );
};
