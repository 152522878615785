/** @format */

import { Wrapper } from './style';

export default function Header({
  title,
  color,
  fontSize,
  lineheight,
  height,
  padding,
  children,
  tire,
}) {
  return (
    <Wrapper
      tire={tire}
      color={color}
      fontSize={fontSize}
      lineheight={lineheight}
      height={height}
      padding={padding}
    >
      {children ? children : title}
    </Wrapper>
  );
}
