/** @format */

import styled from 'styled-components';
import { getStatusColors } from '../../../../css/globalStyle';
import { Select } from '../../AntDesign';
const { Option } = Select;

export const StyledSelect = styled(Select)`
  box-sizing: border-box;
  width: 100%;
  height: ${({ height }) => (height ? height : 'var(--appFilterHeight)')};
  border-radius: var(--appFilterBorderRadius);
  font-size: var(--appFilterFontSize);
  .ant-select-selector {
    width: 100% !important;
  }
  // used in onboarding page
  &.onboarding {
    height: 24px !important;
    line-height: 23px;
    .ant-select-selection-item {
      height: 24px;
      line-height: 23px;
      font-size: ${({ fsize }) => fsize || 'var(--tableFontSize)'};
    }
    .ant-select-selection-search-input {
      height: 22px !important;
      line-height: 21px !important;
    }
  }
  // used in QC board page
  &.autoheight {
    border: ${({ status, borderless }) =>
      borderless === 'true' ? 'none' : getStatusColors({ status }).brd};
    .ant-select-selector {
      font-size: ${({ fsize }) => fsize || 'var(--tableFontSize)'};
      padding: 0 2px !important;
      align-items: center !important;
      background-color: ${({ status }) =>
        getStatusColors({ status }).bg} !important;
      border: ${({ status, borderless }) =>
        borderless === 'true' ? 'none' : getStatusColors({ status }).brd};
    }
    width: ${({ width }) => width};
    .ant-select-arrow {
      margin-right: -10px;
      svg {
        width: 8px;
      }
      path {
        color: ${({ status }) => getStatusColors({ status }).text} !important;
      }
    }
    .ant-select.ant-select-selection-item {
      color: ${({ status }) => getStatusColors({ status }).text} !important;
      flex: none !important;
      white-space: normal !important;
      white-space: normal !important;
      text-overflow: clip !important;
      word-wrap: break-word !important;
    }
    .ant-select-selection-item {
      color: ${({ status }) => getStatusColors({ status }).text} !important;
    }
    .ant-select-selector .ant-select-selection-item {
      font-weight: ${({ status }) =>
        status?.startsWith('rating_') ? '600' : 'normal'};
      padding-left: ${({ status }) =>
        status?.startsWith('rating_') ? '5px' : '0px'};
      line-height: 15px !important;
      word-wrap: break-word !important;
      white-space: break-spaces !important;
      align-self: auto !important;
      padding-inline-end: 0 !important;
      font-size: ${({ fsize }) => fsize || 'var(--tableFontSize)'};
    }
  }
  &.daysoff-cell {
    border: ${({ status, borderless }) =>
      borderless === 'true' ? 'none' : getStatusColors({ status }).brd};
    height: 23px;
    .ant-select-selector {
      font-size: ${({ fsize }) => fsize || 'var(--tableFontSize)'};
      padding: 0 2px !important;
      align-items: center !important;
      background-color: ${({ status }) =>
        getStatusColors({ status }).bg} !important;
      border: ${({ status, borderless }) =>
        borderless === 'true' ? 'none' : getStatusColors({ status }).brd};
    }
    width: ${({ width }) => width};
    .ant-select-arrow {
      display: none;
    }
    .ant-select.ant-select-selection-item {
      color: ${({ status }) => getStatusColors({ status }).text} !important;
      flex: none !important;
      white-space: normal !important;
      white-space: normal !important;
      text-overflow: clip !important;
      word-wrap: break-word !important;
    }
    .ant-select-selection-item {
      color: ${({ status }) => getStatusColors({ status }).text} !important;
    }
    .ant-select-selector .ant-select-selection-item {
      line-height: 15px !important;
      word-wrap: break-word !important;
      white-space: break-spaces !important;
      align-self: auto !important;
      padding-inline-end: 0 !important;
      font-size: ${({ fsize }) => fsize || 'var(--tableFontSize)'};
    }
  }
  // used in Employee, Users, truck-maintenance pages
  &.colored_cell_select {
    ${'' /* border: ${({ status }) => getStatusColors({ status }).brd}; */}
    ${'' /* padding: 1px; */}
    width: ${({ width }) => (width ? width : '100%')};
    height: ${({ height }) => (height ? height : '100%')} !important;
    ${'' /* line-height: 23px; */}
    .ant-select-selection-item {
      color: ${({ status }) => getStatusColors({ status }).text} !important;
      ${
        '' /* height: 24px;
      line-height: 23px; */
      }
    }
    .ant-select-selection-search-input {
      ${
        '' /* height: 22px !important;
      line-height: 21px !important; */
      }
    }
    .ant-select-selector {
      border: none;
      background-color: ${({ status }) =>
        getStatusColors({ status }).bg} !important;
    }
    .ant-select-arrow {
      color: ${({ status }) => getStatusColors({ status }).text} !important;
      font-size: var(--appTableFontSize) !important;
      inset-inline-end: 4px !important;
      &svg {
        height: 10px !important;
        width: 10px !important;
      }
    }
  }
  &.colored_cell_select_with_border {
    border: ${({ status }) => getStatusColors({ status }).brd};
    width: ${({ width }) => (width ? width : '100%')};
    height: ${({ height }) => (height ? height : '100%')} !important;
    .ant-select-selection-item {
      color: ${({ status }) => getStatusColors({ status }).text} !important;
    }

    .ant-select-selector {
      border: ${({ status }) => getStatusColors({ status }).brd};
      background-color: ${({ status }) =>
        getStatusColors({ status }).bg} !important;
    }
    .ant-select-arrow {
      color: ${({ status }) => getStatusColors({ status }).text} !important;
      font-size: var(--appTableFontSize) !important;
      inset-inline-end: 4px !important;
      &svg {
        height: 10px !important;
        width: 10px !important;
      }
    }
  }
  &:where(.css-dev-only-do-not-override-1adbn6x).ant-select-single
    .ant-select-selector
    .ant-select-selection-item,
  &:where(.css-dev-only-do-not-override-1adbn6x).ant-select-single
    .ant-select-selector
    .ant-select-selection-placeholder {
    align-items: center !important;
    align-self: unset !important;
  }
`;

export const StyledOption = styled(Option)`
  font-size: ${({ fsize }) => fsize || 'var(--tableFontSize)'};
`;
