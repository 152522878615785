/** @format */

import { createContext, useContext, useReducer } from 'react';
import { addConnectionsReducer, initialState } from './reducer';

const AddConnectionsContext = createContext();

export const useAddConnections = () => useContext(AddConnectionsContext);

export const AddConnectionsContextProvider = ({ children }) => {
  return (
    <AddConnectionsContext.Provider
      value={useReducer(addConnectionsReducer, initialState)}
    >
      {children}
    </AddConnectionsContext.Provider>
  );
};
