import styled from 'styled-components';

export const Wrapper = styled.div`
  min-width: 272px;
  background: #ffffff;
  mix-blend-mode: normal;
  backdrop-filter: blur(10px);
  border-radius: 5px;
  box-sizing: border-box;
  .ant-tabs-tab-btn {
    font-size: 12px;
  }
  #driver {
  }
  .header {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #374151;
    margin: 16px 0;
  }

  .flex {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  .ml {
    margin-left: 16px;
  }

  .name {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #313e47;
  }

  .mt {
    display: flex;
    grid-gap: 12px;
    margin-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px dashed #eaeaea;
  }

  .text {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #313e47;
    width: 400px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

Wrapper.Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px dashed #f0f2fa;
  padding: 8px 0;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #111;

  .value {
    color: #121929;
  }
`;

Wrapper.Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 84px);
  gap: 30px;
`;
