/** @format */

import React from 'react';
import { Title, Desc, Wrapper, ProgressBar } from './style';

const Progress = ({
  value,
  title,
  percent,
  margin,
  strokeColor,
  secondColor,
  valueBgColor,
  valueColor,
  valueSize,
  width,
  type,
  Icon,
  gapDegree,
}) => {
  return (
    <Wrapper margin={margin} width={width || 130}>
      <Wrapper.Progress>
        <div
          style={{
            transform: `rotate(${type !== 'circle' ? '0' : '-180deg'})`,
          }}
        >
          <ProgressBar
            size={width || 130}
            type={type || 'dashboard'}
            percent={percent || 0}
            strokeColor={strokeColor || '#04A6FB'}
            circleTrail={secondColor || '#F0F4FA'}
            gapDegree={type !== 'circle' ? gapDegree || 60 : ''}
          />
        </div>

        <Title
          bg={valueBgColor || 'transparent'}
          size={valueSize || width - 15 || 115}
          color={valueColor || '#374151'}
        >
          {value || 'value'}
        </Title>
        <Wrapper.Icon>{Icon ? Icon : null}</Wrapper.Icon>
      </Wrapper.Progress>
      <Wrapper.WrapText>
        <Desc>{title || 'title'}</Desc>
      </Wrapper.WrapText>
    </Wrapper>
  );
};

export default React.memo(Progress);
