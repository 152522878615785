/** @format */

import React, { forwardRef } from 'react';
import { GenericInput, Box, AntPassword } from './style';
// import RedStars from '../RedStars';

const Password = forwardRef(
  (
    {
      // isrequired,
      width,
      align,
      icon,
      label,
      bgcolor,
      plcolor,
      color,
      bcolor,
      radius,
      disabled,
      type,
      minwidth,
      placeholder,
      labelmargin,
      boxWidth,
      boxMargin,
      height,
      lineheight,
      defaultValue,
      bordercolor,
      border,
      error,
      errormessage,
      copied,
      min,
      max,
      maxLength,
      value,
      iconRender,

      ...props
    },
    ref
  ) => {
    const onChange = (e) => {
      if (type === 'number') {
        const { value: inputValue } = e.target;
        const reg = /^-?\d*(\.\d*)?$/;
        if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
          props.onChange(e);
        }
      } else {
        props.onChange(e);
      }
    };
    return (
      <GenericInput copied={copied} width={width} margin={labelmargin}>
        {label && label !== '' ? (
          <div style={{ display: 'flex' }}>
            {errormessage && (
              <p
                style={{
                  marginBottom: 0,
                  color: 'red',
                  lineHeight: lineheight,
                  fontSize: '12px',
                  marginLeft: '5px',
                }}
              >
                {errormessage?.replace('{', '')?.replace('}', '')}
              </p>
            )}
          </div>
        ) : null}
        <Box width={boxWidth ? boxWidth : width} boxMargin={boxMargin}>
          {!icon ? (
            ''
          ) : align === 'left' ? (
            <Box.IconLeft src={icon} color={color} />
          ) : (
            <Box.IconRight src={icon} color={color} />
          )}
          <AntPassword
            visibilityToggle
            ref={ref}
            type={type || 'text'}
            maxLength={maxLength}
            height={height}
            align={align}
            bgcolor={bgcolor}
            border={border}
            bordercolor={bordercolor}
            color={color}
            bcolor={bcolor}
            plcolor={plcolor}
            radius={radius}
            disabled={disabled && true}
            icon={icon}
            minwidth={minwidth}
            placeholder={placeholder}
            defaultValue={defaultValue}
            value={value}
            min={min}
            max={max}
            iconRender={iconRender}
            {...props}
            onChange={onChange}
          />
          {error && <span style={{ color: 'red' }}>{error}</span>}
        </Box>
      </GenericInput>
    );
  }
);
Password.displayName = 'Password';
export default React.memo(Password);
