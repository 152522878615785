import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './weeklyInspectionsReducer';

export const TruckWeeklyInspectionsContext = createContext();

export const useTruckWeeklyInspection = () =>
  useContext(TruckWeeklyInspectionsContext);

export const TruckWeeklyInspectionsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <TruckWeeklyInspectionsContext.Provider value={[state, dispatch]}>
      {children}
    </TruckWeeklyInspectionsContext.Provider>
  );
};
