/** @format */

import React from 'react';
import { Tab } from './style';

const TabsForForms = ({
  items,
  margin,
  padding,
  minHeight,
  width,
  onChange,
  defaultActiveKey,
  activeKey,
  fontSize,
}) => {
  return (
    <Tab
      tabBarStyle={{
        backgroundColor: '#f9fafb',
      }}
      horizontalItemPadding={'3px 0px'}
      fontSize={fontSize}
      activeKey={activeKey}
      defaultActiveKey={defaultActiveKey}
      items={items}
      margin={margin}
      padding={padding}
      minHeight={minHeight}
      width={width}
      onChange={onChange}
    />
  );
};

export default React.memo(TabsForForms);
