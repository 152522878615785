/** @format */

export const initialState = {
  dataList: [],
  refetch: () => {},
  fetching: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'refetch':
      return { ...state, refetch: action.payload };
    case 'setDataList':
      return { ...state, dataList: action.payload };

    case 'UPDATE_DRIVER_ADDRESS': {
      const { newAddress, unitId, nameId, dispatcherId } = action.payload;
      const upadetedDataList = state.dataList?.map((dispatcher) => {
        if (dispatcher?.dispatcherId !== dispatcherId) return dispatcher;
        const updatedDrivers = dispatcher?.drivers?.map((driver) => {
          if (driver.unitId !== unitId) return driver;
          return { ...driver, [nameId]: newAddress };
        });
        return { ...dispatcher, drivers: updatedDrivers };
      });
      return { ...state, dataList: upadetedDataList };
    }
    case 'UPDATE_COMMENT': {
      const { newComment, unitId, dispatcherId } = action.payload;
      const upadetedDataList = state.dataList?.map((dispatcher) => {
        if (dispatcher?.dispatcherId !== dispatcherId) return dispatcher;
        const updatedDrivers = dispatcher?.drivers?.map((driver) => {
          if (driver.unitId !== unitId) return driver;
          return { ...driver, comment: newComment };
        });
        return { ...dispatcher, drivers: updatedDrivers };
      });
      return { ...state, dataList: upadetedDataList };
    }
    case 'RE_ASSIGN_DISPATCHER': {
      const { newDispatcher, unitId, dispatcherId } = action.payload;
      const upadetedDataList = state.dataList?.map((dispatcher) => {
        if (dispatcher?.dispatcherId !== dispatcherId) return dispatcher;
        const updatedDrivers = dispatcher?.drivers?.map((driver) => {
          if (driver.unitId !== unitId) return driver;
          return { ...driver, dispatcher2: newDispatcher };
        });
        return { ...dispatcher, drivers: updatedDrivers };
      });
      return { ...state, dataList: upadetedDataList };
    }
    case 'REMOVE_DRIVER_FROM_LIST': {
      const { unitId, dispatcherId } = action.payload;
      const upadetedDataList = state.dataList?.map((dispatcher) => {
        if (dispatcher?.dispatcherId !== dispatcherId) return dispatcher;
        const updatedDrivers = dispatcher?.drivers?.filter(
          (data) => data.unitId !== unitId
        );
        return { ...dispatcher, drivers: updatedDrivers };
      });

      return { ...state, dataList: upadetedDataList };
    }
    default:
      return state;
  }
};
