import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './paInspectionsReducer';

export const TruckPAInspectionsContext = createContext();

export const useTruckPAInspections = () =>
  useContext(TruckPAInspectionsContext);

export const TruckPAInspectionsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <TruckPAInspectionsContext.Provider value={[state, dispatch]}>
      {children}
    </TruckPAInspectionsContext.Provider>
  );
};
