/** @format */

export const initialState = {
  page: 1,
  size: 50,
  total: 0,
  dataList: [],
  nightShiftList: [],
  totalsOfNightShift: {},
  data: [],
  id: '',
  refetch: () => { },
};

export const reducer = (state, action) => {
  let lng = action.payload.length;
  let sums = {
    totalGross: 0,
    avarageGross: 0,
    totalMile: 0,
    avarageMile: 0,
    rpm: 0,
  };
  switch (action.type) {
    case 'changePage':
      return { ...state, page: action.payload };
    case 'setSize':
      return { ...state, size: action.payload };
    case 'setTotal':
      return { ...state, total: action.payload };
    case 'setDataList':
      return {
        ...state,
        dataList: action.payload,
      };
    case 'setNightShiftList':
      return {
        ...state,
        nightShiftList: action.payload,
      };
    case 'CALCULATE_TOTALS_OF_NIGHTSHIFT':
      if (lng > 0) {
        for (let i = 0; i < lng; i++) {
          const item = action.payload[i];
          sums.totalGross += item?.gross || 0;
          sums.totalMile += item?.miles || 0;
          sums.rpm += item?.rpm || 0;
        }
        sums.totalGross = (sums.totalGross).toFixed(2);
        sums.totalMile = (sums.totalMile).toFixed(2);
        sums.avarageGross = (sums.totalGross / lng).toFixed(2);
        sums.avarageMile = (sums.totalMile / lng).toFixed(2);
        sums.rpm = (sums.totalGross / sums.totalMile).toFixed(2);
        return {
          ...state,
          totalsOfNightShift: sums,
        };
      }
      else {
        return {
          ...state,
          totalsOfNightShift: {},
        };
      }

    case 'setData':
      return { ...state, data: action.payload };
    case 'setRefetch':
      return { ...state, refetch: action.payload };

    case 'setId':
      return { ...state, id: action.payload };

    case 'isLoading':
      return { ...state, reload: action.payload };

    default:
      return state;
  }
};
