/** @format */

import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';

export const PermitContext = createContext();

export const usePermit = () => useContext(PermitContext);

export const PermitProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <PermitContext.Provider value={[state, dispatch]}>
      {children}
    </PermitContext.Provider>
  );
};
