import styled from 'styled-components';

export const MainWrapper = styled.div`
  .doc_types {
    .doc_types_title {
      height: 32px;
      font-size: 14px;
      color: #5c6470;
      background: rgba(1, 122, 255, 0.1);
      font-weight: 600;
      text-transform: uppercase;
      padding-left: 10px;
      display: flex;
      align-items: center;
      margin: 10px 0;
    }
  }
  &.hiring_history {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    .doc_types {
      flex: 1;
      .doc_types_title {
        height: 20px;
        font-size: 12px;
        margin: 0 0 5px;
      }
    }
  }
`;
