/** @format */

import { ReactComponent as selectArrow } from 'assets/icons/arrow-down.svg';

import { ReactComponent as zoneHeaderIcon } from 'assets/icons/zoneHeaderIcon.svg';

import styled from 'styled-components';
import { getSelectStyle } from '../../../css/functions';

export const ZoneHeaderIcon = styled(zoneHeaderIcon)``;

const StartsIn = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StatusPlaceholder = styled.div`
  color: ${(props) => getSelectStyle(props).fcolor} !important;
  background: ${(props) =>
    getSelectStyle(props).bg?.replace('0.05', '0.1')} !important;
  /* border: ${(props) => getSelectStyle(props).borderC} !important; */
  border-radius: 2px;
  text-align: left;
  min-width: ${({ minwidth }) => minwidth && minwidth};
  padding: 0 8px;
  width: ${({ width }) => width || '100%'};

  height: 20px;
  line-height: 20px;
  padding-right: 26px;
  position: relative;
  font-size: var(--appTableFontSize);
`;

StatusPlaceholder.Arrow = styled(selectArrow)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 11px;
  width: 12px;
  height: 12px;
  path {
    fill: ${(props) => getSelectStyle(props).fcolor};
  }
`;

const InputPlaceholder = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  width: 100%;
  height: ${({ height }) => (height ? height + 'px' : '100%')};
  padding: 0 11px;
  font-size: 12px;
  background: #fff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: ${({ center }) => center && 'center'};

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left !important;
    height: 100%;
    display: block;
    width: 100%;
    color: #bfbfbf;
    font-size: 12px;
  }
`;

const DriverData = styled.div`
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

DriverData.Name = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left !important;
  width: calc(100% - 20px);
`;

export { DriverData, InputPlaceholder, StartsIn, StatusPlaceholder };
