/** @format */
import * as Sentry from '@sentry/react';

export const initialState = {
  users: ['admin'],
  token: localStorage.getItem('token') || null,
  email: '',
  pageSize: '',
  role: [],
  roles: [],
  currentUser: {},
  permission: {},
  user: {},
  viewPermission: {},
  viewItems: {},
  preLoader: false,
  sideBar: true,
  isImageChanged: false,
};
function clearAllSiteData() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .getRegistrations()
      .then(function (registrations) {
        for (let registration of registrations) {
          registration.unregister();
        }
      })
      .catch(function (error) {
        // eslint-disable-next-line no-console
        console.error('Service worker unregistration failed: ', error);
      });
  }
  var cookies = document.cookie.split('; ');
  for (var c = 0; c < cookies.length; c++) {
    var d = window.location.hostname.split('.');
    while (d.length > 0) {
      var cookieBase =
        encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) +
        '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
        d.join('.') +
        ' ;path=';
      var p = location.pathname.split('/');
      document.cookie = cookieBase + '/';
      while (p.length > 0) {
        document.cookie = cookieBase + p.join('/');
        p.pop();
      }
      d.shift();
    }
  }

  localStorage.clear();

  sessionStorage.clear();

  var req = indexedDB.databases();
  req.onsuccess = function (event) {
    event.target.result.forEach(function (db) {
      indexedDB.deleteDatabase(db.name);
    });
  };

  if ('caches' in window) {
    caches.keys().then(function (keyList) {
      return Promise.all(
        keyList.map(function (key) {
          return caches.delete(key);
        })
      );
    });
  }

  if (window.applicationCache) {
    window.applicationCache.uncache('/');
  }
}

export const reducer = (state, action) => {
  switch (action.type) {
    case 'login':
      action.payload !== undefined &&
        localStorage.setItem('token', action.payload);
      return {
        ...state,
        token: action.payload,
      };

    case 'logout':
      localStorage.removeItem('token');
      localStorage.removeItem('firebaseToken');
      localStorage.removeItem('username');
      localStorage.removeItem('profile-image');
      localStorage.clear();
      clearAllSiteData();
      Sentry.setUser(null);
      return {
        ...state,
        token: null,
        role: '',
        currentUserId: '',
      };
    case 'role':
      return {
        ...state,
        role: action.payload,
      };
    case 'setUserRoles':
      return {
        ...state,
        roles: action.payload,
      };
    case 'setCurrentUser':
      return {
        ...state,
        currentUser: action.payload,
      };
    case 'setPageSize':
      return {
        ...state,
        pageSize: action.payload,
      };
    case 'setPermissions':
      return {
        ...state,
        permission: action.payload,
      };
    case 'setPreLoader':
      return {
        ...state,
        preLoader: action.payload,
      };

    case 'setUser':
      return {
        ...state,
        user: action.payload,
      };
    case 'setEmail':
      return {
        ...state,
        email: action.payload,
      };
    case 'setSideBar':
      return {
        ...state,
        sideBar: action.payload,
      };
    case 'setViewPermission':
      return {
        ...state,
        viewPermission: action.payload,
      };
    case 'setViewItems':
      return {
        ...state,
        viewItems: action.payload,
      };
    case 'setIsImageChanged':
      return {
        ...state,
        isImageChanged: action.payload,
      };
    default:
      return state;
  }
};
