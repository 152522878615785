/** @format */

import { Navigate } from 'react-router-dom';
import { useAuth } from 'context';

const PrivateRoute = ({ children }) => {
  let auth = useAuth();
  let { token } = auth[0];

  return token ? <>{children}</> : <Navigate to='/signin' replace />;
};

export default PrivateRoute;
