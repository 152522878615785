import { memo, useCallback, useEffect, useState } from 'react';
import { LoadsBoard, LoadsCount, MainContent } from './style';
import { PageWrapper } from 'globalCSS';
import { useRequest, useSetQuery, useSearch } from 'hooks';
import { Skleton } from 'loaders';
import { useLocation } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { Toast } from 'modals';

import { ReactComponent as Canceled } from 'assets/icons/canceled-circle.svg';
import { ReactComponent as Completed } from 'assets/icons/completed.svg';
import { ReactComponent as Dispatched } from 'assets/icons/dispatched.svg';
import { ReactComponent as Intransit } from 'assets/icons/in_transit.svg';
import { ReactComponent as LotTr } from 'assets/icons/lot-trailer.svg';
import { ReactComponent as Open } from 'assets/icons/exclamation-circle-outlined.svg';
import { ReactComponent as Recovered } from 'assets/icons/recovered.svg';

import { ArrowDouble, TickIcon, ClockIcon } from 'styledIcons';

export default memo(function PageWrapperWithCountedFilter({ children }) {
  const [loadsCount, setLoadsCount] = useState();
  const [total, setTotal] = useState();
  const [open, setOpen] = useState();
  // hooks
  const { request } = useRequest();
  const setQuery = useSetQuery();
  const query = useSearch();
  let { pathname } = useLocation();
  let path = pathname?.substring(7);

  const getCounts = useMutation({
    mutationFn: () => {
      return request({
        url: '/loads/count?types=AMAZON&types=MARKET_PLACE',
        method: 'GET',
      });
    },
    onSuccess: (res) => {
      const entries = res?.data && Object.entries(res?.data);
      let totalCount = 0;
      const formattedData = entries
        ?.map(([en, count], index) => {
          totalCount += count;
          return { id: index, value: en, count };
        })
        .filter(
          (s) =>
            ![
              'COMPLETED',
              'REJECTED',
              'CANCELED',
              'NEED_LOAD',
              'HISTORY',
            ].includes(s.value)
        );

      setTotal(totalCount);
      setLoadsCount(formattedData);
    },
    onError: ({ message }) => Toast({ type: 'error', message }),
  });

  useEffect(() => {
    if (open) {
      getCounts.mutate('');
    }
  }, [path, open]);

  const loadStatusHandler = useCallback(
    (value) => {
      setQuery('load-status', value === 'ALL' ? null : value);
    },
    [setQuery]
  );

  return (
    <PageWrapper>
      <LoadsCount.Wrapper open={open}>
        {!loadsCount?.length ? (
          <Skleton count={8} width='150px' />
        ) : (
          [{ id: 'uniqueId', value: 'ALL', count: total || 0 }]
            .concat(loadsCount)
            ?.map((l) => {
              return (
                <LoadsCount
                  open={
                    query?.get('load-status')
                      ? query?.get('load-status') == l?.value
                      : l?.value === 'ALL'
                  }
                  key={l?.id}
                  name={l?.value}
                  onClick={() => loadStatusHandler(l?.value)}
                >
                  {returnIcon(l?.value)}
                  {l?.value?.split('_')?.join(' ')}
                  <span className='count'> {l?.count}</span>
                </LoadsCount>
              );
            })
        )}
      </LoadsCount.Wrapper>
      <LoadsBoard dinamicheight={'true'} open={open}>
        <LoadsCount.BtnOpenChanger onClick={() => setOpen(!open)}>
          <ArrowDouble width='20px' height='20px' color='#374151' open={open} />
        </LoadsCount.BtnOpenChanger>
        <MainContent>{children}</MainContent>
      </LoadsBoard>
    </PageWrapper>
  );
});

const returnIcon = (value) => {
  const icons = {
    OPEN: <Open stroke='#FF0000' width='16px' height='16px' />,
    LATE: <Open stroke='#FF0000' width='16px' height='16px' />,
    TBD: <ClockIcon color='#FFC000' width='16px' height='16px' />,
    PLANNING: <ClockIcon color='#0167FF' width='16px' height='16px' />,
    RECOVERED: <Recovered width='16px' height='16px' />,
    CONFIRMED: <TickIcon color='#EC7F31' width='16px' height='16px' />,
    OTP: <TickIcon color='#00B533' width='16px' height='16px' />,
    OTD: <TickIcon color='#00B533' width='16px' height='16px' />,
    DISPATCHED: <Dispatched width='16px' height='16px' />,
    IN_TRANSIT: <Intransit width='16px' height='16px' />,
    COMPLETED: <Completed width='16px' height='16px' />,
    TR: <LotTr width='16px' height='16px' />,
    CAN: <Canceled width='16px' height='16px' />,
    REJ: <Canceled width='16px' height='16px' />,
    CANCELED: <Canceled width='16px' height='16px' />,
    REJECTED: <Canceled width='16px' height='16px' />,
    ALL: null,
  };
  return icons[value] || <Dispatched width='16px' height='16px' />;
};
