/** @format */

import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';

import { useRequest, useSearch, useSetMultiQuery } from 'hooks';

import { FilterMultiSelect } from 'filters';

export const FilterCompany = () => {
  const query = useSearch();
  // const setQuery = useSetQuery();
  const setMultiQuery = useSetMultiQuery();

  const [companyCodes, setCompanyCodes] = useState([]);
  const { request } = useRequest();
  const getCmpns = useMutation({
    mutationFn: () => request({ url: '/v3/load/company_codes' }),
  });

  const getCompanies = () => {
    if (!companyCodes?.length || !companyCodes) {
      getCmpns.mutate('', {
        onSuccess: (res) => {
          let newData = res?.dataList?.map(({ name, description }) => ({
            key: description,
            id: name,
            value: name,
          }));
          setCompanyCodes(newData);
        },
      });
    }
  };

  const onChange = (value) => {
    setMultiQuery('company-code', value);
  };

  return (
    <FilterMultiSelect
      maxTagCount='responsive'
      options={companyCodes}
      onChange={onChange}
      value={query.getAll('company-code') || 'All'}
      onFocus={getCompanies}
      notFoundContent={getCmpns?.isLoading ? 'Loading...' : 'No data found!!'}
    />
  );
};
