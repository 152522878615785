/** @format */

import styled from 'styled-components';

export const SelectWrapper = styled.div`
  width: 100%;
  border: none;
  #statusSwitch {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 8px;
    height: ${({ height }) => height || '24px'};
    width: ${({ height }) => (height ? 'calc(' + height + ' * 2)' : '48px')};
    min-width: ${({ height }) =>
      height ? 'calc(' + height + ' * 2)' : '48px'};
  }
  .ant-switch-checked {
    background: #00b238;
  }
  .ant-switch-handle {
    top: 50%;
    transform: translateY(-50%);
    left: 3px;
    width: ${({ diameter }) => diameter || '18px'} !important;
    height: ${({ diameter }) => diameter || '18px'} !important;
    &::before {
      width: ${({ diameter }) => diameter || '18px'};
      height: ${({ diameter }) => diameter || '18px'};
    }
  }
  .ant-switch-checked .ant-switch-handle {
    left: ${({ diameter }) =>
      diameter ? 'calc(100% - ' + diameter + ' - 3px)' : 'calc(100% - 21px)'};
    inset-inline-start: ${({ diameter }) =>
      diameter
        ? 'calc(100% - ' + diameter + ' - 3px)'
        : 'calc(100% - 21px)'} !important;
  }
`;

export const StatusText = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: ${({ right }) => (right ? right : '8px')};
  left: ${({ left }) => left && left};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #313e47 !important;
`;
StatusText.Content = styled.div``;
StatusText.ContentTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
`;
StatusText.ContentSubTitle = styled.div`
  font-size: 14px;
  span {
    font-weight: 600;
    font-size: 14px;
  }
`;

export const Label = styled.p`
  text-align: left;
  font-family: ${({ fontfamily }) =>
    fontfamily ? fontfamily : 'var(--appPrimaryFont)'};
  margin: ${({ margin }) => margin};
  font-style: normal;
  font-weight: ${({ fontweight }) => (fontweight ? fontweight : 600)};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '15px')};
  line-height: ${({ lineheight }) => (lineheight ? lineheight : '16px')};
  text-transform: ${({ texttransform }) =>
    texttransform ? texttransform : 'uppercase'};
  color: ${({ color }) => (color ? color : '#5c6470')};
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  border: none;
  width: ${({ width }) => width || '220px'};
  height: 32px;
  align-items: center;
  grid-gap: 16px;
  justify-content: center;
  margin-left: auto;
  border-radius: 5px;
  background-color: ${({ bgcolor }) => bgcolor};
`;
