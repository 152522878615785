/** @format */

import styled from 'styled-components';
import { InputLabel } from 'globalCSS';
import { Select } from '../../AntDesign';
const { Option } = Select;
export const SelectWrapper = styled('div')`
  width: ${({ width }) => width || '100%'};

  display: flex;
  flex-direction: column;
  .ant-select-selector {
    border: ${({ border }) => border || '1px solid #c4c4c4'} !important;
  }
  .ant-select-selector .ant-select-selection-item {
    font-size: ${({ fsize }) => fsize || 'var(--appFilterFontSize)'};
    ${
      '' /* border: ${({ border }) => border || '1px solid #c4c4c4'} !important; */
    }
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    font-size: ${({ fsize }) => fsize || 'var(--appFilterFontSize)'};
    ${
      '' /* border: ${({ bordercolor }) => `1px solid ${bordercolor || '#c4c4c4'}`}; */
    }
    ${
      '' /* border: ${({ border }) => border || '1px solid #c4c4c4'} !important; */
    }
  }
  ${'' /* } */}
`;
export const SelectLable = styled(InputLabel)``;
export const StyledSelect = styled(Select)``;

export const StyledOpt = styled(Option)``;
