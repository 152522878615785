/** @format */

import { DateRangePicker } from 'forms';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSearch, replaceParams } from 'hooks';
import { dayjs, customParseFormat } from 'day-js';
dayjs.extend(customParseFormat);
// fitler input

export const FilterDateRange = (key1, key2, format, month, year) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const query = useSearch();

  const onChange = (dates) => {
    if (!dates) {
      navigate(`${pathname}${replaceParams(key1, '')}`);
      navigate(`${pathname}${replaceParams(key2, '')}`);
    }

    if (dates?.length === 2) {
      navigate(`${pathname}${replaceParams('page', null)}`);
      navigate(`${pathname}${replaceParams(month, '')}`);
      navigate(`${pathname}${replaceParams(year, '')}`);
      navigate(
        `${pathname}${replaceParams(
          key1,
          dates[0]
            ? dayjs(dates[0]).format(format || 'YYYY-MM-DDT00:00:00[Z]')
            : ''
        )}`
      );
      navigate(
        `${pathname}${replaceParams(
          key2,
          dates[1]
            ? dayjs(dates[1]).format(format || 'YYYY-MM-DDT23:59:59[Z]')
            : ''
        )}`
      );
    }
  };
  return (
    <DateRangePicker
      format='MM/DD/YYYY'
      showTime={false}
      onCalendarChange={onChange}
      height='28px'
      margin='0px'
      defaultValue={[
        query.get(key1) ? dayjs(query.get(key1)) : null,
        query.get(key2) ? dayjs(query.get(key2)) : null,
      ]}
    />
  );
};

export default FilterDateRange;
