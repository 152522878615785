/** @format */

import React, { useEffect } from 'react';
import Button from '../Button';
import ConfirmBeforeLeaving from '../ConfirmBeforeLeaving';

export const Buttons = ({
  onCancel,
  textSave,
  textCancel,
  isLoading,
  disabled,
  onSave,
  stateChange,
  closeDrawer,
  titleSaveBtn,
  refetch,

  ...props
}) => {
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    if (typeof isLoading === 'boolean') {
      setLoading(isLoading);
    }
  }, [isLoading]);

  const handleSave = () => {
    onSave && onSave();
  };
  const handleCancel = () => {
    onCancel && onCancel();
    closeDrawer && closeDrawer();
    refetch && refetch();
  };
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        gridGap: '16px',
      }}
    >
      {stateChange ? (
        <ConfirmBeforeLeaving
          onConfirm={handleCancel}
          onCancel={() => {}}
          placement='bottomRight'
          zIndex='9999'
        >
          <Button stateChange={stateChange} {...props} id='cancel'>
            {textCancel || 'Cancel'}
          </Button>
        </ConfirmBeforeLeaving>
      ) : (
        <span onClick={handleCancel}>
          <Button id='cancel' {...props}>
            {textCancel || 'Cancel'}
          </Button>
        </span>
      )}

      <Button
        btnType='save'
        onClick={handleSave}
        fontSize='12px'
        disabled={disabled || loading}
        title={titleSaveBtn}
        {...props}
      >
        {textSave || 'Save'}
      </Button>
    </div>
  );
};

export default Buttons;
