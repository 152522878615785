/** @format */

export const RateTypeRenderer = ({ data }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        height: '100%',
      }}
      title={data?.rateType?.replaceAll('-', ' ')}
    >
      {data?.rateType?.replaceAll('-', ' ')}
    </div>
  );
};
