/** @format */

export const initialState = {
  page: 1,
  size: 50,
  total: 0,
  connections: [],
  filtered: {},
  drawerOpen: false,

  selected: {},
  closeDrawer: () => {},
  refetch: () => {},
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'changePage':
      return { ...state, page: action.payload };
    case 'setSize':
      return { ...state, size: action.payload };
    case 'setTotal':
      return { ...state, total: action.payload };
    case 'setConnections':
      return { ...state, connections: action.payload };
    case 'setFiltered':
      return { ...state, filtered: action.payload };

    case 'setDrawerOpen':
      return { ...state, drawerOpen: action.payload };
    case 'setRefetch':
      return { ...state, refetch: action.payload };
    case 'refetch':
      return { ...state, refetch: action.payload };
    case 'closeDrawer':
      return { ...state, closeDrawer: action.payload };
    case 'setSelected':
      return { ...state, selected: action.payload };
    default:
      return state;
  }
};
