import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';

export const ChangeContext = createContext();

export const useChange = () => useContext(ChangeContext);

export const ChangeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ChangeContext.Provider value={[state, dispatch]}>
      {children}
    </ChangeContext.Provider>
  );
};
