/** @format */

import { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';

import { useRequest, useQuery } from 'hooks';

import { useLoads } from 'context';
import { capitalizeFirstLetter } from 'formatter';

import { CellSelect } from 'forms';
import { Toast } from 'modals';

export const StatusChangerRenderer = ({ data }) => {
  const { request } = useRequest();
  const [selectOptions, setSelectOptions] = useState();

  const [status, setStatus] = useState(data?.loadStatus);
  const [hasFocused, setHasFocused] = useState(false);
  const [{ refetch }] = useLoads();

  const saveAssign = useMutation({
    mutationFn: (value) =>
      request({
        url: `/loads/change-load-status/${encodeURIComponent(
          data?.id
        )}?loadStatus=${value}`,
        method: 'POST',
      }),
  });
  const handleSelectChange = (value) => {
    setStatus(value?.replaceAll('_', ' '));
    saveAssign?.mutate(value, {
      onSuccess: (res) => {
        if (res?.success) {
          Toast({ type: 'success', message: res?.message });
          refetch();
        }
      },
      onError: ({ message }) => Toast({ type: 'error', message }),
    });
  };

  useEffect(() => {
    setStatus(data?.loadStatus);
  }, [data]);
  useQuery(
    ['/loads/load_status/list', hasFocused],
    () =>
      !selectOptions?.length &&
      hasFocused &&
      request({ url: '/loads/load_status/list' }),
    {
      onSuccess: (res) => {
        if (res?.success) {
          let newData = res?.data?.map((value) => {
            return { key: capitalizeFirstLetter(value), value: value };
          });
          setSelectOptions(newData);
        }
      },
      onError: ({ message }) => Toast({ type: 'error', message }),
    }
  );
  return (
    <CellSelect
      onFocus={() => {
        setHasFocused(true);
      }}
      selectType='colored_cell_select'
      status={status || data?.loadStatus}
      borderless='true'
      height='inherit'
      options={selectOptions}
      onSelect={(value, opt) => handleSelectChange(value, opt)}
      value={
        capitalizeFirstLetter(status) || capitalizeFirstLetter(data?.loadStatus)
      }
    />
  );
};
