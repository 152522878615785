import { ReactComponent as Distribute } from 'assets/icons/distribute.svg';
import { ReactComponent as Download } from 'assets/icons/download.svg';
import { ReactComponent as Upload } from 'assets/icons/upload-2-line.svg';
import styled from 'styled-components';

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  width: fit-content;
  background: #f9fafb;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  &:hover {
    border-top: 1px solid #e5e7eb;
    border-bottom: 1px solid #e5e7eb;
    color: #1893ff;
    svg path {
      fill: #1893ff;
    }
  }
`;

export const DownloadingSpinner = styled.img`
  width: 18px;
  height: 18px;
`;

MenuItem.Distribute = styled(Distribute)`
  min-width: 20px;
  width: 20px;

  path {
    fill: #374151;
  }
`;
MenuItem.Upload = styled(Upload)`
  min-width: 20px;
  width: 20px;

  path {
    fill: #374151;
  }
`;
MenuItem.Download = styled(Download)`
  min-width: 20px;
  width: 20px;

  path {
    fill: #374151;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: fit-content;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.06));
  border-radius: 4px;
`;
Container.Title = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 500;
  font-size: 13px;
  line-height: 10px;
  cursor: pointer;
  width: fit-content;
  text-align: center;
  color: #374151;
  :hover {
    text-decoration: underline;
  }
`;
