import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './performanceReducer';
export const PerformanceContext = createContext();
export const usePerformance = () => useContext(PerformanceContext);

export const PerformanceProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <PerformanceContext.Provider value={[state, dispatch]}>
      {children}
    </PerformanceContext.Provider>
  );
};
