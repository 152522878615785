/** @format */

import styled from 'styled-components';
import { InputLabel } from 'globalCSS';
import { AutoComplete } from '../../AntDesign';
const { Option } = AutoComplete;
export const AutoCompleteWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  .ant-select-selector {
    border: ${({ border }) => border || '1px solid #c4c4c4'} !important;
  }
`;

export const Label = styled(InputLabel)``;

export const StyledAutoComplete = styled(AutoComplete)``;

StyledAutoComplete.Option = styled(Option)``;
