/** @format */

import styled from 'styled-components';
import { DatePicker } from '../../AntDesign';
import { InputLabel } from 'globalCSS';

const { RangePicker } = DatePicker;

export const MainDatePickerWrapper = styled('div')`
  .ant-picker {
    border-radius: 2px;
    border: ${({ border }) => border || '1px solid #rgb(0 0 0)'};
  }
`;

export const DatePickerLabel = styled(InputLabel)``;

export const DateRangePicker = styled(RangePicker)`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => (height ? height : '32px')};
  min-width: ${({ minwidth }) => minwidth && minwidth};
  background: ${({ bgColor }) => bgColor && bgColor};
  input {
    font-size: ${({ fontSize }) => fontSize && fontSize};
  }
`;
