/** @format */
const originStyle = {
  whiteSpace: 'wrap',
  textAlign: 'left',
  height: 'inherit',
  display: 'flex',
  alignItems: 'center',
  lineHeight: 1.1,
};
export const pickUpDateRenderer = ({ data }) => {
  return <div style={originStyle}>{data?.startDateFormat}</div>;
};
