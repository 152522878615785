import React from 'react';

const FuelManagement = React.lazy(() => import('./FuelManagement'));
const FuelStates = React.lazy(() => import('./FuelStates'));
const FuelStations = React.lazy(() => import('./FuelStations'));
const FuelExpense = React.lazy(() => import('./FuelExpense'));
const FuelDashboard = React.lazy(() => import('./FuelDashboard'));
const Exist = React.lazy(() => import('./Exist'));

export {
  FuelManagement,
  FuelStates,
  FuelStations,
  FuelExpense,
  FuelDashboard,
  Exist,
};
