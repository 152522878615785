/** @format */

import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';

export const OriginalContractContext = createContext();

export const useOriginalContract = () => useContext(OriginalContractContext);

export const OriginalContractPovider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <OriginalContractContext.Provider value={[state, dispatch]}>
      {children}
    </OriginalContractContext.Provider>
  );
};
