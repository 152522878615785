import styled from 'styled-components';
import { ReactComponent as Attachment } from 'assets/icons/attachment.svg';
import { ReactComponent as download } from 'assets/icons/download.svg';

export const Wrapper = styled('div')``;
Wrapper.Box = styled('div')`
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
`;

Wrapper.Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px dashed #f0f2fa;
  padding: 8px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #111;

  .value {
    color: #121929;
  }
`;

Wrapper.Color = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 3px;
  background: ${({ color }) => color || ''};
  margin-right: 15px;
  border: 1px solid #f0f2fa;
`;

Wrapper.Title = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #121929;
`;

export const DownloadIcon2 = styled(download)`
  width: ${({ width }) => width || '25px'};
  height: ${({ width }) => width || '25px'};
  cursor: pointer;
  path {
    fill: ${({ color }) => color};
  }
  opacity: 0.7;
  padding: ${({ padding }) => padding};
  &:hover {
    opacity: 1;
  }
`;

export const AttachmentIcon = styled(Attachment)`
  width: 21px;
  height: 30px;
  path {
    fill: #fff;
  }
`;

Wrapper.File = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  overflow: hidden;
  margin-bottom: 10px;
  background-color: #5c6470;
  padding: 7px;
  margin-right: 10px;
  border-radius: 10px;
`;

Wrapper.FileInfo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
`;
Wrapper.FileInfoText = styled.div`
  width: 45px;
  font-size: 14px;
  color: #fff;
  div.turncate {
    width: calc(90%);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
  }
`;
