import styled from 'styled-components';
import { Modal } from 'antd';

export const Wrapper = styled.div`
  background-color: #fff;
  padding: ${({ noPadding }) => (noPadding ? '0' : '0 20px')};
  padding-top: ${({ paddingtop }) => paddingtop || '43px'};
  position: relative;
  gap: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* min-width: 1450px; */
  overflow: auto;
  position: ${({ position }) => (position ? 'absolute' : 'relative')};
`;

export const AntModal = styled(Modal)`
  overflow: auto;
  padding: 0 20px 20px 20px;
  .ant-modal-footer {
    display: none;
  }
`;
