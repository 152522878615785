/** @format */

import { useEffect, useState } from 'react';
import { Container, InputWrapper, Wrapper, MessageWrap } from './style';
import { Logo } from 'files';
import { useAuth } from 'context';
import { Button } from 'forms';
import { useRequest } from 'hooks';
import OtpInput from 'react-otp-input';
import { useMutation } from '@tanstack/react-query';
import { Toast } from 'modals';
import { Loading } from 'loaders';
import { useNavigate } from 'react-router';
import { setCookie, getCookie } from 'formatter';
import { sidebarController } from '../../../../root/sidebarView';
// import avatar from 'assets/imgs/user-avatar2.png';

const VerifyOtp = ({ setActivePath }) => {
  const [, dispatch] = useAuth();
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const { request } = useRequest();
  const [errorMessage, setErrorMessage] = useState('');
  const [errorOtp, setErrorOtp] = useState('');
  const { VITE_APP_BASE_ME_URL } = import.meta.env;
  const [otp, setOpt] = useState('');

  useEffect(() => {
    setActivePath([]);
    Toast({
      type: 'success',
      message: 'Verification code has been sent to your email.',
    });
    () => {};
  }, []);

  const getUserMe = useMutation({
    mutationFn: () => {
      return request({ baseurl: VITE_APP_BASE_ME_URL });
    },

    onSuccess: (res) => {
      dispatch({
        type: 'setPermissions',
        payload: res?.meta?.permissions,
      });
      localStorage.setItem('tokenExpireDate', res?.meta?.tokenExpireDate);
      localStorage.setItem('username', res?.meta?.fullname);
      localStorage.setItem('nickname', res?.meta?.nickname);
      localStorage.setItem('userId', res?.meta?.id);
      setCookie('username', res?.meta?.fullname, 1);
      setCookie('email', res?.meta?.email, 1);
      setCookie('nickname', res?.meta?.nickname, 1);
      setCookie('userId', res?.meta?.id, 1);
      dispatch({
        type: 'setUser',
        payload: {
          id: res?.meta?.id,
          email: res?.meta?.email,
          fullname: res?.meta?.fullname,
          incomplete: res?.meta?.incomplete,
          nickname: res?.meta?.nickname,
        },
      });
      let viewItems = sidebarController(res?.meta?.permissions);
      dispatch({ type: 'setViewItems', payload: viewItems });
      dispatch({
        type: 'setUserRoles',
        payload: res?.meta?.roles,
      });
      navigate(`${res?.meta?.mainUrl}`);
    },
    onError: () => {
      navigate('/signin');
    },
  });
  // const getLastProfileImage = useMutation({
  //   mutationFn: () => {
  //     () => {
  //       return (
  //         token &&
  //         request({
  //           url: '/user/get-last-profile-image',
  //           method: 'GET',
  //         })
  //       );
  //     };
  //   },
  // });

  const { mutateAsync } = useMutation({
    mutationFn: (params) => {
      return request({
        url: '/auth/otp-code/verification',
        method: 'POST',
        body: params,
      });
    },
  });

  const handleSubmit = () => {
    setLoad(true);
    if (errorOtp === otp && otp.length !== 0) {
      setLoad(false);
      setErrorMessage(errorMessage);
    } else if (
      errorMessage === 'Verification CODE required' &&
      otp.length === 0
    ) {
      setLoad(false);
      setErrorMessage(errorMessage);
    } else {
      if (otp?.length < 6 && 0 !== otp?.length) {
        setLoad(false);
        setErrorMessage('Verification Code is not full!');
      } else {
        mutateAsync(
          {
            email: getCookie('user-email'),
            code: otp,
            platform: 'WEB_DASHBOARD',
          },
          {
            onSuccess: async (res) => {
              if (res?.success && res?.data?.resetPassword) {
                setLoad(false);
                // dispatch({ type: 'setPreLoader', payload: false });
                // dispatch({ type: 'setEmail', payload: res?.data?.email });
                localStorage.setItem('user-email', res?.data?.email);
                localStorage.setItem(
                  'reset-password',
                  res?.data?.resetPassword
                );

                Toast({
                  type: 'success',
                  message: 'You need to set new password.',
                });
                navigate('/reset-password');
              }
              if (res?.success && !res?.data?.resetPassword) {
                // dispatch({ type: 'login', payload: res.meta.token });
                setLoad(false);
                getUserMe?.mutateAsync();
                // getLastProfileImage?.mutate(
                //   {},
                //   {
                //     onSuccess: (response) => {
                //       if (response?.success) {
                //         localStorage.setItem(
                //           'profile-image',
                //           response?.data?.path ? response?.data?.path : avatar
                //         );
                //       }
                //     },
                //   }
                // );
                // dispatch({ type: 'setPreLoader', payload: false });
                navigate('/main/all');
              }
            },
            onError: (err) => {
              if (!err?.success) {
                setErrorOtp(otp);
                setLoad(err?.success);
                setErrorMessage(err?.message);
              }
              setLoad(false);
              // dispatch({ type: 'setPreLoader', payload: false });

              Toast({
                type: 'error',
                message:
                  err?.message || 'Something went wrong while signing in!',
              });
            },
          }
        );
      }
    }
  };

  const onChange = (OTP) => {
    setErrorMessage('');
    setOpt(OTP);
    if (OTP?.length === 6) {
      setLoad(false);
    }
  };
  const h4Style = {
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '33px',
    color: '#313E47',
  };
  const pStyle = {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#313E47',
  };

  const goBack = () => {
    navigate('/signin');
    // <Navigate to='/signin' replace={true} />;
  };

  return (
    <Container>
      <Logo />
      <Wrapper>
        <InputWrapper>
          <div style={{ width: '395px', textAlign: 'center' }}>
            <h4 style={h4Style}>Verification code</h4>
            <p style={pStyle}>
              Please enter the one time password to verify your account. A code
              has been sent to your email.
            </p>
          </div>
        </InputWrapper>
        <InputWrapper>
          <OtpInput
            numInputs={6}
            separator={<span className='otpSep' />}
            inputStyle='inputStyles'
            focusStyle='inputFocusStyle'
            onChange={onChange}
            renderInput={(props) => <input {...props} />}
            value={otp}
          />
        </InputWrapper>
        <MessageWrap>
          <>{errorMessage}</>
        </MessageWrap>

        <InputWrapper>
          <Button width='348px' margin='15px 0' onClick={handleSubmit}>
            {load ? (
              <Loading
                margin='10px'
                color='#fff'
                size='default'
                height={'384px'}
                fontsize='22px'
              />
            ) : (
              <div>Verify</div>
            )}
          </Button>
        </InputWrapper>
        <InputWrapper>
          <div
            style={{
              display: 'flex',
              textAlign: 'center',
              gridGap: 3,
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <div>Go back to</div>{' '}
            <div className='goBackToSignIn' onClick={goBack}>
              {' '}
              Singin page
            </div>
          </div>
        </InputWrapper>
      </Wrapper>
    </Container>
  );
};

export default VerifyOtp;
