import { memo, useEffect, useState } from 'react';
import { Marker } from '@react-google-maps/api';

const MarkerByAddress = ({ icon, handleOpen, point, ...props }) => {
  const [point1, setPoint] = useState({});

  function isValidAddress(address) {
    if (typeof address === 'string') {
      return address.trim() !== '';
    } else if (typeof address === 'object' && address !== null) {
      return typeof address.lat === 'number' && typeof address.lng === 'number';
    }
    return false;
  }

  useEffect(() => {
    if (!isValidAddress(point?.address)) {
      //   setNoGpsData(true);
      return;
    } else {
      geocodeAddress(point?.address)
        .then((coordinates) => {
          setPoint(coordinates);
        })
        .catch((error) => {
          //   setNoGpsData(true);

          // eslint-disable-next-line no-console
          console.log(error, 'error');
        });
    }
  }, [point]);
  if (point1?.lat && point1?.lng)
    return (
      <Marker position={point1} icon={icon} onClick={handleOpen} {...props} />
    );
  return null;
};

export default memo(MarkerByAddress);

export async function geocodeAddress(address) {
  const apiKey = import.meta.env?.VITE_APP_GOOGLE_API_KEY;
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
    address
  )}&key=${apiKey}`;

  try {
    const response = await fetch(url);
    const data = await response.json();

    if (data.status === 'OK') {
      const location = data.results[0].geometry.location;
      return location;
    } else {
      // setNoGpsData(true);
      throw new Error('Geocoding failed: ' + data.status);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Geocoding error:', error);
    //   setNoGpsData(true);
    throw error;
  }
}
