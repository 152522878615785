import styled from 'styled-components';
import { ReactComponent as Eye } from 'assets/icons/eye.svg';
import { ReactComponent as Key } from 'assets/icons/key.svg';
import { ReactComponent as EyeSlash } from 'assets/icons/eye-slash.svg';

export const Wrapper = styled.div`
  width: 100%;
  border-radius: 5px;
  background: #fff;
`;

export const InputsWrapper = styled.div`
  width: 100%;
  padding: 0px 32px 8px;
  background: #ffffff;
  border-radius: 6px;
`;

export const Password = styled.div`
  position: absolute;
  z-index: 3;
  align-items: center;
  top: 16px;
  right: 10px;
`;

Password.Eye = styled(Eye)`
  width: ${({ eye }) => (eye === 'true' ? '15px' : '0px')};
  cursor: pointer;
  height: 40px;
  transition: all 0.2s ease;
`;
Password.EyeSlash = styled(EyeSlash)`
  width: ${({ eye }) => (eye === 'true' ? '0px' : '15px')};
  cursor: pointer;
  height: 40px;
  transition: all 0.2s ease;
`;
Password.Key = styled(Key)`
  width: 15px;
  cursor: pointer;
  height: 40px;
  transition: all 0.2s ease;
`;

export const InputsBox = styled.div`
  /* display: flex; */
  width: 100%;
  grid-column-gap: 30px;
  grid-row-gap: 14px;
  justify-content: ${({ right }) => (right ? 'flex-end' : 'space-between')};

  #button {
    margin-top: 36px;
    align-self: end;
  }

  @media (max-width: 820px) {
    flex-direction: column;
  }
`;

export const InputWrapper = styled.div`
  margin-top: 14px;
  position: relative;
  width: 100%;
  .ant-select-arrow {
    display: none;
  }

  @media (max-width: 820px) {
    width: 100%;
  }
  @media (max-width: 820px) {
    input {
      width: 100%;
    }
  }
`;

InputWrapper.Warn = styled('p')`
  visibility: ${({ incorrect }) => (incorrect ? 'visible' : 'none')};
  opacity: ${({ incorrect }) => (incorrect ? 1 : 0)};
  color: red;
  font-size: 12px;
  transition: all 0.3s ease-in-out;
`;
