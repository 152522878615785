/** @format */

import { useEffect, useState } from 'react';

import { EditIcon } from 'styledIcons';
import { useSetQuery } from 'hooks';

import { useLoads, useAuth } from 'context';

import { DriverData } from '../style';
const originStyle = {
  whiteSpace: 'wrap',
  textAlign: 'left',
  height: 'inherit',
  display: 'flex',
  alignItems: 'center',
  lineHeight: 1.1,
};
export const EditDriver = ({ data }) => {
  const setQuery = useSetQuery();
  const [{ permission }] = useAuth();
  const [{ driverAssigne }, dispatch] = useLoads();
  const [drivers, setDerivers] = useState(data?.driverName);

  const DriverView = () => {
    if (data?.driverId || data?.coDriverId)
      setQuery('driver-info-panel', 'visible');
    setQuery('driver-id', data?.driverId);
    setQuery('co-driver-id', data?.coDriverId);
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    dispatch({ type: 'openAssigner', payload: true });
    dispatch({ type: 'setLoadId', payload: data?.id });
    dispatch({ type: 'setLoadNumber', payload: data?.loadId });
    dispatch({ type: 'assignedDr', payload: data?.driverName });
  };

  useEffect(() => {
    if (
      driverAssigne?.[data?.loadId] &&
      driverAssigne?.[data?.loadId] !== data?.driverName &&
      drivers === data?.driverName
    )
      setDerivers(driverAssigne?.[data?.loadId]);
    else if (data?.driverName != drivers) setDerivers(data?.driverName);

    return () => {};
  }, [data?.driverName, driverAssigne?.[data?.loadId]]);

  return (
    <DriverData
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
      }}
      onClick={() =>
        permission?.['HR Management']?.Driver?.VIEW ? DriverView() : null
      }
    >
      <DriverData.Name style={originStyle} title={drivers || ''}>
        {drivers || ''}
      </DriverData.Name>

      <EditIcon
        title='Click to assign a unit'
        width='10px'
        height='10px'
        style={{ marginLeft: 'auto' }}
        onClick={(e) => handleEdit(e)}
      />
    </DriverData>
  );
};
