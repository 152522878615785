/** @format */

import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';

export const TrailerInspectionsContext = createContext();

export const useTrailerInspections = () =>
  useContext(TrailerInspectionsContext);

export const TrailerInspectionsPovider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <TrailerInspectionsContext.Provider value={[state, dispatch]}>
      {children}
    </TrailerInspectionsContext.Provider>
  );
};
