import styled from 'styled-components';

const Container = styled.div`
  .Toastify__toast {
    min-height: fit-content !important;
  }
  .Toastify__toast-body {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 22px;
    min-height: 22px;
    align-items: start;
    height: fit-content;
  }
  .Toastify__close-button {
    min-height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Toastify__close-button > svg {
    height: 12px;
    width: 12px;
    fill: currentColor;
  }
  .Toastify__progress-bar {
    min-height: 2px;
    height: 2px;
  }
  .Toastify__toast-icon.Toastify--animate-icon.Toastify__zoom-enter {
    margin-top: 4px;
    width: 14px;
  }
  .success {
    border: 1px solid #00b533;
  }
  .info {
    border: 1px solid #04a6fb;
  }
  .warn {
    border: 1px solid #ffc000;
  }
  .error {
    /* background: #fff2f2; */
    border: 1px solid #ff0000;
  }
  .networkOn {
    color: #313e47;
    width: 200px;
    min-height: 38px;
    height: fit-content;
    border-radius: 2px;
    background: #f2fbf5;
    border: 1px solid #00b533;
  }
  .networkOff {
    color: #313e47;
    width: 200px;
    min-height: 38px;
    height: fit-content;
    border-radius: 2px;
    background: #fff2f2;
    border: 1px solid #ff0000;
  }
`;

export {Container};
