export const initialState = {
  page: 1,
  size: 50,
  total: 0,
  locations: [],
  zoneTypes: [],
  filtered: {},
  permission: {},
  refetch: '',

  drawerOpen: false,
  selected: {},
  saveFunction: () => {},
  onCancelFunction: () => {},
  stateChange: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'changePage':
      return { ...state, page: action.payload };
    case 'setSize':
      return { ...state, size: action.payload };
    case 'setTotal':
      return { ...state, total: action.payload };
    case 'setSaveFunction':
      return { ...state, saveFunction: action.payload };
    case 'setOnCancelFunction':
      return { ...state, onCancelFunction: action.payload };
    case 'setStateChange':
      return { ...state, stateChange: action.payload };
    case 'setLocations':
      return { ...state, locations: action.payload };
    case 'setFiltered':
      return { ...state, filtered: action.payload };
    case 'setPermission':
      return { ...state, permission: action.payload };
    case 'setRefetch':
      return { ...state, refetch: action.payload };

    case 'setDrawerOpen':
      return { ...state, drawerOpen: action.payload };
    case 'setSelected':
      return { ...state, selected: action.payload };
    case 'setZones':
      return { ...state, zoneTypes: action.payload };
    default:
      return state;
  }
};
