/** @format */

export const initialState = {
  page: 1,
  size: 50,
  total: 0,
  lastUpdate: '',
  average: 0,
  data: [],
  permission: {},
  refetch: () => {},
  saveFunction: () => {},
  onCancelFunction: () => {},
  stateChange: false,
  modalName: '',
  attachments: [],
  fuelManagementList: [],
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'setDocs': {
      if (action.own === 'true') {
        return {
          ...state,
          attachments: action.payload,
        };
      }

      let formattedData = action?.payload?.map((d) => {
        return {
          attachmentId: d?.attachmentId || d.uploadedFile?.attachmentId,
          date: d?.date,
          docTypeId: d?.docTypeId,
          extension: d?.extension,
          name: d?.name,
          notes: d?.notes,
          path: d?.path || d?.uploadedFile?.path,
          size: d?.size,
          uploadedFile: d?.uploadedFile || [],
        };
      });

      formattedData = state?.attachments?.length
        ? formattedData?.concat(state?.attachments)
        : formattedData;

      return {
        ...state,
        attachments: formattedData,
      };
    }
    case 'changePage':
      return { ...state, page: action.payload };
    case 'setData':
      return { ...state, data: action.payload };
    case 'setLastUpdate':
      return { ...state, lastUpdate: action.payload };
    case 'setAverage':
      return { ...state, average: action.payload };
    case 'setSize':
      return { ...state, size: action.payload };
    case 'setTotal':
      return { ...state, total: action.payload };
    case 'setFuelManagement':
      return { ...state, fuelManagementList: action.payload };

    case 'setPermission':
      return { ...state, permission: action.payload };
    case 'setSaveFunction':
      return { ...state, saveFunction: action.payload };
    case 'setOnCancelFunction':
      return { ...state, onCancelFunction: action.payload };
    case 'setStateChange':
      return { ...state, stateChange: action.payload };
    case 'setRefetch':
      return { ...state, refetch: action.payload };
    case 'setModalName':
      return { ...state, refetch: action.payload };
    default:
      return state;
  }
};
