/** @format */

import {
  Suspense,
  lazy,
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  GoogleMap,
  Marker,
  MarkerClusterer,
  DirectionsRenderer,
  DirectionsService,
  InfoWindow,
  Autocomplete as GoogleAutocomplete,
} from '@react-google-maps/api';
import { useMutation } from '@tanstack/react-query';
import { Popover } from 'antd';
import { dayjs } from 'day-js';

import chartImg from 'assets/icons/Blue_circle.png';
import chartImgRed from 'assets/icons/Red_circle.png';
import intransit from 'assets/icons/triangle-icon-current-moves.svg';

import { Toast } from 'modals';
import { Tooltip } from 'modals';
import { Loading } from 'loaders';
import { Button, Input, Autocomplete } from 'forms';
import { useRequest, useSetQuery, useQuery, useSearch } from 'hooks';

import MainMapTrailerInfoPage from './TrailerInfowindow';
import TruckFuelMapInfoPage from './TruckInfoWindowForFuel';
import MainMapTruckInfoPage from './TruckInfoWindow';
import { InfoStyle } from './Infowindow/style';
import LoadInfoWindow from './LoadInfoWindow';
import { Container } from './style';

const FuelMapInfoPage = lazy(() => import('./FuelInfoWindow'));

const Map = ({
  place,
  width,
  height,
  title,
  zoom,
  children,
  center,
  onClick,
  fromTruckDashboard,
  isLocationAdd,
  isTruckMap,
  isTrailerMap,
  isFuelStationsMap,
  truckData,
  onStationSelect,
  isLoading,
  locations,
  trailerId,
  truckId,
  refetch,
  noRefetch,
  isMainTrailerMap,
  isFuelManagementMap,
  pageRefetch,
  isFromLoad,
  loadDirections,
  fuelDirections,
  gpsDataList,
  selectedItem,
  setSelectedItem,
  waypoints,
  stopList,
  wayList,
  startPoint,
  endPoint,
  isSingleTruckMarker,
  onClickTruckNumber,
}) => {
  const mapStyle = {
    width: width ? `${width}` : '629px',
    height: height ? `${height}` : '100%',
  };

  // const { VITE_APP_GOOGLE_API_KEY } = import.meta.env;
  const { request } = useRequest();
  const setQuery = useSetQuery();
  const query = useSearch();
  const googleRef = useRef(null);
  const [openAddress, setOpenAddress] = useState(false);
  const [state, setState] = useState({ address: '' });
  const [inputMode, setInputMode] = useState('address');
  const [open, setOpen] = useState(false);
  const [clicked, setClicked] = useState('');
  const [response, setResponse] = useState(null);
  const [latLng, setLatLng] = useState(
    center || {
      lat: 36.828175,
      lng: -97.579,
    }
  );
  const [autoCompletePlace, setAutoCompletePlace] = useState({});

  const mapCenter = center || { lat: 36.828175, lng: -97.579 };

  const directionsCallback = useCallback(
    (result) => {
      if (result !== null) {
        if (result.status === 'OK') {
          setResponse({ directions: result });
          // console.log(result, "result");
        }
      }
    },
    [
      loadDirections?.endLocation || loadDirections?.endAddress,
      loadDirections?.startLocation || loadDirections?.startAddress,
      fuelDirections?.endLocation || fuelDirections?.endAddress,
      fuelDirections?.startLocation || fuelDirections?.startAddress,
      waypoints,
      wayList,
      waypoints?.length,
      wayList?.length,
    ]
  );

  const directionsServiceOptions = useMemo(() => {
    let origin;
    let destination;
    if (loadDirections?.startLat && loadDirections?.startLong) {
      origin = { lat: loadDirections.startLat, lng: loadDirections.startLong };
    } else if (loadDirections?.startAddress) {
      origin = loadDirections.startAddress;
    }

    if (loadDirections?.endLat && loadDirections?.endLong) {
      destination = { lat: loadDirections.endLat, lng: loadDirections.endLong };
    } else if (loadDirections?.endAddress) {
      destination = loadDirections.endAddress;
    }

    if (!origin || !destination) {
      return null;
    }

    return {
      origin: origin,
      destination: destination,
      travelMode: 'DRIVING',
      waypoints: waypoints,
      optimizeWaypoints: false,
      unitSystem: window.google.maps.UnitSystem.IMPERIAL,
    };
  }, [
    loadDirections?.startLat,
    loadDirections?.startLong,
    loadDirections?.startAddress,
    loadDirections?.endLat,
    loadDirections?.endLong,
    loadDirections?.endAddress,
    waypoints,
  ]);

  const handleOpenAddress = () => {
    setOpenAddress(!openAddress);
  };
  const onAutoCompleteLoad = (autocomplete) => {
    setAutoCompletePlace(autocomplete);
  };

  const handlePlaceChange = () => {
    if (autoCompletePlace !== null) {
      setAutoCompletePlace(autoCompletePlace);

      const place = autoCompletePlace.getPlace();
      setState({ ...state, address: place?.formatted_address });
      setLatLng({
        lat: place?.geometry?.location?.lat(),
        lng: place?.geometry?.location?.lng(),
      });
    }
  };
  const handleChangeSearch = (e) => {
    const { value } = e.target;

    setState({ ...state, address: value });
    setLatLng({
      lat: null,
      lng: null,
    });
  };
  const handleLatChange = (event) => {
    const { value, name } = event.target;
    setLatLng({ ...latLng, [name]: value });
  };

  const handleInputModeChange = (event) => {
    setInputMode(event.target.value);
  };

  // const { isLoaded } = useLoadScript({
  //   googleMapsApiKey: VITE_APP_GOOGLE_API_KEY,
  //   libraries,
  // });

  const setLocation = useMutation({
    mutationFn: (values) => {
      return (
        (trailerId || query.get('trl-id')) &&
        request({
          url: `/v3/trailers/${
            trailerId || query.get('trl-id')
          }/change_location_manually?latitude=${values?.lat}&longitude=${
            values?.lng
          }`,
          method: 'PUT',
          body: values,
        })
      );
    },
  });
  const onSetLocation = () => {
    setLocation.mutate(latLng, {
      onSuccess: (res) => {
        if (res?.success) {
          Toast({
            type: 'success',
            message: res?.message,
          });
          setOpenAddress(!openAddress);
          setLatLng({ lat: 0, lng: 0 });
          refetch();
          pageRefetch();
          setQuery('trl-id', null);
          setQuery('trl-num', null);
        }
      },
      onError: (err) => {
        Toast({
          type: 'error',
          message: err?.message,
        });
      },
    });
  };
  const customStyles = [
    {
      featureType: 'administrative.province',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#bdbcbc',
          strokeWidth: 1.5,
        },
      ],
    },

    {
      featureType: 'administrative.province',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: 'transparent',
        },
      ],
    },

    {
      featureType: 'landscape',
      elementType: 'all',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },

    {
      featureType: 'landscape.man_made',
      elementType: 'geometry',
      stylers: [
        {
          visibility: 'on',
          color: '#dbdbdb',
        },
      ],
    },
    {
      featureType: 'landscape.natural.landcover',
      elementType: 'all',
      stylers: [
        {
          color: '#fcfcfc',
        },
      ],
    },
    {
      featureType: 'landscape.natural.terrain',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'all',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },

    {
      featureType: 'water',
      elementType: 'all',
      stylers: [
        {
          color: '#aee0f4',
        },
      ],
    },
  ];
  const mapOptions = {
    backgroundColor: 'white',
    minZoom: 4,
    // maxZoom: 18,
    styles: customStyles,
    fullscreenControl: true,
    fullscreenControlOptions: {
      position: fromTruckDashboard
        ? window.google.maps.ControlPosition.LEFT_BOTTOM
        : window.google.maps.ControlPosition.TOP_RIGHT,
    },
  };
  const [trailerNumbers, setTrailerNumbers] = useState([]);

  useQuery(
    ['/trailer_states/list/short_data', isMainTrailerMap],
    () => {
      if (isMainTrailerMap) {
        request({ url: '/trailer_states/list/short_data' });
      } else {
        return Promise.resolve({ data: null });
      }
    },
    {
      onSuccess: (res) => {
        let result =
          res &&
          res?.dataList?.map((item) => {
            return {
              key: item?.trailerNumber,
              value: item?.trailerNumber,
              id: item?.id,
            };
          });
        res && setTrailerNumbers(result);
      },

      refetchOnWindowFocus: false,
    }
  );

  const handleTrailer = (value) => {
    if (value === '' && !value) {
      setQuery('trl-num', '');
    } else {
      setQuery('trl-num', value);
    }
  };
  const handleTrailerSelect = (value, opt) => {
    setQuery('trl-num', value);
    setQuery('trl-id', opt?.id);
    setQuery('page', '');
  };

  function handleLoad(map) {
    googleRef.current = map;
  }
  const autocompleteOptions = {
    componentRestrictions: { country: 'us' },
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedItem && setSelectedItem({});
  };
  const handleOpen = (location) => {
    setOpen(true);
    setClicked(location);
  };
  const motion = () => {
    return {
      path: window.google.maps.SymbolPath.CIRCLE,
      // animation: window.google.maps.Animation.DROP,
      fillColor: '#064867',
      fillOpacity: 1.0,
      strokeWeight: 2,
      strokeColor: '#fff',
      scale: 7,
    };
  };
  const stop = (seconds) => {
    return {
      path: window.google.maps.SymbolPath.CIRCLE,
      fillColor: '#FF0000',
      fillOpacity: 1.0,
      strokeWeight: 2,
      strokeColor: '#fff',
      scale: 7 + (seconds <= 30000 ? seconds / 60 : 30000 / 60) * 0.02,
    };
  };

  const getIcon = ({ status, seconds }) => {
    let st = status?.toLowerCase();

    switch (st) {
      case 'current':
        return {
          icon: intransit,
        };
      case 'stop':
        return {
          icon: stop(seconds),
        };
      default:
        return {
          icon: motion(),
        };
    }
  };

  const isValidAddress = (address) => {
    return typeof address === 'string' && address.trim() !== '';
  };

  const isValidCoordinates = (lat, lng) => {
    return (
      typeof lat === 'number' &&
      !isNaN(lat) &&
      typeof lng === 'number' &&
      !isNaN(lng)
    );
  };

  const isAddressValid = (loadDirections) => {
    return (
      (isValidAddress(loadDirections?.startAddress) &&
        isValidAddress(loadDirections?.endAddress)) ||
      (isValidCoordinates(
        loadDirections?.startLat,
        loadDirections?.startLong
      ) &&
        isValidCoordinates(loadDirections?.endLat, loadDirections?.endLong))
    );
  };

  return (
    <Container id="nightwell-map" fromTruckDashboard={fromTruckDashboard}>
      {/* {isLoaded ? ( */}
      {isTrailerMap && (
        <Popover
          zIndex={1000}
          placement="bottom"
          open={openAddress}
          trigger="click"
          content={
            <InfoStyle height={isMainTrailerMap ? '190px' : '155px'}>
              <div style={{ display: 'grid', gridGap: '4px' }}>
                {isMainTrailerMap ? (
                  <Autocomplete
                    options={trailerNumbers}
                    onChange={handleTrailer}
                    onSelect={(val, opt) => handleTrailerSelect(val, opt)}
                    placeholder="Search to select"
                    value={query.get('trl-num') || ''}
                  />
                ) : null}
                <label style={{ display: 'flex', gridGap: '5px' }}>
                  <input
                    type="radio"
                    value="address"
                    checked={inputMode === 'address'}
                    onChange={handleInputModeChange}
                  />
                  <div>Address</div>
                </label>{' '}
                <GoogleAutocomplete
                  options={autocompleteOptions}
                  onPlaceChanged={handlePlaceChange}
                  onLoad={onAutoCompleteLoad}
                >
                  <Input
                    height="27px"
                    placeholder="Search address"
                    onChange={handleChangeSearch}
                    value={inputMode !== 'latlng' ? state?.address : ''}
                    disabled={inputMode === 'latlng'}
                  />
                </GoogleAutocomplete>
                <label style={{ display: 'flex', gridGap: '5px' }}>
                  <input
                    type="radio"
                    value="latlng"
                    checked={inputMode === 'latlng'}
                    onChange={handleInputModeChange}
                  />
                  <div>Lat-Lng</div>
                </label>
                <div style={{ display: 'flex', gridGap: '5px' }}>
                  <Input
                    type="text"
                    name="lat"
                    height="27px"
                    value={inputMode !== 'address' ? latLng?.lat : ''}
                    onChange={handleLatChange}
                    placeholder="Latitude"
                    disabled={inputMode === 'address'}
                  />
                  <Input
                    type="text"
                    name="lng"
                    height="27px"
                    value={inputMode !== 'address' ? latLng?.lng : ''}
                    onChange={handleLatChange}
                    placeholder="Longitude"
                    disabled={inputMode === 'address'}
                  />
                </div>
              </div>

              <InfoStyle.Row>
                <div />

                <Button
                  position="absolute"
                  right="10px"
                  top="15px"
                  btnType="map_button"
                  onClick={onSetLocation}
                >
                  Save
                </Button>
              </InfoStyle.Row>
            </InfoStyle>
          }
          onOpenChange={handleOpenAddress}
          onCloseClick={() => setOpenAddress(false)}
          title={
            <InfoStyle.Text style={{ fontWeight: 600, color: '#000' }}>
              Set a new location
            </InfoStyle.Text>
          }
        >
          <div
            style={{
              position: 'absolute',
              top: '9px',
              left: '180px',
              zIndex: 1000,
            }}
          >
            <Button
              height="41.3px"
              color="#2B2B2B"
              noneborder="true"
              btnType="map_button"
              fontSize="25px"
              fon
              boxshadow="0 1px 4px -1px rgba(0, 0, 0, 0.2)"
            >
              Add location
            </Button>
          </div>
        </Popover>
      )}

      <GoogleMap
        id="nightwell-map"
        mapContainerStyle={mapStyle}
        center={isMainTrailerMap ? center : latLng || center || mapCenter}
        zoom={zoom ? zoom : 3}
        ref={googleRef}
        options={mapOptions}
        onClick={onClick}
        onLoad={handleLoad}
      >
        {isLocationAdd ? (
          place && Array.isArray(place) ? (
            place?.map((value) => (
              <Marker
                key={value.id}
                position={{ lat: 21, lng: 41 }}
                title={value.name}
              />
            ))
          ) : (
            <Marker
              position={place ? place : center}
              title={title ? title : 'Title'}
            />
          )
        ) : null}
        {isFuelManagementMap &&
          (isLoading ? (
            <Loading />
          ) : (
            locations?.length > 0 &&
            locations?.map((item, index) => {
              return (item?.lat && item?.lng) ||
                (item?.latitude && item?.longitude) ? (
                <TruckFuelMapInfoPage
                  setLatLng={setLatLng}
                  latLng={latLng}
                  place={{
                    lat: item?.latitude || item?.lat,
                    lng: item?.longitude || item?.lng,
                  }}
                  truck={item}
                  trailer={item?.trailer || null}
                  key={index}
                  item={item}
                  type={item?.type}
                  truckId={truckId}
                  refetch={refetch}
                  noRefetch={noRefetch}
                  pageRefetch={pageRefetch}
                  isSingleTruckMarker={isSingleTruckMarker}
                  onClickTruckNumber={onClickTruckNumber}
                />
              ) : null;
            })
          ))}
        {isTruckMap &&
          (isLoading ? (
            <Loading />
          ) : (
            <MarkerClusterer
              styles={[
                { url: chartImg, height: 43, lineHeight: 43, width: 43 },
                { url: chartImgRed, height: 53, lineHeight: 53, width: 53 },
              ]}
              averageCenter
              gridSize={40}
            >
              {(clusterer) =>
                locations?.length > 0 &&
                locations?.map((item, index) => {
                  return (item?.lat && item?.lng) ||
                    (item?.latitude && item?.longitude) ? (
                    <MainMapTruckInfoPage
                      setLatLng={setLatLng}
                      latLng={latLng}
                      place={{
                        lat: item?.latitude || item?.lat,
                        lng: item?.longitude || item?.lng,
                      }}
                      truck={item}
                      trailer={item?.trailer || null}
                      key={index}
                      item={item}
                      type={item?.type}
                      truckId={truckId}
                      clusterer={clusterer}
                      refetch={refetch}
                      noRefetch={noRefetch}
                      pageRefetch={pageRefetch}
                      isSingleTruckMarker={isSingleTruckMarker}
                    />
                  ) : null;
                })
              }
            </MarkerClusterer>
          ))}
        {isTrailerMap &&
          (isLoading ? (
            <Loading />
          ) : (
            locations && (
              <MarkerClusterer
                styles={[
                  { url: chartImg, height: 43, lineHeight: 43, width: 43 },
                  { url: chartImgRed, height: 53, lineHeight: 53, width: 53 },
                ]}
                averageCenter
                gridSize={40}
              >
                {(clusterer) =>
                  locations?.length > 0 &&
                  locations?.map((item, index) => {
                    return (
                      <MainMapTrailerInfoPage
                        setLatLng={setLatLng}
                        latLng={latLng}
                        place={{
                          lat: item?.lat || item?.latitude,
                          lng: item?.lng || item?.longitude,
                          address: item?.address,
                        }}
                        trailer={item}
                        key={index}
                        type={item?.trailerStatus}
                        item={item}
                        clusterer={clusterer}
                        trailerId={item?.trailer}
                        refetch={refetch}
                        noRefetch={noRefetch}
                        pageRefetch={pageRefetch}
                      />
                    );
                  })
                }
              </MarkerClusterer>
            )
          ))}
        {isFromLoad && isAddressValid(loadDirections) && (
          <>
            <DirectionsService
              options={directionsServiceOptions}
              callback={directionsCallback}
            />

            <DirectionsRenderer
              options={{ ...response, suppressMarkers: true }}
            />
          </>
        )}
        {isFromLoad && (
          <LoadInfoWindow
            place={
              loadDirections?.startLat && loadDirections?.startLong
                ? {
                    lat: loadDirections?.startLat,
                    lng: loadDirections?.startLong,
                  }
                : loadDirections?.startLocation || loadDirections?.startAddress
            }
            label={{ text: 'A', color: '#fff' }}
            point={
              startPoint || {
                name: loadDirections?.startFacility,
                address:
                  loadDirections?.startLocation || loadDirections?.startAddress,
                startDate: loadDirections?.startPlannedDepartureDate,
                endDate: loadDirections?.startPlannedArrivalDate,
                stopType: 'PICKUP',
              }
            }
            startOrEnd="true"
          />
        )}
        {isFromLoad &&
          stopList?.length &&
          stopList?.map((point, index) => {
            return (
              <LoadInfoWindow
                key={point?.id}
                label={{ text: `${index + 1}`, color: '#fff' }}
                place={{ lat: point?.latitude, lng: point?.longitude }}
                point={point}
              />
            );
          })}
        {isFromLoad &&
          (loadDirections?.endLocation ||
            (loadDirections?.endLat && loadDirections?.endLong) ||
            loadDirections?.endAddress) && (
            <LoadInfoWindow
              label={{ text: 'B', color: '#fff' }}
              place={
                loadDirections?.endLat && loadDirections?.endLong
                  ? {
                      lat: loadDirections?.endLat,
                      lng: loadDirections?.endLong,
                    }
                  : loadDirections?.endLocation || loadDirections?.endAddress
              }
              point={
                endPoint || {
                  name: loadDirections?.endFacility,
                  address:
                    loadDirections?.endLocation || loadDirections?.endAddress,
                  startDate: loadDirections?.endPlannedDepartureDate,
                  endDate: loadDirections?.endPlannedArrivalDate,
                  stopType: 'DELIVERY',
                }
              }
              startOrEnd="true"
            />
          )}
        {isFromLoad &&
          gpsDataList?.map((point, index) => {
            return (
              <>
                <Marker
                  key={index}
                  position={{ lat: point?.latitude, lng: point?.longitude }}
                  icon={
                    getIcon({
                      status: point?.gpsAction || name,
                      seconds: point?.seconds,
                    }).icon
                  }
                  onClick={() =>
                    handleOpen({ lat: point?.latitude, lng: point?.longitude })
                  }
                />
                {open &&
                  clicked?.lat === point?.latitude &&
                  clicked?.lng === point?.longitude && (
                    <InfoWindow
                      position={{ lat: point?.latitude, lng: point?.longitude }}
                      onCloseClick={handleClose}
                    >
                      <InfoStyle>
                        <InfoStyle.Text fas>
                          <Tooltip
                            zIndex={9999}
                            placement="top"
                            title={point?.address}
                          >
                            <div className="turncated">
                              {point?.address || 'N/A-'}
                            </div>
                          </Tooltip>
                        </InfoStyle.Text>
                        <InfoStyle.Text loc>
                          {dayjs(point?.timeStamp).format('MMM-DD-YY HH:mm') ||
                            'N/A-'}
                        </InfoStyle.Text>
                        <InfoStyle.Text loc>
                          {point?.seconds > 0 &&
                          Number(dayjs(point?.seconds).format('H')) >= 1
                            ? 'Stopped for ' +
                              dayjs(point?.seconds).format('H') +
                              ' h '
                            : ''}
                          {point?.seconds > 0 &&
                          Number(dayjs(point?.seconds).format('m')) >= 1
                            ? dayjs(point?.seconds).format('m') + 'mins'
                            : ''}
                        </InfoStyle.Text>
                      </InfoStyle>
                    </InfoWindow>
                  )}
              </>
            );
          })}
        {isFuelStationsMap ? (
          <>
            {locations?.length ? (
              <Suspense fallback="">
                <FuelMapInfoPage
                  locations={locations}
                  // key={index}
                  // point={point}
                  onStationSelect={onStationSelect}
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                />
              </Suspense>
            ) : null}
            {/* {locations?.map((point, index) => {
              return (
                <Suspense key={index} fallback=''>
                  <FuelMapInfoPage
                  locations
                    key={index}
                    point={point}
                    onStationSelect={onStationSelect}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                  />
                </Suspense>
              );
            })} */}
            {truckData?.lat && truckData?.lng ? (
              <TruckFuelMapInfoPage
                setLatLng={setLatLng}
                latLng={latLng}
                place={{
                  lat: truckData?.latitude || truckData?.lat,
                  lng: truckData?.longitude || truckData?.lng,
                }}
                truck={truckData}
                trailer={truckData?.trailer || null}
                item={truckData}
                type={truckData?.type}
                truckId={truckId}
                refetch={refetch}
                noRefetch={noRefetch}
                pageRefetch={pageRefetch}
                isSingleTruckMarker
              />
            ) : null}
          </>
        ) : null}
        {children && children}
      </GoogleMap>
    </Container>
  );
};

export default memo(Map);
