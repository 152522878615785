/** @format */

export const initialState = {
  page: 1,
  size: 50,
  total: 0,
  inspections: [],
  inspectors: [],
  attachments: [],
  truckNumber: '',
  selectInspection: {},
  drawerOpen: false,

  refetch: () => {},
  saveFunction: () => {},
  onCancelFn: () => {},
  stateChange: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'setDocs': {
      if (action.own === 'true') {
        return {
          ...state,
          attachments: action.payload,
        };
      }

      let formattedData = action?.payload?.map((d) => {
        return {
          attachmentId: d?.attachmentId || d.uploadedFile?.attachmentId,
          date: d?.date || d?.uploadedFile?.date,
          docTypeId: d?.docTypeId || d?.uploadedFile?.docTypeId,
          docTypeName: d?.docTypeName || d?.uploadedFile?.docTypeName,
          extension: d?.extension || d?.uploadedFile?.extension,
          fileOriginalName:
            d?.fileOriginalName || d?.uploadedFile?.fileOriginalName,
          name: d?.name || d?.uploadedFile?.name,
          notes: d?.notes || d?.uploadedFile?.notes,
          path: d?.path || d?.uploadedFile?.path,
          size: d?.size || d?.uploadedFile?.size,
          createdAtDate: d?.createdAtDate,
          createdByNickname: d?.createdByNickname,
          note: d?.note,
          // uploadedFile: d?.uploadedFile || [],
        };
      });

      formattedData = state?.attachments?.length
        ? formattedData?.concat(state?.attachments)
        : formattedData;

      return {
        ...state,
        attachments: formattedData,
      };
    }

    case 'changePage':
      return { ...state, page: action.payload };
    case 'setSize':
      return { ...state, size: action.payload };
    case 'setTruckNumber':
      return { ...state, truckNumber: action.payload };
    case 'setPermission':
      return { ...state, permission: action.payload };
    case 'setDrawerOpen':
      return { ...state, drawerOpen: action.payload };
    case 'setTotal':
      return { ...state, total: action.payload };
    case 'setSelectedInspection':
      return { ...state, selectInspection: action.payload };
    case 'setInspections':
      return { ...state, inspections: action.payload };
    case 'setInspectors':
      return { ...state, inspectors: action.payload };
    case 'setRefetch':
      return { ...state, refetch: action.payload };

    case 'setSaveFunction':
      return { ...state, saveFunction: action.payload };
    case 'setOnCancelFunction':
      return { ...state, onCancelFn: action.payload };
    case 'setStateChange':
      return { ...state, stateChange: action.payload };
    default:
      return state;
  }
};
