import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';

export const TruckMaintenance = createContext();

export const useTruckMaintenance = () => useContext(TruckMaintenance);

export const TruckMaintenancePovider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <TruckMaintenance.Provider value={[state, dispatch]}>
      {children}
    </TruckMaintenance.Provider>
  );
};
