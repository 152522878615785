/** @format */

import { toast } from 'react-toastify';

const Toast = ({ type, message, position, duration, icons }) => {
  const p = position || 'top-right';
  let newType = type === 'warning' || type === 'warn' ? 'warn' : type;
  return toast[newType](message, {
    position: p ? p : 'top-right',
    autoClose: duration || 1000,
    className: `${type}`,
    icon: icons,
    toastId: message,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
  });
};
export default Toast;
