/** @format */

export const initialState = {
  drawerOpen: false,
  service: {},
  attachments: [],
  serviceId: null,
  refetch: () => {},
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'setDocs': {
      if (action.own === 'true') {
        return {
          ...state,
          attachments: action.payload,
        };
      }

      let formattedData = action?.payload?.map((d) => {
        return {
          attachmentId: d?.attachmentId || d.uploadedFile?.attachmentId,
          date: d?.date,
          docTypeId: d?.docTypeId,
          extension: d?.extension,
          name: d?.name,
          notes: d?.notes,
          path: d?.path || d?.uploadedFile?.path,
          size: d?.size,
          uploadedFile: d?.uploadedFile || [],
          createdAtDate: d?.createdAtDate,
          createdByNickname: d?.createdByNickname,
          note: d?.note,
        };
      });

      formattedData = state?.attachments?.length
        ? formattedData?.concat(state?.attachments)
        : formattedData;

      return {
        ...state,
        attachments: formattedData,
      };
    }
    // booleans
    case 'setDrawerOpen':
      return { ...state, drawerOpen: action.payload };
    case 'setService':
      return { ...state, service: action.payload };
    case 'setTruckCliams':
      return { ...state, truckClaims: action.payload };
    case 'setServiceId':
      return { ...state, serviceId: action.payload };
    case 'setRefetch':
      return { ...state, refetch: action.payload };
    default:
      return state;
  }
};
