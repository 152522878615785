/** @format */

import React from 'react';
import { GenericTextArea, Label, TextArea, Box } from './style';
import RedStars from '../RedStars';

const Textarea = ({
  isrequired,
  width,
  align,
  icon,
  label,
  bgcolor,
  plcolor,
  color,
  bcolor,
  bwidth,
  radius,
  disabled,
  type,
  minwidth,
  placeholder,
  labelmargin,
  size,
  height,
  minHeight,
  editable,
  maxWidth,
  defaultValue,
  bordercolor,
  bstyle,
  border,
  error,
  rows,
  padding,
  onFocus,

  ...props
}) => {
  return (
    <GenericTextArea width={width} margin={labelmargin}>
      {label && label !== '' ? (
        <Label>
          {label}
          {isrequired ? <RedStars /> : null}
        </Label>
      ) : null}
      <Box width={width}>
        {!icon ? (
          ''
        ) : align === 'left' ? (
          <Box.IconLeft src={icon} color={color} />
        ) : (
          <Box.IconRight src={icon} color={color} />
        )}
        <TextArea
          style={{ resize: 'none' }}
          type={type}
          rows={rows}
          height={height}
          min_height={minHeight}
          align={align}
          bgcolor={bgcolor}
          border={border}
          bordercolor={bordercolor}
          color={color}
          bcolor={bcolor}
          editable={editable}
          maxWidth={maxWidth}
          bwidth={bwidth}
          plcolor={plcolor}
          radius={radius}
          disabled={disabled && true}
          icon={icon}
          minwidth={minwidth}
          placeholder={placeholder}
          styles={{ size }}
          bstyle={bstyle}
          defaultValue={defaultValue}
          padding={padding}
          onFocus={onFocus}
          {...props}
        />
        {error && <span style={{ color: 'red' }}>{error}</span>}
      </Box>
    </GenericTextArea>
  );
};

export default React.memo(Textarea);
