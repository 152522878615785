/** @format */

import { useCallback, useState } from 'react';
import {
  Wrapper,
  InputsBox,
  Card,
  AntImage,
  PdfReader,
  WordReader,
  ExcelReader,
  TextReader,
  JsonReaderIcon,
  ZipReader,
  Mp4File,
  MovFile,
  Mp3File,
  SqlReader,
  HtmlReader,
  PptReader,
  FileWrapper,
  CustomMask,
  DownloadingSpinner,
} from './style';
import { Header } from 'forms';
import loading from 'assets/icons/Spinner.gif';

import { DownloadIcon, DeleteIcon, IconView } from 'styledIcons';

import loadingSpinner from 'assets/icons/image-loading-spinner.gif';
import uploadingSpinner from 'assets/icons/Spinner.gif';
// import { useMutation } from '@tanstack/react-query';
import { Toast, DeleteConfirm } from 'modals';
import { Tooltip } from '../../AntDesign';
import { useLocation } from 'react-router-dom';
const useDownloadFile = () => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [fileId, setFileId] = useState(null);
  const [fileIndex, setFileIndex] = useState(null);

  // Utility function to encode file paths for URLs
  const encodeFilePath = useCallback((path) => {
    if (typeof path === 'string') {
      const lastIndex = path.lastIndexOf('/');
      return (
        path.substring(0, lastIndex + 1) +
        encodeURIComponent(path.substring(lastIndex + 1))
      );
    }
    return path;
  }, []);

  const downloadFile = useCallback(
    async (fileName, filePath, fileId, photoIndex) => {
      setIsDownloading(true);
      setFileId(fileId);
      setFileIndex(photoIndex);
      try {
        const encodedFilePath = encodeFilePath(filePath);
        const response = await fetch(encodedFilePath, {
          headers: {
            'Content-Type': 'application/octet-stream',
          },
          method: 'GET',
        });

        if (!response.ok) throw new Error(`Error: ${response.statusText}`);

        const contentLength = response.headers.get('Content-Length');
        let receivedLength = 0;
        const reader = response.body.getReader();
        const chunks = []; // chunks of received data

        // Read the data
        let read = await reader.read();
        while (!read.done) {
          chunks.push(read.value);
          receivedLength += read.value.length;

          if (contentLength) {
            setDownloadProgress(
              (receivedLength / parseInt(contentLength)) * 100
            );
          }

          read = await reader.read();
        }

        const blob = new Blob(chunks);
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        setIsDownloading(true);

        // Clean up
        window.URL.revokeObjectURL(downloadUrl);
        link.remove();
      } catch (error) {
        Toast({
          type: 'error',
          message: error?.message,
        });
      } finally {
        setIsDownloading(false);
      }
    },
    [encodeFilePath]
  );
  return { isDownloading, downloadProgress, downloadFile, fileId, fileIndex };
};
export default function ShowFiles({
  files,
  imgWidth,
  imgHeight,
  title,
  unDeletable,
  showConfirm,
  previewFile,
  deletePermission,
  onCancel,
  isFromNoteUploader,
  isFileUploading,
}) {
  const { pathname, search } = useLocation();
  const { isDownloading, downloadFile, fileId } = useDownloadFile();

  const encodeFilePath = (path) => {
    if (typeof path === 'string') {
      const lastIndex = path.lastIndexOf('/');
      return (
        path.substring(0, lastIndex + 1) +
        encodeURIComponent(path.substring(lastIndex + 1))
      );
    }
    return pathname + search;
  };
  const handleDownload = (e, fileOrgName, imagePath, fileId, photoIndex) => {
    e.stopPropagation();
    downloadFile(fileOrgName, imagePath, fileId, photoIndex);
  };

  const showFile = (f, index) => {
    let file = f?.extension ? f?.extension : f?.uploadedFile?.extension;
    let fileName = f?.fileOriginalName
      ? f?.fileOriginalName
      : f?.name?.substring(14);
    switch (true) {
      case file === 'image/jpeg':
      case file === 'image/gif':
      case file === 'image/svg+xml':
      case file === 'image/bmp':
      case file === 'image/png':
      case file === 'image/webp':
      case file === 'image/*':
        return (
          <FileWrapper
            width={imgWidth}
            height={imgHeight}
            onClick={(e) => previewFile && previewFile(e, f, index)}
          >
            <div className='uploading'>
              {!isDownloading && (
                <CustomMask width={imgWidth} height={imgHeight}>
                  <div style={{ textAlign: 'center' }}>
                    <IconView width='25px' height='25px' color='#fff' />
                  </div>
                </CustomMask>
              )}
            </div>
            <AntImage
              imgWidth={imgWidth}
              imgHeight={imgHeight}
              preview={false}
              src={f?.path}
              placeholder={
                <AntImage
                  imgWidth={imgWidth}
                  imgHeight={imgHeight}
                  preview={false}
                  src={loadingSpinner}
                  alt={f?.name}
                />
              }
              alt='file'
            />
          </FileWrapper>
        );
      case file === 'application/pdf':
        return (
          <a
            href={encodeFilePath(f?.path)}
            download={f?.fileOriginalName || fileName}
            onClick={(e) =>
              handleDownload(
                e,
                f?.path,
                f?.fileOriginalName || fileName,
                isFromNoteUploader,
                f?.id || f?.attachmentId
              )
            }
          >
            <FileWrapper
              title='Download a file'
              width={imgWidth}
              height={imgHeight}
            >
              <div className='uploading'>
                {isDownloading ? (
                  <AntImage
                    imgWidth={imgWidth}
                    imgHeight={imgHeight}
                    preview={false}
                    src={loadingSpinner}
                    alt={f?.name}
                  />
                ) : (
                  <div className='uploading'>
                    {!isDownloading && (
                      <CustomMask width={imgWidth} height={imgHeight}>
                        <div style={{ textAlign: 'center' }}>
                          {isFromNoteUploader ? (
                            <IconView width='15px' height='15px' color='#fff' />
                          ) : isDownloading &&
                            (f?.id || f?.attachmentId) === fileId ? (
                            <DownloadingSpinner
                              width='25px'
                              height='25px'
                              src={loading}
                            />
                          ) : (
                            <DownloadIcon
                              width='25px'
                              height='25px'
                              color='#fff'
                            />
                          )}
                        </div>
                      </CustomMask>
                    )}
                  </div>
                )}
              </div>
              <PdfReader imgWidth={imgWidth} imgHeight={imgHeight} />
            </FileWrapper>
          </a>
        );
      case file === 'text/plain':
        return (
          <a
            href={encodeFilePath(f?.path)}
            download={fileName}
            onClick={(e) =>
              handleDownload(
                e,
                f?.path,
                f?.fileOriginalName || fileName,
                isFromNoteUploader,
                f?.id || f?.attachmentId
              )
            }
          >
            <FileWrapper
              title='Download a file'
              width={imgWidth}
              height={imgHeight}
            >
              <div className='uploading'>
                {isDownloading ? (
                  <AntImage
                    imgWidth={imgWidth}
                    imgHeight={imgHeight}
                    preview={false}
                    src={loadingSpinner}
                    alt={f?.name}
                  />
                ) : (
                  <>
                    {isFromNoteUploader ? (
                      <IconView width='15px' height='15px' color='#fff' />
                    ) : isDownloading &&
                      (f?.id || f?.attachmentId) === fileId ? (
                      <DownloadingSpinner
                        width='25px'
                        height='25px'
                        src={loading}
                      />
                    ) : (
                      <DownloadIcon width='25px' height='25px' color='#fff' />
                    )}
                  </>
                )}
              </div>
              <TextReader imgWidth={imgWidth} imgHeight={imgHeight} />
            </FileWrapper>
          </a>
        );
      case file === 'application/json':
        return (
          <a
            href={encodeFilePath(f?.path)}
            download={fileName}
            onClick={(e) =>
              handleDownload(
                e,
                f?.path,
                f?.fileOriginalName || fileName,
                isFromNoteUploader,
                f?.id || f?.attachmentId
              )
            }
          >
            <FileWrapper
              title='Download a file'
              width={imgWidth}
              height={imgHeight}
            >
              <div className='uploading'>
                {isDownloading ? (
                  <AntImage
                    imgWidth={imgWidth}
                    imgHeight={imgHeight}
                    preview={false}
                    src={loadingSpinner}
                    alt={f?.name}
                  />
                ) : isDownloading && (f?.id || f?.attachmentId) === fileId ? (
                  <DownloadingSpinner
                    width='25px'
                    height='25px'
                    src={loading}
                  />
                ) : (
                  <DownloadIcon width='25px' height='25px' color='#fff' />
                )}
              </div>
              <JsonReaderIcon imgWidth={imgWidth} imgHeight={imgHeight} />
            </FileWrapper>
          </a>
        );
      case file === 'application/x-zip-compressed':
        return (
          <a
            href={encodeFilePath(f?.path)}
            download={fileName}
            onClick={(e) =>
              handleDownload(
                e,
                f?.path,
                f?.fileOriginalName || fileName,
                isFromNoteUploader,
                f?.id || f?.attachmentId
              )
            }
          >
            <FileWrapper
              title='Download a file'
              width={imgWidth}
              height={imgHeight}
            >
              <div className='uploading'>
                {isDownloading ? (
                  <AntImage
                    imgWidth={imgWidth}
                    imgHeight={imgHeight}
                    preview={false}
                    src={loadingSpinner}
                    alt={f?.name}
                  />
                ) : isDownloading && (f?.id || f?.attachmentId) === fileId ? (
                  <DownloadingSpinner
                    width='25px'
                    height='25px'
                    src={loading}
                  />
                ) : (
                  <DownloadIcon width='25px' height='25px' color='#fff' />
                )}
              </div>
              <ZipReader imgWidth={imgWidth} imgHeight={imgHeight} />
            </FileWrapper>
          </a>
        );
      case file ===
        'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return (
          <a
            href={encodeFilePath(f?.path)}
            download={fileName}
            onClick={(e) =>
              handleDownload(
                e,
                f?.path,
                f?.fileOriginalName || fileName,
                isFromNoteUploader,
                f?.id || f?.attachmentId
              )
            }
          >
            <FileWrapper
              title='Download a file'
              width={imgWidth}
              height={imgHeight}
            >
              <div className='uploading'>
                {isDownloading ? (
                  <AntImage
                    imgWidth={imgWidth}
                    imgHeight={imgHeight}
                    preview={false}
                    src={loadingSpinner}
                    alt={f?.name}
                  />
                ) : isDownloading && (f?.id || f?.attachmentId) === fileId ? (
                  <DownloadingSpinner
                    width='25px'
                    height='25px'
                    src={loading}
                  />
                ) : (
                  <DownloadIcon width='25px' height='25px' color='#fff' />
                )}
              </div>
              <PptReader imgWidth={imgWidth} imgHeight={imgHeight} />
            </FileWrapper>
          </a>
        );
      case file === 'text/html':
        return (
          <a
            href={encodeFilePath(f?.path)}
            download={fileName}
            onClick={(e) =>
              handleDownload(
                e,
                f?.path,
                f?.fileOriginalName || fileName,
                isFromNoteUploader,
                f?.id || f?.attachmentId
              )
            }
          >
            <FileWrapper
              title='Download a file'
              width={imgWidth}
              height={imgHeight}
            >
              <div className='uploading'>
                {isDownloading ? (
                  <AntImage
                    imgWidth={imgWidth}
                    imgHeight={imgHeight}
                    preview={false}
                    src={loadingSpinner}
                    alt={f?.name}
                  />
                ) : isDownloading && (f?.id || f?.attachmentId) === fileId ? (
                  <DownloadingSpinner
                    width='25px'
                    height='25px'
                    src={loading}
                  />
                ) : (
                  <DownloadIcon width='25px' height='25px' color='#fff' />
                )}
              </div>
              <HtmlReader imgWidth={imgWidth} imgHeight={imgHeight} />
            </FileWrapper>
          </a>
        );
      case file === 'video/mp4':
        return (
          <a
            href={encodeFilePath(f?.path)}
            download={fileName}
            onClick={(e) =>
              handleDownload(
                e,
                f?.path,
                f?.fileOriginalName || fileName,
                isFromNoteUploader,
                f?.id || f?.attachmentId
              )
            }
          >
            <FileWrapper
              title='Download a file'
              width={imgWidth}
              height={imgHeight}
            >
              <div className='uploading'>
                {isDownloading ? (
                  <AntImage
                    imgWidth={imgWidth}
                    imgHeight={imgHeight}
                    preview={false}
                    src={loadingSpinner}
                    alt={f?.name}
                  />
                ) : (
                  <>
                    {isFromNoteUploader ? (
                      <IconView width='15px' height='15px' color='#fff' />
                    ) : isDownloading &&
                      (f?.id || f?.attachmentId) === fileId ? (
                      <DownloadingSpinner
                        width='25px'
                        height='25px'
                        src={loading}
                      />
                    ) : (
                      <DownloadIcon width='25px' height='25px' color='#fff' />
                    )}
                  </>
                )}
              </div>
              <Mp4File imgWidth={imgWidth} imgHeight={imgHeight} />
            </FileWrapper>
          </a>
        );
      case file === 'video/quicktime':
        return (
          <a
            href={encodeFilePath(f?.path)}
            download={fileName}
            onClick={(e) =>
              handleDownload(
                e,
                f?.path,
                f?.fileOriginalName || fileName,
                isFromNoteUploader,
                f?.id || f?.attachmentId
              )
            }
          >
            <FileWrapper
              title='Download a file'
              width={imgWidth}
              height={imgHeight}
            >
              <div className='uploading'>
                {isDownloading ? (
                  <AntImage
                    imgWidth={imgWidth}
                    imgHeight={imgHeight}
                    preview={false}
                    src={loadingSpinner}
                    alt={f?.name}
                  />
                ) : (
                  <>
                    {isFromNoteUploader ? (
                      <IconView width='15px' height='15px' color='#fff' />
                    ) : isDownloading &&
                      (f?.id || f?.attachmentId) === fileId ? (
                      <DownloadingSpinner
                        width='25px'
                        height='25px'
                        src={loading}
                      />
                    ) : (
                      <DownloadIcon width='25px' height='25px' color='#fff' />
                    )}
                  </>
                )}
              </div>
              <MovFile imgWidth={imgWidth} imgHeight={imgHeight} />
            </FileWrapper>
          </a>
        );
      case file === 'application/octet-stream':
        return (
          <a
            href={encodeFilePath(f?.path)}
            download={fileName}
            onClick={(e) =>
              handleDownload(
                e,
                f?.path,
                f?.fileOriginalName || fileName,
                isFromNoteUploader,
                f?.id || f?.attachmentId
              )
            }
          >
            <FileWrapper
              title='Download a file'
              width={imgWidth}
              height={imgHeight}
            >
              <div className='uploading'>
                {isDownloading ? (
                  <AntImage
                    imgWidth={imgWidth}
                    imgHeight={imgHeight}
                    preview={false}
                    src={loadingSpinner}
                    alt={f?.name}
                  />
                ) : (
                  <>
                    {isFromNoteUploader ? (
                      <IconView width='15px' height='15px' color='#fff' />
                    ) : isDownloading &&
                      (f?.id || f?.attachmentId) === fileId ? (
                      <DownloadingSpinner
                        width='25px'
                        height='25px'
                        src={loading}
                      />
                    ) : (
                      <DownloadIcon width='25px' height='25px' color='#fff' />
                    )}
                  </>
                )}
              </div>
              <MovFile imgWidth={imgWidth} imgHeight={imgHeight} />
            </FileWrapper>
          </a>
        );
      case file === 'audio/mpeg':
        return (
          <a
            href={encodeFilePath(f?.path)}
            download={fileName}
            onClick={(e) =>
              handleDownload(
                e,
                f?.path,
                f?.fileOriginalName || fileName,
                isFromNoteUploader,
                f?.id || f?.attachmentId
              )
            }
          >
            <FileWrapper
              title='Download a file'
              width={imgWidth}
              height={imgHeight}
            >
              <div className='uploading'>
                {isDownloading ? (
                  <AntImage
                    imgWidth={imgWidth}
                    imgHeight={imgHeight}
                    preview={false}
                    src={loadingSpinner}
                    alt={f?.name}
                  />
                ) : (
                  <>
                    {isFromNoteUploader ? (
                      <IconView width='15px' height='15px' color='#fff' />
                    ) : isDownloading &&
                      (f?.id || f?.attachmentId) === fileId ? (
                      <DownloadingSpinner
                        width='25px'
                        height='25px'
                        src={loading}
                      />
                    ) : (
                      <DownloadIcon width='25px' height='25px' color='#fff' />
                    )}
                  </>
                )}
              </div>
              <Mp3File imgWidth={imgWidth} imgHeight={imgHeight} />
            </FileWrapper>
          </a>
        );
      case file === '':
        return (
          <a
            href={encodeFilePath(f?.path)}
            download={fileName}
            onClick={(e) =>
              handleDownload(
                e,
                f?.path,
                f?.fileOriginalName || fileName,
                isFromNoteUploader,
                f?.id || f?.attachmentId
              )
            }
          >
            <FileWrapper
              title='Download a file'
              width={imgWidth}
              height={imgHeight}
            >
              <div className='uploading'>
                {isDownloading ? (
                  <AntImage
                    imgWidth={imgWidth}
                    imgHeight={imgHeight}
                    preview={false}
                    src={loadingSpinner}
                    alt={f?.name}
                  />
                ) : isDownloading && (f?.id || f?.attachmentId) === fileId ? (
                  <DownloadingSpinner
                    width='25px'
                    height='25px'
                    src={loading}
                  />
                ) : (
                  <DownloadIcon width='25px' height='25px' color='#fff' />
                )}
              </div>
              {fileName?.endsWith('.sql') && (
                <SqlReader imgWidth={imgWidth} imgHeight={imgHeight} />
              )}
            </FileWrapper>
          </a>
        );
      case file ===
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return (
          <a
            href={encodeFilePath(f?.path)}
            download={fileName}
            onClick={(e) =>
              handleDownload(
                e,
                f?.path,
                f?.fileOriginalName || fileName,
                isFromNoteUploader,
                f?.id || f?.attachmentId
              )
            }
          >
            <FileWrapper
              title='Download a file'
              width={imgWidth}
              height={imgHeight}
            >
              <div className='uploading'>
                {isDownloading ? (
                  <AntImage
                    imgWidth={imgWidth}
                    imgHeight={imgHeight}
                    preview={false}
                    src={loadingSpinner}
                    alt={f?.name}
                  />
                ) : isDownloading && (f?.id || f?.attachmentId) === fileId ? (
                  <DownloadingSpinner
                    width='25px'
                    height='25px'
                    src={loading}
                  />
                ) : (
                  <DownloadIcon width='25px' height='25px' color='#fff' />
                )}
              </div>
              <WordReader imgWidth={imgWidth} imgHeight={imgHeight} />
            </FileWrapper>
          </a>
        );

      case file ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return (
          <a
            href={encodeFilePath(f?.path)}
            download={fileName}
            onClick={(e) =>
              handleDownload(
                e,
                f?.path,
                f?.fileOriginalName || fileName,
                isFromNoteUploader,
                f?.id || f?.attachmentId
              )
            }
          >
            <FileWrapper
              title='Download a file'
              width={imgWidth}
              height={imgHeight}
            >
              <div className='uploading'>
                {isDownloading ? (
                  <AntImage
                    imgWidth={imgWidth}
                    imgHeight={imgHeight}
                    preview={false}
                    src={loadingSpinner}
                    alt={f?.name}
                  />
                ) : isDownloading && (f?.id || f?.attachmentId) === fileId ? (
                  <DownloadingSpinner
                    width='25px'
                    height='25px'
                    src={loading}
                  />
                ) : (
                  <DownloadIcon width='25px' height='25px' color='#fff' />
                )}
              </div>
              <ExcelReader imgWidth={imgWidth} imgHeight={imgHeight} />
            </FileWrapper>
          </a>
        );
      case file === 'text/csv':
        return (
          <a
            href={encodeFilePath(f?.path)}
            download={fileName}
            onClick={(e) =>
              handleDownload(
                e,
                f?.path,
                f?.fileOriginalName || fileName,
                isFromNoteUploader,
                f?.id || f?.attachmentId
              )
            }
          >
            <FileWrapper
              title='Download a file'
              width={imgWidth}
              height={imgHeight}
            >
              <div className='uploading'>
                {isDownloading ? (
                  <AntImage
                    imgWidth={imgWidth}
                    imgHeight={imgHeight}
                    preview={false}
                    src={loadingSpinner}
                    alt='Loading...'
                  />
                ) : isDownloading && (f?.id || f?.attachmentId) === fileId ? (
                  <DownloadingSpinner
                    width='25px'
                    height='25px'
                    src={loading}
                  />
                ) : (
                  <DownloadIcon width='25px' height='25px' color='#fff' />
                )}
              </div>
              <ExcelReader imgWidth={imgWidth} imgHeight={imgHeight} />
            </FileWrapper>
          </a>
        );
    }
  };

  return (
    <Wrapper title={title}>
      {title?.length && <Header title='Trailer Movement files' />}

      <InputsBox title={title}>
        {/* <Img.PreviewGroup
          style={{ padding: 0 }}
          preview={{
            icons,
            loading: fetchImage?.isLoading,
          }}
        > */}
        {files?.map((item, index) => {
          return (
            <Card
              trmoves={false}
              imgWidth={imgWidth}
              imgHeight={imgHeight}
              key={index}
            >
              {!unDeletable && deletePermission && (
                <div
                  style={{
                    width: '20px',
                    height: '20px',
                    minWidth: '20px',
                    position: 'absolute',
                    backgroundColor: '#fff',
                    borderRadius: '100%',
                    zIndex: 999,
                    top: '3px',
                    right: '5px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <DeleteConfirm
                    title={` ${
                      item?.name || item?.uploadedFile?.fileOriginalName
                    }`}
                    onConfirm={() => showConfirm(item)}
                    onCancel={onCancel}
                  >
                    <DeleteIcon title='Delete file' color='red' />
                  </DeleteConfirm>
                </div>
              )}
              <Card.Image imgWidth={imgWidth} imgHeight={imgHeight}>
                <div>{showFile(item, index)}</div>
              </Card.Image>
              <Card.Content>
                {item?.trailerExpenditureCategoryDTOS?.map((sub, i) => (
                  <div
                    style={{
                      display: 'grid',
                      padding: '6px',
                      gridTemplateColumns: '0.25fr 0.15fr 1fr',
                    }}
                    key={i}
                  >
                    <Tooltip
                      zIndex={9999}
                      placement='top'
                      title={sub?.symbol}
                      color='blue'
                    >
                      <div id='inner' className='turncated'>
                        {sub?.symbol}
                      </div>
                    </Tooltip>
                    <span style={{ margin: '0 3px' }}>-</span>
                    <Tooltip
                      zIndex={9999}
                      placement='top'
                      title={sub?.description}
                      color='blue'
                    >
                      <div className='turncated'>{sub?.description}</div>
                    </Tooltip>
                  </div>
                ))}
              </Card.Content>
            </Card>
          );
        })}
        {isFileUploading ? <DownloadingSpinner src={uploadingSpinner} /> : ''}
        {/* </Img.PreviewGroup> */}
      </InputsBox>
    </Wrapper>
  );
}
