/** @format */

import { Wrapper } from './style';
import { NotFoundIcon } from 'styledIcons';
const NotFound = () => {
  return (
    <Wrapper>
      <NotFoundIcon width='100vw' height='100vh' />
    </Wrapper>
  );
};

export default NotFound;
