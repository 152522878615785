/** @format */

import React, { forwardRef } from 'react';
import { AntAutoComplete } from './style';

const Autocomplete = forwardRef(
  ({ placeholder, options, isPending, ...props }, ref) => {
    return (
      <AntAutoComplete
        allowClear
        popupMatchSelectWidth={false}
        placeholder={placeholder}
        options={options || []}
        notFoundContent={isPending ? 'Loading...' : 'No data found!'}
        filterOption={(inputValue, option) =>
          option?.value?.toUpperCase()?.indexOf(inputValue?.toUpperCase()) !==
          -1
        }
        ref={ref}
        {...props}
      />
    );
  }
);
Autocomplete.displayName = 'Autocomplete';
export default React.memo(Autocomplete);
