/** @format */
import { StyleSheetManager } from 'styled-components';

const StyleSheetManagerWithFilteredProps = ({ children }) => {
  return (
    <StyleSheetManager shouldForwardProp={(prop) => prop[0] !== '$'}>
      {children}
    </StyleSheetManager>
  );
};
export default StyleSheetManagerWithFilteredProps;
