/** @format */

import { createContext, useContext, useReducer } from 'react';

import { reducer, initialState } from './reducer';

export const TireAndBrakeContext = createContext();

export const useTireAndBrake = () => useContext(TireAndBrakeContext);

export const TireAndBrakeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <TireAndBrakeContext.Provider value={[state, dispatch]}>
      {children}
    </TireAndBrakeContext.Provider>
  );
};
