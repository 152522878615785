export const initialState = {
  optionsOfLoadStatus: [], // api/loads/load_status/list

};

export const reducer = (state, action) => {
  switch (action.type) {

    case 'setOptionsOfLoadStatus':
      return { ...state, optionsOfLoadStatus: action.payload };
    default:
      return state;
  }
};
