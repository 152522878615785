/** @format */
import { useSetQuery, useRequest, useSearch } from 'hooks';

import { useLoads } from 'context';
import { FilterAutocomplete } from 'filters';
import { useMutation } from '@tanstack/react-query';

export const FilterDriver = () => {
  const query = useSearch();
  const setQuery = useSetQuery();
  const [{ driversList }, dispatch] = useLoads();
  const { request } = useRequest();
  const getDrs = useMutation({
    mutationFn: () => request({ url: '/units/drivers' }),
  });

  const getDrivers = () => {
    if (!driversList?.length || !driversList) {
      getDrs.mutate('', {
        onSuccess: (res) => {
          let newData = res?.dataList?.map((key) => {
            const newKey = key?.driver2?.trim()
              ? `${key?.driver1} / ${key.driver2}}`
              : key?.driver1;

            return {
              key: newKey,
              id: key?.unitId,
              value: newKey,
            };
          });
          dispatch({ type: 'setDriversList', payload: newData });
        },
      });
    }
  };

  const onChange = (e, key) => {
    setQuery('unit-id', key?.id);
    setQuery('drivers', key?.value);
  };

  return (
    <FilterAutocomplete
      options={driversList}
      onChange={onChange}
      defaultValue={query?.get('drivers') || ''}
      onFocus={getDrivers}
      notFoundContent={getDrs?.isLoading ? 'Loading...' : 'No data found!!'}
    />
  );
};
