/** @format */

import { Popconfirm } from 'antd';
import { memo } from 'react';

const PopConfirm = ({
  content,
  children,
  cancelText,
  okText,
  title,
  icon,
  isLoading,
  open,
  placement,
  onConfirm,
  onCancel,
}) => {
  return (
    <Popconfirm
      open={open}
      icon={icon ? icon : null}
      title={title}
      description={content}
      onCancel={onCancel}
      onConfirm={onConfirm}
      cancelText={cancelText || 'Cancel'}
      okText={okText || 'Confirm'}
      okButtonProps={{
        disabled: isLoading ? true : false,
        danger: okText === 'Delete' ? true : false,
      }}
      placement={placement ? placement : 'top'}
      isLoading={isLoading}
    >
      {children}
    </Popconfirm>
  );
};

export default memo(PopConfirm);
