/** @format */

import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';

import { useSetQuery, useRequest, useSearch } from 'hooks';

import { FilterAutocomplete } from 'filters';

export const FilterBroker = () => {
  const query = useSearch();
  const setQuery = useSetQuery();

  const [brokers, setBrokers] = useState([]);
  const { request } = useRequest();
  const getBrkrs = useMutation({
    mutationFn: (query) =>
      request({ url: `/company/brokers/list?company-name=${query}` }),
  });

  const getBrokers = (value) => {
    if (value)
      getBrkrs.mutate(value, {
        onSuccess: (res) => {
          let newData = res?.dataList?.map(({ name, id }) => ({
            key: id,
            id: id,
            value: name,
          }));
          setBrokers(newData);
        },
      });
    else onChange();
  };

  const onChange = (e, key) => {
    setQuery('broker-id', key?.id);
    setQuery('broker-name', key?.value);
  };

  return (
    <FilterAutocomplete
      options={brokers}
      onChange={getBrokers}
      defaultValue={query?.get('broker-name') || ''}
      onSelect={onChange}
      notFoundContent={getBrkrs?.isLoading ? 'Loading...' : 'No data found!!'}
    />
  );
};
