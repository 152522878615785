/** @format */

import React from 'react';

const Policy = React.lazy(() => import('./Policy'));
const Change = React.lazy(() => import('./Change'));
const Endorsement = React.lazy(() => import('./Endorsement'));
const Accident = React.lazy(() => import('./Accident'));
const InsuranceStatistics = React.lazy(() => import('./Statistics'));
const AccidentExpenses = React.lazy(() => import('./AccidentExpenses'));

export {
  Policy,
  Change,
  Endorsement,
  Accident,
  InsuranceStatistics,
  AccidentExpenses,
};
