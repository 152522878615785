/** @format */

import replaceParams from './replaceParams';
import { useStableNavigate } from './useStableNavigate';

const useSetQuery = () => {
  const navigate = useStableNavigate();
  const setQuery = (key, value) => {
    navigate(`${window.location.pathname}${replaceParams(key, value)}`);
  };
  return setQuery;
};

export default useSetQuery;
