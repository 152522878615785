import React from 'react';
import { Wrapper } from './style';

const FilterWrap = (props) => {
  const { children, open } = props;

  return <Wrapper open={open}>{children}</Wrapper>;
};

export default React.memo(FilterWrap);
