/** @format */

import React, { useRef, useEffect, useState } from 'react';
import Row from './Row';
import { AntCollapse, GridTable } from './styled';

const Counter = ({ length, names, InnerRenderer, InnerRendererParams }) => {
  return (
    <GridTable.Counter>
      {InnerRenderer ? (
        <InnerRenderer value={InnerRendererParams} length={length} />
      ) : (
        <>
          {length} {length > 1 ? names?.[1] || 'items' : names?.[0] || 'item'}
        </>
      )}
    </GridTable.Counter>
  );
};
const Collapse = ({
  item,
  groupRowRendererParams,
  columnDefs,
  gridtemplatecolumns,
  Header,
  openAllCollapse,
  defaultOpenCollapse,
  clearActiveOnCell,
  rowHeight,
  onRowClick,
  order,
  activeKey,
}) =>
  // ref
  {
    const collapseTableRef = useRef(null);
    const collapseHeaderRef = useRef(null);
    const [isExpanded, setIsExpanded] = useState(
      openAllCollapse || defaultOpenCollapse
    );
    useEffect(() => {
      setIsExpanded(openAllCollapse || defaultOpenCollapse);
    }, [openAllCollapse, defaultOpenCollapse]);

    const toggleCollapse = (e) => {
      !e?.target?.classList?.contains('active-collapse') &&
        e?.target?.classList?.add('active-collapse');
      e?.target
        ?.closest('.new-table-collapse')
        ?.classList?.toggle('close-collapse');
      e?.target
        ?.closest('.new-table-collapse')
        ?.classList?.toggle('close-collapse');
    };
    const onBlurCollapse = () => {
      collapseHeaderRef?.current?.classList.contains('active-collapse') &&
        collapseHeaderRef?.current?.classList.remove('active-collapse');
    };

    const panelHeader = (
      <GridTable.CollapseHeader
        ref={collapseHeaderRef}
        onDoubleClick={toggleCollapse}
        onClick={toggleCollapse}
        onBlur={onBlurCollapse}
      >
        {groupRowRendererParams?.suppressCount && (
          <Counter
            length={item[groupRowRendererParams?.dataField]?.length}
            names={groupRowRendererParams?.groupItemName}
            InnerRenderer={groupRowRendererParams?.innerCounterRenderer}
            InnerRendererParams={item[groupRowRendererParams?.titleField]}
          />
        )}
        {Header && <Header value={item[groupRowRendererParams?.titleField]} />}
      </GridTable.CollapseHeader>
    );

    const panelContent = (
      <div className='inner-collapse-data'>
        {item[groupRowRendererParams?.dataField]?.map((item, i) => (
          <Row
            key={i}
            i={i}
            item={item}
            columnDefs={columnDefs}
            rowHeight={rowHeight}
            gridtemplatecolumns={gridtemplatecolumns}
            clearActiveOnCell={clearActiveOnCell}
            onRowClick={onRowClick}
          />
        ))}
      </div>
    );

    const collapseItems = [
      {
        key: order?.toString(),
        label: panelHeader,
        children: panelContent,
      },
    ];

    return (
      <GridTable.Collapse
        className={`new-table-collapse ${
          isExpanded ? activeKey : 'close-collapse'
        }`}
        ref={collapseTableRef}
      >
        <AntCollapse
          defaultActiveKey={isExpanded ? [activeKey] : []}
          items={collapseItems}
        />
      </GridTable.Collapse>
    );
  };

export default React.memo(Collapse);
