/** @format */

import { MainWrapper, MultiSelectLabel, StyledMultiSelect } from './style';
import RedStars from '../RedStars';

const MultiSelect = ({
  defaultValue,
  isrequired,
  label,
  width,
  border,
  value,
  ...props
}) => {
  return (
    <MainWrapper width={width} border={border}>
      {label && (
        <MultiSelectLabel>
          {label}
          {isrequired ? <RedStars /> : null}
        </MultiSelectLabel>
      )}
      <StyledMultiSelect
        width={width}
        border={border}
        popupMatchSelectWidth={false}
        mode='multiple'
        maxTagCount={'responsive'}
        value={value || []}
        defaultValue={defaultValue || []}
        optionLabelProp='label'
        notFoundContent={'No data found!'}
        {...props}
      />
    </MainWrapper>
  );
};

export default MultiSelect;
