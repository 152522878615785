import { Select } from '../../AntDesign';
import styled from 'styled-components';
import { InputLabel } from 'globalCSS';

const { Option } = Select;

export const SearchSelectWrapper = styled('div')`
  width: ${({ width }) => width || '100%'};
  display: flex;
  flex-direction: column;
  min-width: ${({ minwidth }) => minwidth && minwidth};
  .ant-select-item-option-content {
    display: flex !important;
    justify-content: space-around !important;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    font-weight: 400;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: ${({ bgcolor }) => bgcolor && bgcolor};
  }
  .ant-select-selector {
    height: 100% !important;
    height: ${({ height }) => (height ? height : '32px')} !important;
    align-items: center;
    padding-left: 7px !important;
  }
  .ant-select-item {
    height: fit-content !important;
  }
  .ant-select-selection-item {
    color: ${({ tiltecolor }) => tiltecolor && tiltecolor};
    font-weight: ${({ tiltecolor }) => tiltecolor && '400'};
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    /* height: ${({ height }) => (height ? height : '32px')} !important; */
    overflow: hidden;
    .turncated {
      text-overflow: ellipsis;
      width: calc(98%);
      white-space: nowrap;
      overflow: hidden;
      text-align: left;
    }
  }

  .ant-select-dropdown .ant-select-dropdown-placement-topLeft {
    width: unset !important;
    min-width: unset !important;
  }

  .ant-select-selction-search-input {
    ::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      font-weight: 400;
    }
    :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      font-weight: 400;
    }
    ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      font-weight: 400;
    }
    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      font-weight: 400;
    }
    ::-ms-input-placeholder {
      /* Microsoft Edge */
      font-weight: 400;
    }

    ::placeholder {
      /* Most modern browsers support this now. */
      font-weight: 400;
    }
  }
`;

export const CustomSelect = styled(Select)`
  width: ${({ width }) => width && width};
  /* height: ${({ height }) => height && height}; */
  height: 32px !important;
`;

export const SearchSelectLabel = styled(InputLabel)``;

export const CustomOption = styled(Option)``;
