/** @format */

import styled from 'styled-components';

export const PanelWrap = styled.div`
  width: 100%;
  .table {
    width: 100%;
  }
  .files-box {
    margin-bottom: 10px;
  }
`;

PanelWrap.Table = styled.div`
  display: flex;
  align-items: start;
  gap: 10px;
  margin-bottom: 10px;
  width: 100%;
  .name-box {
    width: calc(65% - 97px);
  }
  .author-box {
    width: 18%;
    ${'' /* min-width: 160px; */}
    ${'' /* max-width: 160px; */}
  }
  .date-box {
    width: 17%;
  }
  .action-box {
    width: 97px;
    ${'' /* min-width: 120px; */}
    ${'' /* max-width: 120px; */}
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
  }
  .name-box,
  .author-box,
  .date-box,
  .action-box {
    .key {
      color: #666;
      font-weight: 600;
      text-align: left !important;
    }
    .value {
      color: black;
      overflow: hidden;
      text-align: left;
    }
  }
`;
