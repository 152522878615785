/** @format */

import { memo } from 'react';
import { StyledRadioGroup } from './style';

const RadioGroup = ({ options, value, onChange }) => {
  return (
    <StyledRadioGroup options={options} value={value} onChange={onChange} />
  );
};

export default memo(RadioGroup);
