/** @format */

import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';

export const ConnectionsContext = createContext();

export const useConnections = () => useContext(ConnectionsContext);

export const ConnectionsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ConnectionsContext.Provider value={[state, dispatch]}>
      {children}
    </ConnectionsContext.Provider>
  );
};
