import styled from 'styled-components';
import { getStatusColors } from 'globalCSS';
export const LoadsBoard = styled.div`
  width: 100%;
  ${({ dinamicheight, open }) =>
    dinamicheight === 'true'
      ? open
        ? 'height: calc(100% - 38px)'
        : 'height: 100%'
      : 'height: 100%'};
  position: relative;
  transition: all 0.3s;

  #loadingWrapper {
    position: absolute;
    top: 45%;
    left: 45%;
  }

  @media (max-width: 1400px) {
    .scrollable {
      overflow-x: auto !important;
    }
  }
`;
export const MainContent = styled.div`
  position: relative;
  height: 100%;
`;
export const LoadsCount = styled.button`
  min-width: 100px;
  font-family: var(--appPrimaryFont);
  font-size: var(--appTableFontSize);
  line-height: 16px;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  color: #313e47;
  align-items: center;
  border-radius: 5px;
  outline: none;
  height: 32px;
  cursor: pointer;
  transition: all 100ms linear;
  position: relative;
  border: ${({ name, open }) =>
    name !== 'ALL'
      ? getStatusColors({ status: name }).brd
      : open
        ? '1px solid rgba(0,0,0,1)'
        : '1px solid rgba(0,0,0,0.5)'};
  background: ${({ name }) => getStatusColors({ status: name }).bg};
  color: ${({ name }) => getStatusColors({ status: name }).text};
  padding: 4px 8px;
  gap: 8px;
  box-shadow: ${({ open }) =>
    open && '0px 2px 12px 2px   rgba(107,107,107,0.75)'};
  :hover {
    opacity: 0.8;
  }
  :open {
    opacity: 1;
  }

  .count {
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    color: #313e47;
  }
`;

LoadsCount.Wrapper = styled.div`
  padding: ${({ open }) => (open ? '5px' : '0px 5px')};
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  transition: all 0.3s;
  height: ${({ open }) => (open ? '42px' : '0')};
  overflow: hidden;
`;
LoadsCount.BtnOpenChanger = styled.div`
  position: absolute;
  width: 28px;
  height: 28px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  top: 0px;
  background: #f0f4ff;
  display: grid;
  place-items: center;
  border-radius: 0px 0px 5px 5px;
  z-index: 3;
`;