/** @format */

import { useEffect, useState } from 'react';
import { InfoWindow, Marker } from '@react-google-maps/api';
import { InfoStyle } from './style';

import { dayjs } from 'day-js';

const LoadInfoWindow = ({ place, point, label, startOrEnd }) => {
  const [open, setOpen] = useState(false);
  const [noGpsData, setNoGpsData] = useState(false);
  const [markerPosition, setMarkerPosition] = useState(null);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = (load) => {
    if (load?.id === point?.id) setOpen(true);
  };

  const geocodeAddress = async (address) => {
    const apiKey = import.meta.env?.VITE_APP_GOOGLE_API_KEY;
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address
    )}&key=${apiKey}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.status === 'OK') {
        const location = data.results[0].geometry.location;
        return location;
      } else {
        setNoGpsData(true);
        throw new Error('Geocoding failed: ' + data.status);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Geocoding error:', error);
      setNoGpsData(true);
      throw error;
    }
  };
  function isValidAddress(address) {
    if (typeof address === 'string') {
      return address.trim() !== '';
    } else if (typeof address === 'object' && address !== null) {
      return typeof address.lat === 'number' && typeof address.lng === 'number';
    }
    return false;
  }

  useEffect(() => {
    const isLatLngObject = (obj) =>
      typeof obj === 'object' &&
      obj !== null &&
      'lat' in obj &&
      'lng' in obj &&
      !isNaN(Number(obj.lat)) &&
      !isNaN(Number(obj.lng));
    if (!isValidAddress(place)) {
      setNoGpsData(true);
      return;
    } else {
      if (!isLatLngObject(place)) {
        isValidAddress(place) &&
          geocodeAddress(place)
            .then((coordinates) => {
              setMarkerPosition(coordinates);
            })
            .catch((error) => {
              setNoGpsData(true);

              // eslint-disable-next-line no-console
              console.log(error, 'error');
            });
      } else {
        setMarkerPosition(place);
      }
    }
  }, [place]);

  return (
    <>
      {markerPosition ? (
        <Marker
          position={markerPosition}
          label={label}
          icon={
            startOrEnd
              ? ''
              : {
                  path: window.google.maps.SymbolPath.CIRCLE,
                  scale: 10,
                  fillColor:
                    point?.typeOfStop === 'PICKUP' ||
                    point?.stopType === 'PICKUP'
                      ? 'green'
                      : 'red',
                  fillOpacity: 0.7,
                  strokeWeight: 0,
                  rotation: 0,
                }
          }
          onClick={() => handleOpen(point)}
        />
      ) : (
        <Marker
          position={place}
          label={label}
          icon={
            startOrEnd
              ? ''
              : {
                  path: window.google.maps.SymbolPath.CIRCLE,
                  scale: 10,
                  fillColor:
                    point?.typeOfStop === 'PICKUP' ||
                    point?.stopType === 'PICKUP'
                      ? 'green'
                      : 'red',
                  fillOpacity: 0.7,
                  strokeWeight: 0,
                  rotation: 0,
                }
          }
          onClick={() => handleOpen(point)}
        />
      )}
      ;
      {open ? (
        <InfoWindow position={place} onCloseClick={handleClose}>
          <InfoStyle>
            <InfoStyle.Row>
              <InfoStyle.Text
                name='true'
                title={`Location name: ${point?.name}`}
              >
                {point?.name || point?.locationName || '--'}
              </InfoStyle.Text>
              {point?.typeOfStop || point?.stopType ? (
                <InfoStyle.Span
                  stopType={point?.typeOfStop || point?.stopType}
                  title={point?.typeOfStop || point?.stopType}
                >
                  {point?.typeOfStop || point?.stopType}
                </InfoStyle.Span>
              ) : null}
            </InfoStyle.Row>
            <InfoStyle.Text lineHeight='13px' loc='true' title='address'>
              <div className='turncated' title={point?.address || '--'}>
                {point?.address || '--'}
              </div>
            </InfoStyle.Text>

            {point?.contactNumber1 || point?.contactNumber2 ? (
              <InfoStyle.Text>
                <div title='Phone number 1'>
                  Phone number:
                  {point?.contactNumber1
                    ? point?.contactNumber1
                    : point?.contactNumber2}
                </div>
              </InfoStyle.Text>
            ) : null}
            {point?.email ? (
              <InfoStyle.Text>
                <div title='EMAIL'>EMAIL:{point?.email}</div>
              </InfoStyle.Text>
            ) : null}

            {point?.startDate ? (
              <InfoStyle.Text lineHeight='13px' style={{ marginTop: '10px' }}>
                <div>Start date:</div>
                <div>{dayjs(point?.startDate).format('MM/DD/YYYY HH:mm')}</div>
              </InfoStyle.Text>
            ) : null}
            {point?.endDate ? (
              <InfoStyle.Text lineHeight='13px' style={{ marginTop: '10px' }}>
                <div>End date:</div>
                <div>{dayjs(point?.endDate).format('MM/DD/YYYY HH:mm')}</div>
              </InfoStyle.Text>
            ) : null}
          </InfoStyle>
        </InfoWindow>
      ) : null}
      {noGpsData && (
        <div
          style={{
            position: 'absolute',
            color: 'red',
            left: '50%',
            background: '#fff',
            fontWeight: 600,
            padding: '5px 10px',
            transform: 'translateX(-50%)',
          }}
        >
          No GPS data available
        </div>
      )}
    </>
  );
};

export default LoadInfoWindow;
