/** @format */
import axios from 'axios';
// import * as Sentry from '@sentry/react';
import { getCookie, deleteAllCookies } from 'formatter';
const useRequest = () => {
  const { VITE_APP_BASE_URL } = import.meta.env;
  const environment = import.meta.env.MODE;
  // const sendToSentry = (error, context) => {
  //   if (environment === 'pre-release') {
  //     Sentry.captureException(new Error(`${context}: ${error.toString()}`));
  //   }
  // };
  const request = async ({
    baseurl = VITE_APP_BASE_URL,
    url = '',
    method = 'GET',
    body = null,
    headers = {},
    includeToken = true,
    isPost = true,
    isRefreshToken = false,
  }) => {
    try {
      if (body && isPost) {
        body = JSON.stringify(body);
        headers['Content-Type'] = 'application/json';
      }
      if (includeToken) {
        headers['Authorization'] = `Bearer ${getCookie('token')}`;
      } else {
        // sendToSentry(new Error('Token not found'), 'Token Error');
        throw new Error('Authentication token not found.');
      }
      if (isRefreshToken) {
        const currentDate = new Date();
        headers = { ...headers, currentUserDate: currentDate.toISOString() };
      }

      const response = await axios({
        url: `${baseurl}${url}`,
        method: method,
        data: body,
        headers: headers,
      });
      return response.data;
    } catch (error) {
      if (environment === 'pre-release') {
        // const errorContext = error.response
        //   ? `Error: ${error.response.data.message}, ${error}`
        //   : `No response received from server: ${error}`;
        // Sentry.captureException(new Error(errorContext));
      }

      if (error?.response?.status === 401) {
        localStorage.clear();
        deleteAllCookies();
      }

      if (!error?.response) {
        throw new Error(error?.message);
      }
      throw error?.response?.data;
    }
  };

  return { request };
};

export default useRequest;
