/** @format */

import loading from 'assets/icons/Spinner.gif';
import { useAuth } from 'context';
import Button from './index.jsx';
import Toast from '../../ModalComponents/Toast';
import { useLocation } from 'react-router';
import { DownloadingSpinner } from './style';
import { DownloadIcon } from 'styledIcons';
import { downloader } from 'hooks';
import { useMutation } from '@tanstack/react-query';

export default function ExportButton({
  url,
  method,
  type,
  searchText,
  disabled,
  btnType,
  noText,
}) {
  const [state] = useAuth();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  params.delete('size');
  const newUrl = `?${params.toString()}`;
  const downLoad = useMutation({
    mutationFn: () => {
      return (
        url &&
        downloader.xslx(
          `${url}${searchText ? searchText : newUrl || ''}`,
          state.token,
          method || 'POST',
          type || 'xlsx'
        )
      );
    },
  });
  const downloadXSLX = () => {
    if (!disabled) {
      downLoad.mutate('', {
        onSuccess: (res) => {
          if (res?.success) {
            Toast({
              type: 'success',
              message: 'Data successfully downloaded',
            });
          }
        },
        onError: (err) =>
          Toast({
            type: 'error',
            message: err?.message,
          }),
      });
    }
  };
  return (
    <Button
      noText={noText}
      btnType={btnType || 'export'}
      onClick={downloadXSLX}
      title='Download'
    >
      {downLoad.isPending ? (
        <DownloadingSpinner src={loading} />
      ) : (
        <DownloadIcon />
      )}
      {noText ? '' : <div> {downLoad.isPending ? ' Exporting' : 'Export'}</div>}
    </Button>
  );
}
