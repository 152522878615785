/** @format */

import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';
export const DispatcherKpiBoardContext = createContext();
export const useDispatcherKpiBoard = () =>
  useContext(DispatcherKpiBoardContext);

export const DispatcherKpiBoardProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DispatcherKpiBoardContext.Provider value={[state, dispatch]}>
      {children}
    </DispatcherKpiBoardContext.Provider>
  );
};
