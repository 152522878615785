/** @format */

import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';

export const LocationPageContext = createContext();

export const useLocationContext = () => useContext(LocationPageContext);

const LocationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <LocationPageContext.Provider value={[state, dispatch]}>
      {children}
    </LocationPageContext.Provider>
  );
};

export default LocationProvider;
