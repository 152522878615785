/** @format */

import styled from 'styled-components';
import { Drawer } from '../../AntDesign';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: overlay;
  overflow-x: hidden;
  background-color: #fff;
  padding: ${({ nopadding }) => (nopadding == 'true' ? '0' : '10px 0 20px 0')};
  gap: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: ${({ position }) => (position ? position : 'relative')};
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow: auto;
  position: ${({ position }) => (position ? position : 'relative')};
  .ant-drawer-header button {
    left: -10px;
  }
`;

export const AntModal = styled(Drawer)`
  overflow: auto;
  padding: ${({ padding }) => (padding ? padding : 0)};
`;

export const ModalClose = styled.div`
  width: 28px;
  height: 36px;
  background: #fff;
  position: fixed;
  top: 4px;
  right: ${({ width }) => width || '54.9%'};
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  cursor: pointer;

  svg.close-icon {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
  }
`;
