/** @format */

import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';

export const EndorsementContext = createContext();

export const useEndorsement = () => useContext(EndorsementContext);

export const EndorsementProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <EndorsementContext.Provider value={[state, dispatch]}>
      {children}
    </EndorsementContext.Provider>
  );
};
