/** @format */

import { useState } from 'react';
import Header from '../FormHeader';
import { ItemWrap, Container, Wrapper } from './style';
import Datepicker from '../Datepicker';
import Uploader from './Uploader';
import { useMutation } from '@tanstack/react-query';
import { useRequest } from 'hooks';
import { Toast } from 'modals';
import { Loading } from 'loaders';
import Buttons from '../ButtonsSaveCancel';
import { dayjs } from 'day-js';

export default function SetDocsTrailer({
  closeDrawer,
  item,
  doctypeId,
  url,
  refetch,
  getCurrent,
  dispatch,
  urlUpload,
  driverRefetch,
  driverId,
  data,
  truckId,
  trailerId,
  isTrailer,
  isTruck,
  trip,
}) {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    date: '',
    expireDate: '',
    notes: '',
    uploadedFile: {},
    formData: '',
    docTypeId: doctypeId,
    name: item?.name,
    size: '',
  });

  const [length, setlength] = useState(0);
  const { request } = useRequest();
  const { formData, date, expireDate, notes } = state;
  const saveEquipment = useMutation({
    mutationFn: (dataList) => {
      const value = dataList?.map((att) => att?.attachmentId);
      return (
        truckId &&
        request({
          url: `/v2/trucks/${Number(truckId)}/documents`,
          method: 'POST',
          body: value,
        })
      );
    },
  });

  const saveTrailerDocs = useMutation({
    mutationFn: (dataList) => {
      const value = dataList?.map((att) => att?.attachmentId);
      return (
        // urlUpload !== undefined &&
        // attachmentId &&
        request({
          url: urlUpload,
          method: 'POST',
          body: value,
        })
      );
    },
  });

  const uploadFileMutation = useMutation({
    mutationKey: ['file'],
    mutationFn: async (file) => {
      return await request({
        url: trip
          ? `${url}?doc_type_id=${state.docTypeId}${
              notes ? '&note=' + notes : ''
            }${
              date
                ? '&register_date=' +
                  dayjs(date).format('YYYY-MM-DDTHH:mm:ss[Z]')
                : ''
            }${
              expireDate
                ? '&expire_date=' +
                  dayjs(expireDate).format('YYYY-MM-DDTHH:mm:ss[Z]')
                : ''
            }`
          : url,
        method: 'POST',
        'Content-Type': 'multipart/form-data',
        body: file,
        isPost: false,
      });
    },
  });
  /// Driver info uchun funksiyalar 22/02/2023 Asadbek
  const editDriver2 = useMutation({
    mutationFn: (values) => {
      return (
        data?.id &&
        request({
          url: `/driver/${data?.id}`,
          method: 'PUT',
          body: {
            ...data,
            attachments:
              data?.attachments && values?.value
                ? [...data.attachments, ...values.value]
                : data?.attachments
                ? data?.attachments
                : values?.value
                ? values?.value
                : [],
          },
        })
      );
    },
  });
  const onSave = async () => {
    setLoading(true);
    (isTruck || isTrailer) &&
      formData.append(
        'object_id',
        isTruck ? truckId : isTrailer ? trailerId : null
      );
    if (!trip) {
      formData.append('doc_type_id', doctypeId);
      formData.append(
        'register_date',
        date ? new Date(date).toISOString() : ''
      );
      formData.append(
        'expire_date',
        expireDate ? new Date(expireDate).toISOString() : ''
      );
      formData.append('note', notes);
    }

    const refetchDr = async () => {
      driverId &&
        driverRefetch?.mutateAsync(
          {
            unitNumber: driverId,
          },
          {
            onSuccess: (res) => {
              dispatch({
                type: 'setDriverInfo',
                payload: res?.dataList || [res?.data],
              });
            },
            onError: (err) =>
              Toast({
                type: 'error',
                message: err?.message,
              }),
          }
        );
    };
    const updateDriver = async (data) => {
      editDriver2.mutateAsync(
        { value: data },
        {
          onSuccess: (res) => {
            if (res?.success) {
              setLoading(false);
              closeDrawer();
              refetchDr();
            } else {
              setLoading(false);
            }
          },
          onError: () => {
            setLoading(false);
            Toast({
              type: 'error',
              message: `${
                data?.firstName + ' ' + data?.lastName
              } doesn't have E-Mail or Company, please check and update driver first.`,
            });
          },
        }
      );
    };

    uploadFileMutation
      .mutateAsync(formData)
      .then((res) => {
        if (res?.success) {
          if (driverId) {
            updateDriver(res?.dataList);
          } else {
            if (trip) {
              dispatch({
                type: 'setDocs',
                payload: [res?.data],
                own: true,
              });
            } else
              dispatch({
                type: 'setDocs',
                payload: res?.dataList,
                own: true,
              });
            if (urlUpload) {
              saveTrailerDocs.mutate(res?.dataList, {
                onSuccess: (res) => {
                  if (res?.success) {
                    refetch();
                    if (getCurrent !== null) {
                      getCurrent();
                    }
                    return closeDrawer();
                  }
                },
                onError: (err) =>
                  Toast({
                    type: 'error',
                    message: err?.message,
                  }),
              });
            } else if (truckId !== undefined && Number(truckId)) {
              saveEquipment.mutate(res?.dataList, {
                onSuccess: (res) => {
                  if (res?.success) {
                    refetch && refetch();
                    if (getCurrent !== null) {
                      getCurrent();
                    }
                    return closeDrawer();
                  }
                },
                onError: (err) =>
                  Toast({
                    type: 'error',
                    message: err?.message,
                  }),
              });
            }

            setLoading(false);
            setState((prev) => {
              return {
                ...prev,
                uploadedFile: res?.data,
              };
            });

            closeDrawer();
          }
        } else {
          setLoading(false);
          Toast({
            type: 'error',
            message: res?.message,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        Toast({
          type: 'error',
          message: err?.message,
        });
      });
  };

  const handleDocDate = (value) => {
    setState({
      ...state,
      date: value,
    });
  };

  const handleExpDate = (value) => {
    setState({
      ...state,
      expireDate: value,
    });
  };

  const noteHandler = (e) => {
    const { value } = e.target;

    setState({
      ...state,
      notes: value,
    });
  };

  const docDateInput = (
    <Datepicker
      onChange={handleDocDate}
      format='MM/DD/YYYY'
      bgcolor='#fff'
      height='32px'
      label='Document date'
      labelmargin='0 0 4px 0'
      width='100%'
      bordercolor='#D8D9DD'
      margin='5px 0 4px 0'
      showTime={false}
      value={state?.date || null}
    />
  );

  const expireDateInput = (
    <Datepicker
      onChange={handleExpDate}
      format='MM/DD/YYYY'
      bgcolor='#fff'
      label='Expire date'
      labelmargin='0 0 4px 0'
      width='100%'
      margin='5px 0 4px 0'
      height='32px'
      bordercolor='#D8D9DD'
      showTime={false}
      value={state?.expireDate || null}
    />
  );

  return (
    <Container>
      {loading ? (
        <div className='flexCenter'>
          <Loading />
        </div>
      ) : (
        <>
          <Header title='Upload document' />
          <Wrapper>
            <Uploader
              setState={setState}
              trip={trip}
              state={state}
              setlength={setlength}
            />
            {item?.expirable ? (
              <ItemWrap.Row calc additional style={{ columnGap: '24px' }}>
                {docDateInput}
                {expireDateInput}
              </ItemWrap.Row>
            ) : (
              <div>{docDateInput}</div>
            )}
            <ItemWrap.Row upload nogap>
              <span
                style={{
                  margin: '12px 0 4px',
                  fontSize: '12px',
                  lineHeight: '16px',
                  fontWeight: '600',
                }}
              >
                NOTES
              </span>
              <ItemWrap.TextArea
                placeholder='Notes...'
                onChange={noteHandler}
                value={notes || ''}
              />
            </ItemWrap.Row>
            <div style={{ marginTop: '32px' }}>
              <Buttons
                onSave={onSave}
                onCancel={closeDrawer}
                isloading={!length ? true : false}
                disabled={!state?.formData}
                titleSaveBtn={!state?.formData && 'Please upload file(s)!'}
              />
            </div>
          </Wrapper>
        </>
      )}
    </Container>
  );
}
