/** @format */
import styled from 'styled-components';

const Wrapper = styled.div`
  display: ${({ display }) => (display == 'true' ? 'flex' : 'block')};
  min-height: 100vh;
  overflow-x: hidden;
  padding: auto;
  background-size: cover;
  background-color: ${({ bg_color }) => bg_color};
  white-space: pre-line;
`;
export { Wrapper };
