// Initial state configuration for the reducer
export const initialState = {
  page: 1,
  size: 10,
  total: 0,
  permission: {},
  attachments: [],
  pageDataList: [],
  driverPageDataList: [],
  trailerPageDataList: [],
  pageData: {},
  saveFunction: () => { },
  onCancelFunction: () => { },
  stateChange: false,
  // options
  endorsmentNumberAndIdOptions: [],
  policyNumberAndIdOptions: [],
  userOptions: [],
  reasonTypeOptions: [],
};

// Reducer function to manage state updates based on action types
export const reducer = (state, action) => {
  switch (action.type) {
    case 'setDocs': {
      if (action.own === 'true') {
        // If the action is from the owner, directly update attachments
        return {
          ...state,
          attachments: action.payload,
        };
      }

      // Format the data for non-owner actions
      const formattedData =
        action.payload?.map((d) => ({
          attachmentId: d?.attachmentId || d.uploadedFile?.attachmentId,
          date: d?.date,
          docTypeId: d?.docTypeId,
          extension: d?.extension,
          name: d?.name,
          notes: d?.notes,
          path: d?.path || d?.uploadedFile?.path,
          size: d?.size,
          uploadedFile: d?.uploadedFile || [],
        })) || [];

      // Concatenate with existing attachments if any
      const updatedAttachments = state.attachments.length
        ? formattedData.concat(state.attachments)
        : formattedData;

      return {
        ...state,
        attachments: updatedAttachments,
      };
    }

    case 'setPermission':
      return { ...state, permission: action.payload };

    case 'setPageData':
      return { ...state, pageData: action.payload };

    case 'setPageDataList':
      return { ...state, pageDataList: action.payload };
    case 'setTrailerPageDataList':
      return { ...state, trailerPageDataList: action.payload };
    case 'setDriverPageDataList':
      return { ...state, driverPageDataList: action.payload };

    case 'setPage':
      return { ...state, page: action.payload };

    case 'setSize':
      return { ...state, size: action.payload };

    case 'setTotal':
      return { ...state, total: action.payload };

    case 'setSaveFunction':
      return { ...state, saveFunction: action.payload };

    case 'setOnCancelFunction':
      return { ...state, onCancelFunction: action.payload };

    case 'setStateChange':
      return { ...state, stateChange: action.payload };
    case 'setEndorsmentNumberAndIdOptions':
      return { ...state, endorsmentNumberAndIdOptions: action.payload };
    case 'setPolicyNumberAndIdOptions':
      return { ...state, policyNumberAndIdOptions: action.payload };
    case 'setUserOptions':
      return { ...state, userOptions: action.payload };
    case 'setReasonTypeOptions':
      return { ...state, reasonTypeOptions: action.payload };

    default:
      return state;
  }
};
