/** @format */

import { Select } from '../../AntDesign';
import { StyledMultiSelect } from './style';

const { Option } = Select;

const MultiSelect = ({ options, ...props }) => {
  return (
    <StyledMultiSelect
      popupMatchSelectWidth={false}
      mode='multiple'
      optionLabelProp='label'
      notFoundContent={'No data found!'}
      maxTagCount='responsive'
      {...props}
    >
      {options?.length
        ? options?.map((option, index) => (
            <Option
              value={option.value}
              label={option.value}
              key={option.id || index}
              id={option.id || index}
            >
              <div className='demo-option-label-item'>
                {option.value === null
                  ? 'All'
                  : props.isKeyOption === 'true'
                  ? option.key
                  : option.value}
              </div>
            </Option>
          ))
        : null}
    </StyledMultiSelect>
  );
};

export default MultiSelect;
