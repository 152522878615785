import styled from 'styled-components';
import { ReactComponent as Attachment } from 'assets/icons/attachment.svg';
import { ReactComponent as Delete } from 'assets/icons/delete.svg';

export const Uploder = styled.div`
  position: relative;
  width: 100%;
  height: ${({ height }) => (height ? height : '120px')};
  border: 2px dashed #0363ff;
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '2px')};
  margin: 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f6ff;

  &:hover,
  &:focus {
    opacity: 0.8;
  }
`;

Uploder.Label = styled.div`
  text-align: center;
  color: #d9d9d9;
  font-weight: 600;
  padding: 10px;
`;

Uploder.Text = styled('p')`
  font-size: 14px;
  color: #6b7280;
  font-weight: 400;
  font-family: var(--appPrimaryFont);
  margin-bottom: 0;
`;

Uploder.Input = styled('input')`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

Uploder.Preview = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

Uploder.Wrapper = styled.div`
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
`;

Uploder.PreviewTitle = styled('p')`
  font-weight: 500;
  margin-bottom: 20px;
`;

Uploder.PreviewItem = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  overflow: hidden;
  /* margin-bottom: 10px; */
  background-color: #5c6470;
  padding: 9px;
  margin-right: 10px;
  border-radius: 10px;
`;

Uploder.PreviewInfo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
`;

Uploder.InfoText = styled.div`
  width: 100%;
  font-size: 14px;
  color: #fff;
  margin: -5px;
  div.turncate {
    width: calc(100%);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
  }
`;

export const AttachmentIcon = styled(Attachment)`
  width: 26px;
  height: 45px;
  margin-bottom: 8px;
  path {
    fill: #fff;
  }
`;

export const ButtonBox = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DeleteIcon = styled(Delete)`
  width: ${({ multiple }) => (multiple ? '14px' : '25px')};
  height: ${({ multiple }) => (multiple ? '14px' : '25px')};
  position: absolute;
  top: 8px;
  right: -2px;
  transform: translateX(-50%);
  cursor: pointer;
  path {
    fill: red;
  }
`;
