/** @format */

import styled from 'styled-components';
import { Modal } from '../../AntDesign';
import { ReactComponent as close } from 'assets/icons/close-line.svg';
import { ReactComponent as steps } from 'assets/icons/step-point.svg';
import { ReactComponent as current } from 'assets/icons/triangle-icon-current-moves.svg';

export const Wrapper = styled.div`
  width: 100%;
  background-color: #fff;
  position: relative;
  overflow-x: hidden;
  display: 'flex';
  .flex {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  .column {
    gap: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  button[aria-label='Close'] {
    display: none !important;
  }
`;

Wrapper.Button = styled.div`
  position: absolute;
  z-index: 5;
  top: 5px;
  right: 15px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ showmap }) =>
    !showmap ? '0.9fr 2fr' : '0.6fr 1.5fr 1.5fr'};
  /* gap: 20px; */
`;

export const AntModal = styled(Modal)`
  min-width: 1190px;
  overflow: auto;
  overflow-y: hidden !important;
  padding: 0;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: rgba(92, 100, 112, 0.6);
`;

export const Header = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #313e47;
  margin-bottom: 13px;
  text-transform: uppercase;
  font-family: Roboto;
`;
export const Icon = styled.div``;

Icon.Current = styled(current)`
  width: 18px;
  cursor: pointer;
  height: 18px;
`;
Icon.Close = styled(close)`
  position: absolute;
  top: 0;
  right: 0;
  width: 18px;
  height: 18px;
  cursor: pointer;
`;
Icon.Default = styled(steps)`
  width: 18px;
  height: 18px;
  cursor: pointer;
`;
