/** @format */

import { createContext, useContext, useReducer } from 'react';
import { initialState, reducer } from './reducer';
export const SafetyDashboard = createContext();

export const useSafetyDashboard = () => useContext(SafetyDashboard);

const SafetyDashboardProvider = ({ children }) => {
  return (
    <SafetyDashboard.Provider value={useReducer(reducer, initialState)}>
      {children}
    </SafetyDashboard.Provider>
  );
};

export default SafetyDashboardProvider;
