/** @format */
/**
 * Here QC Teams are seen in sequence.
 *
 * @returns {React.Component} The `QcReportPage` page's component returning to the this url "https://tms.nightwell-logistics.com/qc-board/reports"
 */

import { memo, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useRequest, useQuery } from 'hooks';
import { useAuth, useQcReport } from 'context';
import { TopBarTitle, Pagination, Table, ExportButton } from 'forms';
import { Toast } from 'modals';
import { deleteQueryParams } from 'formatter';
import { PageWrapper, TopBar } from 'globalCSS';

import { columns } from './header';
import {
  FilterWrapper,
  TableRow,
  TablesWrapper,
  TotalsRow,
  Wrapper,
} from './style';
import TopHeaderFilter from '../TopHeaderFilter';

const QcReportPage = () => {
  const { search } = useLocation();
  const { request } = useRequest();
  const [authState] = useAuth();
  const [constantsState, dispatch] = useQcReport();
  const { total, qcReport, permission } = constantsState;
  const {
    totalLoad,
    onTime,
    delayed,
    controllable,
    unControllable,
    rejected,
    canceled,
    performance,
  } = qcReport;

  // for getting permittion to action in this page
  useEffect(() => {
    if (authState?.permission?.['Data Library']?.Company) {
      dispatch({
        type: 'setPermission',
        payload: authState?.permission?.['Data Library']?.Company,
      });
    }
  }, [authState?.permission?.['Data Library']?.Company?.toString()]);

  // for getting necessary queries of search, 'string'
  const newSearch = useMemo(() => {
    const params = new URLSearchParams(search);
    const keysToDelete = ['driver-name', 'id'];
    return deleteQueryParams(params, keysToDelete);
  }, [search]);
  // for getting data of page, '{}'
  const getConstantsList = useQuery(
    ['/qc-report/list', newSearch],
    () =>
      request({
        url: `/qc-report/list${newSearch || '?'}`,
      }),
    {
      onSuccess: (data) => {
        dispatch({ type: 'setQcReport', payload: data?.data });
        dispatch({ type: 'setTotal', payload: data?.meta?.total });
        dispatch({ type: 'setRefetch', payload: getConstantsList?.refetch });
      },
      onError: ({ message }) => Toast({ type: 'error', message }),
    }
  );

  // for getting widths of each column, '[]'
  const widthOfHeaderColumns = useMemo(() =>
    [{ width: 200 }, ...columns]
      .map(({ width, flex }) =>
        flex ? flex + 'fr' : width ? width + 'px' : '1fr'
      )
      ?.join(' ')
  );

  return (
    <PageWrapper>
      <TopBar>
        <TopBarTitle title={'QC Board / Reports'} total={total} />
        <TopBar.Right>
          <FilterWrapper>
            <TopHeaderFilter isHideTeams={true} isHideMembers={true} />
          </FilterWrapper>
          {permission?.DOWNLOAD && (
            <ExportButton url={'/v3/qc-report/export'} btnType='export' />
          )}
        </TopBar.Right>
      </TopBar>

      <TablesWrapper>
        <Wrapper.Header grid={widthOfHeaderColumns} from_top='0px' z_index={1}>
          {[
            { headerName: 'QC team' },
            ...columns.map(({ headerName }) => ({
              headerName: headerName,
            })),
          ].map(({ headerName }, i) => (
            <Wrapper.Cell key={i}>
              <h1>{headerName || ''}</h1>
            </Wrapper.Cell>
          ))}
        </Wrapper.Header>

        {qcReport?.qcReportList?.map((rep, i) => {
          return (
            <TableRow key={i}>
              <div className='team-name'>{rep?.qcTeamName}</div>
              <div className='table-box'>
                <Table
                  removeHeader
                  rowData={rep?.qcReportDetails}
                  columnDefs={columns}
                  isFetching={getConstantsList?.isFetching}
                />
              </div>
            </TableRow>
          );
        })}
      </TablesWrapper>
      <TotalsRow>
        <Wrapper.Header
          background='#ffffd6'
          grid={widthOfHeaderColumns}
          from_top='90%'
          z_index={1}
        >
          {[
            '',
            '',
            totalLoad || '0',
            onTime || '0',
            delayed || '0',
            controllable || '0',
            unControllable || '0',
            rejected || '0',
            canceled || '0',
            (performance || 0) + '%',
          ].map((headerName, i) => (
            <Wrapper.Cell
              key={i}
              background={i == 0 || i == 1 ? '#f4f4f4' : 'none'}
              border_left={(i == 0 || i == 1) && '1px solid inherit'}
            >
              <h1>{headerName || ''}</h1>
            </Wrapper.Cell>
          ))}
        </Wrapper.Header>
      </TotalsRow>
      <Pagination length={columns?.length} total={total} />
    </PageWrapper>
  );
};

export default memo(QcReportPage);
