/** @format */

import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';
export const DriversContext = createContext();

export const useDrivers = () => useContext(DriversContext);

export const DriversProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DriversContext.Provider value={[state, dispatch]}>
      {children}
    </DriversContext.Provider>
  );
};
