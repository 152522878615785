/** @format */

import styled from 'styled-components';
import avatar from 'assets/imgs/logo-avatar.png';

export const UploadImage = styled('input')`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;
export const ProfileImage = styled('img')`
  object-fit: cover;
  width: 150px;
  height: 175px;
`;
export const PersonnalBox = styled.div`
  width: 100%;
  margin: 20px 10px;
  display: flex;
  gap: 24px;
`;

PersonnalBox.Left = styled.div`
  padding-top: 14px;
  display: flex;
  flex-direction: column;
`;
PersonnalBox.Preview = styled.div`
  width: 150px;
  height: 175px;
`;
export const Avatar = styled('div')`
  width: 150px;
  height: 175px;
  background: url(${avatar});
`;
