/** @format */

import { useState, useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Toast } from 'modals';
import { Loading } from 'loaders';
import {
  AntCollapse,
  AntPanelHead,
  Wrapper,
  AntImage,
  PdfReader,
  TextReader,
  HtmlReader,
  PptReader,
  SqlReader,
  ZipReader,
  Mp3File,
  Mp4File,
  JsonReaderIcon,
  WordReader,
  ExcelReader,
  FileWrapper,
} from './style';
import { DownloadIcon } from 'styledIcons';
import Body from './PanelBody';
import SubInfo from './SubInfo';
import { MainModal } from 'modals';
import SetDocsTrailer from '../SetDocMultiple';
import loadingSpinner from 'assets/icons/image-loading-spinner.gif';
import { useRequest } from 'hooks';
import { useLocation } from 'react-router-dom';

const UploaderListNew = ({
  fileTypes,
  isLoading,
  fetching,
  data,
  dispatch,
  handleFind,
  margintop,
  noPanelHead,
  refetch,
  getCurrent,
  loadsadd,
  headertitle,
  isHandleFind,
  loadId,
  driverRefetch,
  driverId,
  editPermission,
  deletePermission,
  urlUpload,
  truckId,
  trailerId,
  isTrailer,
  isTruck,
  // removeParent,
  isParentUploader,
  trip,
}) => {
  const [galView, setGalView] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { pathname, search } = useLocation();
  const [doc, setItem] = useState({});
  const [id, setId] = useState();

  const handleCollapse = (id) => {
    setSelectedId(id);
  };
  const closeDrawerFile = () => {
    setDrawerOpen(false);
  };

  const encodeFilePath = (path) => {
    if (typeof path === 'string') {
      const lastIndex = path.lastIndexOf('/');
      return (
        path.substring(0, lastIndex + 1) +
        encodeURIComponent(path.substring(lastIndex + 1))
      );
    }
    return pathname + search;
  };

  const downloadDocs = useMutation({
    mutationFn: ({ url, extension }) => {
      return fetch(encodeFilePath(url), {
        method: 'GET',
        headers: {},
      })
        .then((response) => {
          response.arrayBuffer().then(function (buffer) {
            const url = window.URL.createObjectURL(new Blob([buffer]));
            const link = document.createElement('a');

            link.href = url;
            link.download = extension || 'image.jpeg' || 'image.bmp';
            document.body.appendChild(link);
            link.click();
          });
        })
        .catch((error) => {
          return error;
        });
    },
  });

  const download = useCallback((e, path, extension) => {
    if (e.preventDefault) {
      e.preventDefault();
    }
    if (!path?.length) {
      return;
    }
    const fetchUrl = `${encodeFilePath(path)}`;

    downloadDocs?.mutate(
      { url: fetchUrl, extension },
      {
        onSuccess: (res) => {
          if (res?.success) {
            Toast({
              type: 'success',
              message: 'Document successfully downloaded',
            });
          }
        },
        onError: (err) =>
          Toast({
            type: 'error',
            message: err?.message,
          }),
      }
    );
  }, []);

  const showFile = (f) => {
    let lastDot = f?.name.lastIndexOf('.');
    let ext = f?.name?.substring(lastDot);
    let file = f?.extension ? f?.extension : f?.uploadedFile?.extension;
    let imgWidth = '65px';
    let imgHeight = '80px';
    let withModal = 'false';
    switch (true) {
      case file === 'image/jpeg':
        return (
          <AntImage
            imgWidth={imgWidth}
            imgHeight={imgHeight}
            preview={!withModal}
            src={f?.path}
            placeholder={
              <AntImage
                imgWidth={imgWidth}
                imgHeight={imgHeight}
                preview={false}
                src={loadingSpinner}
                alt='Loading...'
              />
            }
            alt='file'
          />
        );
      case file === 'image/gif':
        return (
          <AntImage
            imgWidth={imgWidth}
            imgHeight={imgHeight}
            preview={!withModal}
            src={f?.path}
            placeholder={
              <AntImage
                imgWidth={imgWidth}
                imgHeight={imgHeight}
                preview={false}
                src={loadingSpinner}
                alt='Loading...'
              />
            }
            alt='file'
          />
        );
      case file === 'image/svg+xml':
        return (
          <AntImage
            imgWidth={imgWidth}
            imgHeight={imgHeight}
            preview={!withModal}
            src={f?.path}
            placeholder={
              <AntImage
                imgWidth={imgWidth}
                imgHeight={imgHeight}
                preview={false}
                src={loadingSpinner}
                alt='Loading...'
              />
            }
            alt='file'
          />
        );
      case file === 'image/bmp':
        return (
          <AntImage
            imgWidth={imgWidth}
            imgHeight={imgHeight}
            preview={!withModal}
            src={f?.path}
            placeholder={
              <AntImage
                imgWidth={imgWidth}
                imgHeight={imgHeight}
                preview={false}
                src={loadingSpinner}
                alt='Loading...'
              />
            }
            alt='file'
          />
        );
      case file === 'image/png':
        return (
          <AntImage
            imgWidth={imgWidth}
            imgHeight={imgHeight}
            preview={!withModal}
            src={f?.path}
            placeholder={
              <AntImage
                imgWidth={imgWidth}
                imgHeight={imgHeight}
                preview={false}
                src={loadingSpinner}
                alt='Loading...'
              />
            }
            alt='file'
          />
        );
      case file === 'image/*':
        return (
          <AntImage
            imgWidth={imgWidth}
            imgHeight={imgHeight}
            preview={!withModal}
            src={f?.path}
            placeholder={
              <AntImage
                imgWidth={imgWidth}
                imgHeight={imgHeight}
                preview={false}
                src={loadingSpinner}
                alt='Loading...'
              />
            }
            alt='file'
          />
        );
      case file === 'application/pdf':
        return (
          <a
            href={encodeFilePath(f?.path)}
            download='cargoprime.pdf'
            onClick={(e) => download(e, f?.path, 'cargoprime.pdf')}
          >
            <FileWrapper width={imgWidth} height={imgHeight}>
              <div className='uploadimg'>
                {downloadDocs?.isLoading ? (
                  <AntImage
                    imgWidth={imgWidth}
                    imgHeight={imgHeight}
                    preview={false}
                    src={loadingSpinner}
                    alt='Loading...'
                  />
                ) : (
                  <>
                    <DownloadIcon />
                    <span>Download</span>
                  </>
                )}
              </div>
              <PdfReader imgWidth={imgWidth} imgHeight={imgHeight} />
            </FileWrapper>
          </a>
        );
      case file === 'text/plain':
        return (
          <a
            href={f?.path}
            download='cargoprime.txt'
            onClick={(e) => download(e, f?.path, 'cargoprime.txt')}
          >
            <FileWrapper width={imgWidth} height={imgHeight}>
              <div className='uploadimg'>
                {downloadDocs?.isLoading ? (
                  <AntImage
                    imgWidth={imgWidth}
                    imgHeight={imgHeight}
                    preview={false}
                    src={loadingSpinner}
                    alt='Loading...'
                  />
                ) : (
                  <>
                    <DownloadIcon />
                    <span>Download</span>
                  </>
                )}
              </div>
              <TextReader imgWidth={imgWidth} imgHeight={imgHeight} />
            </FileWrapper>
          </a>
        );
      case file === 'application/json':
        return (
          <a
            href={f?.path}
            download='cargoprime.json'
            onClick={(e) => download(e, f?.path, 'cargoprime.json')}
          >
            <FileWrapper width={imgWidth} height={imgHeight}>
              <div className='uploadimg'>
                {downloadDocs?.isLoading ? (
                  <AntImage
                    imgWidth={imgWidth}
                    imgHeight={imgHeight}
                    preview={false}
                    src={loadingSpinner}
                    alt='Loading...'
                  />
                ) : (
                  <>
                    <DownloadIcon />
                    <span>Download</span>
                  </>
                )}
              </div>
              <JsonReaderIcon imgWidth={imgWidth} imgHeight={imgHeight} />
            </FileWrapper>
          </a>
        );
      case file === 'application/x-zip-compressed':
        return (
          <a
            href={f?.path}
            download='cargoprime.zip'
            onClick={(e) => download(e, f?.path, 'cargoprime.zip')}
          >
            <FileWrapper width={imgWidth} height={imgHeight}>
              <div className='uploadimg'>
                {downloadDocs?.isLoading ? (
                  <AntImage
                    imgWidth={imgWidth}
                    imgHeight={imgHeight}
                    preview={false}
                    src={loadingSpinner}
                    alt='Loading...'
                  />
                ) : (
                  <>
                    <DownloadIcon />
                    <span>Download</span>
                  </>
                )}
              </div>
              <ZipReader imgWidth={imgWidth} imgHeight={imgHeight} />
            </FileWrapper>
          </a>
        );
      case file ===
        'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return (
          <a
            href={f?.path}
            download='cargoprime.pptx'
            onClick={(e) => download(e, f?.path, 'cargoprime.pptx')}
          >
            <FileWrapper width={imgWidth} height={imgHeight}>
              <div className='uploadimg'>
                {downloadDocs?.isLoading ? (
                  <AntImage
                    imgWidth={imgWidth}
                    imgHeight={imgHeight}
                    preview={false}
                    src={loadingSpinner}
                    alt='Loading...'
                  />
                ) : (
                  <>
                    <DownloadIcon />
                    <span>Download</span>
                  </>
                )}
              </div>
              <PptReader imgWidth={imgWidth} imgHeight={imgHeight} />
            </FileWrapper>
          </a>
        );
      case file === 'text/html':
        return (
          <a
            href={f?.path}
            download='cargoprime.html'
            onClick={(e) => download(e, f?.path, 'cargoprime.html')}
          >
            <FileWrapper width={imgWidth} height={imgHeight}>
              <div className='uploadimg'>
                {downloadDocs?.isLoading ? (
                  <AntImage
                    imgWidth={imgWidth}
                    imgHeight={imgHeight}
                    preview={false}
                    src={loadingSpinner}
                    alt='Loading...'
                  />
                ) : (
                  <>
                    <DownloadIcon />
                    <span>Download</span>
                  </>
                )}
              </div>
              <HtmlReader imgWidth={imgWidth} imgHeight={imgHeight} />
            </FileWrapper>
          </a>
        );
      case file === 'video/mp4':
        return (
          <a
            href={f?.path}
            download='cargoprime.mp4'
            onClick={(e) => download(e, f?.path, 'cargoprime.mp4')}
          >
            <FileWrapper width={imgWidth} height={imgHeight}>
              <div className='uploadimg'>
                {downloadDocs?.isLoading ? (
                  <AntImage
                    imgWidth={imgWidth}
                    imgHeight={imgHeight}
                    preview={false}
                    src={loadingSpinner}
                    alt='Loading...'
                  />
                ) : (
                  <>
                    <DownloadIcon />
                    <span>Download</span>
                  </>
                )}
              </div>
              <Mp4File imgWidth={imgWidth} imgHeight={imgHeight} />
            </FileWrapper>
          </a>
        );
      case file === 'audio/mpeg':
        return (
          <a
            href={f?.path}
            download='cargoprime.mp3'
            onClick={(e) => download(e, f?.path, 'cargoprime.mp3')}
          >
            <FileWrapper width={imgWidth} height={imgHeight}>
              <div className='uploadimg'>
                {downloadDocs?.isLoading ? (
                  <AntImage
                    imgWidth={imgWidth}
                    imgHeight={imgHeight}
                    preview={false}
                    src={loadingSpinner}
                    alt='Loading...'
                  />
                ) : (
                  <>
                    <DownloadIcon />
                    <span>Download</span>
                  </>
                )}
              </div>
              <Mp3File imgWidth={imgWidth} imgHeight={imgHeight} />
            </FileWrapper>
          </a>
        );
      case file === '':
        return (
          <a
            href={f?.path}
            download={f?.name || `cargoprime${ext}`}
            onClick={(e) => download(e, f?.path, f?.name || `cargoprime${ext}`)}
          >
            <FileWrapper width={imgWidth} height={imgHeight}>
              <div className='uploadimg'>
                {downloadDocs?.isLoading ? (
                  <AntImage
                    imgWidth={imgWidth}
                    imgHeight={imgHeight}
                    preview={false}
                    src={loadingSpinner}
                    alt='Loading...'
                  />
                ) : (
                  <>
                    <DownloadIcon />
                    <span>Download</span>
                  </>
                )}
              </div>
              {ext === '.sql' && (
                <SqlReader imgWidth={imgWidth} imgHeight={imgHeight} />
              )}
            </FileWrapper>
          </a>
        );
      case file ===
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return (
          <a
            href={f?.path}
            download='cargoprime.docx'
            onClick={(e) => download(e, f?.path, 'cargoprime.docx')}
          >
            <FileWrapper width={imgWidth} height={imgHeight}>
              <div className='uploadimg'>
                {downloadDocs?.isLoading ? (
                  <AntImage
                    imgWidth={imgWidth}
                    imgHeight={imgHeight}
                    preview={false}
                    src={loadingSpinner}
                    alt='Loading...'
                  />
                ) : (
                  <>
                    <DownloadIcon />
                    <span>Download</span>
                  </>
                )}
              </div>
              <WordReader imgWidth={imgWidth} imgHeight={imgHeight} />
            </FileWrapper>
          </a>
        );
      case file ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return (
          <a
            href={f?.path}
            download='cargoprime.xlsx'
            onClick={(e) => download(e, f?.path, 'cargoprime.xlsx')}
          >
            <FileWrapper width={imgWidth} height={imgHeight}>
              <div className='uploadimg'>
                {downloadDocs?.isLoading ? (
                  <AntImage
                    imgWidth={imgWidth}
                    imgHeight={imgHeight}
                    preview={false}
                    src={loadingSpinner}
                    alt='Loading...'
                  />
                ) : (
                  <>
                    <DownloadIcon />
                    <span>Download</span>
                  </>
                )}
              </div>
              <ExcelReader imgWidth={imgWidth} imgHeight={imgHeight} />
            </FileWrapper>
          </a>
        );
    }
  };

  //delete files
  const { request } = useRequest();

  const deleteAttachment = useMutation({
    mutationFn: (id) => {
      return request({
        url: `/v2/file_attachments/${id}`,
        method: 'DELETE',
      });
    },
  });
  function showConfirm(attached) {
    !driverId
      ? deleteAttachment.mutate(attached?.id || attached?.attachmentId, {
          onSuccess: () => {
            let newData = data?.attachments?.filter(
              (doc) =>
                doc.attachmentId !== attached?.attachmentId ||
                doc.id !== attached?.id
            );
            dispatch({
              type: 'setDocs',
              payload: newData,
              own: 'true',
            });
          },
        })
      : deleteAttachment.mutate(attached?.id || attached?.attachmentId, {
          onSuccess: () => {
            driverRefetch?.mutate(
              {
                unitNumber: driverId,
              },
              {
                onSuccess: (res) => {
                  dispatch({
                    type: 'setDriverLoading',
                    payload: false,
                  }),
                    dispatch({
                      type: 'setDriverInfo',
                      payload: res?.dataList || [res?.data],
                    });
                },
              }
            );
          },
        });
  }

  return (
    <Wrapper margintop={margintop}>
      <MainModal
        drawerOpen={drawerOpen}
        width='720px'
        closeDrawer={closeDrawerFile}
        noPadding
      >
        <SetDocsTrailer
          closeDrawer={closeDrawerFile}
          dispatch={dispatch}
          id={id}
          doctypeId={id}
          refetch={refetch}
          getCurrent={getCurrent || null}
          item={doc}
          driverRefetch={driverRefetch}
          driverId={driverId}
          urlUpload={urlUpload}
          data={data}
          truckId={truckId}
          trailerId={trailerId}
          isTrailer={isTrailer}
          isTruck={isTruck}
          trip={trip}
          url={
            loadId
              ? `/v2/file_attachments/${loadId}/trip/upload`
              : '/v2/file_attachments/upload/new/list'
          }
        />
      </MainModal>
      {!noPanelHead && (
        <AntPanelHead
          header={headertitle || 'Upload Files'}
          collapsible='disabled'
          className='listheader'
          isload={loadsadd?.toString()}
        />
      )}
      {isLoading ? (
        <Loading height='300' />
      ) : fileTypes?.length === 0 ? (
        <div
          style={{
            width: '100%',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <center style={{ paddingTop: 12, width: '100%' }}>
            No file types!
          </center>
        </div>
      ) : (
        <AntCollapse
          bordered
          onChange={handleCollapse}
          accordion
          items={fileTypes?.map((item) => {
            return {
              key: item?.id,
              className: 'panel',
              label: (
                <Body
                  setItem={setItem}
                  setGalView={setGalView}
                  galView={galView}
                  item={item}
                  files={fileTypes}
                  editPermission={
                    isParentUploader === 'true' && item?.parentDocType
                      ? false
                      : data?.permission?.EDIT || editPermission
                  }
                  attachments={data?.attachments?.filter((attach) => {
                    return (
                      attach?.docTypeName === item?.name ||
                      attach?.name === item?.name ||
                      attach?.docTypeId === item?.id
                    );
                  })}
                  setId={setId}
                  setDrawerOpen={setDrawerOpen}
                  refetch={handleFind}
                  isLoad={isLoading}
                  isFetching={fetching}
                />
              ),

              children: (
                <Wrapper>
                  <SubInfo
                    data={data}
                    dispatch={dispatch}
                    id={selectedId}
                    isLoading={isLoading}
                    download={download}
                    galView={galView}
                    showFile={showFile}
                    showConfirm={showConfirm}
                    isHandleFind={isHandleFind}
                    driverRefetch={driverRefetch}
                    driverId={driverId}
                    deletePermission={
                      isParentUploader === 'true' && item?.parentDocType
                        ? false
                        : data?.permission?.DELETE_DOCUMENT || deletePermission
                    }
                    // setCount={setCount}
                    attachments={
                      data?.attachments?.length > 0 &&
                      data?.attachments?.filter((att) =>
                        // att?.name === item?.name

                        {
                          return (
                            att?.docTypeName === item?.name ||
                            att?.name === item?.name ||
                            att?.docTypeId === item?.id
                          );
                        }
                      )
                    }
                  />
                </Wrapper>
              ),
            };
          })}
        />
      )}
    </Wrapper>
  );
};

export default UploaderListNew;
