/** @format */

import { ConfigProvider } from 'antd';
import { FeedbackIcon } from '../../../styledIcons';
import { FloatButton } from '../../AntDesign';
import { useLocation } from 'react-router';

export default function FeedBackButton() {
  let { pathname } = useLocation();
  const mode = import.meta.env.MODE;
  const redirectTo = () => {
    window.open('https://forms.gle/PQggPinJ7Zns8wGt5', '_blank');
  };
  return pathname !== '/signin' && mode !== 'staging' ? (
    <ConfigProvider
      theme={{
        token: {
          colorFillContent: 'orange',
          colorBgElevated: 'orange',
          colorPrimary: 'orange',
          colorText: '#fff',
        },
      }}
    >
      <FloatButton
        shape='circle'
        style={{ right: 24, background: 'orange' }}
        icon={<FeedbackIcon width='20px' height='20px' color='#fff' />}
        onClick={redirectTo}
        title='Feedback'
      />
    </ConfigProvider>
  ) : (
    ''
  );
}
