/** @format */

import { Popover } from 'antd';
import React, { useState } from 'react';

function LoadPopover(props) {
  const { content, children, placement, trigger, id, marginXS } = props;
  const [visible, setVisible] = useState(false);
  const handleVisibleChange = (visible) => {
    setVisible(visible);
  };

  return (
    <Popover
      id={id}
      placement={placement}
      content={content}
      trigger={trigger || 'click'}
      open={visible}
      onOpenChange={handleVisibleChange}
      marginXS={marginXS}
      {...props}
    >
      {children}
    </Popover>
  );
}

export default React.memo(LoadPopover);
