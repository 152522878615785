/** @format */

import React, { useEffect, useState } from 'react';
// import logo from 'assets/icons/logi-colored-lighter.svg';
import logo from 'assets/imgs/SidebarLogo2.png';
import { useAuth } from 'context';
import { useRequest, useSearch } from 'hooks';
import { useMutation } from '@tanstack/react-query';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { data } from 'sidebar-data';
import {
  Avatar,
  CustomPopover,
  ExpandRightIcon,
  Logo,
  LogoWrapper,
  MinimizeBtn,
  ReactMenu,
  Sidebar,
  User,
  UsersMenu,
} from './style';
import { deleteAllCookies, setCookie, getCookie } from 'formatter';
import avatar from 'assets/imgs/user-avatar2.png';
export const SidebarComponent = ({ setActivePath }) => {
  const [open, setOpen] = useState(true);
  const [
    { role, user, viewItems, sideBar, pageSize, isImageChanged, permission },
    dispatch,
  ] = useAuth();
  const [profileImage, setProfileImage] = useState(
    localStorage.getItem('profile-image')
  );
  const [visible, setVisible] = useState(false);
  const [items, setItems] = useState([]);
  const [activeKey, setActiveKey] = useState(window.location.pathname);
  const location = useLocation();
  const navigate = useNavigate();
  const { request } = useRequest();
  const query = useSearch();

  useEffect(() => {
    const size = getCookie('size') || 50;

    setCookie(
      'currentPath',
      `${location.pathname}?${
        location.search?.includes('size')
          ? 'size=' + (pageSize ? pageSize : size || '50')
          : ''
      }`,
      1
    );
  }, []);

  const goProfile = () => {
    navigate('/profile');
  };
  const openChat = () => {
    navigate('/chat');
    setVisible(false);
  };
  const getAnImage = useMutation({
    mutationFn: () =>
      getCookie('token') &&
      request({
        url: '/user/get-last-profile-image',
      }),
    onSuccess: (res) => {
      setCookie('profile-image', res?.data?.path ? res?.data?.path : avatar);
    },
  });

  useEffect(() => {
    if (profileImage !== getCookie('profile-image')) {
      getAnImage.mutate();
      setProfileImage(getCookie('profile-image'));
    }
  }, [profileImage]);

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === 'profile-image') {
        setProfileImage(getCookie('profile-image'));
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  const toggleSidebar = () => {
    if (open) {
      localStorage.setItem('openKeys', JSON.stringify([]));
    }
    setOpen(!open);
    dispatch({ type: 'setSideBar', payload: !sideBar });
    localStorage.setItem('sidebar', open);
  };

  // const { mutate } = useMutation({
  //   mutationFn: () =>
  //     localStorage.getItem('firebaseToken') &&
  //     request({
  //       url: `/v2/users/${user?.userId}/firebase-details/${localStorage.getItem(
  //         'firebaseToken'
  //       )}`,
  //       method: 'DELETE',
  //     }),
  // });
  //logout
  const onLogOut = () => {
    // mutate();
    dispatch({ type: 'logout' });
    deleteAllCookies();
    navigate('/signin');
    setCookie('path', '/signin', 1);
  };

  //poppup
  const handleVisibleChange = (visible) => {
    setVisible(visible);
  };

  useEffect(() => {
    if (location.pathname.includes('/main/')) {
      setActiveKey(['/main/*']);
    } else if (location.pathname.includes('/loads')) {
      setActiveKey(['/loads']);
    } else if (location.pathname.includes('/qc-board/performance/')) {
      setActiveKey(['/qc-board/performance/*']);
    } else if (location.pathname.includes('/qc-board/panel/')) {
      setActiveKey(['/qc-board/panel/*']);
    } else if (
      location.pathname.includes('/department/dispatcher/performance')
    ) {
      setActiveKey(['/department/dispatcher/performance/*']);
    } else if (location.pathname.includes('/accounting-department/expenses')) {
      setActiveKey(['/accounting-department/expenses']);
    } else if (location.pathname.includes('/tr-department/calendar')) {
      setActiveKey(['/tr-department/calendar']);
    } else if (location.pathname.includes('/insurance-department/changes/')) {
      setActiveKey(['/insurance-department/changes/*']);
    } else if (
      location.pathname.includes('/department/dispatcher/pre-planning/')
    ) {
      setActiveKey(['/department/dispatcher/pre-planning/*']);
    } else {
      setActiveKey(location.pathname);
    }
    return () => {};
  }, [location.pathname]);

  const getContants = useMutation({
    mutationFn: () =>
      getCookie('token') &&
      request({
        url: '/constants/values',
      }),
    onSuccess: (res) => {
      if (res?.success) {
        const data = res?.data;

        if (
          Object.prototype.hasOwnProperty.call(data, 'load_disabled_prev-day')
        ) {
          data.day_type = 'day';
        } else if (
          Object.prototype.hasOwnProperty.call(data, 'load_disabled_prev-week')
        ) {
          data.day_type = 'week';
        } else {
          data.day_type = 'week';
          data['load_disabled_prev-week'] = 1;
        }

        setCookie('constants', JSON.stringify({ ...data }));
      }
    },
  });
  useEffect(() => {
    if (Object.keys(permission).length !== 0) {
      getContants.mutate();
    }
  }, [query.get('modal-const-add'), query.get('modal-const-edit')]);

  const FileData = (name) => {
    if (
      getCookie('profile-image') !== undefined &&
      getCookie('profile-image') !== null
    ) {
      return (
        <div
          style={{
            borderRadius: '100%',
            overflow: 'hidden',
            width: '25px',
            height: '25px',
          }}
        >
          <UsersMenu.Icon
            width='25px'
            height='25px'
            src={
              getCookie('profile-image') !== undefined &&
              `${getCookie('profile-image')}`
            }
            alt='user-avatar'
            margin='0px'
          />
        </div>
      );
    } else {
      return <Avatar name={name} />;
    }
  };

  const content = (
    <UsersMenu>
      <UsersMenu.Item onClick={goProfile}>
        {FileData()} <span>Profile</span>
      </UsersMenu.Item>
      <UsersMenu.Item onClick={openChat}>
        <UsersMenu.Chat />
        <span>Chat</span>
      </UsersMenu.Item>
      <UsersMenu.Item>
        <UsersMenu.Setting />
        <span>Setting </span>
      </UsersMenu.Item>
      <UsersMenu.Item>
        <UsersMenu.Time /> <span>Activity log</span>
      </UsersMenu.Item>

      <UsersMenu.Item>
        <UsersMenu.Help /> <span>Help</span>
      </UsersMenu.Item>
      <UsersMenu.Divider />
      <UsersMenu.Item onClick={onLogOut}>
        <UsersMenu.Logout /> <span>Log out</span>
      </UsersMenu.Item>
    </UsersMenu>
  );

  useEffect(() => {
    localStorage.getItem('profile-image');
    return () => {};
  }, [isImageChanged]);

  const onMouseEnter = (e) => {
    const anchor = e.target;
    const url = new URL(anchor.href);
    const search = url.searchParams;

    if (search?.has('size')) {
      const size = getCookie('size') || 50;
      if (search.get('size') !== size) {
        search.set('size', size);
        anchor.setAttribute('href', url.href);
      }
    }
  };

  function getItem({ title, pathname, Icon, child, type, search }) {
    return {
      key: pathname,
      icon: Icon ? (
        <Icon
          className='icon'
          active={location.pathname.includes(pathname)?.toString()}
        />
      ) : null,
      children: child,
      label: (
        <>
          <NavLink
            to={`${pathname}${search}`}
            onMouseEnter={onMouseEnter}
            tabIndex={-1}
          />
          {title}
        </>
      ),
      type,
    };
  }

  useEffect(() => {
    let sidebarData =
      viewItems &&
      data
        ?.filter(({ hidden, title }) => !hidden && viewItems?.[title]?.view)
        ?.map((i) =>
          getItem({
            ...i,
            child: i?.child?.length
              ? i?.child
                  ?.filter(
                    ({ hidden, title }) =>
                      !hidden && viewItems?.[i?.title]?.[title]?.view
                  )
                  ?.map((ch) =>
                    getItem({
                      ...ch,
                      pathname: `${i?.pathname}${ch?.pathname}`,
                      child: ch?.child?.length
                        ? ch?.child
                            ?.filter(
                              ({ hidden, title }) =>
                                !hidden &&
                                viewItems?.[i?.title]?.[ch?.title]?.[title]
                                  ?.view
                            )
                            ?.map((sch) =>
                              getItem({
                                ...sch,
                                pathname: `${i?.pathname}${ch?.pathname}${sch?.pathname}`,
                                child: null,
                              })
                            )
                        : null,
                    })
                  )
              : null,
          })
        );
    setItems(sidebarData);

    return () => {};
  }, [viewItems]);

  const handleClick = ({ key }) => {
    let expDate = getCookie('expireDate');
    setCookie('path', key, expDate);
    setActivePath([key]);
    setActiveKey([key]);
  };
  useEffect(() => {
    setCookie('path', activeKey);
    setCookie('currentPath', activeKey);

    return () => {};
  }, [activeKey]);

  return (
    <Sidebar open={open} tabIndex={-1}>
      <LogoWrapper close={open.toString()}>
        <Logo onClick={toggleSidebar} close={open.toString()}>
          <img
            src={logo}
            alt='cargo prime logo'
            loading='lazy'
            width='143px'
            height='34px'
          />
        </Logo>
        <MinimizeBtn onClick={toggleSidebar} close={open.toString()}>
          <MinimizeBtn.Img />
        </MinimizeBtn>
      </LogoWrapper>
      <ReactMenu
        theme='light'
        inlineCollapsed={!open}
        mode='inline'
        defaultSelectedKeys={getCookie('currentPath') ?? getCookie('path')}
        selectedKeys={activeKey}
        tabIndex={-1}
        items={items}
        onClick={(key) => handleClick(key)}
      />

      <div style={{ marginTop: 'auto', position: 'sticky', bottom: 0 }}>
        <User close={open.toString()}>
          <CustomPopover
            overlayInnerStyle={{ padding: 0 }}
            placement='rightBottom'
            content={content}
            trigger='click'
            open={visible}
            onOpenChange={handleVisibleChange}
          >
            <User.Box>
              <UsersMenu.Icon
                width='32px'
                height='32px'
                src={getCookie('profile-image') ?? avatar}
                alt='user-avatar'
                margin='0px'
              />
            </User.Box>

            <User.BoxContent close={open.toString()}>
              <User.BoxLeft>
                <div className='user'>{user?.nickname || user?.fullname}</div>
                <div className='role'>{role && role}</div>
              </User.BoxLeft>
              <User.BoxRight>
                <ExpandRightIcon />
              </User.BoxRight>
            </User.BoxContent>
          </CustomPopover>
        </User>
      </div>
    </Sidebar>
    // </MainWrapper>
  );
};

export default React.memo(SidebarComponent);
