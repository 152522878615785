import styled from 'styled-components';
import { AutoComplete } from '../../AntDesign';
export const AntAutoComplete = styled(AutoComplete)`
  box-sizing: border-box;
  width: 100%;
  width: ${({ width }) => width || '100% !important'};
  height: ${({ height }) => height || '27px'} !important;
  padding: 0px !important;
  margin: 0 !important;
  display: block;
  .ant-select-selector {
    align-items: center;
    border: none;
    margin: 0 !important;
    align-items: center;
    .ant-select-selection-search {
      inset-inline-start: inherit;
      inset-inline-end: inherit;
      display: flex;
      align-items: center;
    }
  }
  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }
  .ant-select-clear {
    top: 5px;
    inset-inline-end: -1px;
  }

  &.no-border {
    .ant-select-selector {
      border: none;
    }
  }
`;
