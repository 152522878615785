/** @format */

import styled from 'styled-components';
import { ReactComponent as download } from 'assets/icons/download.svg';
import { ReactComponent as zoomin } from 'assets/icons/zoom-in-line.svg';
import { ReactComponent as zoomout } from 'assets/icons/zoom-out-line.svg';
import { ReactComponent as rotateClockwise } from 'assets/icons/clockwise-rotate.svg';
import { ReactComponent as Right } from 'assets/icons/arrow-right-s-line.svg';
import { ReactComponent as Left } from 'assets/icons/arrow-left-s-line.svg';
import { ReactComponent as close } from 'assets/icons/close-line.svg';
import { ReactComponent as tickIcon } from 'assets/icons/tickIcon.svg';

import pdf from 'assets/icons/pdf-logo.png';
import htmlFile from 'assets/icons/file-html.png';
import mp3File from 'assets/icons/file-mp3.png';
import mp4File from 'assets/icons/file-mp4.png';
import movFile from 'assets/icons/file-mov.png';
import ppt from 'assets/icons/file-ppt.png';
import sqlFile from 'assets/icons/file-sql.png';
import zipFile from 'assets/icons/file-zip.png';
import txtFile from 'assets/icons/file-text.png';
import word from 'assets/icons/word-logo.png';
import excel from 'assets/icons/excel-logo.png';
import jsonFile from 'assets/icons/file-json.png';
import { Image, Modal, Popover } from '../../AntDesign';

const CustomMask = styled.div`
  width: ${({ width }) => (width ? width : '50px')};
  height: ${({ height }) => (height ? height : 'i50px')};
  display: flex;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  font-size: 16px;
  color: #fff;
  align-items: center;
  justify-content: center;
  z-index: 10;
  cursor: pointer;
`;

const Wrapper = styled.div`
  width: 100%;
  padding: ${({ title }) => (title?.length ? '14px 0px' : 0)};
  border-radius: 5px;
  background: #fff;
  .ant-modal {
    width: 1000px;
  }

  .ant-image-preview-operations-operation {
    margin-left: 12px;
    padding: 0 !important;
    cursor: pointer;
  }
`;

export const Tick = styled(tickIcon)`
  cursor: pointer;
`;

const FileWrapper = styled.div`
  width: ${({ width }) => width || '50px'};
  height: ${({ height }) => height || '50px'};
  position: relative;
  .uploading {
    position: absolute;
    pointer-events: none;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.6);
    font-size: 10px;
    color: white;

    .wrapper-btns {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &:hover {
    .uploading {
      opacity: 1;
      svg {
        opacity: 1;
      }
    }
  }
`;

const InputsBox = styled.div`
  display: flex;
  position: relative;
  /* width: 100%; */
  width: ${({ maxCount }) => maxCount && '108px'};
  flex-wrap: ${({ maxCount }) => (maxCount ? 'no-wrap' : 'wrap')};
  grid-column-gap: ${({ title }) => (title?.length ? '30px' : '')};
  overflow: ${({ maxCount }) => maxCount && 'hidden'};
  grid-row-gap: 14px;
  justify-content: ${({ right }) => (right ? 'flex-end' : 'flex-start')};

  .ant-image-preview-operations-operation {
    margin-left: 12px;
    padding: 0 !important;
    cursor: pointer;
  }

  .ant-image-mask-info {
    font-size: ${({ fontSize }) => fontSize};
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    grid-row-gap: 0;
    margin: 0;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 820px) {
    flex-direction: column;
  }
`;

const DownloadIcon = styled(download)`
  width: ${({ width }) => width || '25px'};
  height: ${({ width }) => width || '25px'};
  cursor: pointer;
  /* position: absolute; */
  /* top: -17px;
  right: 2px; */
`;
const ZoomIn = styled(zoomin)`
  width: ${({ width }) => width || '25px'};
  height: ${({ width }) => width || '25px'};
  cursor: pointer;
  path {
    fill: ${({ color }) => color};
  }
  padding: ${({ padding }) => padding};
  line-height: ${({ lineheight }) => lineheight};
  /* position: absolute; */
  /* top: -17px;
  right: 2px; */
`;
const ZoomOut = styled(zoomout)`
  width: ${({ width }) => width || '25px'};
  height: ${({ width }) => width || '25px'};
  cursor: pointer;
  path {
    fill: ${({ color }) => color};
  }
  padding: ${({ padding }) => padding};
  /* position: absolute; */
  /* top: -17px;
  right: 2px; */
`;
const RotateRight = styled(rotateClockwise)`
  width: ${({ width }) => width || '25px'};
  height: ${({ width }) => width || '25px'};
  cursor: pointer;
  path {
    fill: ${({ color }) => color};
  }
  padding: ${({ padding }) => padding};
  /* position: absolute; */
  /* top: -17px;
  right: 2px; */
`;
const Forward = styled(Right)`
  width: ${({ width }) => width || '25px'};
  height: ${({ width }) => width || '25px'};
  cursor: pointer;
  path {
    fill: ${({ color }) => color};
  }
  /* position: absolute; */
  /* top: -17px;
  right: 2px; */
`;
const Back = styled(Left)`
  width: ${({ width }) => width || '25px'};
  height: ${({ width }) => width || '25px'};
  cursor: pointer;
  path {
    fill: ${({ color }) => color};
  }
  /* position: absolute; */
  /* top: -17px;
  right: 2px; */
`;
const Close = styled(close)`
  width: ${({ width }) => width || '25px'};
  height: ${({ width }) => width || '25px'};
  cursor: pointer;
  path {
    fill: ${({ color }) => color};
  }
  /* position: absolute; */
  /* top: -17px;
  right: 2px; */
`;
const CloseIcon = styled(close)`
  z-index: 100;
  position: absolute;
  width: 18px;
  height: 18px;
  right: -10px;
  top: -10px;
  cursor: pointer;
  path {
    fill: ${({ color }) => color || 'red'};
  }
  /* position: absolute; */
  /* top: -17px;
  right: 2px; */
`;
const ButtonBox = styled.div`
  width: 120px;
  justify-content: space-between;
  display: flex;
  position: absolute;
  top: -17px;
  right: 30px;
`;
const DownloadIcon2 = styled(download)`
  width: ${({ width }) => width || '25px'};
  height: ${({ width }) => width || '25px'};
  cursor: pointer;
  path {
    fill: ${({ color }) => color};
  }
  padding: ${({ padding }) => padding};
  /* path {
    fill: white;
  } */
`;
const PreviewModal = styled(Modal)`
  width: 1000px;
  /* height: 650px; */
`;
const CustomImage = styled('img')`
  width: ${({ width }) => width}px;
  width: ${({ height }) => height}px;
  max-height: 100vh;
`;

const Container = styled.div`
  display: flex;
  grid-gap: 20px;
  padding: 10px;
`;

const Card = styled.div`
  display: ${({ trmoves }) => (!trmoves ? 'flex' : 'grid')};
  grid-template-columns: ${({ trmoves }) => (!trmoves ? '' : '1fr 1fr')};
  /* width: 225px; */
  position: relative;
  grid-column-gap: ${({ trmoves }) => (!trmoves ? '4px' : '30px')};
  max-width: 396px;
  height: ${({ imgHeight }) => (imgHeight ? imgHeight : '170px')};
  margin-top: ${({ trmoves }) => (!trmoves ? 0 : '15px')};
`;

const CurrentInfo = styled(Popover)``;

Card.Image = styled.div`
  height: ${({ imgHeight }) => (imgHeight ? imgHeight : '170px')};
  width: ${({ imgWidth }) => (imgWidth ? imgWidth : '120px')};
  border-radius: 4px;
  overflow: hidden;

  .ant-image {
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }
`;

Card.Content = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({ imgHeight }) => (imgHeight ? imgHeight : '170px')};

  overflow-y: auto;
  overflow-x: hidden;
  #inner {
    color: #017aff;
    text-transform: uppercase;
    font-weight: 700;
  }

  div.turncated {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
  }
`;

const Img = styled(Image)`
  position: relative;
  height: ${({ imgHeight }) => (imgHeight ? imgHeight : '170px')};
  width: ${({ imgWidth }) => (imgWidth ? imgWidth : '120px')};
  border-radius: 4px;
  overflow: hidden;
  object-fit: fill;

  .ant-image-preview-operations-operation {
    margin-left: 12px;
    padding: 0 !important;
    cursor: pointer;
  }
`;

const AntImage = styled(Image)`
  object-fit: cover;
  width: ${({ imgWidth }) => (imgWidth ? imgWidth : '35px')} !important;
  height: ${({ imgHeight }) => (imgHeight ? imgHeight : '35px')} !important;
  cursor: pointer;
  &:hover {
    opacity: 1 !important;
  }
`;
export const DownloadingSpinner = styled.img`
  width: 50px;
  height: 50px;
`;
const PdfReader = styled.div`
  background-image: url(${pdf});
  background-repeat: no-repeat;
  width: ${({ imgWidth }) => (imgWidth ? imgWidth : '150px')};
  height: ${({ imgHeight }) => (imgHeight ? imgHeight : '175px')};
  background-size: contain;
  margin-left: 6px;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;
const TextReader = styled.div`
  background-image: url(${txtFile});
  background-repeat: no-repeat;
  width: ${({ imgWidth }) => (imgWidth ? imgWidth : '150px')};
  height: ${({ imgHeight }) => (imgHeight ? imgHeight : '175px')};
  background-size: contain;
  margin-left: 6px;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;
const HtmlReader = styled.div`
  background-image: url(${htmlFile});
  background-repeat: no-repeat;
  width: ${({ imgWidth }) => (imgWidth ? imgWidth : '150px')};
  height: ${({ imgHeight }) => (imgHeight ? imgHeight : '175px')};
  background-size: contain;
  margin-left: 6px;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;
const PptReader = styled.div`
  background-image: url(${ppt});
  background-repeat: no-repeat;
  width: ${({ imgWidth }) => (imgWidth ? imgWidth : '150px')};
  height: ${({ imgHeight }) => (imgHeight ? imgHeight : '175px')};
  background-size: contain;
  margin-left: 6px;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;
const SqlReader = styled.div`
  background-image: url(${sqlFile});
  background-repeat: no-repeat;
  width: ${({ imgWidth }) => (imgWidth ? imgWidth : '150px')};
  height: ${({ imgHeight }) => (imgHeight ? imgHeight : '175px')};
  background-size: contain;
  margin-left: 6px;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;
const ZipReader = styled.div`
  background-image: url(${zipFile});
  background-repeat: no-repeat;
  width: ${({ imgWidth }) => (imgWidth ? imgWidth : '150px')};
  height: ${({ imgHeight }) => (imgHeight ? imgHeight : '175px')};
  background-size: contain;
  margin-left: 6px;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;
const Mp3File = styled.div`
  background-image: url(${mp3File});
  background-repeat: no-repeat;
  width: ${({ imgWidth }) => (imgWidth ? imgWidth : '150px')};
  height: ${({ imgHeight }) => (imgHeight ? imgHeight : '175px')};
  background-size: contain;
  margin-left: 6px;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;
const Mp4File = styled.div`
  background-image: url(${mp4File});
  background-repeat: no-repeat;
  width: ${({ imgWidth }) => (imgWidth ? imgWidth : '150px')};
  height: ${({ imgHeight }) => (imgHeight ? imgHeight : '175px')};
  background-size: contain;
  margin-left: 0px;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;
const MovFile = styled.div`
  background-image: url(${movFile});
  background-repeat: no-repeat;
  width: ${({ imgWidth }) => (imgWidth ? imgWidth : '150px')};
  height: ${({ imgHeight }) => (imgHeight ? imgHeight : '175px')};
  background-size: contain;
  margin-left: 0px;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;
const JsonReaderIcon = styled.div`
  background-image: url(${jsonFile});
  background-repeat: no-repeat;
  width: ${({ imgWidth }) => (imgWidth ? imgWidth : '150px')};
  height: ${({ imgHeight }) => (imgHeight ? imgHeight : '175px')};
  background-size: contain;
  margin-left: 6px;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;
const WordReader = styled.div`
  background-image: url(${word});
  background-repeat: no-repeat;
  width: ${({ imgWidth }) => (imgWidth ? imgWidth : '150px')};
  height: ${({ imgHeight }) => (imgHeight ? imgHeight : '175px')};
  background-size: contain;
  margin-left: 6px;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;
const ExcelReader = styled.div`
  background-image: url(${excel});
  background-repeat: no-repeat;
  width: ${({ imgWidth }) => (imgWidth ? imgWidth : '150px')};
  height: ${({ imgHeight }) => (imgHeight ? imgHeight : '175px')};
  background-size: contain;
  margin-left: 6px;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;
const InfoStyle = styled.div`
  display: flex;
  grid-gap: 5px;
  background-color: white;
  width: fit-content;
  max-width: 600px;
  flex-wrap: wrap;
  height: fit-content;
  position: relative;
`;

export {
  InfoStyle,
  Wrapper,
  InputsBox,
  DownloadIcon,
  DownloadIcon2,
  ZoomIn,
  ZoomOut,
  RotateRight,
  Forward,
  Back,
  Close,
  ButtonBox,
  PreviewModal,
  CustomImage,
  Container,
  Card,
  Img,
  CustomMask,
  AntImage,
  PdfReader,
  WordReader,
  ExcelReader,
  TextReader,
  HtmlReader,
  PptReader,
  SqlReader,
  ZipReader,
  Mp3File,
  Mp4File,
  MovFile,
  JsonReaderIcon,
  CurrentInfo,
  CloseIcon,
  FileWrapper,
};
