import styled from 'styled-components';
import { SwapRightOutlined } from '@ant-design/icons';

import { useSearch, useSetQuery } from 'hooks';

import { Input } from '../../AntDesign';

// filter input
const Filter = styled.div`
  display: flex;
  /* flex-direction: column; */
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  .ant-input-number-affix-wrapper input.ant-input-number-input {
    padding: 0;
    height: 27px;
  }
  .ant-input-number-group-addon {
    padding: 0;
  }
  .filters-wrapper {
    display: flex;
    background: #fff;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #d8d9dd;
    height: 29px;
    line-height: 33px !important;
    padding: 0 5px;
    overflow: hidden;
    input {
      border: none;
      outline: none;
      padding: 0;
    }
    .arrow {
      margin: 2px 10px 0 0;
    }
    & > div {
      height: 33px;
    }
    & .ant-input-number.ant-input-number-borderless {
      border: none !important;
      height: 29px !important;
      background: transparent !important;
      & .ant-input-number-input-wrap {
        line-height: 33px !important;
        height: 29px !important;
      }
      input {
        height: 29px !important;
        padding: 0 !important;
      }
    }
    span {
      padding: 0 5px;
    }
  }
`;

export const InputFilter = (key1, key2, placeholder, prefix, suffix) => {
  const setQuery = useSetQuery();
  const query = useSearch();

  const onChange = (value, name) => {
    if (value === '' || !value || value === null || value === 'null') {
      if (name === key1) {
        setQuery(key1, null);
      }
      if (name === key2) {
        setQuery(key2, '');
      }
    }
  };

  const onKeyDown = (event) => {
    if (event?.code === 'Enter' || event?.code === 'NumpadEnter') {
      let { name, value } = event.target;
      if (name === key1) {
        setQuery(key1, value);
      }
      if (name === key2) {
        setQuery(key2, value);
      }
    }
  };

  return (
    <Filter>
      <div className='filters-wrapper'>
        {prefix && <span>{prefix}</span>}
        <Input
          variant={false}
          controls={false}
          defaultValue={query.get(key1) || ''}
          onChange={(value) => onChange(value.target.value, key1)}
          onKeyDown={(value) => onKeyDown(value, key1)}
          height='26px'
          placeholder={placeholder}
          name={key1}
        />
        <div className='arrow'>
          <SwapRightOutlined />
        </div>
        <Input
          variant={false}
          controls={false}
          defaultValue={query.get(key2) || ''}
          onChange={(value) => onChange(value.target.value, key2)}
          onKeyDown={(value) => onKeyDown(value, key2)}
          height='26px'
          placeholder={placeholder}
          name={key2}
        />
        {suffix && <span>{suffix}</span>}
      </div>
    </Filter>
  );
};

export default InputFilter;
