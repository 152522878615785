/** @format */

import styled from 'styled-components';

export const InfoStyle = styled.div`
  background-color: white;
  width: 300px;
  min-height: 80px;
  height: 155px;
`;

InfoStyle.Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  ${'' /* height: 20px; */}
`;

InfoStyle.Text = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${({ name, fas, driver }) =>
    name ? '#007fba' : fas ? '#000' : driver ? '#777' : '#ACADAE'};
  font-size: 13px;
  font-weight: ${({ name, fas }) => (name ? 600 : fas ? 600 : 500)};
  padding: 1px 10px;
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : '20px')};
`;

InfoStyle.Span = styled.div`
  padding: 1px 10px;
  border-radius: 50px;
  font-weight: 500;
  background: ${({ stopType }) =>
    stopType === 'PICKUP'
      ? '#0db010'
      : stopType === 'DELIVERY'
      ? '#FF1749'
      : 'transparent'};
  font-size: 12px;
  color: #fff;
  /* width: ${({ trailer }) => trailer && 'fit-content'}; */
`;
