/** @format */

export const initialState = {
  total: 0,
  permission: {},
  departmentList: [],
  departmentTypes: [],

  refetch: Function.prototype,

  onSaveFn: Function.prototype,
  onCancelFn: Function.prototype,
  onStateChange: Function.prototype,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'setTotal':
      return { ...state, total: action.payload };
    case 'setPermission':
      return { ...state, permission: action.payload };
    case 'setDepartmentType':
      return { ...state, departmentTypes: action.payload };
    case 'setDepartmentList':
      return { ...state, departmentList: action.payload };
    case 'setRefetch':
      return { ...state, refetch: action.payload };
    // for asking before closing drawer
    case 'setOnSaveFn':
      return { ...state, onSaveFn: action.payload };
    case 'setOnCancelFn':
      return { ...state, OnCancelFn: action.payload };
    case 'setStateChange':
      return { ...state, stateChange: action.payload };
    default:
      return state;
  }
};
