/**
 * Adds a comma as a thousands separator to a given string or number.
 *
 * @param {string|number} str - The input string or number to format.
 * @returns {string} The formatted string with commas as thousands separators.
 *
 * @example
 * // Format a numeric string
 * const result = thousandsSeparatorByComa('1234567');
 * console.log(result); // "1,234,567"
 *
 * @example
 * // Format a number
 * const result = thousandsSeparatorByComa(1234567);
 * console.log(result); // "1,234,567"
 *
 * @example
 * // Handle a number with decimal places
 * const result = thousandsSeparatorByComa(1234567.89);
 * console.log(result); // "1,234,567.89"
 *
 * @example
 * // Handle a string with decimal places
 * const result = thousandsSeparatorByComa('1234567.89');
 * console.log(result); // "1,234,567.89"
 *
 * @example
 * // Handle a small number
 * const result = thousandsSeparatorByComa(123);
 * console.log(result); // "123"
 *
 * @example
 * // Handle a number less than 1000
 * const result = thousandsSeparatorByComa(999);
 * console.log(result); // "999"
 */

function thousandsSeparatorByComa(str) {
  if (str === undefined || str === null) {
    return '';
  }

  // Convert the input to a string and add thousands separators
  const thousandsSeparated = str
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return thousandsSeparated;
}

export { thousandsSeparatorByComa };
