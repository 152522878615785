/** @format */

import styled from 'styled-components';
import { Tabs } from '../../AntDesign';

export const Tab = styled(Tabs)`
  .ant-tabs-nav::before {
    display: none;
  }

  .ant-tabs-nav-list {
    width: 100%;
    display: flex;
    width: 750px;
    padding: 4px;
    align-items: flex-start;
  }

  .ant-tabs-tab {
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    grid-gap: 8px;
    flex: 1 0 0;
    border: none;
    border-radius: 4px;
    background-color: #f9fafb;
    box-shadow: none;
    width: 100%;
    color: #4b5563;

    &.ant-tabs-tab-active {
      color: #2563eb;
      background-color: #fff;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05),
        0px 1px 6px 0px rgba(0, 0, 0, 0.06);
    }
  }

  .ant-tabs-ink-bar {
    display: none;
  }
`;
