/** @format */

import { memo, useState } from 'react';

import { Divider } from '../../../../AntDesign';
import { Wrapper } from './style';
import Uploader from '../../../Uploader';

import { Toast } from 'modals';
import { useRequest, useQuery } from 'hooks';

const Truck = ({ item }) => {
  const [fileTypes, setFileTypes] = useState([]);
  const { request } = useRequest();

  const fetchDocs = useQuery(
    ['/doc-type/by_doc_type?by_doc_type=TRUCK'],
    () =>
      request({
        url: '/doc-type/by_doc_type?by_doc_type=TRUCK',
        method: 'GET',
      }),
    {
      onSuccess: (data) => {
        if (data?.success) {
          setFileTypes(data?.dataList);
        }
      },
      onError: (err) =>
        Toast({
          type: 'error',
          message: err?.message,
        }),
    }
  );

  return (
    <Wrapper>
      <Wrapper.Box>
        {item?.truckNumber ? (
          <Wrapper.Content>
            <div>Truck number</div>
            <div className='value'>{item?.truckNumber || 'N/A'}</div>
          </Wrapper.Content>
        ) : null}
        {item?.truckVin ? (
          <Wrapper.Content>
            <div>Vin number</div>
            <div className='value'>{item?.truckVin || 'N/A'}</div>
          </Wrapper.Content>
        ) : null}
        {item?.plateNumber ? (
          <Wrapper.Content>
            <div>Plate number</div>
            <div className='value'>{item?.plateNumber || 'N/A'}</div>
          </Wrapper.Content>
        ) : null}
        {item?.truckYear ? (
          <Wrapper.Content>
            <div>Truck Year</div>
            <div className='value'>{item?.truckYear || 'N/A'}</div>
          </Wrapper.Content>
        ) : null}
        {item?.truckMake ? (
          <Wrapper.Content>
            <div>Truck make</div>
            <div className='value'>{item?.truckMake || 'N/A'}</div>
          </Wrapper.Content>
        ) : null}
        {item?.model ? (
          <Wrapper.Content>
            <div>Model</div>
            <div className='value'>{item?.model || 'N/A'}</div>
          </Wrapper.Content>
        ) : null}
        {item?.truckCompany ? (
          <Wrapper.Content>
            <div>Company</div>
            <div className='value'>{item?.truckCompany || 'N/A'}</div>
          </Wrapper.Content>
        ) : null}
        {item?.truckOwnershipType ? (
          <Wrapper.Content>
            <div>Ownership type</div>
            <div className='value'>
              {item?.truckOwnershipType?.replaceAll('_', ' ') || 'N/A'}
            </div>
          </Wrapper.Content>
        ) : null}
        <Wrapper.Content>
          <div>Color</div>

          <Wrapper.Content className='value'>
            <Wrapper.Color
              color={item?.truckColor}
              title={item?.truckColor || 'N/A'}
            />
            {item?.truckColor || 'N/A'}
          </Wrapper.Content>
        </Wrapper.Content>
      </Wrapper.Box>

      <Divider />
      <Wrapper.Title>Truck documents</Wrapper.Title>

      <Uploader
        marginTop='0'
        fileTypes={fileTypes}
        isLoading={fetchDocs?.isLoading}
        data={{ attachments: item?.truckAttachments }}
        isHandleFind
      />
    </Wrapper>
  );
};

export default memo(Truck);
