/** @format */

import { useEffect } from 'react';
// import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';
import {
  deleteAllCookies,
  // refreshToken,
  getCookie,
  setCookie,
} from 'formatter';
import { useUserMe } from 'hooks';
import { dayjs, utc, duration } from 'day-js';
import { useAuth } from 'context';
dayjs.extend(duration);
dayjs.extend(utc);

const AuthManager = ({ setActivePath, children }) => {
  const [, dispatch] = useAuth();
  const getUserMe = useUserMe();
  const navigate = useNavigate();
  let tokenFromCookie = getCookie('token');
  // const expireDate = getCookie('tokenExpireDate');

  // const onIdle = () => {
  //   sessionStorage.clear();
  //   localStorage.clear();
  //   deleteAllCookies();
  //   dispatch({ type: 'logout' });
  //   navigate('/signin');
  // };

  // const onAction = () => {
  //   const currentTime = dayjs.utc();
  //   const expirationTime = dayjs.utc(expireDate);
  //   const twoHoursBeforeExp = expirationTime.subtract(10, 'minute');

  //   if (currentTime.isAfter(expirationTime)) {
  //     dispatch({ type: 'logout' });
  //     navigate('/signin');
  //   } else if (
  //     currentTime.isAfter(twoHoursBeforeExp) &&
  //     currentTime.isBefore(expirationTime)
  //   ) {
  //     refreshToken();
  //   }
  // };

  // const { getRemainingTime } = useIdleTimer({
  //   onIdle,
  //   onAction,
  //   debounce: 2000,
  //   timeout: 7200000,
  //   events: [
  //     'mousemove',
  //     'keydown',
  //     'wheel',
  //     'DOMMouseScroll',
  //     'mousewheel',
  //     'mousedown',
  //     'touchstart',
  //     'touchmove',
  //     'MSPointerDown',
  //     'MSPointerMove',
  //     'visibilitychange',
  //     'focus',
  //   ],
  //   name: 'idle-timer',
  // });

  // useEffect(() => {
  //   return () => {};
  // }, [getRemainingTime()]);
  useEffect(() => {
    if (!tokenFromCookie) {
      deleteAllCookies();
      localStorage.clear();
      navigate('/signin');
      dispatch({ type: 'logout' });
    } else {
      setCookie('path', location?.pathname);
      setCookie('usersTimeZone', dayjs().format());
      setActivePath(location?.pathname);
      setCookie('size', 50, 1);
      if (tokenFromCookie) {
        getUserMe?.mutate();
      }
    }
  }, []);

  return <>{children}</>;
};

export default AuthManager;
