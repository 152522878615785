/** @format */

import { useStableNavigate } from './useStableNavigate';
import { replaceParamsMultipleQueries } from './replaceParamsMultipleQueries';
export const useMultipleQueries = () => {
  const navigate = useStableNavigate();
  const setMultipleQueries = (queries) => {
    navigate(
      `${window.location.pathname}${replaceParamsMultipleQueries(queries)}`
    );
  };

  return setMultipleQueries;
};
