/** @format */
import '../polyfills.js';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ToastProvider from './components/Generic/ModalComponents/Toast/ToastProvider.jsx';
import './index.css';
import MainContextProvider from './context';
import Root from './root/index.jsx';
import StyleSheetManagerWithFilteredProps from './root/StyleSheet';
import 'leaflet/dist/leaflet.css';
// import { getCookie } from 'formatter';

// import * as Sentry from '@sentry/react';
// // Sentry configuration based on the environment
// const environment = import.meta.env.MODE;
// const dsnKey = import.meta.env.VITE_APP_SENTRY_DSN_KEY;

// // Define environments where Sentry should not be active
// const excludedEnvironments = ['production', 'pre-release'];

// if (excludedEnvironments.includes(environment)) {
//   Sentry.init({
//     dsn: dsnKey,
//     environment,
//     ignoreErrors: [401, 403, 404, 409],
//     replaysSessionSampleRate: 0.1, // Sample rate for session replays
//     replaysOnErrorSampleRate: 1.0, // Sample rate when an error occurs
//     integrations: [
//       Sentry.replayIntegration({
//         maskAllText: false,
//         blockAllMedia: false,
//       }),
//     ],
//     tracesSampleRate: 1.0,
//     beforeSend(event, hint) {
//       // Access the original error object
//       const error = hint.originalException;

//       if (error && shouldReportError(error)) {
//         return event;
//       }
//       // Return null to prevent sending the event to Sentry
//       return null;
//     },
//   });
// }
// function shouldReportError(error) {
//   if (!error.response) {
//     // If there's no response object, assume we should report the error
//     return true;
//   }

//   const { status, data } = error.response;
//   // Exclude specific status codes
//   if ([401, 403, 404, 409].includes(status)) {
//     return false; // Do not send these errors to Sentry
//   }

//   // Exclude errors with specific messages
//   const errorMessages = [
//     'Token Expired',
//     'Such Load Number is exists',
//     ' is exists',
//     'uncompressed assets',
//     'H.map is not a function.',
//     'Network Error',
//     'Unauthorized',
//     `Cannot read properties of undefined (reading 'location')`,
//     `t.apply is not a function`,
//     'Error: Login Failed: Your username or password is incorrect',
//     'Error undefined: Error: No response received from server: Error: Authentication token not found.',
//     'undefined: Error: No response received from server: Error: Authentication token not found.',
//   ];

//   let errorMessage = '';
//   if (typeof data === 'string') {
//     errorMessage = data;
//   } else if (typeof data === 'object' && data?.message) {
//     errorMessage = data?.message; // Assuming that the error message might be in data.message
//   }

//   if (errorMessages?.some((msg) => errorMessage?.includes(msg))) {
//     return false;
//   }

//   return true;
// }

// if (getCookie('username')) {
//   Sentry.setUser({
//     username: getCookie('username'), // Optional: Include if available
//   });
// }

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
  },
});
if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .getRegistrations()
    .then(function (registrations) {
      for (let registration of registrations) {
        registration.unregister();
      }
    })
    .catch(function (err) {
      // eslint-disable-next-line no-console
      console.error('Service worker unregister failed: ', err);
    });
}

ReactDOM.createRoot(document.getElementById('root')).render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <MainContextProvider>
        <StyleSheetManagerWithFilteredProps>
          <ToastProvider>
            <Root />
          </ToastProvider>
        </StyleSheetManagerWithFilteredProps>
      </MainContextProvider>
    </QueryClientProvider>
  </BrowserRouter>
);
