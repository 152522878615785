/** @format */

import React from 'react';
import { Tooltip } from '../../../AntDesign';
import { Container, WrapperTr } from './style';
import {
  ErrorCircleIconFilled,
  UploadIcon,
  IconList,
  IconGallery,
  TickCircleFilledIcon,
} from 'styledIcons';
import { dayjs } from 'day-js';

const Body = ({
  setItem,
  item,
  setId,
  setDrawerOpen,
  setGalView,
  attachments,
  editPermission,
}) => {
  function getExpirationStatus(expirationDate) {
    const today = dayjs();
    const expiration = dayjs(expirationDate);
    const daysUntilExpiration = expiration.diff(today, 'days');
    if (daysUntilExpiration > 30) {
      return {
        status: 'GOOD',
        icon: (
          <TickCircleFilledIcon color='#00CC0E' width='14px' height='14px' />
        ),
      };
    } else if (daysUntilExpiration > 10) {
      return {
        status: 'WARNING',
        icon: (
          <ErrorCircleIconFilled color='#FFC000' width='14px' height='14px' />
        ),
      };
    } else {
      return {
        status: 'EXPIRED',
        icon: <ErrorCircleIconFilled color='red' width='14px' height='14px' />,
      };
    }
  }
  return (
    <Container>
      <WrapperTr>
        <Container.Flex>
          {attachments?.length && attachments[0]?.expireDate ? (
            <div
              style={{
                minWidth: '15px',
                width: '15px',
                margin: '0 5px 0 0',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {
                getExpirationStatus(
                  attachments?.length && attachments[0]?.expireDate
                )?.icon
              }
            </div>
          ) : null}

          {attachments === undefined && item?.name
            ? item?.name + ' ( ' + 0 + ' )'
            : attachments !== undefined || attachments?.length <= 0
            ? item?.name + ' ( ' + attachments?.length + ' )'
            : item?.name + ' ( ' + attachments?.length + ' )'}
          {attachments?.length && attachments[0]?.expireDate ? (
            <Container.Title
              status={
                getExpirationStatus(
                  attachments?.length && attachments[0]?.expireDate
                )?.status
              }
            >
              {dayjs(attachments[0]?.expireDate) &&
                'Expiration date: ' +
                  dayjs(attachments[0]?.expireDate)?.format('DD.MM.YYYY')}
            </Container.Title>
          ) : null}
        </Container.Flex>
      </WrapperTr>

      <WrapperTr icons='true'>
        <div className='iconsWrapper'>
          {editPermission ? (
            <Tooltip
              zIndex={9999}
              placement='top'
              title={'Upload file'}
              color='blue'
            >
              <div>
                <UploadIcon
                  width='16px'
                  height='16px'
                  color='#374151'
                  margin='0 15px 0 0'
                  onClick={(e) => {
                    e.stopPropagation();
                    setItem(item);
                    setId(item?.id);
                    setDrawerOpen(true);
                  }}
                />
              </div>
            </Tooltip>
          ) : null}

          <Tooltip
            zIndex={9999}
            placement='top'
            title={'List view'}
            color='blue'
          >
            <div>
              <IconList
                width='16px'
                height='16px'
                color='#374151'
                margin='0 15px 0 0'
                onClick={(e) => {
                  e.stopPropagation();
                  setItem(item);
                  setGalView(false);
                }}
              />
            </div>
          </Tooltip>

          <Tooltip
            zIndex={9999}
            placement='top'
            title={'Gallery view'}
            color='blue'
          >
            <div>
              <IconGallery
                width='16px'
                height='16px'
                color='#374151'
                onClick={(e) => {
                  e.stopPropagation();
                  setGalView(true);
                }}
              />
            </div>
          </Tooltip>
        </div>
      </WrapperTr>
    </Container>
  );
};
export default React.memo(Body);
