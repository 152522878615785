/** @format */

import React from 'react';

const Drivers = React.lazy(() => import('./Drivers/Driver'));
const MedicalCertificate = React.lazy(() =>
  import('./Drivers/MedicalCertificate')
);
const Cdl = React.lazy(() => import('./Drivers/Cdl'));
const Employee = React.lazy(() => import('./Employees/Employee'));
const Office = React.lazy(() => import('./Employees/Office'));
const Department = React.lazy(() => import('./Employees/Department'));
const Team = React.lazy(() => import('./Teams/Team'));
const Dispatcher = React.lazy(() => import('./Teams/Dispatcher'));
const QcTeams = React.lazy(() => import('./Teams/Qc'));
const DriverOnBoarding = React.lazy(() => import('./Drivers/DriverOnBoarding'));
const DriversDaysOff = React.lazy(() => import('./Drivers/DriversDaysOff'));

const HrStatistics = React.lazy(() => import('./Statistics'));
const Users = React.lazy(() => import('./Users'));
const Roles = React.lazy(() => import('./Roles'));
const KanbanBoard = React.lazy(() => import('./KanbanBoard'));
const BlackList = React.lazy(() => import('./KanbanBlackList'));

export {
  Drivers,
  Employee,
  Office,
  Department,
  HrStatistics,
  Cdl,
  Users,
  Roles,
  MedicalCertificate,
  Team,
  Dispatcher,
  QcTeams,
  DriverOnBoarding,
  DriversDaysOff,
  KanbanBoard,
  BlackList,
};
