/** @format */

export const initialState = {
  page: 1,
  size: 50,
  total: 0,
  certificatesList: [],
  filtered: [],
  isLoading: false,
  reNewingData: {},
  refetch: {},
  selected: {},
  drawerOpen: false,
  permission: {},
  drawerOpenDriver: false,
  driverInfo: [],
  driverLoading: false,
  driverRefetch: () => {},
  driverId: '',
  saveFunction: () => {},
  onCancelFunction: () => {},
  stateChange: false,
  attachments: [],
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'setDocs': {
      if (action.own === 'true') {
        return {
          ...state,
          attachments: action.payload,
        };
      }

      let formattedData = action?.payload?.map((d) => {
        return {
          attachmentId: d?.attachmentId || d.uploadedFile?.attachmentId,
          date: d?.date || d?.uploadedFile?.date,
          docTypeId: d?.docTypeId || d?.uploadedFile?.docTypeId,
          docTypeName: d?.docTypeName || d?.uploadedFile?.docTypeName,
          extension: d?.extension || d?.uploadedFile?.extension,
          fileOriginalName:
            d?.fileOriginalName || d?.uploadedFile?.fileOriginalName,
          name: d?.name || d?.uploadedFile?.name,
          notes: d?.notes || d?.uploadedFile?.notes,
          path: d?.path || d?.uploadedFile?.path,
          size: d?.size || d?.uploadedFile?.size,
          createdAtDate: d?.createdAtDate,
          createdByNickname: d?.createdByNickname,
          note: d?.note,
          // uploadedFile: d?.uploadedFile || [],
        };
      });

      formattedData = state?.attachments?.length
        ? formattedData?.concat(state?.attachments)
        : formattedData;

      return {
        ...state,
        attachments: formattedData,
      };
    }
    case 'changePage':
      return { ...state, page: action.payload };
    case 'reNewData':
      return { ...state, reNewingData: action.payload };
    case 'setPermission':
      return { ...state, permission: action.payload };
    case 'setSize':
      return { ...state, size: action.payload };
    case 'setTotal':
      return { ...state, total: action.payload };
    case 'setCertificatesList':
      return { ...state, certificatesList: action.payload };
    case 'setLoading':
      return { ...state, isLoading: action.payload };
    case 'setFiltered':
      return { ...state, filtered: action.payload };

    case 'setDrawerOpen':
      return { ...state, drawerOpen: action.payload };
    case 'setRefetch':
      return { ...state, refetch: action.payload };
    case 'setSelected':
      return { ...state, selected: action.payload };
    case 'setDrawerOpenDriver':
      return { ...state, drawerOpenDriver: action.payload };
    case 'setDriverInfo':
      return { ...state, driverInfo: action.payload };
    case 'setDriverLoading':
      return { ...state, driverLoading: action.payload };
    case 'setDriverRefetch':
      return { ...state, driverRefetch: action.payload };
    case 'setDriverId':
      return { ...state, driverId: action.payload };
    case 'setSaveFunction':
      return { ...state, saveFunction: action.payload };
    case 'setOnCancelFunction':
      return { ...state, onCancelFunction: action.payload };
    case 'setStateChange':
      return { ...state, stateChange: action.payload };

    default:
      return state;
  }
};
