/** @format */

import styled from 'styled-components';
const PageWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: ${({ performance }) => performance && 'overlay'};
  margin: ${({ margin }) => margin || '0'};
  padding: ${({ padding }) => padding || '0 5px'};
`;

const TopBar = styled.div`
  width: 100%;
  min-height: ${({ height }) => height || '43px'};
  height: ${({ height }) => height || '43px'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e7ebf3;
  background: #fff;
  border-radius: 4px;
  position: ${({ isSticky }) => (isSticky ? 'sticky' : 'relative')};
  padding: ${({ nopadding, isSticky }) =>
    nopadding ? '0 5px 0 0' : isSticky ? '5px 0px 5px 5px' : '0 33px 0 12px'};
  z-index: ${({ isSticky }) => (isSticky ? 800 : 'unset')};
  top: ${({ isSticky }) => (isSticky ? 0 : 'unset')};
`;
const PerformanceTopFilter = styled.div`
  width: 100%;
  min-height: ${({ height }) => height || '80px'};
  height: ${({ height }) => height || '80px'};
  display: grid;
  grid-template-columns: ${({ length }) =>
    length ? `repeat(${length}, 1fr)` : '1fr 1fr 1fr 1fr 1fr'};
  grid-gap: 12px;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border-radius: 4px;
  position: relative;
  padding: ${({ nopadding }) => (nopadding ? '0 5px 0 0' : '0 33px 0 12px')};
`;

TopBar.Right = styled.div`
  display: flex;
  justify-content: space-between;
  width: fit-content;
  grid-gap: 15px;
`;
const TableWrapper = styled.div`
  overflow-x: hidden;
  width: ${({ width }) => (width ? width : ' 99.4%')};
  height: ${({
    height,
    withoutpagination,
    topless,
    performance,
    overlay,
    dispatch,
  }) =>
    height ||
    (withoutpagination == 'true'
      ? 'calc(100% - 43px)'
      : topless === 'true'
      ? '100%'
      : performance === 'true'
      ? 'calc(100% - 10px)'
      : dispatch === 'true'
      ? 'calc(100% - 103px)'
      : overlay === 'true'
      ? 'calc(100% - 78px)'
      : 'calc(100% - 85px)')};
  position: relative;
  &.has_status_bar {
    height: ${({ open, withoutpagination }) =>
      open
        ? `calc(100% - ${withoutpagination == 'true' ? '90px' : '132px'})`
        : `calc(100% - ${withoutpagination == 'true' ? '43px' : '85px'})`};
  }
  border-top: ${({ indispatch }) =>
    indispatch ? ' 1px solid #101828' : 'none'};
`;

const Actions = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: ${({ flex_direction }) => flex_direction || 'row'};
  justify-content: ${({ justify_content }) => justify_content || 'flex-start'};
  align-items: ${({ align_items }) => align_items || 'center'};
  gap: ${({ gap }) => gap || '2px'};
  padding: 7px 0;
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    > div {
      pointer-events: none;
    }
  }
`;

Actions.Action = styled.div`
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: ${({ color }) => color || '#04a6fb'};
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    > div {
      pointer-events: none;
    }
  }
`;
const InputLabel = styled('p')`
  width: 100%;
  text-align: left;
  font-family: var(--appPrimaryFont);
  margin: 0 0 4px 0;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: ${({ labelColor }) => (labelColor ? labelColor : ' #5c6470;')};
`;

const Turncated = styled.div`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '27px'};
  line-height: ${({ height }) => (height ? height : '27px')};
  gap: ${({ gap }) => gap || '10px'};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: ${({ text_align }) => text_align || 'left'};
  cursor: ${({ cursor }) => cursor || 'inherite'};
  span.driver {
    &:hover {
      color: blue;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

const AnchorDiv = styled.div`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '27px'};
  display: flex;
  align-items: ${({ align_items }) => align_items || 'center'};
  justify-content: ${({ justify_content }) => justify_content || 'start'};
  gap: ${({ gap }) => gap || '10px'};

  color: ${({ color }) => color || '#1890ff'};
  font-weight: ${({ font_weight }) => font_weight || 500};
  text-decoration: ${({ text_decoration }) => text_decoration || 'underline'};
  cursor: pointer;
  &.date {
    color: #313e37;
    text-decoration: none;
    font-family: 600px;
    &:hover {
      color: #1890ff !important;
      text-decoration: underline !important;
    }
  }
`;

const CellWrapper = styled.div`
  height: ${({ height }) => height || '27px'};
  width: 100%;
  display: ${({ display }) => display || 'flex'};
  flex-direction: ${({ flex_direction }) => flex_direction || 'row'};
  align-items: ${({ align_items }) => align_items || 'center'};
  justify-content: ${({ justify_content }) => justify_content || 'start'};
  gap: ${({ gap }) => gap || '10px'};
`;

const ColoredBox = styled.div`
  font-size: var(--appTableFontSize);
  border-radius: 3px;
  display: flex;
  width: ${({ width }) => width || '100%'};
  justify-content: center;
  align-items: center;
  cursor: pointer;

  background: ${({ status }) =>
    getStatusColors({ status }).bg_hover || getStatusColors({ status }).bg};

  &.daysoff {
    padding: 0 4px;
    width: fit-content;
    background-color: ${({ bg }) => bg || 'inherite'};
    height: 23px;
    gap: 5px;
    color: #fff;
  }
  &.status {
    padding: 0 5px;
    height: 100%;
    ${'' /* height: 22px; */}
    min-width: 100%;
    max-width: 110px;
    background-color: ${({ status }) => getStatusColors({ status }).bg};
    border: ${({ status }) => getStatusColors({ status }).brd};
    color: ${({ status }) => getStatusColors({ status }).text};
  }
  &.zone-color {
    height: 20px;
    min-width: 50px;
    max-width: 80px;
    background-color: ${({ status }) => status};
  }
  &.air {
    border-radius: 0;
    height: 100%;
    color: ${({ status }) => getStatusColors({ status }).text};
  }

  &.permit {
    height: 20px;
    padding: 7px 4px;
    min-width: 50px;
    max-width: 68px;
    line-height: 5px;
    background-color: ${({ status }) => getStatusColors({ status }).bg};
    border: ${({ status }) => getStatusColors({ status }).brd};
    color: ${({ status }) => getStatusColors({ status }).text};
  }
  &.expired_square {
    width: 12px;
    min-width: 12px;
    min-height: 12px;
    border-radius: 2px;
    background: ${({ status }) => getStatusColors({ status }).bg};
  }
  &.daysdiff {
    height: 20px;
    padding: 0px 4px;
    border-radius: 2px;
    font-size: 11px;
    font-weight: 600;
    color: ${({ status }) => getStatusColors({ status }).bg};
    background: inherit;
  }
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => width || '100%'};
  height: 26px;
  position: relative;
  padding: 4px 18px;
  background: ${({ type }) => getType(type)};
  color: #fff;
  border-radius: 20px;
  text-align: center;
  font-family: var(--appPrimaryFont);
  font-size: ${(props) => {
    return props.fontsize;
  }};
`;

const size = {
  small: '480px',
  medium: '768px',
  large: '1024px',
  // You can add more sizes as needed
};

const MediaQueries = {
  small: `(max-width: ${size.small})`,
  medium: `(max-width: ${size.medium})`,
  large: `(max-width: ${size.large})`,
  // Additional media queries can be defined here
};

export {
  PageWrapper,
  TopBar,
  TableWrapper,
  Actions,
  InputLabel,
  Turncated,
  AnchorDiv,
  CellWrapper,
  ColoredBox,
  getStatusColors,
  Box,
  PerformanceTopFilter,
  MediaQueries,
};

// declaration functions for styles
function getType(type) {
  type = type.toLowerCase();

  switch (type) {
    case 'active':
    case 'successful':
      return 'rgba(0, 178, 56, 0.8)';

    case 'inactive':
    case 'error':
      return 'rgba(255, 0, 0, 0.7)';
    default:
      return 'black';
  }
}
function getStatusColors({ status }) {
  let stringedStatus = typeof status === 'string' ? status : '';
  stringedStatus = stringedStatus
    .toString()
    ?.toLowerCase()
    .replaceAll(' ', '_')
    .replaceAll('-', '_'); // 'ab_cd_ef
  switch (stringedStatus) {
    case 'true':
    case 'transit':
    case 'active':
    case 'in_transit':
      return {
        bg: '#24538F',
        brd: '0.5px solid #24538F',
        text: '#ffffff',
      };
    case 'accident':
      return {
        bg: '#FF4068',
        brd: '1px solid #FF4068',
        text: '#ffffff',
      };
    case 'ready':
    case 'ready_1':
    case 'fixed':
      return {
        bg: '#48742C',
        brd: '0.5px solid #48742C',
        text: '#ffffff',
      };
    case 'ready_2':
    case 'request':
      return {
        bg: '#BB261A',
        brd: '0.5px solid #BB261A',
        text: '#ffffff',
      };
    case 'ready_3':
    case 'completed':
      return {
        bg: '#000000',
        brd: '0.5px solid #000000',
        text: '#ffffff',
      };
    case 'day':
    case 'DAY':
      return {
        bg: '#FFE5A0',
        brd: '0.5px solid #FFE5A0',
        text: '#000000',
      };
    case 'night':
    case 'NIGHT':
      return {
        bg: '#0A53A8',
        brd: '0.5px solid #0A53A8',
        text: '#ffffff',
      };
    case 'open':
    case 'damaged':
    case 'null':
      return {
        bg: 'rgba(255, 0, 0, 0.05)',
        brd: '0.5px solid #FF0000',
        text: '#FF0000',
      };
    case 'accident_closed':
      return {
        bg: '#00CC0E',
        brd: '0.5px solid #00CC0E',
        text: '#FFF',
      };
    case 'accident_open':
      return {
        bg: '#DC2626',
        brd: '0.5px solid #DC2626',
        text: '#FFF',
      };
    case 'no_next_load':
      return {
        bg: '#C14242',
        brd: '0.5px solid #C14242',
        text: '#fff',
      };
    case 'need_load':
      return {
        bg: '#CBE0BE',
        brd: '0.5px solid #CBE0BE',
        text: '#000',
      };
    case 'home':
    case 'vacation':
      return {
        bg: '#EDC9C4',
        brd: '0.5px solid #EDC9C4',
        text: '#000000',
      };
    case 'rest':
      return {
        bg: '#EA3323',
        brd: '0.5px solid #EA3323',
        text: '#fff',
      };
    case 'reserved':
    case 'resultant_need_fixed':
      return {
        bg: '#FFFE54',
        brd: '0.5px solid #FFFE54',
        text: '#000',
      };
    case 'planning':
      return {
        bg: 'rgba(4, 166, 251, 0.05)',
        brd: '0.5px solid #1893FF',
        text: '#1893FF',
      };

    case 'hold':
      return {
        bg: '#ffffff',
        brd: '0.5px solid #000',
        text: '#000',
      };
    case 'shop':
    case 'confirmed':
      return {
        bg: '#F19D60',
        brd: '0.5px solid #F19D60',
        text: '#000000',
      };

    case 'dispatched':
      return {
        bg: '#FBE5A2',
        brd: '0.5px solid ##FBE5A2',
        text: '#000000',
      };
    case 'recovered':
      return {
        bg: 'rgba(8, 92, 131, 0.05)',
        brd: '0.5px solid #085C83',
        text: '#085C83',
      };
    case 'cancelled':
    case 'rejected':
      return {
        bg: 'rgba(136, 0, 27, 0.05)',
        brd: '0.5px solid #88001B',
        text: '#88001B',
      };
    case 'has_next_load':
    case 'has_weekly_plan':
      return {
        bg: '#3FBF3F',
        brd: '0.5px solid #3FBF3F',
        text: '#fff',
      };

    case 'new':
      return {
        bg: 'rgba(236, 127, 49, 0.05)',
        brd: '0.5px solid #EC7F31',
        text: '#EC7F31',
      };
    //! DOT Report
    case 'open_dotreport':
      return {
        bg: 'rgba(0,100,0, .05)',
        brd: '0.5px solid darkgreen',
        text: 'darkgreen',
      };
    case 'closed_dotreport':
      return {
        bg: 'rgba(32, 116, 185, .05)',
        brd: '0.5px solid #2074B9',
        text: '#2074B9',
      };
    case 'data_qs_processing_dotreport':
      return {
        bg: 'rgba(255, 23, 73, .05)',
        brd: '0.5px solid #FF1749',
        text: '#FF1749',
      };
    case 'termination_dotreport':
      return {
        bg: 'rgba(117, 83, 246, .05)',
        brd: '0.5px solid #7553F6',
        text: '#7553F6',
      };
    case 'in_person_meeting_scheduled_dotreport':
      return {
        bg: 'rgba(0, 181, 51, .05)',
        brd: '0.5px solid #00B533',
        text: '#00B533',
      };

    case 'cdl_normal':
    case 'medical_normal':
    case 'employee_active':
    case 'not_expired':
    case 'contracted':
      return {
        bg: 'rgb(51,193,96)',
        brd: '1px solid rgb(51,193,96)',
        text: '#ffffff',
      };
    case 'cdl_warning':
    case 'expire_soon':
    case 'medical_warning':
      return {
        bg: '#FF9900',
        brd: '1px solid #FF9900',
        text: '#ffffff',
      };
    case 'applicant':
      return {
        bg: '#fcbe61cc',
        brd: '1px solid #fcbe61cc',
        text: '#000',
      };
    case 'on_list':
    case 'resultant_temporarily_fixed':
      return {
        bg: '#9cff98cc',
        brd: '1px solid #9cff98cc',
        text: '#015e27',
      };
    case 'terminated':
      return {
        bg: '#c20101cc',
        brd: '1px solid #c20101cc',
        text: '#ffffff',
      };
    case 'vocation':
      return {
        bg: '#2949ffcc',
        brd: '1px solid #2949ffcc',
        text: '#ffffff',
      };
    case 'cdl_expired':
    case 'medical_expired':
      return {
        bg: '#FF4068',
        brd: '1px solid #FF4068',
        text: '#ffffff',
      };
    case 'employee_inactive':
      return {
        bg: 'rgb(92,100,112)',
        brd: '1px solid rgb(92,100,112)',
        text: '#ffffff',
      };

    case 'employee_request':
      return {
        bg: 'rgb(255, 84, 0)',
        brd: '1px solid rgb(255, 84, 0)',
        text: '#ffffff',
      };

    // weekly_insp_status
    case 'weekly_insp_checked':
      return {
        bg: '#DCF5DE',
        brd: '1px solid #DCF5DE',
        text: '#00CC0E',
      };
    case 'weekly_insp_partially':
      return {
        bg: '#FFF9E5',
        brd: '1px solid #FFF9E5',
        text: '#fa9600',
      };
    case 'weekly_insp_not_checked':
      return {
        bg: 'rgba(255, 64, 104, 0.20)',
        brd: '1px solid rgba(255, 64, 104, 0.20)',
        text: '#FF4068',
      };
    case 'in_driver':
      return {
        bg: 'rgba(4, 166, 251, 0.1);',
        brd: '1px solid #1893FF',
        text: '#1893FF',
      };

    case 'not_redy':
      return {
        bg: '#fff8e1',
        brd: '1px solid rgba(136, 0, 27, 0.05);',
        text: '1px solid #88001B',
      };

    case 'false':
    case 'in_active':
      return {
        bg: 'rgba(114, 118, 142, 0.05)',
        brd: '1px solid #72768E',
        text: '#5C6470',
      };

    case 'expired':
      return {
        bg: 'rgba(255, 0, 0, 0.05)',
        brd: '1px solid #FF0000',
        text: '#FF0000',
      };

    case 'available':
      return {
        bg: '#E6F0FF',
        brd: '0.5px solid #0167FF',
        text: '#0167FF',
      };
    case 'otp':
    case 'otd':
      return {
        bg: '#DCF5DE',
        brd: '0.5px solid #00CC0E',
        text: '#00CC0E',
      };

    case 'tr':
      return {
        bg: 'rgba(0, 0, 0, 0.05)',
        brd: '0.5px solid #000000',
        text: '#000000',
      };
    case 'late':
    case 'can':
    case 'rej':
    case 'canceled':
    case 'broke_down':
      return {
        bg: 'rgba(255, 0, 0, 0.05)',
        brd: '0.5px solid #FF0000',
        text: '#FF0000',
      };

    case 'load_out':
    case 'need_to_check':
      return {
        bg: 'rgba(92, 100, 112, 0.05)',
        brd: '0.5px solid #5C6470',
        text: '#5C6470',
      };

    case 'loaded':
      return {
        bg: '#FFF9E6',
        brd: '0.5px solid #FFC000',
        text: '#FFC000',
      };

    case 'miss_loaded':
      return {
        bg: 'rgba(255, 23, 73, .05)',
        brd: '0.5px solid #FF1749',
        text: '#FF1749',
      };
    case 'rating_low_rate':
    case 'rating_low_gross':
    case 'rating_lr/lg':
      return {
        bg: 'rgba(255, 255, 255, 0)',
        brd: '0.5px solid #FF1749',
        text: '#FF1749',
      };

    case 'rating_well_done':
      return {
        bg: 'rgba(255, 255, 255, 0)',
        brd: '0.5px solid #00CC0E',
        text: '#00CC0E',
      };

    case 'rating_thank_you':
      return {
        bg: 'rgba(255, 255, 255, 0)',
        brd: '0.5px solid #1893FF',
        text: '#1893FF',
      };
    case 'gps_issue':
      return {
        bg: 'rgba(117, 83, 246, .05)',
        brd: '0.5px solid #7553F6',
        text: '#7553F6',
      };

    case 'tbd':
      return {
        bg: 'rgba(255, 192, 0, 0.04)',
        brd: '0.5px solid #FFC000',
        text: '#FFC000',
      };
    case 'with_driver':
      return {
        bg: '#909092',
        brd: '0.5px solid #909092',
        text: '#fff',
      };
    // Pickup Delivery status on QC board page
    case 'pu_del_late':
    case 'pu_del_cancel':
      return {
        bg: '#FFCFC9',
        brd: '1px solid #FFCFC9',
        text: '#313e47',
      };
    case 'pu_del_transit':
      return {
        bg: '#1893FF',
        brd: '1px solid #1893FF',
        text: '#ffffff',
      };
    case 'pu_del_del':
    case 'pu_del_otp':
    case 'pu_del_otd':
    case 'pu_del_on_time':
    case 'policy_active':
      return {
        bg: '#00CC0E',
        brd: '1px solid #00CC0E',
        text: '#fff',
      };

    case 'policy_inactive':
      return {
        bg: 'rgba(255, 0, 0, 0.7)',
        brd: '0.5px solid #FF0000',
        text: '#fff',
      };

    case 'policy_pending':
      return {
        bg: '#FFC000',
        brd: '0.5px solid #FFC000',
        text: '#fff',
      };
    case 'policy_canceled':
      return {
        bg: '#5C6470',
        brd: '1px solid #5C6470',
        text: '#ffffff',
      };
    case 'pu_del_removed':
      return {
        bg: '#E8EAED',
        brd: '1px solid #E8EAED',
        text: '#313e47',
      };

    case 'pu_del_hold':
      return {
        bg: '#FF9900',
        brd: '1px solid #FF9900',
        text: '#ffffff',
      };
    case 'pu_del_risky':
    case 'pu_del_wlb_late':
    case 'resultant_accident':
      return {
        bg: '#B10202',
        brd: '1px solid #B10202',
        text: '#ffffff',
      };
    case 'pu_del_empty_back':
    case 'pu_del_at_pu':
      return {
        bg: '#FF9900',
        brd: '1px solid #FF9900',
        text: '#ffffff',
      };
    case 'pu_del_wlb_otd':
    case 'pu_del_tbd':
    case 'pu_del_rest':
      return {
        bg: '#D4EDBC',
        brd: '1px solid #D4EDBC',
        text: '#313e47',
      };

    case 'pu_del_at_del':
      return {
        bg: '#FF9900',
        brd: '1px solid #FF9900',
        text: '#ffffff',
      };
    case 'pu_del_brok_recovered':
      return {
        bg: '#00CC0E',
        brd: '1px solid #00CC0E',
        text: '#ffffff',
      };

    // tracking status
    // gps task status
    // cron tasks status// permit expiration status
    // permit status
    // boolean status
    // fleet onboard type
    // fleet onboard status
    // resultant of maintenance
    // service status of maintenance
    // daysdiff_status of expiredDate
    // planning pm
    // current pm
    // citation
    case 'tracking_no_need':
      return {
        bg: '#E6E6E6',
        brd: '1px solid #E6E6E6',
        text: '#313e47',
      };
    case 'tracking_request':
      return {
        bg: '#FFE5A0',
        brd: '1px solid #FFE5A0',
        text: '#313e47',
      };
    case 'cron_started':
      return {
        bg: '#93B5C6',
        brd: '1px solid #93B5C6',
        text: '#ffffff',
      };
    case 'cron_stopped':
      return {
        bg: '#93B5C6',
        brd: '1px solid #93B5C6',
        text: '#ffffff',
      };
    case 'pm_re_scheduled':
      return {
        bg: '#9333EA',
        brd: '1px solid #9333EA',
        text: '#ffffff',
      };
    case 'daysoff_on':
      return {
        bg: '#ffffff',
        brd: '0.5px solid #e2ffe2',
        text: '#313e47',
      };
    // SUCCESS COLOR
    case 'success':
    case 'pm_not_due':
    case 'daysdiff_non_expired':
    case 'service_closed':
    case 'cron_success':
    case 'gps_success':
    case 'tracking_on':
    case 'permit_non_expired':
    case 'resultant_fixed':
    case 'daysdiff_normal':
    case 'daysdiff_not_expired':
    case 'pm_active':
    case 'fleet_onboard_done':
    case 'fleet_onboard_pickup':
    case 'boolean_true':
    case 'permit_active':
    case 'citation_closed':
      return {
        bg: '#00CC0E',
        brd: '1px solid #00CC0E',
        text: '#ffffff',
      };
    // FAILERU COLOR
    case 'failure':
    case 'pm_over_due':
    case 'service_pending':
    case 'fleet_onboard_drop':
    case 'cron_failed':
    case 'gps_failed':
    case 'tracking_off':
    case 'daysdiff_expired':
    case 'permit_expired':
    case 'service_open':
    case 'daysoff_off':
    case 'citation_open':
      return {
        bg: '#FF4068',
        brd: '1px solid #FF4068',
        text: '#ffffff',
      };
    // WARNING COLOR
    case 'warning':
    case 'pm_due':
    case 'daysdiff_warning':
    case 'daysdiff_expire_soon':
    case 'daysdiff_about_to_expire':
    case 'resultant_broke_down':
    case 'permit_about_to_expire':
    case 'fleet_onboard_tbd':
      return {
        bg: '#FF9900',
        brd: '1px solid #FF9900',
        text: '#ffffff',
      };
    // INFO COLOR
    case 'info':
    case 'pm_planning':
    case 'fleet_onboard_planning':
    case 'citation_on_process':
      return {
        bg: '#0167FF',
        brd: '1px solid #0167FF',
        text: '#ffffff',
      };
    // SPECIAL COLOR
    case 'special':
    case 'permit_no_driver':
    case 'citation_need_lawyer':
      return {
        bg: '#9333EA',
        brd: '1px solid #9333EA',
        text: '#ffffff',
      };
    // CUSTOM COLOR
    case 'custom':
    case 'pm_scheduled':
      return {
        bg: '#C084FC',
        brd: '1px solid #C084FC',
        text: '#ffffff',
      };
    // EXTRA COLOR
    case 'extra':
    case 'pm_shop':
      return {
        bg: '#FF7E29',
        brd: '1px solid #FF7E29',
        text: '#ffffff',
      };
    // NATURAL COLOR
    case 'neutral':
    case 'pm_inactive':
    case 'boolean_false':
    case 'permit_inactive':
    case 'daysoff_vocation':
      return {
        bg: '#5C6470',
        brd: '1px solid #5C6470',
        text: '#ffffff',
      };
    default:
      return {
        bg: '#ffffff',
        brd: '1px solidrgba(92, 100, 112, 0.6)',
        text: '#313e47',
      };
  }
}
