/**
 * Sets a cookie the a specified name, value, and expiration date.
 *
 * @param {string} cName - The name of the cookie.
 * @param {string} cValue - The value of the cookie.
 * @param {Date|number} expireDate - The expiration date of the cookie.
 *                                   Can be a Date object or a number representing days from now.
 * @return {boolean} True if the cookie was set successfully, false otherwise.
 *
 * @example
 * // Set a cookie with a name 'username', value 'JohnDoe', that expires in 7 days
 * setCookie('username', 'JohnDoe', 7);
 *
 * @example
 * // Set a cookie with a name 'session_id, value '123456789', with a specified expiration date
 * setCookie('session_id', '123456789', new Date('2024-12-31T23:59:59'));
 *
 * @example
 * Attempt to set a cookie with an invalid name
 * const result = setCookie('', 'value', 7);
 * console.log(result); // false
 *
 * @example
 * // Attempt to set a cookie with an invalid expiration date
 * const result = setCookie('name', 'value', 'invalid');
 * console.log(result); // false
 */

function setCookie(cName, cValue, expireDate) {
  // Validate inputs
  if (typeof cName !== 'string' || cName.length === 0) {
    return false;
  }

  if (typeof cValue !== 'string' || cValue.length === 0) {
    return false;
  }

  // Determine the expiration date
  let date;
  if (expireDate instanceof Date) {
    // if expirationDate is a Date object, use it
    date = expireDate;
  } else if (typeof expireDate === 'number') {
    // If expireDate is a number, treat it as days from now
    date = new Date(Date.now() + expireDate * 24 * 60 * 60 * 1000);
  } else {
    // Default to 10 hours from now if now valid expireDate is provided
    date = new Date(Date.now() + 10 * 60 * 60 * 1000);
  }

  // Append the expiration date to the cookie string
  let cookieString = `${cName}=${cValue}; path=/; expires=${date?.toUTCString()};`;

  // Optionally, you can add more cookie attributes like Secure and SameSite
  // cookieString += 'Secure;';
  // cookieString += 'SameSite=Strict;';

  // Set the cookie in the document
  document.cookie = cookieString;

  return true;
}

export { setCookie };
