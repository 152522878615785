/** @format */

export const TypeRenderer = ({ data }) => {
  return (
    <div
      style={{
        minHeight: '30px',
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        height: '100%',
      }}
      title={
        data?.pickupType?.replaceAll('_', ' ') |
        data?.pickupType?.replaceAll('_', ' ')
      }
    >
      {data?.pickupType?.replaceAll('_', ' ') +
        ' | ' +
        data?.dropType?.replaceAll('_', ' ')}
    </div>
  );
};
