/** @format */

import React from 'react';
import { SkeletonContainer } from './styled';

const Skeleton = ({ count, width, height, gap, radius }) => {
  return (
    <SkeletonContainer height={height} gap={gap}>
      {Object.keys(Array.apply(0, Array(count)))?.map((i) => (
        <SkeletonContainer.Element radius={radius} width={width} key={i} />
      ))}
    </SkeletonContainer>
  );
};

export default React.memo(Skeleton);
