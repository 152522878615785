/**
 * Formats a US or Uzbekistan local phone number string into a standardized format.
 *
 * @param {string} phoneNumberString - The input phone number string to be formatted.
 * @returns {object} An object containing the formatted phone number and its format.
 *
 * @example
 * // Format a US phone number
 * const result = formatUsLocalPhoneNumber('1234567890');
 * console.log(result); // { value: "(123)-456-7890", format: "usa" }
 *
 * @example
 * // Format an Uzbekistan phone number
 * const result = formatUsLocalPhoneNumber('998971234567');
 * console.log(result); // { value: "(99)-897-1234567", format: "uzb" }
 *
 * @example
 * // Handle an empty input
 * const result = formatUsLocalPhoneNumber('');
 * console.log(result); // { value: "", format: "" }
 *
 * @example
 * // Handle an invalid input
 * const result = formatUsLocalPhoneNumber('123');
 * console.log(result); // { value: "123", format: "invalid" }
 */
function formatUsLocalPhoneNumber(phoneNumberString) {
  // Check if phoneNumberString is provided
  if (!phoneNumberString) {
    return { value: '', format: '' };
  }

  // Remove non-digit characters
  const cleaned = phoneNumberString.replace(/\D/g, '');

  // Get the first 10 digits (maximum allowed for US or Uzbekistan numbers)
  const maxLengthCleaned = cleaned.substring(0, 10);

  // Check the length of the cleaned string
  if (maxLengthCleaned.length === 9) {
    // Check if it matches the format for Uzbekistan numbers
    const uzbMatch = maxLengthCleaned.match(/^(\d{2})(\d{3})(\d{4})$/);
    if (uzbMatch) {
      return {
        value: `(${uzbMatch[1]})-${uzbMatch[2]}-${uzbMatch[3]}`,
        format: 'uzb',
      };
    }
  } else if (maxLengthCleaned.length === 10) {
    // Check if it matches the format for US numbers
    const usMatch = maxLengthCleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (usMatch) {
      return {
        value: `(${usMatch[1]})-${usMatch[2]}-${usMatch[3]}`,
        format: 'usa',
      };
    }
  }

  // Return the original string with an "invalid" format
  return { value: phoneNumberString, format: 'invalid' };
}

export { formatUsLocalPhoneNumber };
