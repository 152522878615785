import { useContext } from 'react';
import { StableNavigateContext } from 'context';

export const useStableNavigate = () => {
  const navigateRef = useContext(StableNavigateContext);

  if (!navigateRef.current) {
    throw new Error('StableNavigate context is not initialized');
  }

  return navigateRef.current;
};
