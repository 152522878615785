/** @format */

import { useDedicatedLine } from 'context';
import { useMultipleQueries } from 'hooks';
import { Actions } from 'globalCSS';
export const ActionsRenderer = ({ data }) => {
  // context
  const [{ permission }] = useDedicatedLine();
  const { EDIT } = permission;
  // hooks
  const setMultipleQueries = useMultipleQueries();

  const handleEdit = (id) => {
    setMultipleQueries({
      'modal-name': 'dedicated-line-edit',
      id: id,
    });
  };

  return (
    <Actions>
      {EDIT && (
        <Actions.Action
          title='Feature is not ready yet'
          onClick={() => handleEdit(data?.id)}
        >
          Edit
        </Actions.Action>
      )}
    </Actions>
  );
};
