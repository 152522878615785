/** @format */

export const initialState = {
  page: 1,
  size: 50,
  total: 0,
  zoneList: [],
  selected: {},
  permission: {},
  meta: {},

  refetch: () => {},
  saveFunction: () => {},
  onCancelFunction: () => {},
  stateChange: false,
};
export const reducer = (state, action) => {
  switch (action.type) {
    case 'setSaveFunction':
      return { ...state, saveFunction: action.payload };
    case 'setOnCancelFunction':
      return { ...state, onCancelFunction: action.payload };
    case 'setStateChange':
      return { ...state, stateChange: action.payload };
    case 'changePage':
      return { ...state, page: action.payload };

    case 'setSize':
      return { ...state, size: action.payload };
    case 'setTotal':
      return { ...state, total: action.payload };
    case 'setZoneList':
      return { ...state, zoneList: action.payload };
    case 'setSelected':
      return { ...state, selected: action.payload };
    case 'setPermission':
      return { ...state, permission: action.payload };
    case 'setMeta':
      return { ...state, meta: action.payload };
    case 'setRefetch':
      return { ...state, refetch: action.payload };
    default:
      return state;
  }
};
